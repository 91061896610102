import {
  Box,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Cancel as CloseIcon } from "@mui/icons-material";
import ImageIcon from "@mui/icons-material/Image";
import ReadMoreLess from "../../../CustomComponents/ReadMoreLess";
import CustomTablePagination from "../../../CustomComponents/CustomTablePagination";
import { fileStorageServices } from "../../../../Redux/services/fileStorage.services";
import { useState } from "react";
import { getTimeDifference } from "../../../../utilities/ReusableFunctions";
import { CustomPreviewOutlinedIcon } from "../../../CustomComponents/Icons/IconButtons";

const ScreenShotComponent = ({ step, setPreviewShot }) => {
  const [loadingPreviewImage, setLoadingPreviewImage] = useState(false);
  const onClickPreviewScreenShot = async (e, stepData) => {
    e.stopPropagation();
    setLoadingPreviewImage(true);
    const fileObj = [{ fileProvider: stepData?.fileProvider, fileName: stepData?.fileName }];
    try {
      const base64Image = await fileStorageServices.getFileFromStorage(fileObj);
      setPreviewShot(base64Image);
    } catch (error) {
      console.log(error);
    }
    setLoadingPreviewImage(false);
  };

  return (
    <IconButton onClick={(e) => onClickPreviewScreenShot(e, step)} color="primary" size="small" className="overlaybase">
      {loadingPreviewImage ? <CircularProgress size={20} /> : <ImageIcon fontSize="small" />}

      <Box className="overlay overlayIcon" onClick={(e) => onClickPreviewScreenShot(e, step)}>
        <CustomPreviewOutlinedIcon title={"Preview"} />
      </Box>
    </IconButton>
  );
};

const highlightedStyle = {
  borderCollapse: "collapse",
  boxShadow: "0 0 10px #DCD4FB",
  border: "1px solid #DCD4FB",
  background: "#E7E9EB",
  transform: "scale(1.00022)",
  transition: "transform 0.2s, box-shadow 0.2s",
  elevation: "above",
  "& td": { padding: "0px 10px", borderLeft: "none !important" },
};

const statusStyles = {
  PASSED: { backgroundColor: "#ebfceb", color: "#4CAF50" },
  FAILED: { backgroundColor: "#FFEAEB", color: "#F9594B" },
  SKIPPED: { backgroundColor: "#e6f2f7", color: "#61C5F6" },
  DISABLED: { backgroundColor: "#e6f2f7", color: "#61C5F6" },
};

const StatusChip = ({ label }) => (
  <Chip size="small" className="custom-chip" label={label} sx={{ ...statusStyles[label], minWidth: 70 }} />
);

const useFilteredData = (data, status, page, rowsPerPage) => {
  return (
    data
      ?.filter((el) => status === "All" || el?.result === status)
      ?.slice((page - 1) * rowsPerPage, page * rowsPerPage) || []
  );
};

const ExecutionListOfSteps = ({
  page,
  rowsPerPage,
  setPage,
  setRowsPerPage,
  selectedRow,
  previewShot,
  setPreviewShot,
  // handleOnClickStep,
  exeInstanceResults,
  selStatus,
}) => {
  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(Number(event.target.value));
    setPage(1);
  };

  const filteredData = useFilteredData(exeInstanceResults?.testStepResultResponseDtoList, selStatus, page, rowsPerPage);

  return (
    <Grid item sm={12} className="border-right">
      {previewShot !== null ? (
        <Box sx={{ boxShadow: 5, mx: 1, p: 1 }} height={350} className="relative">
          <img src={previewShot} alt="Screenshot" height={"100%"} style={{ objectFit: "contain" }} width="100%" />
          <IconButton
            size="small"
            color="error"
            onClick={() => setPreviewShot(null)}
            className="CloseIcon preCloseIcon"
            sx={{ backgroundColor: "#fff" }}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      ) : (
        <>
          <TableContainer sx={{ height: 400 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                    <Typography variant="h6" color="primary">
                      #
                    </Typography>
                  </TableCell>
                  <TableCell width={"20%"} align="left">
                    STEP
                  </TableCell>
                  <TableCell width={"25%"} align="left">
                    TEST DATA
                  </TableCell>
                  <TableCell width={"15%"} align="left">
                    RESULT
                  </TableCell>
                  <TableCell width={"10%"} align="left">
                    SCREENSHOT
                  </TableCell>
                  <TableCell align="left">REMARKS</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.map((step, i) => {
                  const isSelected = selectedRow?.sequenceNumber === step?.sequenceNumber;
                  return (
                    <TableRow
                      key={`${step?.sequenceNumber}_${i}`}
                      selected={isSelected}
                      sx={{
                        ...(isSelected ? highlightedStyle : {}),
                        // cursor: "pointer",
                        ...(!isSelected && {
                          "&:hover": {
                            backgroundColor: "#F3F3F3",
                            transform: "scale(1.00022)",
                            transition: "transform 0.2s, box-shadow 0.2s",
                          },
                        }),
                      }}
                      // onClick={() => handleOnClickStep(step)}
                    >
                      <TableCell width={10} align="left">
                        <Box my={1} gap={0.2} className="v-center">
                          <Typography variant="bold" color="primary">
                            #
                          </Typography>
                          <Typography>{step?.sequenceNumber}</Typography>
                        </Box>
                      </TableCell>
                      <TableCell width={20} align="left">
                        {step?.testStepName}
                      </TableCell>
                      <TableCell align="left">
                        <ReadMoreLess maxLength={40} maxWidth="100%" text={step?.data || ""} />
                      </TableCell>
                      <TableCell align="left">
                        <Box className="v-center" gap={1}>
                          <StatusChip label={step?.result} />
                          <Typography sx={{ fontSize: "10px", fontWeight: "bold", opacity: 0.6 }}>
                            {getTimeDifference(step?.startedAt, step?.finishedAt)?.readableFormat}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell align="center">
                        {step.fileName && <ScreenShotComponent step={step} setPreviewShot={setPreviewShot} />}
                      </TableCell>
                      <TableCell align="left">
                        <ReadMoreLess maxLength={50} maxWidth="100%" text={step?.errorDetails || ""} />
                      </TableCell>
                    </TableRow>
                  );
                })}
                {filteredData.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      No data available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <CustomTablePagination
            currentPage={page}
            rowsLength={exeInstanceResults?.testStepResultResponseDtoList?.length}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </>
      )}
    </Grid>
  );
};

export default ExecutionListOfSteps;
