import { useMediaQuery } from "@mui/material";

const useDeviceType = (deviceType) => {
  const isLaptop = useMediaQuery("(min-width:1024px) and (max-width:1440px)");
  const isLargeLaptop = useMediaQuery("(min-width:1441px) and (max-width:1920px)");

  if (deviceType === "md") {
    return isLaptop;
  }

  if (deviceType === "lg") {
    return isLargeLaptop;
  }

  return false;
};

export default useDeviceType;
