import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";

const CustomDeleteModal = ({ loading, disabled, onClickDelete, handleClose }) => {
  const { t } = useTranslation();

  return (
    <Dialog className="dialogCus" open={true} onClose={handleClose}>
      <DialogTitle variant="h6" id="alert-dialog-title">
        {t("Are you sure want to delete ?")}
      </DialogTitle>
      <DialogActions>
        <Button variant="outlined" color="error" size="small" onClick={handleClose}>
          {t("No")}
        </Button>
        <LoadingButton
          variant="outlined"
          size="small"
          onClick={onClickDelete}
          autoFocus
          disabled={disabled}
          className="loader-button"
          loading={loading}
          startIcon={<></>}
          loadingPosition="start">
          {t("Yes")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CustomDeleteModal;
