import { Box, Dialog, DialogContent, IconButton, Typography } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

import CustomReactAgGrid from "../../CustomComponents/CustomReactAgGrid";
import CustomButton from "../../CustomComponents/CustomButtons/CustomButton";
import { useSelector } from "react-redux";
import { testCaseListHeadCells } from "../../TestCases/testCaseListHeadCells";
import { useCallback, useRef } from "react";
import SkeletonLoader from "../../SkeletonLoader";

const AddTestCases = ({ selectedTestCases, handleAddTestCases, handleShowAddTestCases }) => {
  const gridRef = useRef();

  const onClickAddTestcases = useCallback(() => {
    const gridApi = gridRef.current?.api;
    if (gridApi) {
      const selectedNodes = gridApi.getSelectedNodes();
      const selectedData = selectedNodes.map((node) => node.data);
      handleAddTestCases(selectedData);
    }
  }, []);

  const { testcase_list: testCaseRows, testcase_list_loading: testConfigLoading } = useSelector(
    (state) => state.testcase,
  );

  return (
    <Dialog fullWidth maxWidth="lg" open onClose={handleShowAddTestCases}>
      <DialogContent className="flex column" sx={{ p: 0 }}>
        <Box p={1} className="space-between">
          <Typography variant="h6">Test Case List</Typography>
          <IconButton onClick={handleShowAddTestCases} size="small" color="error">
            <CancelIcon fontSize="small" />
          </IconButton>
        </Box>
        {testConfigLoading ? (
          <SkeletonLoader />
        ) : (
          <CustomReactAgGrid
            className="noBorderRadiusAggrid"
            gridRef={gridRef}
            columnDefs={testCaseListHeadCells}
            rowData={testCaseRows}
            rowHeight={52}
            selectedRowIds={selectedTestCases?.map((each) => each.testCaseId)}
          />
        )}

        <CustomButton className="no-shadow" onClick={onClickAddTestcases} sx={{ m: 1, ml: "auto" }}>
          Add Test Cases
        </CustomButton>
      </DialogContent>
    </Dialog>
  );
};

export default AddTestCases;
