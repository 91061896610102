import { Box, Divider, Typography } from "@mui/material";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import CustomVideoPlayer from "./CustomVideoPlayer";

const EngineHelpInfo = () => (
  <Box
    sx={{
      p: 3,
      width: "25%",
      position: "fixed",
      right: 0,
      top: 0,
      bottom: 0,
      overflowY: "auto",
      bgcolor: "background.paper",
      pt: "120px",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    }}>
    <Box className="v-center" gap={1} py={1}>
      <Typography variant="h6">?</Typography>
      <Divider orientation="vertical" flexItem />
      <Typography>Help Text</Typography>
    </Box>
    <Divider sx={{ mb: 1 }} />
    <Typography variant="body1" paragraph>
      This interface provides detailed information and control over your engines, allowing you to optimize and customize
      their use according to your testing needs. In this interface you can:
      <br />
      <b>View and manage your available engines.</b>
      <br />
      <b>See the engine name, user, OS, and number of linked devices.</b>
      <br />
      In addition to viewing these details, you can also link new devices to an engine or manage existing devices. This
      allows for streamlined configuration and efficient allocation of testing resources, ensuring your testing
      processes are well-organized and effectively managed.
    </Typography>

    <Box className="v-center" gap={1} pb={1}>
      <PlayCircleIcon color="primary" fontSize="small" />
      <Divider orientation="vertical" flexItem />
      <Typography>Help Video</Typography>
    </Box>
    <Divider sx={{ mb: 1 }} />
    <CustomVideoPlayer
      src="https://www.youtube.com/watch?v=qLmgX4kd-Sg&ab_channel=KairosTechnologies"
      type="video/mp4"
    />
    {/* <Typography variant="body1" paragraph>
      Consequat mauris nunc congue nisi vitae suscipit. Fringilla est ullamcorper eget nulla facilisi etiam dignissim
      diam. Pulvinar elementum integer enim neque volutpat ac tincidunt. Ornare suspendisse sed nisi lacus sed viverra
      tellus. Purus sit amet volutpat consequat mauris. Elementum eu facilisis sed odio morbi. Euismod lacinia at quis
      risus sed vulputate odio. Morbi tincidunt ornare massa eget egestas purus viverra accumsan in. In hendrerit
      gravida rutrum quisque non tellus orci ac. Pellentesque nec nam aliquam sem et tortor. Habitant morbi tristique
      senectus et. Adipiscing elit duis tristique sollicitudin nibh sit. Ornare aenean euismod elementum nisi quis
      eleifend. Commodo viverra maecenas accumsan lacus vel facilisis. Nulla posuere sollicitudin aliquam ultrices
      sagittis orci a.
    </Typography> */}
  </Box>
);

export default EngineHelpInfo;
