import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Box, Grid, Paper, TextField, Typography } from "@mui/material";
import { useDispatch } from "react-redux";

import InnerHeader from "../Layout/InnerHeader";
import { clearAllTestcaseData, setTestStepsData } from "../../Redux/reducers/testcaseSlice";
import { macrosServices } from "../../Redux/services/macros.services";
import CustomBackButton from "../CustomComponents/CustomButtons/CustomBackButton";
import CreateTestStepsContainer from "../TestCases/Create/CreateTestStepsContainer";
import SkeletonLoader from "../SkeletonLoader";

const validateForm = (inputs) => {
  const errors = {};
  if (!inputs.name) {
    errors.name = "Macro Name is required.";
  }

  return errors;
};

const CreateMacro = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const scrollRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const macroId = searchParams.get("macroId");
  const autId = searchParams.get("autId");
  const autName = searchParams.get("autName");
  const autType = searchParams.get("autType");
  const macroVersion = searchParams.get("macroVersion");

  const [formInputs, setFormInputs] = useState({
    name: "",
    description: "",
  });
  const [errors, setErrors] = useState({});

  const [loadingCreateMacro, setLoadingCreateMacro] = useState(false);
  const [loadingMacroDetails, setLoadingMacroDetails] = useState(false);

  useEffect(() => {
    setSearchParams((prev) => {
      const newState = new URLSearchParams(prev);
      newState.delete("stepViewType");
      return newState;
    });
    if (macroId) {
      fetchMacroDetailsById();
    }
    return () => {
      dispatch(clearAllTestcaseData());
    };
  }, []);

  const fetchMacroDetailsById = useCallback(async () => {
    setLoadingMacroDetails(true);
    try {
      const { data } = await macrosServices.getMacroById(macroId, macroVersion);
      setFormInputs({ name: data?.name, description: data?.description });
      dispatch(setTestStepsData(data?.testStepResponseList?.sort((a, b) => a.sequenceNumber - b.sequenceNumber)));
    } catch (error) {
      console.error("Error fetching macro details:", error);
    }
    setLoadingMacroDetails(false);
  }, [macroId, dispatch]);

  const handleCreateMacro = async (stepsData) => {
    const validationErrors = validateForm(formInputs);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      scrollToTop();
      return;
    }
    setLoadingCreateMacro(true);
    const payLoad = {
      version: macroVersion || null,
      name: formInputs.name,
      description: formInputs.description,
      autId: autId,
      testStepRequestList: stepsData,
      ...(macroId ? { id: macroId } : {}),
    };

    try {
      await (macroId ? macrosServices.updateMacro(payLoad) : macrosServices.createMacro(payLoad));
      navigate(`/macros?autName=${autName}&autId=${autId}&autType=${autType}`);
    } catch (error) {
      console.error("Error creating/updating macro:", error);
    }
    setLoadingCreateMacro(false);
  };

  const getInputLabelProps = () => (macroId ? { shrink: true } : undefined);

  const handleBack = () => {
    // navigate(-2);
    navigate(`/macros?autType=${autType}&autName=${autName}&autId=${autId}`);
  };

  const scrollToTop = () =>
    setTimeout(() => scrollRef.current.scrollIntoView({ behavior: "smooth", block: "start" }), 400);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormInputs((prev) => ({ ...prev, [name]: value }));
    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  return (
    <>
      <InnerHeader>
        <Box className="v-center" width="100%" sx={{ gap: 1 }}>
          <CustomBackButton onClick={handleBack}>Back</CustomBackButton>
          <Typography variant="h6">CREATE MACRO</Typography>
        </Box>
      </InnerHeader>
      <Box ref={scrollRef} className="pt74">
        <Paper sx={{ p: 2, mb: 2 }}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                name="name"
                value={formInputs.name}
                onChange={handleChange}
                label="Macro Name"
                InputLabelProps={getInputLabelProps()}
                size="small"
                fullWidth
                error={Boolean(errors.name)}
                helperText={errors.name}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="description"
                value={formInputs.description}
                onChange={handleChange}
                label="Description"
                InputLabelProps={getInputLabelProps()}
                size="small"
                fullWidth
              />
            </Grid>
          </Grid>
        </Paper>
        {loadingMacroDetails ? (
          <SkeletonLoader />
        ) : (
          <CreateTestStepsContainer loadingSaveButton={loadingCreateMacro} handleSaveTestCase={handleCreateMacro} />
        )}
      </Box>
    </>
  );
};

export default CreateMacro;
