import { useState, useRef, useEffect } from "react";
import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import EditOutlined from "@mui/icons-material/EditOutlined";
import { headCells } from "./Model";
import { CustomAgGrid } from "../AgGrid";
import InnerHeader from "../Layout/InnerHeader";
import CreateScheduledTest from "./CreateScheduledTest";
import KiTAPServer from "../../services/KiTAPServer";
import PreviewScheduledTest from "./PreviewScheduledTest";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { DeleteAlertPopup } from "../ReusableComponents/CustomAlerts";
import SkeletonLoader from "../SkeletonLoader";
import { useSearchParams } from "react-router-dom";

const ActionsCell = ({ data, colDef }) => {
  const [editScheduledData, setEditScheduledData] = useState({});
  const row = data;
  const handleClose = (resData) => {
    if (resData) {
      colDef?.setScheduledListData((prevState) => {
        return prevState?.map((each) => {
          if (each?.id === resData?.id) {
            return { ...each, ...resData };
          }
          return each;
        });
      });

      setEditScheduledData({});
    } else {
      setEditScheduledData({});
    }
  };
  const handlePreview = () => {
    colDef?.onClickPreview(row?.id);
  };
  const onClosePreview = async () => {
    setEditScheduledData(row);
    colDef?.closePreview();
  };

  return (
    <>
      <Tooltip title="Edit" placement="top-end" arrow>
        <IconButton color="success" onClick={onClosePreview} size="small">
          <EditOutlined />
        </IconButton>
      </Tooltip>
      <Tooltip title="Preview" placement="top-end" arrow>
        <IconButton onClick={handlePreview} size="small" color="secondary">
          <VisibilityOutlinedIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete" placement="top-end" arrow>
        <IconButton
          onClick={() => {
            colDef?.deleteOption(row?.id, "single");
          }}
          size="small"
          color="error">
          <DeleteForeverOutlinedIcon fontSize="12px" />
        </IconButton>
      </Tooltip>
      {Object.keys(editScheduledData).length > 0 && (
        <CreateScheduledTest defaultData={editScheduledData} handleClose={handleClose} />
      )}
    </>
  );
};

const ScheduledList = () => {
  const gridRef = useRef();
  const scrollRef = useRef();
  const [scheduledListData, setScheduledListData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [showCreateScheduledTest, setShowCreateScheduledTest] = useState(false);
  const [previewScheduledData, setPreviewScheduledData] = useState({});
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [delFun, setDelFun] = useState(false);
  const [alertTitle, setAlertTitle] = useState("");
  const [singleDeleteId, setSingleDeleteId] = useState(false);
  const [scheduleLoading, setScheduleLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const schedId = searchParams?.get("scheduleId");

  const autoScroll = () => {
    setTimeout(() => {
      scrollRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }, 600);
  };

  const onClickPreview = async (scheduledId) => {
    const { data } = await KiTAPServer.getScheduledTestDetails(scheduledId);
    setPreviewScheduledData(data);
    setSearchParams({ scheduleId: scheduledId });
    autoScroll();
  };
  const closePreview = () => {
    setPreviewScheduledData({});
  };
  const fetchScheduledList = async () => {
    setScheduleLoading(true);
    try {
      const { data } = await KiTAPServer.getScheduledList();
      setScheduledListData(data?.schedulerResponseList || []);
    } catch (error) {
      console.log(error);
    }

    setScheduleLoading(false);
  };
  const deleteOption = (rowId, type) => {
    if (type?.toLowerCase() === "multiple") {
      setDeleteAlertOpen(true);
      setAlertTitle("Are you sure! want to delete selected test cases?");
      setDelFun(true);
    } else {
      setDeleteAlertOpen(true);
      setAlertTitle("Are you sure! want to delete selected  test case?");
      setSingleDeleteId(rowId);
    }
  };
  const onClickDelete = async () => {
    setDeleteAlertOpen(false);
    setScheduledListData((prevState) => {
      return prevState;
    });
    await KiTAPServer?.deleteScheduledList(selected);
    const seledtedRows = scheduledListData?.filter((each) => !selected?.includes(each?.id));
    setPreviewScheduledData({});
    setScheduledListData(seledtedRows);
  };

  const singleDelete = async () => {
    const rowId = singleDeleteId;
    const sel = [];
    setDeleteAlertOpen(false);
    rowId && sel.push(rowId);
    if (sel?.length) {
      await KiTAPServer?.deleteScheduledList(sel);
      setScheduledListData((prevState) => prevState?.filter((each) => !sel?.includes(each?.id)));
    }
    setPreviewScheduledData({});
  };
  const action = {
    headerName: "Actions",
    sortable: false,
    cellRenderer: ActionsCell,
    lockPosition: "right",
    cellClass: "locked-col",
    setScheduledListData: setScheduledListData,
    onClickPreview: onClickPreview,
    closePreview: closePreview,
    deleteOption: deleteOption,
    suppressColumnsToolPanel: true,
    filter: false,
    headerTooltip: "Actions",
    gridRef: gridRef,
  };

  useEffect(() => {
    fetchScheduledList();
    if (schedId) {
      onClickPreview(schedId);
    }
    headCells[headCells.length - 1] = action;
  }, []);

  const handleCloseScheduledTest = (newRow) => {
    newRow && setScheduledListData((prevState) => [newRow, ...prevState]);
    setShowCreateScheduledTest(false);
  };

  return (
    <Box ref={scrollRef} sx={{ width: "100%" }}>
      <InnerHeader>
        <Typography variant="h6">SCHEDULED TESTS</Typography>
      </InnerHeader>
      <Box sx={{ width: "100%", mb: 2 }} className="pt74">
        {scheduleLoading && <SkeletonLoader />}
        <Box component="form" noValidate autoComplete="off">
          <Box className="createBtn">
            {selected?.length === 0 ? (
              <Button onClick={() => setShowCreateScheduledTest(true)} size="small" variant="contained">
                Create New Execution Schedule
              </Button>
            ) : (
              <Tooltip title="Delete">
                <IconButton size="small" onClick={() => deleteOption(null, "multiple")}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
          <CustomAgGrid
            gridRef={gridRef}
            headCells={headCells}
            setSelected={setSelected}
            rows={scheduledListData}
            columnsAutoFit={true}
          />
        </Box>
      </Box>

      {showCreateScheduledTest && <CreateScheduledTest handleClose={handleCloseScheduledTest} />}

      {Object.keys(previewScheduledData)?.length > 0 && (
        <PreviewScheduledTest previewScheduledData={previewScheduledData} />
      )}
      {deleteAlertOpen && (
        <DeleteAlertPopup
          open={deleteAlertOpen}
          setOpen={setDeleteAlertOpen}
          delFunction={delFun ? onClickDelete : singleDelete}
          alertTitle={alertTitle}
        />
      )}
    </Box>
  );
};

export default ScheduledList;
