/* eslint-disable no-mixed-operators */
import { memo, useCallback, useState } from "react";
import { VariableSizeList as List } from "react-window";
import { TableBody } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { setSelectedStepsData } from "../../../../Redux/reducers/testcaseSlice";
import CustomTableRow from "./CustomTableRow";
import { macrosServices } from "../../../../Redux/services/macros.services";
const newStep = { id: null, name: "drop" };
const Row = memo(({ index, style, data }) => {
  const {
    page,
    rowsPerPage,
    rowData,
    formattedData,
    selectedMacroId,
    selectedStepsData,
    filteredStepActionData,
    stepViewType,
    setSelectedMacroId,
    handleCheckboxChange,
    handleMacroRow,
    onSelectUIElementsData,
    onChangeAction,
    onChangeDisabled,
    onChangeScreenShot,
    handleStepNameChange,
    handleDeleteStep,
    cloneStep,
    handleSubmitAdvanceSettings,
    moveRow,
    handleDropMacro,
  } = data;
  const isSelected = Boolean(
    selectedStepsData?.some((step) => step.sequenceNumber === formattedData[index].sequenceNumber),
  );
  return (
    <CustomTableRow
      index={(page - 1) * rowsPerPage + index}
      style={style}
      selectedMacroId={selectedMacroId}
      setSelectedMacroId={setSelectedMacroId}
      key={formattedData[index].sequenceNumber}
      isSelected={isSelected}
      row={formattedData[index]}
      rowData={rowData}
      filteredStepActionData={filteredStepActionData}
      isExpanded={stepViewType}
      moveRow={moveRow}
      handleDropMacro={handleDropMacro}
      handleCheckboxChange={handleCheckboxChange}
      handleMacroRow={handleMacroRow}
      onSelectUIElementsData={onSelectUIElementsData}
      onChangeAction={onChangeAction}
      onChangeDisabled={onChangeDisabled}
      onChangeScreenShot={onChangeScreenShot}
      handleStepNameChange={handleStepNameChange}
      handleDeleteStep={handleDeleteStep}
      cloneStep={cloneStep}
      handleSubmitAdvanceSettings={handleSubmitAdvanceSettings}
    />
  );
});

Row.displayName = "Row";

const TableRows = memo(({ page, rowsPerPage, setRowData, rowData, formattedData }) => {
  const [selectedMacroId, setSelectedMacroId] = useState(null);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const autType = searchParams.get("autType");
  const stepViewType = searchParams.get("stepViewType");

  const { selected_steps_data: selectedStepsData } = useSelector((state) => state.testcase);
  const { step_action_data: stepActionData } = useSelector((state) => state.testcase);
  const filteredStepActionData = stepActionData.filter((each) => each?.applicableAutTypes?.includes(autType));

  const handleCheckboxChange = useCallback(
    (event, index) => {
      const isChecked = event.target.checked;
      const newSelectedSteps = isChecked
        ? [...selectedStepsData, rowData[index]]
        : selectedStepsData.filter((step) => step.sequenceNumber !== rowData[index].sequenceNumber);
      dispatch(setSelectedStepsData(newSelectedSteps));
    },
    [dispatch, rowData, selectedStepsData],
  );

  const handleMacroRow = async (index) => {
    try {
      const { data } = await macrosServices.getMacroById(rowData[index]?.id, rowData[index]?.version);
      setRowData((prevData) => {
        const newData = [...prevData];
        newData[index] = { ...newData[index], steps: data?.testStepResponseList };
        return newData;
      });
      setSelectedMacroId(rowData[index]?.id);
    } catch (error) {
      console.log(error);
    }
    return true;
  };

  const onSelectUIElementsData = useCallback(
    (elData, index) => {
      setRowData((prevData) => {
        const newData = [...prevData];
        newData[index] = { ...newData[index], elementLocator: elData };
        return newData;
      });
    },
    [setRowData],
  );

  const onChangeAction = useCallback(
    (e, index) => {
      // const isRequired = filteredStepActionData?.find((each) => each?.actionCode === e.target.value)?.isElementRequired;
      setRowData((prevData) => {
        const newData = [...prevData];
        newData[index] = { ...newData[index], action: e.target.value };
        return newData;
      });
    },
    [filteredStepActionData, setRowData],
  );

  const onChangeDisabled = useCallback(
    (e, index) => {
      setRowData((prevData) => {
        const newData = [...prevData];
        newData[index] = { ...newData[index], isDisable: e.target.checked };
        return newData;
      });
    },
    [setRowData],
  );

  const onChangeScreenShot = useCallback(
    (e, index) => {
      setRowData((prevData) => {
        const newData = [...prevData];
        newData[index] = { ...newData[index], screenshot: e.target.checked };
        return newData;
      });
    },
    [setRowData],
  );

  const handleStepNameChange = useCallback(
    (e, index) => {
      setRowData((prevData) => {
        const newData = [...prevData];
        newData[index] = { ...newData[index], name: e.target.value };
        return newData;
      });
    },
    [setRowData],
  );

  const handleDeleteStep = useCallback(
    (index) => {
      setRowData((prevData) => {
        const newStepsData = [...prevData];
        newStepsData.splice(index, 1);
        const modifiedData = newStepsData.map((each, i) => ({ ...each, sequenceNumber: i + 1 }));

        return modifiedData;
      });
      selectedMacroId && setSelectedMacroId(null);
      selectedStepsData && dispatch(setSelectedStepsData([]));
      dispatch(setSelectedStepsData([]));
    },
    [setRowData, dispatch],
  );

  const cloneStep = useCallback(
    (selectedStepData) => {
      const cloneNumber = 1;
      const newStep = {
        ...selectedStepData,
        name: selectedStepData.name.includes("Cloned")
          ? `${selectedStepData.name.replace(/Cloned \(\d+\)$/, "")}Cloned (${cloneNumber})`
          : `${selectedStepData.name} Cloned (1)`,
        id: null,
        sequenceNumber: selectedStepData.sequenceNumber + 1,
      };

      setRowData((prevState) => {
        const newStepsData = [
          ...prevState.slice(0, selectedStepData.sequenceNumber),
          newStep,
          ...prevState.slice(selectedStepData.sequenceNumber),
        ].map((each, i) => ({ ...each, sequenceNumber: i + 1 }));

        return newStepsData;
      });
    },
    [setRowData],
  );

  const handleDropMacro = useCallback(
    (item, hoverIndex) => {
      const newStepsData = [...rowData];
      newStepsData.splice(
        hoverIndex,
        0,
        item.row?.steps ? item.row : { ...item.row, steps: [], sequenceNumber: hoverIndex + 1 },
      );
      setRowData(newStepsData.map((each, i) => ({ ...each, sequenceNumber: i + 1 })));
    },
    [rowData, setRowData],
  );

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const newStepsData = [...rowData];
      const [draggedStep] = newStepsData.splice(dragIndex, 1);
      newStepsData.splice(hoverIndex, 0, draggedStep);
      const modifiedData = newStepsData.map((each, i) => ({ ...each, sequenceNumber: i + 1 }));
      setRowData(modifiedData);
    },
    [rowData, setRowData],
  );

  const handleSubmitAdvanceSettings = useCallback(
    (data, index) => {
      setRowData((prevData) => {
        const newData = [...prevData];
        newData[index] = {
          ...newData[index],
          tdmAttributeType: data?.tdmAttributeType || "",
          tdmAttributeProperties: data?.tdmAttributeProperties || [],
        };
        return newData;
      });
    },
    [setRowData],
  );

  const getItemSize = useCallback(
    (index) => {
      const row = formattedData[index];
      return row?.steps && selectedMacroId ? 50 * (row?.steps.length + 1) : 50;
    },
    [selectedMacroId, formattedData],
  );
  const showDropHere = formattedData.length < 8 && stepViewType === "macros";

  const itemData = {
    page,
    rowsPerPage,
    rowData,
    formattedData: [...formattedData, ...(!showDropHere ? [] : [{ ...newStep, sequenceNumber: rowData.length + 1 }])],
    selectedMacroId,
    selectedStepsData,
    filteredStepActionData,
    stepViewType,
    setSelectedMacroId,
    handleCheckboxChange,
    handleMacroRow,
    onSelectUIElementsData,
    onChangeAction,
    onChangeDisabled,
    onChangeScreenShot,
    handleStepNameChange,
    handleDeleteStep,
    cloneStep,
    handleSubmitAdvanceSettings,
    moveRow,
    handleDropMacro,
  };

  return (
    <TableBody>
      <div key={`${selectedMacroId}-${page}`}>
        <List
          height={400}
          width={"100%"}
          itemCount={showDropHere ? formattedData.length + 1 : formattedData?.length}
          itemSize={getItemSize}
          itemData={itemData}>
          {Row}
        </List>
      </div>
    </TableBody>
  );
});

TableRows.displayName = "TableRows";

export default TableRows;
