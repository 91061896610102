import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const ResultLabelComponent = ({ title, value, spanProps, ...props }) => {
  const { t } = useTranslation();

  return (
    <Typography className="resultLabelTitle" {...props}>
      {t(title)} :{" "}
      <Typography sx={{ wordBreak: "break-all" }} className="resultLabelValue" component="span" {...spanProps}>
        {value}
      </Typography>
    </Typography>
  );
};

export default ResultLabelComponent;
