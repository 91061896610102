import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { showToast } from "../reducers/toastSlice";
import { ApiServerUrlV1 } from "../../constants";

const fetchTestCases = createAsyncThunk("testcase/fetchTestCases", async (autId, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.get(`${ApiServerUrlV1}/test-cases?autId=${autId}`);
    return response.data;
  } catch (error) {
    dispatch(showToast({ message: "Failed to fetch test cases", type: "error" }));
    return rejectWithValue(error.message);
  }
});

const fetchAgentTestCasesForAut = createAsyncThunk(
  "testcase/fetchAgentTestCasesForAut",
  async ({ agentId, autId }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`${ApiServerUrlV1}/test-cases/agent?agentId=${agentId}&autId=${autId}`);
      return response.data;
    } catch (error) {
      dispatch(showToast({ message: "Failed to fetch agent test cases for AUT", type: "error" }));
      return rejectWithValue(error.message);
    }
  },
);

const getAllActionData = createAsyncThunk("testcase/getAllActionData", async (_, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.get(`${ApiServerUrlV1}/actions`);
    return response.data;
  } catch (error) {
    dispatch(showToast({ message: "Failed to fetch agent test cases for AUT", type: "error" }));
    return rejectWithValue(error.message);
  }
});

const getElementLocatorDetails = createAsyncThunk(
  "testcase/getElementLocatorDetails",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`${ApiServerUrlV1}/element-locators`);
      return response.data;
    } catch (error) {
      dispatch(showToast({ message: "Failed to fetch element locator details for testcase", type: "error" }));
      return rejectWithValue(error.message);
    }
  },
);

const createTestCase = (testCase) => {
  return axios.post(`${ApiServerUrlV1}/test-cases`, testCase);
};

const updateTestCase = (testCase) => {
  return axios.put(`${ApiServerUrlV1}/test-cases`, testCase);
};

const getTestcaseDetailsById = (testCaseId, testCaseVersion) => {
  return axios.get(`${ApiServerUrlV1}/test-cases/${testCaseId}/${testCaseVersion}`);
};

const createTestData = (testData) => {
  return axios.post(`${ApiServerUrlV1}/test-data`, testData);
};

const updateTestData = (testData) => {
  return axios.put(`${ApiServerUrlV1}/test-data`, testData);
};
const getTestData = (testCaseId, version) => {
  return axios.get(`${ApiServerUrlV1}/test-data?testCaseId=${testCaseId}&&testCaseVersion=${version}`);
};

const getTestDataById = (dataId) => {
  return axios.get(`${ApiServerUrlV1}/test-data/${dataId}`);
};

const executeTestCase = (testCase, env) => {
  return axios.post(
    `${ApiServerUrlV1}/execution-configs?createConfig=false&execute=true&environmentId=${env}`,
    testCase,
  );
};

const getExecutionDetailsById = (exeId) => {
  return axios.get(`${ApiServerUrlV1}/executions/${exeId}`);
};
const getExeInstanceStepWiseResult = (exeId) => {
  return axios.get(`${ApiServerUrlV1}/test-result/${exeId}`);
};
// configs
const getExecutionConfigs = (autId) => {
  return axios.get(`${ApiServerUrlV1}/execution-configs?autId=${autId}`);
};

const createExecutionConfig = (config, env) => {
  return axios.post(`${ApiServerUrlV1}/execution-configs?createConfig=true&execute=false&environmentId=${env}`, config);
};

const updateExecutionConfig = (config, env) => {
  return axios.put(`${ApiServerUrlV1}/execution-configs?updateConfig=true&execute=false&environmentId=${env}`, config);
};

const getExecutionConfigDetails = (configId, version) => {
  return axios.get(`${ApiServerUrlV1}/execution-configs/${configId}/${version}`);
};

const getTestCaseExecutions = (testCaseId, startDate, endDate) => {
  return axios.get(
    `${ApiServerUrlV1}/executions/test-cases?testCaseId=${testCaseId}&fromDate=${startDate}&toDate=${endDate}`,
  );
};

const getDashBoardDetails = () => {
  return axios.get(`${ApiServerUrlV1}/dashboards`);
};

const getExecuteConfig = (id, version) => {
  return axios.post(
    `${ApiServerUrlV1}/executions/execution-config?executionConfigId=${id}&executionConfigVersion=${version}`,
  );
};
const deleteTestData = (data) => {
  return axios.delete(`${ApiServerUrlV1}/test-data`, { data });
};

const deleteTestConfigs = (data) => {
  return axios.delete(`${ApiServerUrlV1}/execution-configs`, { data });
};

const createJiraBug = (data) => {
  return axios.post(`${ApiServerUrlV1}/jira/defect`, data);
};

export const testcaseServices = {
  fetchTestCases,
  fetchAgentTestCasesForAut,
  createTestCase,
  updateTestCase,
  createTestData,
  updateTestData,
  getTestData,
  getTestDataById,
  getTestcaseDetailsById,
  getAllActionData,
  executeTestCase,
  deleteTestData,
  getExecutionDetailsById,
  getExeInstanceStepWiseResult,
  getExecutionConfigs,
  createExecutionConfig,
  updateExecutionConfig,
  getExecutionConfigDetails,
  getTestCaseExecutions,
  getDashBoardDetails,
  deleteTestConfigs,
  getElementLocatorDetails,
  getExecuteConfig,
  createJiraBug,
};
