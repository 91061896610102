import axios from "axios";
import { ApiServerUrlV1 } from "../../constants";

const getLocalEngineRegisterKey = () => {
  return axios.post(`${ApiServerUrlV1}/engines/key/local`);
};

const getAllDevicesList = () => {
  return axios.get(`${ApiServerUrlV1}/devices`);
};

const getListOfEngines = () => {
  return axios.get(`${ApiServerUrlV1}/engines`);
};

const addEngineDevices = (data) => {
  return axios.post(`${ApiServerUrlV1}/devices`, data);
};
const editEngineDevices = (data) => {
  return axios.put(`${ApiServerUrlV1}/devices`, data);
};
const deleteDevice = (data) => {
  return axios.delete(`${ApiServerUrlV1}/devices`, { data });
};

const linkDevices = (data) => {
  return axios.put(`${ApiServerUrlV1}/engines/map-devices`, data);
};
export const enginesServices = {
  getLocalEngineRegisterKey,
  getAllDevicesList,
  getListOfEngines,
  addEngineDevices,
  editEngineDevices,
  deleteDevice,
  linkDevices,
};
