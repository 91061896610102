import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Button, Typography } from "@mui/material";

// Import useForm and Controller from React Hook Form
import { useForm, Controller } from "react-hook-form";
import { testcaseServices } from "../../../Redux/services/testcase.services";
import { showToast } from "../../../Redux/reducers/toastSlice";
import { useDispatch } from "react-redux";

export default function JiraBugCreate({ setCreateBugData }) {
  const dispatch = useDispatch();
  // Initialize React Hook Form
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onClickCreate = async (data) => {
    const requestData = {
      fields: {
        project: {
          key: "KT",
        },
        summary: data?.summary,
        description: data?.description,
        issuetype: {
          name: "Bug",
        },
      },
    };
    try {
      await testcaseServices.createJiraBug(requestData);

      dispatch(showToast({ message: "Successfully Created", type: "success" }));
    } catch {
      dispatch(showToast({ message: "Failed to create defect in Jira. Please try again later.", type: "error" }));
    }

    setCreateBugData(false);
  };

  return (
    <Box p="16px">
      <Typography mb="8px" variant="h6">
        AUT (Jira Project):
      </Typography>
      <Typography>
        Issue Type: <b>Bug</b>
      </Typography>
      <Box
        component="form"
        sx={{ mt: "16px", "& .MuiFormControl-root": { marginBottom: "16px" } }}
        onSubmit={handleSubmit(onClickCreate)} // Use handleSubmit here
      >
        <Controller
          name="summary"
          control={control}
          rules={{ required: "Summary is required", minLength: 4 }}
          render={({ field }) => (
            <TextField
              {...field}
              required
              size="small"
              fullWidth
              label="Summary"
              variant="outlined"
              error={Boolean(errors.summary)}
              helperText={Boolean(errors.summary?.message)}
            />
          )}
        />
        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <TextField {...field} size="small" fullWidth label="Description" variant="outlined" multiline rows={4} />
          )}
        />
        <Box textAlign="right">
          <Button
            type="submit" // Add type="submit" to the button
            variant="contained"
            size="small"
            color="primary">
            Create
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
