import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement } from "chart.js";
import { Typography, Box } from "@mui/material";
import { summarizeTestData, ExecutionStatusCodes, truncateText } from "./ExecutionConstants";

// Register required components
ChartJS.register(ArcElement);

const TestResultsChart = ({ testcasesList }) => {
  const summary = summarizeTestData(testcasesList);

  const data = {
    labels: Object.values(ExecutionStatusCodes).map((code) => code.status),
    datasets: [
      {
        data: [summary.passed.count, summary.failed.count, summary.notExecuted.count],
        backgroundColor: Object.values(ExecutionStatusCodes).map((code) => code.color),
        hoverBackgroundColor: Object.values(ExecutionStatusCodes).map((code) => code.color),
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    cutout: "70%",
    maintainAspectRatio: false,
  };

  return (
    <Box className="testcase-donut-report-container">
      <Box className="relative center">
        <Box width="130px" height="150px">
          <Doughnut data={data} options={options} />
        </Box>
        <Box className="absolute center column">
          <Typography variant="h5">{summary?.total}</Typography>
          <Typography variant="body2">{summary?.total.length > 1 ? "Test Cases" : "Test Case"}</Typography>
        </Box>
      </Box>
      <Box className="flex column" marginLeft="10px">
        {Object.entries(summary).map(
          ([key, { count, statusCode }]) =>
            key !== "total" && (
              <Box
                key={key}
                className="v-center"
                marginBottom="5px"
                sx={{ color: ExecutionStatusCodes[statusCode]?.color }}>
                <Box width={11} height={11} bgcolor={ExecutionStatusCodes[statusCode]?.color} marginRight={0.5} />
                <Typography className="small-text">
                  {truncateText(ExecutionStatusCodes[statusCode]?.status, 7)} - {count} (
                  {Math.round((count / summary.total) * 100)}%)
                </Typography>
              </Box>
            ),
        )}
      </Box>
    </Box>
  );
};

export default TestResultsChart;
