import { useCallback, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";

import ListOfSteps from "./ListOfSteps";
import CustomLoadingButton from "../../../CustomComponents/CustomButtons/CustomLoadingButton";
import StepManagementContainer from "./StepManagementContainer";
import BulkEdit from "../BulkEdit/BulkEdit";
import FullScreenDialog from "./FullWidthScreen";
import { useLocation, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { CustomExpandIcon } from "../../../CustomComponents/Icons/IconButtons";

const styles = {
  noMargin: { m: "0px !important", p: 0 },
  accordion: {
    "& .Mui-expanded": { minHeight: "40px !important", m: 0 },
    "&:before": { display: "none" },
    border: "none",
    borderRadius: "12px !important",
    paddingRight: "5px",
    boxShadow: "none !important",
    px: "0px !important",
    color: "#64748B",
  },
  accordionSummary: {
    border: "none",
    p: 0,
    m: "0px !important",
    "& .MuiPaper-root": { m: "0px !important" },
    "& .MuiAccordionSummary-root": { minHeight: "40px", px: "0px !important" },
    "& .MuiAccordionSummary-content": { m: 0, py: 0, px: "0px !important" },
    "& .MuiAccordion-root": { p: 0, m: "0px !important" },
    "& .Mui-expanded": {
      pt: 0.5,
      alignItems: "center",
      m: "0px !important",
      p: 0,
      "& svg": { width: "auto" },
      transform: "none !important",
    },
  },
};

const CreateTestStepsContainer = ({
  loadingSaveButton = false,
  isExpanded = true,
  handleSaveTestCase = () => null,
  toggleAccordion,
}) => {
  const location = useLocation();
  const { pathname } = location;
  const [searchParams, setSearchParams] = useSearchParams();
  const { test_steps_data: testStepsData } = useSelector((state) => state.testcase);

  const [rowData, setRowData] = useState(testStepsData || []);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const testCaseId = Number.parseInt(searchParams.get("testCaseId"));
  const macroId = Number.parseInt(searchParams.get("macroId"));
  const stepViewType = Number.parseInt(searchParams.get("stepViewType"));
  const isBulkEdit = JSON.parse(searchParams.get("isBulkEdit"));
  const isFullScreen = JSON.parse(searchParams.get("isFullScreen"));

  const toggleFullScreenDialog = useCallback(() => {
    setSearchParams((prev) => {
      const newState = new URLSearchParams(prev);
      newState.set("isFullScreen", !isFullScreen);
      return newState;
    });
  }, [setSearchParams]);

  const renderComponent = () => (
    <>
      {/* <Box className="v-center" sx={{ p: 1, gap: 1 }}>
        <IconButton sx={{ ml: "auto" }} size="small">
          <InfoOutlinedIcon />
        </IconButton>
        {isBulkEdit && (
          <CustomButton
            sx={{ py: 0.2 }}
            onClick={handleAddStep}
            color="success"
            variant="outlined"
            startIcon={<AddOutlinedIcon />}>
            Add Step
          </CustomButton>
        )}
        <CustomLoadingButton>Export Steps</CustomLoadingButton>
        <CustomButton onClick={() => null} variant="contained" size="small" color="warning">
          Import Steps
        </CustomButton>
        <CustomLoadingButton>Download Template</CustomLoadingButton>
      </Box> */}

      <Grid container>
        {isBulkEdit ? (
          <BulkEdit
            rowData={rowData}
            page={page}
            rowsPerPage={rowsPerPage}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            setRowData={setRowData}
          />
        ) : (
          <ListOfSteps
            page={page}
            rowsPerPage={rowsPerPage}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            setRowData={setRowData}
          />
        )}
        <StepManagementContainer />
      </Grid>
    </>
  );

  const testCaseButton = testCaseId ? "Update Test Case" : "Create Test Case";
  const macroButton = macroId ? "Update Macro" : "Create Macro";

  return (
    <Box mt={1} className="container">
      <Accordion sx={styles.accordion} expanded={isExpanded}>
        <AccordionSummary
          sx={styles.accordionSummary}
          expandIcon={
            !pathname?.includes("macros") ? (
              <IconButton disabled={!testCaseId} onClick={toggleAccordion}>
                <Button
                  sx={{
                    variant: "outlined",
                    border: "1px solid",
                    padding: "4px 12px",
                    borderRadius: "4px",
                  }}>
                  {isExpanded ? "View Test Data" : "View Test Steps"}
                </Button>
              </IconButton>
            ) : (
              <></>
            )
          }>
          <Box className="space-between" sx={{ width: "100%", px: 1 }}>
            <Box className="v-center">
              {isExpanded ? (
                <CustomExpandIcon onClick={toggleFullScreenDialog} />
              ) : (
                <Typography variant="bold">
                  List of Steps -{" "}
                  <Typography variant="bold" sx={{ color: "#E68200" }} component="span">
                    [ {isBulkEdit ? rowData?.length : testStepsData?.length}]
                  </Typography>
                </Typography>
              )}
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={styles.noMargin}>
          {isFullScreen ? (
            <FullScreenDialog open={isFullScreen} setOpen={toggleFullScreenDialog}>
              {renderComponent()}
            </FullScreenDialog>
          ) : (
            renderComponent()
          )}
          <CustomLoadingButton
            disabled={loadingSaveButton || stepViewType === "add" || (!rowData?.length && !testStepsData?.length)}
            loading={loadingSaveButton}
            sx={{ float: "right", m: 1 }}
            onClick={() => handleSaveTestCase(isBulkEdit ? rowData : testStepsData)}>
            {!pathname?.includes("macros") ? testCaseButton : macroButton}
          </CustomLoadingButton>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default CreateTestStepsContainer;
