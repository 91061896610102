import { useState, Suspense, useMemo, useLayoutEffect } from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { setupAxiosInterceptors } from "./services/axios-interceptor";
import "./i18n";
import "./App.css";

import LicenseAgreement from "./components/LicenseAgreement";
import SessionTimeout from "./utilities/SessionTimeout";
import { idealTimeout } from "./constants";
import { ColorModeContext, useMode } from "./theme/theme";
import RoutesManager from "./RoutesManager";
import { SideBarContext } from "k-labs-kit";
import { useTranslation } from "react-i18next";
// import CustomChatBot from "./components/ChatBot";
import { useDispatch, useSelector } from "react-redux";
import CustomAlert from "./components/CustomComponents/CustomAlert";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { testcaseServices } from "./Redux/services/testcase.services";
import { authServices } from "./Redux/services/auth.services";
import { setUserDetails } from "./Redux/reducers/authSlice";
import { decryptData } from "./helpers/encryptionUtils";
import { showToast } from "./Redux/reducers/toastSlice";

const App = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [theme, colorMode] = useMode();
  const userDetails = useSelector((state) => state.auth.user_details);
  const [drawerWidth, setDrawerWidth] = useState(200);

  const contextValue = useMemo(() => ({ setDrawerWidth, drawerWidth }), [setDrawerWidth, drawerWidth]);
  const isULAAgreed = userDetails?.isULAAgreed || false;
  const token = userDetails?.Token || "";

  // const loadBotScript = () => {
  //   const script = document.createElement("script");
  //   script.src = "https://d3dqyamsdzq0rr.cloudfront.net/widget/chat/js/sia-ai-chat-widget-soks-global-v3.js";
  //   script.type = "text/javascript";
  //   document.head.appendChild(script);

  //   const container = document.createElement("div");
  //   container.id = "skilai-x2n331-widget-container";
  //   container.setAttribute("bot-lang", "en");
  //   container.setAttribute("bot-id", "s_5ea12b");
  //   container.setAttribute("bot-auth-key", "HYYIYBHSHJSDJSDJK");
  //   container.setAttribute("env", "qa");
  //   container.className = "skil-ai-v-css";
  //   document.body.appendChild(container);
  //   // setOpen(true)
  // };

  useLayoutEffect(() => {
    try {
      const encryptedUser = localStorage.getItem("kitapUser");
      const userData = encryptedUser ? decryptData(JSON.parse(encryptedUser)) : {};

      if (userData && !token) {
        dispatch(setUserDetails(userData));
      }

      setupAxiosInterceptors(userData, navigate, dispatch);

      if (token) {
        dispatch(testcaseServices.getAllActionData());
        dispatch(testcaseServices.getElementLocatorDetails());
      }
    } catch (error) {
      dispatch(showToast({ message: error?.response?.data?.message || error.message, type: "error" }));
    }
  }, [token]);

  const handleLogout = async () => {
    try {
      await authServices.logout(userDetails);
    } catch (error) {
      dispatch(showToast({ message: error?.response?.data?.message || error.message, type: "error" }));
    }
    localStorage.removeItem("kitapUser");
    dispatch(setUserDetails({}));
    navigate("/login");
  };

  const handleSessionTimeout = () => {
    try {
      if (userDetails?.SessionId) {
        dispatch(showToast({ message: t("error_msg_session_expired_message"), type: "error" }));
        handleLogout();
      }
    } catch (error) {
      dispatch(showToast({ message: error?.response?.data?.message || error.message, type: "error" }));
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Suspense fallback={null}>
            <SideBarContext.Provider value={contextValue}>
              <RoutesManager />
              {/* {userDetails?.Token && !checkAccess("customChatBot", "Hide") && <CustomChatBot />} */}
            </SideBarContext.Provider>
          </Suspense>

          {token && !isULAAgreed && <LicenseAgreement />}
          {token && <SessionTimeout timeout={idealTimeout} onTimeout={handleSessionTimeout} />}
          <CustomAlert />
        </ThemeProvider>
      </ColorModeContext.Provider>
    </DndProvider>
  );
};

export default App;
