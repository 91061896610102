import { Box } from "@mui/material";

import DeviceCard from "./DeviceCard";

const DevicesList = ({ devicesList, onClickEdit }) => {
  return (
    <Box sx={{ display: "grid", gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))", gap: 2 }}>
      {devicesList?.map((device, index) => (
        <DeviceCard key={index} device={device} onClickEdit={onClickEdit} isDeviceList={true} />
      ))}
    </Box>
  );
};

export default DevicesList;
