import axios from "axios";

// import { decryptData, encryptData } from "../helpers/encryptionUtils";

import {
  EXECUTION,
  EXECUTION2,
  DASHBOARD,
  DASHBOARD2,
  SUITE,
  SUITE2,
  EXECUTION3,
  DATA3,
  SUITE3,
  kitapBot,
  ApiServerUrlV1,
  ApiServerUrlV2,
  REPORTS,
  // ENABLED_FEATURES,
} from "../constants";

const headers = {
  accept: "*/*",
  userId: "mohan",
  "Content-Type": "application/json",
};

class KiTAPServer {
  getActionData() {
    return axios.get(`${ApiServerUrlV1}/actions`);
  }
  // aut apis
  // getListOfAutTypes() {
  //   return axios.get(`${ApiServerUrlV1}/auts/count`);
  // }
  getListOfAutAllAuts() {
    return axios.get(`${ApiServerUrlV2}/auts`);
  }
  // getListOfAuts(autType) {
  //   return axios.get(`${ApiServerUrlV2}/auts` + "?autType=" + autType);
  // }
  getAutById(autId) {
    return axios.get(`${ApiServerUrlV1}/auts/${autId}`);
  }

  // agent aut apis
  getListOfAgentAutTypes(agentId) {
    return axios.get(`${ApiServerUrlV1}/auts/agent/count?agentId=${agentId}`);
  }

  getListOfAgentAuts(autType, agentId) {
    return axios.get(`${ApiServerUrlV1}/auts/agent?autType=${autType}&agentId=${agentId}`);
  }

  // test suite apis
  saveTestSuite(testsuite) {
    const testsuiteJSON = JSON.stringify(testsuite);
    return axios.post(SUITE2, testsuiteJSON, { headers: headers });
  }

  getListofTestSuites(autId) {
    return axios.get(`${SUITE2}?autId=${autId}`);
  }
  getTestSuiteById(id) {
    return axios.get(`${SUITE2}/${id}`);
  }
  updateTestSuite(testsuite) {
    return axios.put(SUITE2, testsuite, { headers: headers });
  }
  deleteTestSuite(ids) {
    return axios.delete(SUITE2, { data: ids });
  }
  executeTestSuite(suiteId) {
    return axios.post(`${EXECUTION2}/test-suite?testSuiteId=${suiteId}`);
  }
  exportTestSuites(ids) {
    return axios.post(`${SUITE}/export`, ids, { responseType: "arraybuffer" });
  }

  // test case apis
  fetchTestCases(autId) {
    return axios.get(`${ApiServerUrlV2}/test-cases?autId=${autId}`);
  }

  getListOfTestCaseNames(autId) {
    return axios.get(`${ApiServerUrlV1}/test-cases/names?autId=${autId}`);
  }

  getTestCaseById(testCaseId) {
    return axios.get(`${ApiServerUrlV2}/test-cases/${testCaseId}`);
  }

  saveTestCase(testcase) {
    return axios.post(`${ApiServerUrlV2}/test-cases/low-code`, testcase);
  }

  updateTestCase(testcase) {
    return axios.put(`${ApiServerUrlV2}/test-cases/low-code`, testcase);
  }

  updateTestCaseAsNewVersion(testCaseId, testcase) {
    return axios.post(`${ApiServerUrlV1}/test-cases/low-code/new-version/${testCaseId}`, testcase, headers);
  }

  executeTestCases(data) {
    return axios.post(EXECUTION3, data);
  }
  deleteTestCase(testCaseIds) {
    return axios.delete(`${ApiServerUrlV1}/test-cases`, { data: testCaseIds });
  }

  getCloneTestCase(testCaseDetails) {
    return axios.post(`${ApiServerUrlV1}/test-cases/clone?id=${testCaseDetails.id}&version=${testCaseDetails.version}`);
  }
  exportTestCase(testCaseData) {
    return axios.get(
      `${ApiServerUrlV1}/test-cases/export-excel?id=${testCaseData.id}&version=${testCaseData.version}`,
      {
        responseType: "blob", // Ensure the response is treated as a binary file
      },
    );
  }

  importTestCase(formData, autId) {
    return axios.post(`${ApiServerUrlV1}/test-cases/import-excel?autId=${autId}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  // execution request apis
  abortExecution(ids) {
    return axios.put(`${EXECUTION}/abort-test-case`, ids);
  }
  getTestCaseExecutionReq(testCaseId) {
    return axios.get(`${EXECUTION}/test-case?testCaseId=${testCaseId}`);
  }
  deleteExecutionRequests(requestIds) {
    return axios.delete(`${EXECUTION}/test-case`, { data: requestIds });
  }

  // test data apis
  getTestData(testCaseId) {
    return axios.get(`${ApiServerUrlV2}/test-data?testCaseId=${testCaseId}`);
  }
  saveTestData(testData) {
    return axios.post(DATA3, testData);
  }
  updateTestData(testCaseData) {
    return axios.put(DATA3, testCaseData, headers);
  }
  deleteTestData(testDataIds) {
    return axios.delete(`${ApiServerUrlV2}/test-data`, { data: testDataIds });
  }

  // key Generate
  getGenerateKey() {
    return axios.post(`${ApiServerUrlV1}/registration-keys`);
  }

  // AGENT APIS
  getListOfAgents() {
    return axios.get(`${ApiServerUrlV1}/agents`);
  }

  // apis for locator auto population
  openBrowserInstance(data) {
    return axios.post(`${ApiServerUrlV1}/locator/start`, data);
  }
  closeBrowserInstance() {
    return axios.post(`${ApiServerUrlV1}/locator/stop`);
  }

  executeAgentAut(testCaseId) {
    return axios.post(`${ApiServerUrlV1}/agent-execution?testCaseId=${testCaseId}`);
  }
  getAgentStatus(agentId) {
    return axios.get(`${ApiServerUrlV1}/agents/isOnline/${agentId}`);
  }

  // Dashboard apis
  getAutDashboard(autId, fromDate, toDate) {
    return axios.get(`${DASHBOARD2}/aut-dashboard?autId=${autId}&fromDate=${fromDate}&toDate=${toDate}`);
  }
  getTestCaseDashboard(testCaseId, fromDate, toDate) {
    return axios.get(`${EXECUTION3}?testCaseId=${testCaseId}&fromDate=${fromDate}&toDate=${toDate}`);
  }
  getTestSuiteDashboard(testSuiteId, fromDate, toDate) {
    return axios.get(`${EXECUTION3}?testSuiteId=${testSuiteId}&fromDate=${fromDate}&toDate=${toDate}`);
  }

  getAllExeDashboard(fromDate, toDate) {
    return axios.get(`${EXECUTION}?fromDate=${fromDate}&toDate=${toDate}`);
  }

  getSingleTestSuiteTestCasesDashboard(testResultId) {
    return axios.get(`${DASHBOARD}/testsuite?testSuiteResultId=${testResultId}`);
  }

  getTestStepDashboard(testResultId) {
    return axios.get(`${DASHBOARD}/${testResultId}`);
  }

  // report apis
  getExecutionReports(id) {
    return axios.get(`${ApiServerUrlV1}/low-code-test-case-result?testCaseId=${id}`);
  }
  getSingleTestCaseHistoricalGraph(testCaseId) {
    return axios.get(`${ApiServerUrlV1}/low-code-test-case-result/graph-for-testcase/${testCaseId}`);
  }
  getSingleTestCaseReportsOnResultId(resultId) {
    return axios.get(`${ApiServerUrlV1}/low-code-test-case-result/graph-for-teststep/${resultId}`);
  }

  getsingleExeDetails(id) {
    return axios.get(`${EXECUTION3}/${id}`);
  }

  abortTestExecution(exeIds) {
    return axios.put(`${EXECUTION3}/abort`, exeIds);
  }

  // get mobile device list
  getDeviceList(environmentProvider, type, isDisable, localExecution) {
    let url = `${ApiServerUrlV1}/devices/execution?environmentProvider=${environmentProvider}&type=${type}&isLocal=${localExecution}`;
    if (isDisable) {
      url += `&isDisable=${false}`;
    }
    return axios.get(url);
  }

  uploadFile(fileInput) {
    return axios.post(`${ApiServerUrlV1}/file-manager`, fileInput, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  uploadSuites(fileInput) {
    return axios.post(SUITE3, fileInput, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  deleteFile(fileName) {
    return axios.delete(`${ApiServerUrlV1}/file-manager?binaryName=${fileName}`);
  }

  getZephyrTestCases() {
    return axios.get(`${ApiServerUrlV1}/zephyr/tree/2/test-cases-summary/`);
  }

  getZephyrTestCaseSteps(testCaseId) {
    return axios.get(`${ApiServerUrlV1}/zephyr/testcase/${testCaseId}/test-steps-lowcode/`);
  }

  importTestCases(connnectionDetails) {
    return axios.post(`${ApiServerUrlV1}/import/testcase`, connnectionDetails);
  }

  updateImportedTestCase(testcase) {
    return axios.put(`${ApiServerUrlV1}/import/testcase`, testcase);
  }

  getCICDPipeLineList() {
    return axios.get(`${ApiServerUrlV1}/pipelines`);
  }
  deleteCICDPipeLine(data) {
    return axios.delete(`${ApiServerUrlV1}/pipelines`, {
      data: {
        deleteIdList: data,
      },
    });
  }
  createPileLine(data) {
    return axios.post(`${ApiServerUrlV1}/pipelines`, data);
  }

  updateCICDPipeLine(data) {
    return axios.put(`${ApiServerUrlV1}/pipelines`, data);
  }
  executeCICDPlineLine(name) {
    return axios.post(`${ApiServerUrlV1}/executePipelineJob/${name}`);
  }

  getPipeLineTestDetails(id) {
    return axios.get(`${ApiServerUrlV1}/pipelines/${id}`);
  }
  ChatBotData(data) {
    return axios.post(kitapBot, data);
  }

  // engine
  getListOfEngines() {
    return axios.get(`${ApiServerUrlV1}/engines`);
  }

  getListOfDevices(engineName) {
    return axios.get(`${ApiServerUrlV1}/devices/execution?environmentProvider=KITAP&engineName=${engineName}`);
  }
  addEngineDevices(data) {
    return axios.post(`${ApiServerUrlV1}/devices`, data);
  }
  editEngineDevices(data) {
    return axios.put(`${ApiServerUrlV1}/devices`, data);
  }
  deleteDevice(data) {
    return axios.delete(`${ApiServerUrlV1}/file-manager`, { data });
  }

  // power bi reports
  getPowerBiReport() {
    return axios.get(`${REPORTS}`);
  }
}

export default new KiTAPServer();
