import { useCallback, useMemo, useState } from "react";
import { AgGridReact } from "@ag-grid-community/react";
import { ModuleRegistry } from "@ag-grid-community/core";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { Box, MenuItem, Select } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import CustomAgGridPagination from "../../AgGrid/CustomAgGridPagination";

import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";

// Register AG Grid modules
ModuleRegistry.registerModules([ClientSideRowModelModule]);

// Suppress specific AG Grid Enterprise error messages
// const suppressAgGridEnterpriseErrors = () => {
//   const originalConsoleError = console.error;
//   console.error = (...args) => {
//     const errorString = args.join(" ");
//     const errorMessagesToSuppress = [
//       "trial license",
//       "AG Grid Enterprise",
//       "License Key Not Found",
//       "it is not licensed for development",
//       "******************************************************",
//     ];

//     if (!errorMessagesToSuppress.some((msg) => errorString.includes(msg))) {
//       originalConsoleError(...args);
//     }
//   };
// };

const CustomReactAgGrid = ({
  gridRef,
  columnDefs = [],
  rowData = [],
  selectedRowIds = [],
  defaultColDefOverrides = {},
  suppressMenu = true,
  suppressRowClickSelection = true,
  onSelectionChanged,
  rowHeight = 42,
  className = "",
  context = {},
  ...props
}) => {
  const [, setSearchParams] = useSearchParams();
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  // suppressAgGridEnterpriseErrors();

  // Default column definitions
  const defaultColDef = useMemo(
    () => ({
      flex: 1,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      ...defaultColDefOverrides,
    }),
    [defaultColDefOverrides],
  );

  // Handle page size change
  const handlePageSizeChange = useCallback((e) => {
    const newPageSize = Number.parseInt(e.target.value, 10);
    setPageSize(newPageSize);
  }, []);

  // Update pagination information
  const handlePaginationChange = useCallback(() => {
    const gridApi = gridRef.current?.api;
    if (gridApi) {
      setCurrentPage(gridApi.paginationGetCurrentPage() + 1);
      setTotalPages(gridApi.paginationGetTotalPages());
    }
  }, [gridRef]);

  // Define row class rules
  const rowClassRules = useMemo(
    () => ({
      "preview-highlight": (params) => Boolean(params?.data?.preview),
    }),
    [],
  );

  // Grid options configuration
  const gridOptions = useMemo(
    () => ({
      domLayout: "autoHeight",
      suppressCellFocus: true,
      getRowId: (params) => params.data?.sequenceNumber || params.data?.id || params.data?._id,
      isRowSelectable: () => true,
      rowDragManaged: true,
      animateRows: true,
    }),
    [],
  );

  // Handle filter changes
  const handleFilterChange = useCallback(() => {
    const gridApi = gridRef.current?.api;
    if (gridApi) {
      const filterModel = gridApi.getFilterModel();
      const hasActiveFilters = Object.keys(filterModel).length > 0;

      setSearchParams((prev) => {
        const newState = new URLSearchParams(prev);
        newState.set("filter", hasActiveFilters);
        return newState;
      });

      gridApi.hideOverlay();
      gridApi.deselectAll();
      if (gridApi?.rowModel?.rowsToDisplay?.length === 0) {
        gridApi.showNoRowsOverlay();
      }
    }
  }, [gridRef]);

  // Determine available page sizes based on row data length
  const availablePageSizes = useMemo(() => {
    const pageSizes = [10, 25, 50, 100];
    const largestValidPageSize = pageSizes.find((size) => size >= rowData?.length);
    return pageSizes.filter((size) => rowData?.length >= size || size <= largestValidPageSize);
  }, [rowData]);

  // Handle grid ready event
  const handleGridReady = useCallback(
    (params) => {
      if (selectedRowIds.length) {
        params.api.forEachNode((node) => {
          if (selectedRowIds.includes(node.data?.id || node.data?._id)) {
            node.setSelected(true);
          }
        });
      }
    },

    [selectedRowIds],
  );

  return (
    <Box className={`ag-theme-quartz ${className} relative`}>
      <AgGridReact
        ref={gridRef}
        context={context}
        suppressMenuHide={suppressMenu}
        rowSelection="multiple"
        rowBuffer={0}
        tooltipShowDelay={10}
        animateRows
        pagination
        rowHeight={rowHeight}
        suppressPaginationPanel
        suppressScrollOnNewData
        defaultColDef={defaultColDef}
        paginationPageSize={pageSize}
        rowData={rowData}
        columnDefs={columnDefs}
        suppressMenu={suppressMenu}
        suppressRowClickSelection={suppressRowClickSelection}
        rowModelType="clientSide"
        rowClassRules={rowClassRules}
        gridOptions={gridOptions}
        onGridReady={handleGridReady}
        onPaginationChanged={handlePaginationChange}
        onSelectionChanged={onSelectionChanged}
        onFilterChanged={handleFilterChange}
        paginationNumberFormatter={(params) => params.value.toLocaleString()}
        suppressMovableColumns={true}
        {...props}
      />

      {totalPages > 0 && (
        <Box className="space-between" sx={{ px: 2 }}>
          <Select value={pageSize} onChange={handlePageSizeChange} size="small" sx={{ px: 1 }}>
            {availablePageSizes.map((size) => (
              <MenuItem key={size} value={size}>
                {size}
              </MenuItem>
            ))}
          </Select>

          <CustomAgGridPagination gridRef={gridRef} currentPage={currentPage} totalPages={totalPages} />
        </Box>
      )}
    </Box>
  );
};

export default CustomReactAgGrid;
