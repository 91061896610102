import { useMemo } from "react";
import { Box, Paper, Typography } from "@mui/material";
import { CustomAgGrid } from "../AgGrid";
import { AutListHeadCellsAgent } from "../Applications/Auts/AutsListModel";
import { ActionsCellOfAgentAutList } from "./AgentAutActionCells";

export default function AutsList(props) {
  const { type, gridRef, listOfAuts } = props;

  const ClosePreview = () => {
    // do something;
  };

  const action = {
    headerName: "Actions",
    sortable: false,
    cellRenderer: ActionsCellOfAgentAutList,
    cellClass: "locked-col",
    suppressColumnsToolPanel: true,
    filter: false,
    width: 300,
    headerTooltip: "Actions",
  };

  useMemo(() => {
    AutListHeadCellsAgent[AutListHeadCellsAgent.length - 1] = action;
  }, []);

  return (
    <Paper className="customHeadMain">
      <Typography variant="h6">
        {type} Apps: <span>[{listOfAuts?.length}]</span>
      </Typography>
      <Box className="appList">
        <CustomAgGrid
          gridRef={gridRef}
          ClosePreview={ClosePreview}
          headCells={AutListHeadCellsAgent}
          rows={listOfAuts}
        />
      </Box>
    </Paper>
  );
}
