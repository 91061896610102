import { Box, Grid, IconButton, Typography } from "@mui/material";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import SkeletonLoader from "../../SkeletonLoader";

// Extend dayjs with the relativeTime plugin
dayjs.extend(relativeTime);

const TestDataListItem = ({ item, isPreview, isDisabled, onClickTestData }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const testDataId = Number.parseInt(searchParams.get("testDataId"), 10);

  if (item.id === "new") {
    return (
      <Box
        className="center"
        sx={{ p: 2, borderBottom: "1px solid #DBDFE0", boxShadow: 4, borderRadius: "4px", cursor: "pointer" }}>
        <Typography variant="bold" sx={{ opacity: 0.6 }}>
          Creating...
        </Typography>
      </Box>
    );
  }

  const handleDeleteTestData = (e) => {
    e.stopPropagation();
    setSearchParams((prevParams) => {
      const newParams = new URLSearchParams(prevParams);
      newParams.set("testDataDeleteId", item.id);
      return newParams;
    });
  };

  return (
    <Box
      onClick={() => !isDisabled && onClickTestData(item)}
      className="space-between"
      sx={{
        px: 1,
        py: 0.5,
        borderBottom: "1px solid #DBDFE0",
        boxShadow: (theme) => (item.id === testDataId ? theme.shadows[4] : "none"),
        borderRadius: "4px",
        cursor: "pointer",
      }}>
      <Box className="flex column">
        <Typography variant="bold" sx={{ opacity: 0.6 }}>
          {item.name}
        </Typography>
        <Typography variant="bold" sx={{ opacity: 0.6, fontSize: "10px" }}>
          {dayjs(item.createdAt).fromNow()} / {item.createdBy}
        </Typography>
      </Box>
      {!isPreview && (
        <Box className="v-center">
          <IconButton disabled={item.id === testDataId} size="small" onClick={handleDeleteTestData} color="error">
            <DeleteOutlinedIcon fontSize="small" />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

const TestDataList = ({
  onClickTestData,
  loadingTestData = false,
  testDataList,
  isPreview = false,
  onClickAddNewTestData,
}) => {
  const isDisabled = testDataList?.some((each) => each.id === "new");

  return (
    <Grid item xs={3} sx={{ borderRight: "1px solid #ccc" }}>
      <Box className="space-between" sx={{ background: "#F4F1FF", px: 1, height: 42 }}>
        <Box className="v-center">
          <Typography variant="bold">Test Data List</Typography>
        </Box>
        {!isPreview && (
          <IconButton sx={{ pr: 0.5 }} disabled={isDisabled} ml="auto" onClick={onClickAddNewTestData} color="success">
            <AddBoxOutlinedIcon />
          </IconButton>
        )}
      </Box>

      <Box>
        {loadingTestData && <SkeletonLoader />}
        {!loadingTestData &&
          testDataList?.map((each) => (
            <TestDataListItem
              isDisabled={isDisabled}
              onClickTestData={onClickTestData}
              key={each.id}
              item={each}
              isPreview={isPreview}
            />
          ))}
      </Box>
    </Grid>
  );
};

export default TestDataList;
