import { Box, Grid, Typography } from "@mui/material";
import InnerHeader from "../Layout/InnerHeader";
import ReportCard from "./ReportCard";
import { useState, useEffect } from "react";
import KiTAPServer from "../../services/KiTAPServer";
import SkeletonLoader from "../SkeletonLoader";

export default function ReportsHomePage() {
  const [reportData, setReportData] = useState([]);
  const [loadingReport, setLoadingReport] = useState(false);

  const getReport = async () => {
    setLoadingReport(true);
    try {
      const { data } = await KiTAPServer.getPowerBiReport();
      setReportData(data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingReport(false);
    }
  };

  useEffect(() => {
    getReport();
  }, []);

  return (
    <>
      <InnerHeader>
        <Typography variant="h6">DASHBOARD</Typography>
      </InnerHeader>

      <Box className="pt74" width={"100%"}>
        {loadingReport && <SkeletonLoader />}

        <Grid container spacing={2}>
          {reportData?.map((report, index) => (
            <ReportCard key={index} title={report.name} reportData={report} />
          ))}
        </Grid>
      </Box>
    </>
  );
}
