import { Controller } from "react-hook-form";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemIcon,
  Typography,
  Box,
  TextField,
  Grid,
  FormControlLabel,
  Switch,
  FormLabel,
  RadioGroup,
  Radio,
  FormHelperText,
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { attributes, definePropertiesBasedOnAttributes } from "./DataTypes";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useState } from "react";
import citiesData from "./Data/Cities.json";
import CountriesData from "./Data/CountriesData.json";
import statesData from "./Data/States.json";

const isValidDate = (date, format) => {
  return dayjs(date, format).isValid();
};

const isDependencyMet = () => {
  // Implement logic to check if the dependencies for the property are met
  // This function should return true if dependencies are met, false otherwise
  return true; // Placeholder implementation
};

const DataTypeSelector = ({ control, errors, trigger, setValue, watch }) => {
  const tdmAttributeProperties = watch("tdmAttributeProperties");

  // State to manage global dropdown values
  const [globalDropdownValues, setGlobalDropdownValues] = useState({
    continent: "",
    country: "",
    state: "",
  });
  const { continent, country, state } = globalDropdownValues;

  const handleDataTypeChange = (field, event) => {
    const selectedValue = event.target.value;
    field.onChange(selectedValue);

    const item = attributes.find((attr) => attr.attributeName === selectedValue);
    if (item) {
      const newAttribute = {
        ...item,
        tdmAttributeProperties: definePropertiesBasedOnAttributes(item.attributeName, false),
      };
      setValue("tdmAttributeProperties", newAttribute.tdmAttributeProperties);
    }
  };

  const fetchDropdownOptions = (type) => {
    const continents = ["Europe", "Oceania", "Asia", "South America", "Africa", "North America"];
    const filteredCountries = continent ? CountriesData.filter((country) => country.Continent === continent) : [];
    const filteredStates = country ? statesData.filter((state) => state.country === country) : [];
    const filteredCities = state ? citiesData.filter((city) => city.state === state) : [];
    switch (type) {
      case "continent":
        return continents?.map((continent) => ({
          label: continent,
          value: continent,
        }));

      case "country":
        return filteredCountries.map((country) => ({
          label: country.Country,
          value: country.Country,
        }));

      case "state":
        return filteredStates.map((state) => ({
          label: state.states,
          value: state.states,
        }));

      case "city":
        return filteredCities.map((city) => ({
          label: city.city,
          value: city.city,
        }));

      default:
        return [];
    }
  };

  const updateGlobalDropdown = (key, value) => {
    // Update the global dropdown values
    setGlobalDropdownValues((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const renderPropertyInput = (field, error, property, propertyIndex, properties) => {
    if (!isDependencyMet(property, properties)) {
      return null;
    }
    switch (property?.type) {
      case "text":
      case "number":
        return (
          <TextField
            type={property.type === "number" ? "number" : "text"}
            {...field}
            InputProps={{ inputProps: property.type === "number" ? { min: "1", step: "1" } : {} }}
            label={property.keyDisplayName}
            onChange={(event) => {
              const value = event.target.value.trim().replace(/^0+/, "");
              field.onChange(property.type === "number" ? Number.parseInt(value, 10) : event.target.value);
              trigger(`tdmAttributeProperties[${propertyIndex}].value`);
            }}
            size="small"
            fullWidth
            error={Boolean(error)}
            helperText={error?.message}
            variant="outlined"
          />
        );

      case "toggle":
        return (
          <FormControlLabel
            sx={{ m: 0 }}
            control={<Switch {...field} size="small" checked={field.value} />}
            label={property.keyDisplayName}
          />
        );

      case "radio":
        return (
          <FormControl component="fieldset">
            <FormLabel component="legend">{property.keyDisplayName}</FormLabel>
            <RadioGroup sx={{ m: 0 }} row {...field}>
              {property.options.map((option, optIndex) => (
                <FormControlLabel
                  sx={{ m: 0 }}
                  key={`radio-${propertyIndex}-${optIndex}`}
                  value={option.value}
                  control={<Radio size="small" color="primary" />}
                  label={option.key}
                />
              ))}
            </RadioGroup>
          </FormControl>
        );

      case "select":
        if (property?.dropdownType === "simple") {
          const dropdownOptions = property?.options || fetchDropdownOptions(property.key);
          return (
            <TextField
              select
              label={property.keyDisplayName}
              disabled={dropdownOptions?.length === 0}
              size="small"
              fullWidth
              error={Boolean(error)}
              helperText={error?.message}
              variant="outlined"
              {...field}
              onChange={(e) => {
                field.onChange(e.target.value);
                if (property.key === "dateFormat") {
                  const format = property.options.find((option) => option.value === e.target.value)?.label;
                  setValue(`tdmAttributeProperties[${propertyIndex}].selectedFormat`, format);
                }
                trigger(`tdmAttributeProperties[${propertyIndex}].value`);
                updateGlobalDropdown(property.key, e.target.value);
              }}>
              {dropdownOptions.map((option, optIndex) => (
                <MenuItem key={`option-${propertyIndex}-${optIndex}`} value={option?.value}>
                  {option?.label}
                </MenuItem>
              ))}
            </TextField>
          );
        } else if (property?.dropdownType === "multiselect") {
          return (
            <TextField
              select
              label={property.keyDisplayName}
              size="small"
              fullWidth
              error={Boolean(error)}
              helperText={error?.message}
              variant="outlined"
              {...field}
              multiple>
              {property.options.map((option, optIndex) => (
                <MenuItem key={`option-${propertyIndex}-${optIndex}`} value={option.value}>
                  {option.key}
                </MenuItem>
              ))}
            </TextField>
          );
        } else if (property?.dropdownType === "autocomplete") {
          return (
            <Box sx={{ width: "100%" }}>
              {/* Implement the autocomplete feature here */}
              <FormHelperText error={Boolean(error)}>{error?.message}</FormHelperText>
            </Box>
          );
        } else if (property?.dropdownType === "date") {
          const dateFormat = property?.dateFormat || "YYYY-MM-DD";
          return (
            <Box sx={{ width: "100%" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={property.keyDisplayName}
                  slotProps={{ textField: { error: Boolean(error), size: "small" } }}
                  {...field}
                  sx={{ width: "100%" }}
                  size="small"
                  value={isValidDate(field.value, dateFormat) ? dayjs(field.value, dateFormat) : null}
                  onChange={(newValue) => {
                    field.onChange(newValue ? dayjs(newValue).format(dateFormat) : null);
                    trigger(`tdmAttributeProperties[${propertyIndex}].value`);
                  }}
                  disableFuture
                  format={dateFormat}
                />
              </LocalizationProvider>
              <FormHelperText error={Boolean(error)}>{error?.message}</FormHelperText>
            </Box>
          );
        }
        break;
      default:
        return null;
    }
    return null;
  };

  return (
    <>
      <Grid item xs={6}>
        <FormControl disabled size="small" fullWidth error={Boolean(errors.tdmAttributeType)}>
          <InputLabel>Select Data Type</InputLabel>
          <Controller
            name="tdmAttributeType"
            control={control}
            render={({ field }) => (
              <Select
                size="small"
                {...field}
                disabled
                label="Select Data Type"
                onChange={(event) => handleDataTypeChange(field, event)}
                renderValue={(selected) => {
                  const selectedAttr = attributes.find((attribute) => attribute.attributeName === selected);
                  return selectedAttr ? (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {selectedAttr.icon}
                      <Typography variant="body1" sx={{ ml: 1 }}>
                        {selectedAttr.attributeName}
                      </Typography>
                    </Box>
                  ) : (
                    ""
                  );
                }}>
                {attributes?.map((attribute) => (
                  <MenuItem key={attribute.attributeName} value={attribute.attributeName}>
                    <ListItemIcon>{attribute.icon}</ListItemIcon>
                    <Typography variant="body1">{attribute.attributeName}</Typography>
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>
      </Grid>

      {tdmAttributeProperties?.length &&
        tdmAttributeProperties?.map((property, propIndex) => (
          <Grid key={`property-${propIndex}`} item xs={6}>
            <Controller
              name={`tdmAttributeProperties[${propIndex}].value`}
              control={control}
              render={({ field, fieldState: { error } }) =>
                renderPropertyInput(field, error, property, propIndex, tdmAttributeProperties)
              }
            />
          </Grid>
        ))}
    </>
  );
};

export default DataTypeSelector;
