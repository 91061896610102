import { Box, Typography } from "@mui/material";
import InnerHeader from "../../Layout/InnerHeader";
import AUTDashboardIndexPage from "./AUTDashboardIndexPage.js";

const ExecutionReports = () => {
  return (
    <>
      <InnerHeader>
        <Box className="v-center" gap={1}>
          <Typography variant="h6">EXECUTION REPORTS</Typography>
        </Box>
      </InnerHeader>
      <Box className="pt74" sx={{ width: "100%" }}>
        <AUTDashboardIndexPage />
      </Box>
    </>
  );
};

export default ExecutionReports;
