import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";
import { Box, Grid, IconButton, Typography, Tooltip, CircularProgress } from "@mui/material";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import { useDispatch } from "react-redux";
import { useDrag, useDrop } from "react-dnd";
import { testcaseServices } from "../../../Redux/services/testcase.services";
import { setConfigTestCases, setTestStepsData } from "../../../Redux/reducers/testcaseSlice";
import DragIndicatorOutlinedIcon from "@mui/icons-material/DragIndicatorOutlined";
import { CustomDeleteIcon } from "../../CustomComponents/Icons/IconButtons";

const ItemType = "TEST_CASE";

const TestCaseCard = ({
  testCase,
  index,
  moveTestCase,
  loadingTestCaseDetails,
  handleDeleteTestCase,
  handleOnClickTestCase,
}) => {
  const [searchParams] = useSearchParams();
  const testCaseId = searchParams.get("testCaseId");
  const isSelected = Number.parseInt(testCaseId) === Number.parseInt(testCase?.testCaseId);

  const [, ref] = useDrag({
    type: ItemType,
    item: { index },
  });

  const [, drop] = useDrop({
    accept: ItemType,
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveTestCase(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  return (
    <Box
      ref={(node) => ref(drop(node))}
      onClick={() => handleOnClickTestCase(testCase)}
      className="space-between"
      sx={{
        cursor: "pointer",
        px: 1,
        py: 0.5,
        borderBottom: "1px solid #DBDFE0",
        background: isSelected ? "#eeebf8" : "#fff",
        borderRadius: 0,
        boxShadow: isSelected ? 5 : "none",
        "&:hover": { boxShadow: isSelected ? 3 : "none" },
        transition: "background-color 0.3s ease",
      }}>
      <Box className="v-center" gap={0.4}>
        <DragIndicatorOutlinedIcon fontSize="small" color="primary" />
        <Box className="flex column">
          <Typography>{testCase?.name}</Typography>
          <Typography sx={{ fontSize: "10px" }}>
            {testCase?.noOfSteps} steps / {testCase?.createdBy}
          </Typography>
        </Box>
      </Box>
      {loadingTestCaseDetails === testCase?.testCaseId && <CircularProgress sx={{ ml: "auto" }} size={20} />}
      <CustomDeleteIcon
        size="small"
        onClick={(e) => handleDeleteTestCase(e, testCase)}
        height="18"
        width="18"
        placement="left"
      />
    </Box>
  );
};

TestCaseCard.propTypes = {
  testCase: PropTypes.shape({
    testCaseId: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    noOfSteps: PropTypes.number,
    createdBy: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
  moveTestCase: PropTypes.func.isRequired,
  loadingTestCaseDetails: PropTypes.number,
  handleDeleteTestCase: PropTypes.func.isRequired,
  handleOnClickTestCase: PropTypes.func.isRequired,
};

const SelectedTestCaseList = ({ configTestCases, handleShowAddTestCases }) => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const testCaseId = Number.parseInt(searchParams.get("testCaseId"));
  const [loadingTestCaseDetails, setLoadingTestCaseDetails] = useState(null);

  const handleAddTestCase = () => {
    handleShowAddTestCases();
  };

  const handleOnClickTestCase = async (testCaseDetails) => {
    setLoadingTestCaseDetails(testCaseDetails?.testCaseId, "testCaseId");
    try {
      const { data } = await testcaseServices.getTestcaseDetailsById(
        testCaseDetails?.testCaseId,
        testCaseDetails?.testCaseVersion,
      );
      const { macroBinderResponseList, testStepResponseList } = data;
      const macroItems = macroBinderResponseList.map((each) => {
        const { id, sequenceNumber, macroWithStepsResponse } = each;
        const { testStepResponseList, ...rest } = macroWithStepsResponse;
        return {
          binderId: id,
          sequenceNumber,
          ...rest,
          steps: testStepResponseList?.sort((a, b) => a.sequenceNumber - b.sequenceNumber),
        };
      });
      const updatedSteps = [...testStepResponseList, ...macroItems].sort((a, b) => a.sequenceNumber - b.sequenceNumber);
      dispatch(setTestStepsData(updatedSteps));
      setSearchParams((params) => {
        params.set("testCaseId", testCaseDetails?.testCaseId);
        params.set("version", testCaseDetails?.testCaseVersion);
        return params;
      });
    } catch (error) {
      console.log(error);
    }
    setLoadingTestCaseDetails(null);
  };

  const handleDeleteTestCase = (e, testCase) => {
    e.stopPropagation();
    const newData = configTestCases.filter((each) => each.testCaseId !== testCase?.testCaseId);
    dispatch(setConfigTestCases(newData));
    if (testCaseId === testCase?.testCaseId) {
      setSearchParams((params) => {
        params.delete("testCaseId");
        params.delete("version");
        return params;
      });
    }
  };

  const moveTestCase = (fromIndex, toIndex) => {
    const updatedTestCases = Array.from(configTestCases);
    const [movedItem] = updatedTestCases.splice(fromIndex, 1);
    updatedTestCases.splice(toIndex, 0, movedItem);
    dispatch(setConfigTestCases(updatedTestCases));
  };

  useEffect(() => {
    if (configTestCases?.length && !testCaseId) {
      handleOnClickTestCase(configTestCases[0]);
    }
  }, [configTestCases, testCaseId]);

  return (
    <Grid item xs={3} sx={{ borderRight: "1px solid #ccc" }}>
      <Box
        className="space-between"
        sx={{ background: "#F4F1FF", px: 1, height: 42, display: "flex", alignItems: "center" }}>
        <Typography variant="bold">Test Case List</Typography>
        <Tooltip title="Add Test Case" placement="left" arrow>
          <IconButton size="small" color="success" onClick={handleAddTestCase}>
            <AddBoxOutlinedIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
      <Box sx={{ minHeight: "50vh", maxHeight: "65vh", overflowY: "auto" }}>
        {configTestCases?.map((testCase, index) => (
          <TestCaseCard
            key={testCase.testCaseId}
            index={index}
            moveTestCase={moveTestCase}
            loadingTestCaseDetails={loadingTestCaseDetails}
            handleOnClickTestCase={handleOnClickTestCase}
            testCase={testCase}
            handleDeleteTestCase={handleDeleteTestCase}
          />
        ))}
      </Box>
    </Grid>
  );
};

SelectedTestCaseList.propTypes = {
  configTestCases: PropTypes.arrayOf(
    PropTypes.shape({
      testCaseId: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      noOfSteps: PropTypes.number,
      createdBy: PropTypes.string,
    }),
  ).isRequired,
  handleShowAddTestCases: PropTypes.func.isRequired,
};

export default SelectedTestCaseList;
