import { Grid, Paper } from "@mui/material";
import AutTile from "./AutTile";

const AutsGridViewList = ({ listOfAuts, selectedAuts, setSelectedAuts }) => {
  const onChangeCheckbox = (autdetails) => {
    setSelectedAuts((prevState) => {
      const index = prevState.findIndex((each) => each?.id === autdetails?.id);
      if (index === -1) {
        return [...prevState, autdetails];
      }
      return prevState.filter((item) => item?.id !== autdetails?.id);
    });
  };
  return (
    <Paper>
      <Grid container p={1.5}>
        {listOfAuts?.map((aut, i) => (
          <AutTile
            key={i}
            aut={aut}
            checked={Boolean(selectedAuts.find((each) => each?.id === aut.id))}
            onChangeCheckbox={onChangeCheckbox}
          />
        ))}
      </Grid>
    </Paper>
  );
};

export default AutsGridViewList;
