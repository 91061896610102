import { Box, Typography, Accordion, AccordionSummary, AccordionDetails, Button, Divider } from "@mui/material";
import Desktop from "../../images/desktop.png";
import RadioButtonCheckedRoundedIcon from "@mui/icons-material/RadioButtonCheckedRounded";
import { useState, useEffect, useRef, useMemo } from "react";
import AUTTile from "../ReusableComponents/AutTile";
import ModalPopup from "../ReusableComponents/ModalPopup";
import KiTAPServer from "../../services/KiTAPServer";
import GenerateKey from "./GenerateKey/Index";
import Filters from "./Filters";
import AutsList from "./AgentAutsList";
import AgentTile, { NoAgentTile } from "../ReusableComponents/AgentTile";
import VpnKeyOutlinedIcon from "@mui/icons-material/VpnKeyOutlined";
import { setListOfAutsFormat } from "../../utilities/ReusableFunctions";
import Configurations from "../../resources/Config.json";
import { useCheckAccess } from "../../CustomHooks/useCheckAccess";
import { showToast } from "../../Redux/reducers/toastSlice";
import { useDispatch } from "react-redux";

export default function AgentsList() {
  const dispatch = useDispatch();
  const gridRef = useRef();
  const [agent, setAgent] = useState({});
  const [expanded, setExpanded] = useState(true);
  const [agents, setAgents] = useState([]);
  const [autTypes, setAutTypes] = useState([]);
  const [listOfAuts, setListOfAuts] = useState([]);
  const [openGenerateKeyDialogBox, setOpenGenerateKeyDialogBox] = useState(false);
  const [generatedkey, setGeneratedkey] = useState("");
  const [type, setType] = useState("");

  const { checkAccess } = useCheckAccess();

  const reset = () => {
    setAgent({});
    setOpenGenerateKeyDialogBox(false);
    setGeneratedkey("");
  };

  const getListOfAgents = async () => {
    try {
      const res = await KiTAPServer.getListOfAgents();
      setAgents(res?.data);
    } catch (error) {
      dispatch(showToast({ message: error?.response?.data?.message || error.message, type: "error" }));
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      getListOfAgents();
    }, Configurations?.agnetsStatusRefreshTime);

    return () => clearInterval(interval);
  }, []);

  useMemo(() => {
    getListOfAgents();
  }, []);

  const openKey = async () => {
    try {
      const KeyResult = await KiTAPServer.getGenerateKey();
      setGeneratedkey(KeyResult?.data?.key);
      setOpenGenerateKeyDialogBox(true);
    } catch (error) {
      dispatch(showToast({ message: error?.response?.data?.message || error.message, type: "error" }));
    }
  };

  const onClickAgent = async (agent) => {
    setExpanded(false);
    setAgent(agent);
    try {
      const res = await KiTAPServer.getListOfAgentAutTypes(agent?.id);
      setAutTypes(res?.data);
    } catch (error) {
      dispatch(showToast({ message: error?.response?.data?.message || error.message, type: "error" }));
    }
  };

  const getListOfAuts = async (autType) => {
    if (autType) {
      try {
        const res = await KiTAPServer.getListOfAgentAuts(autType, agent?.id);
        setListOfAuts(setListOfAutsFormat(res?.data, agent?.id));
      } catch (error) {
        dispatch(showToast({ message: error?.response?.data?.message || error.message, type: "error" }));
      }
    }
  };

  const onClickAUTTile = (data) => {
    setType(data);
    getListOfAuts(data);
  };

  return (
    <Box className="agentMain pt74">
      <Box textAlign={"right"}>
        {checkAccess("agents", "Create") && (
          <Button onClick={openKey} variant="contained" color="primary" size="small">
            <VpnKeyOutlinedIcon sx={{ marginRight: "8px", fontSize: "16px" }} />
            Request for Agent Registration Key
          </Button>
        )}
      </Box>

      <Box className="customHeadMain">
        <Typography className="customHead" variant="h6">
          Agent List
        </Typography>
        <Accordion className="DS-Acc" expanded={expanded}>
          {Object.keys(agent).length > 0 && (
            <AccordionSummary
              expandIcon={
                <Typography
                  onClick={() => {
                    setExpanded(!expanded);
                    reset();
                  }}>
                  View All
                </Typography>
              }
              aria-controls="panel1a-content">
              {agent && (
                <Box className="active-ds">
                  <img src={Desktop} alt={"Desktop"} />
                  <Typography>Name: {agent?.name}</Typography>
                  <Typography>IP Address: {agent?.ipAddress}</Typography>
                  {agent?.isOnline ? (
                    <>
                      <Box className="Aonline">
                        <RadioButtonCheckedRoundedIcon /> Online{" "}
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box className="Aonline Aoffline">
                        <RadioButtonCheckedRoundedIcon /> Offline{" "}
                      </Box>
                    </>
                  )}
                </Box>
              )}
            </AccordionSummary>
          )}
          <AccordionDetails>
            <Filters />
            <Box
              className="AgentSListMain"
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(4, 1fr)",
              }}>
              {agents?.length > 0 ? (
                Array.isArray(agents) &&
                agents?.map((agent) => {
                  return <AgentTile onClickAgent={onClickAgent} agent={agent} key={"agent"} />;
                })
              ) : (
                <NoAgentTile />
              )}
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box>
        {autTypes?.length !== 0 && (
          <>
            <Typography variant="h6" mt={"16px"} mb={"8px"}>
              {"AUT's"} Available
            </Typography>
            <Divider />
          </>
        )}
        <Box
          className="DBListMain Aauts"
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(5, 1fr)",
            mt: "8px",
          }}>
          {Array.isArray(autTypes) &&
            autTypes?.map((e, i) => (
              <AUTTile key={i} autType={e} onClickAUTTile={onClickAUTTile} type={type} enableAdd={false} />
            ))}
        </Box>
      </Box>

      {listOfAuts?.length > 0 && <AutsList gridRef={gridRef} listOfAuts={listOfAuts} type={type} />}

      <ModalPopup
        open={openGenerateKeyDialogBox}
        setOpen={setOpenGenerateKeyDialogBox}
        data={<GenerateKey generatedkey={generatedkey} />}
      />
    </Box>
  );
}
