import { Card, Box, Typography, CardContent } from "@mui/material/";
import InnerHeader from "../Layout/InnerHeader";
import { Messages } from "../../constants";
import { useParams } from "react-router-dom";

export default function License() {
  const { info } = useParams();
  const data = Messages.find((e) => e.ExpiryType === info);

  return (
    <Box width="100%">
      <InnerHeader>
        <Typography>UPGRADE LICENSE</Typography>
      </InnerHeader>
      <Card className="genKeyMain">
        <CardContent>
          <Box>
            <Typography variant="h6">{data?.Header}</Typography>
            <Typography className="code">{data?.Message}</Typography>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}
