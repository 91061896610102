import { Box, Card, CardContent, Divider, IconButton, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CustomLoadingButton from "../CustomComponents/CustomButtons/CustomLoadingButton";
import EngineIcon from "../CustomComponents/Icons/EngineIcon";

const EngineTile = ({ engine, handleSelectDevice }) => {
  const { isLocal, name, pcUser, os, mobileDeviceIdList } = engine;

  return (
    <Card>
      <CardContent sx={{ pb: "16px !important" }}>
        <Box className="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          <Box
            className="center"
            sx={{
              backgroundColor: isLocal ? "#F3EEFD" : "#FDF1DB",
              borderRadius: 1,
              p: 1,
            }}>
            <EngineIcon color={isLocal ? "#8E54E9" : "#E68200"} />
          </Box>
          <Box className="center column">
            <Typography
              className="center"
              sx={{
                backgroundColor: isLocal ? "#F3EEFD" : "#FDF1DB",
                color: isLocal ? "#8E54E9" : "#E68200",
                borderRadius: 1,
                py: 0.5,
                px: 1,
                textTransform: "uppercase",
                fontWeight: 500,
              }}>
              {isLocal ? "Local" : "Server"}
            </Typography>
            <Typography sx={{ fontSize: 10, opacity: 0.6, color: "#8FA1AA" }}>2 hrs ago</Typography>
          </Box>
        </Box>
        <Box className="flex column" sx={{ gap: 1, my: 1 }}>
          <Typography sx={{ color: "#64748B", fontSize: "0.8rem" }}>
            Name:{" "}
            <Typography component="span" sx={{ fontWeight: 600 }}>
              {name}
            </Typography>
          </Typography>
          <Typography sx={{ color: "#64748B", fontSize: "0.8rem" }}>
            User:{" "}
            <Typography component="span" sx={{ fontWeight: 600 }}>
              {pcUser}
            </Typography>
          </Typography>
          <Typography sx={{ color: "#64748B", fontSize: "0.8rem" }}>
            OS:{" "}
            <Typography component="span" sx={{ fontWeight: 600 }}>
              {os}
            </Typography>
          </Typography>
        </Box>
        <Divider sx={{ my: 1 }} />
        <Box className="space-between">
          <Typography sx={{ color: "#64748B", fontSize: "0.8rem" }}>
            No. of Devices:{" "}
            <Typography component="span" sx={{ fontWeight: 600 }}>
              {mobileDeviceIdList?.length || 0}
            </Typography>
          </Typography>
          <Box className="v-center">
            <IconButton color="primary" size="small">
              <InfoOutlinedIcon fontSize="small" />
            </IconButton>
            <CustomLoadingButton onClick={() => handleSelectDevice(engine)}>Link Devices</CustomLoadingButton>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default EngineTile;
