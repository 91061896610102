import { Box, FormControl, InputLabel, MenuItem, OutlinedInput, Select, Tab } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { autServices } from "../../../Redux/services/aut.services";
import AUTDashboard from "./AUTDashboard";
import ExecutionsList from "./ExecutionsList";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";

const SelectAut = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams({ autType: "All" });
  const autType = searchParams.get("autType");
  const selectedTab = searchParams.get("selectedTab");
  const autId = Number.parseInt(searchParams.get("autId"));

  const { aut_list: autList } = useSelector((state) => state.aut);

  const handleAutTypeChange = (event) => {
    const selectedType = event.target.value;
    setSearchParams((prev) => {
      const newState = new URLSearchParams(prev);
      newState.set("autType", selectedType);
      autId && newState.delete("autId");
      autId && newState.delete("autName");
      return newState;
    });

    if (selectedType !== "All") {
      dispatch(autServices.fetchListOfAuts(selectedType));
    }
  };

  const handleAutNameChange = (event) => {
    const selectedAut = event.target.value;
    setSearchParams((prevParams) => {
      const newParams = new URLSearchParams(prevParams);
      newParams.set("autName", selectedAut?.name);
      newParams.set("autId", selectedAut?.id);
      return newParams;
    });
  };

  useEffect(() => {
    if (autType && autType !== "All") {
      try {
        dispatch(autServices.fetchListOfAuts(autType));
      } catch (error) {
        console.log(error);
      }
    }
  }, []);
  return (
    <Box className="v-center" gap={2} mb={2}>
      <FormControl
        className="lineargradient-formcontrol"
        fullWidth
        variant="outlined"
        size="small"
        sx={{ maxWidth: "180px" }}>
        <InputLabel id="aut-type-select-label">AUT Type</InputLabel>
        <Select
          labelId="aut-type-select-label"
          id="aut-type-select"
          value={autType}
          onChange={handleAutTypeChange}
          input={<OutlinedInput label="AUT Type" />}>
          <MenuItem value="All">All</MenuItem>
          <MenuItem disabled value="SALESFORCE">
            Salesforce
          </MenuItem>
          <MenuItem value="ANDROID">Android</MenuItem>
          <MenuItem value="IOS">iOS</MenuItem>
          <MenuItem value="WEB">Web Application</MenuItem>
        </Select>
      </FormControl>

      {autType && autType !== "All" && selectedTab !== "OVERVIEW" && (
        <FormControl
          className="lineargradient-formcontrol"
          fullWidth
          variant="outlined"
          size="small"
          sx={{ maxWidth: "180px" }}>
          <InputLabel id="aut-name-select-label">AUT Name</InputLabel>
          <Select
            labelId="aut-name-select-label"
            id="aut-name-select"
            value={autList?.find((each) => each?.id === autId) || ""}
            onChange={handleAutNameChange}
            input={<OutlinedInput label="AUT Name" />}>
            {autList.map((app) => (
              <MenuItem key={app.id} value={app}>
                {app.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </Box>
  );
};

const LabTabs = () => {
  const [searchParams, setSearchParams] = useSearchParams({ selectedTab: "OVERVIEW" });
  const selectedTab = searchParams.get("selectedTab") || "OVERVIEW";

  const handleTabChange = (event, newValue) => {
    setSearchParams((prevParams) => {
      const newParams = new URLSearchParams(prevParams);
      newParams.set("selectedTab", newValue);
      return newParams;
    });
  };

  return (
    <Box sx={{ width: "100%", typography: "body1", "& .MuiTabPanel-root": { paddingX: 0 } }}>
      <SelectAut />
      <TabContext value={selectedTab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleTabChange}>
            {["OVERVIEW", "ALL", "COMPLETED", "INPROGRESS"].map((tab) => (
              <Tab key={tab} label={tab} value={tab} />
            ))}
          </TabList>
        </Box>

        <TabPanel value="OVERVIEW">
          <AUTDashboard />
        </TabPanel>
        {["ALL", "COMPLETED", "INPROGRESS"].map((tab) => (
          <TabPanel key={tab} value={tab}>
            <ExecutionsList />
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  );
};

export default LabTabs;
