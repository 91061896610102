import axios from "axios";
import { ApiServerUrlV1 } from "../../constants";

const createMacro = (macro) => {
  return axios.post(`${ApiServerUrlV1}/macros`, macro);
};

const getMacrosList = (autId) => {
  return axios.get(`${ApiServerUrlV1}/macros?autId=${autId}`);
};
const updateMacro = (macro) => {
  return axios.put(`${ApiServerUrlV1}/macros`, macro);
};
const getMacroById = (macroId, macroVersion) => {
  return axios.get(`${ApiServerUrlV1}/macros/${macroId}/${macroVersion}`);
};

const deleteMacro = (data) => {
  return axios.delete(`${ApiServerUrlV1}/macros`, { data });
};

export const macrosServices = {
  createMacro,
  updateMacro,
  getMacrosList,
  getMacroById,
  deleteMacro,
};
