import { formatDateTime } from "../../utils/formatDate";

// function ScheduledDate(props) {
//   return <>{ formatDateTime(props?.data?.CreatedDate)}</>;
// }

// function Active(props) {
//   return <>{props?.data?.enabled?.toString()}</>;
// }

const CreatedDate = (props) => formatDateTime(props?.data?.createdAt);

export const headCells = [
  {
    sortable: false,
    headerCheckboxSelection: true,
    checkboxSelection: true,
    lockPosition: "left",
    cellClass: "locked-col",
    suppressColumnsToolPanel: true,
    filter: false,
    width: 100,
  },
  {
    field: "name",
    headerName: "Name",
    sortable: true,
    suppressColumnsToolPanel: true,
    filter: true,
    headerTooltip: "Name",
  },
  {
    field: "description",
    headerName: "Description",
    sortable: true,
    suppressColumnsToolPanel: true,
    filter: true,
    headerTooltip: "Description",
  },
  // {
  //   field: "Active",
  //   headerName: "Active",
  //   sortable: false,
  //   filter: false,
  //   headerTooltip: "Active",
  //   cellRenderer: Active,
  // },
  // {
  //   field: "ScheduledDate",
  //   headerName: "Schedule Time",
  //   sortable: false,
  //   filter: false,
  //   headerTooltip: "Schedule Time",
  //   cellRenderer: ScheduledDate,
  // },
  // {
  //   field: "noOfTimes",
  //   headerName: "No of Times",
  //   sortable: true,
  //   filter: false,
  //   headerTooltip: "No of Times",
  // },
  // {
  //   field: "frequency",
  //   headerName: "Frequency",
  //   sortable: true,
  //   filter: true,
  //   headerTooltip: "Frequency",
  // },
  {
    field: "createdAt",
    headerName: "Created Date",
    headerTooltip: "Created Date",
    sortable: true,
    filter: false,
    cellRenderer: CreatedDate,
  },
  {},
];
