import { useEffect, useState } from "react";
import {
  Box,
  Chip,
  Dialog,
  DialogTitle,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import { Cancel as CloseIcon, FileUploadOutlined as ExportIcon } from "@mui/icons-material";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement } from "chart.js";
import CustomButton from "../../../CustomComponents/CustomButtons/CustomButton";
import CustomLoadingButton from "../../../CustomComponents/CustomButtons/CustomLoadingButton";
import CustomCarousel from "../../../CustomComponents/CustomCarousel";
import FullScreenDialog from "../../../TestCases/Create/CreateTestStepsContainer/FullWidthScreen";

import { StatusButtons } from "../ExecutionConstants";

import { useSearchParams } from "react-router-dom";
import { testcaseServices } from "../../../../Redux/services/testcase.services";
import { formatEpochTimeLocale, getTimeDifference } from "../../../../utilities/ReusableFunctions";
import { CustomExpandIcon } from "../../../CustomComponents/Icons/IconButtons";
import PreviewDataContainer from "./PreviewDataContainer";
import SkeletonLoader from "../../../SkeletonLoader";

ChartJS.register(ArcElement);

const chartData = {
  labels: ["Passed", "Failed", "Not Executed"],
  datasets: [
    {
      data: [7, 3],
      backgroundColor: ["#61C5F6", "#EEEEEE"],
      hoverBackgroundColor: ["#61C5F6", "#EEEEEE"],
      borderWidth: 0,
    },
  ],
};

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: { legend: { display: false }, tooltip: { enabled: false }, datalabels: { display: false } },
  cutout: "70%",
};

const statusStyles = {
  PASSED: { backgroundColor: "#ebfceb", color: "#4CAF50" },
  FAILED: { backgroundColor: "#FFEAEB", color: "#F9594B" },
  SKIPPED: { backgroundColor: "#e6f2f7", color: "#61C5F6" },
};
const initialTestResults = [
  { date: "01-May-24", time: "2:20PM", status: "Passed" },
  { date: "08-May-24", time: "3:20PM", status: "Failed" },
  { date: "08-May-24", time: "3:20PM", status: "Passed" },
  { date: "08-May-24", time: "3:20PM", status: "Failed" },
  { date: "08-May-24", time: "3:20PM", status: "Passed" },
  { date: "08-May-24", time: "3:20PM", status: "Failed" },
  { date: "08-May-24", time: "3:20PM", status: "Passed" },
];

const SelectTestData = () => {
  const [selected, setSelected] = useState("TestData_04");

  const handleChange = (event) => {
    setSelected(event.target.value);
  };

  return (
    <FormControl className="lineargradient-formcontrol" fullWidth variant="outlined" size="small">
      <InputLabel id="demo-simple-select-label">Test Data</InputLabel>
      <Select
        size="small"
        sx={{ height: "28px", marginRight: "8px" }}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selected}
        onChange={handleChange}
        label="Test Data"
        input={<OutlinedInput label="Test Data" />}>
        {["TestData_01", "TestData_02", "TestData_03", "TestData_04", "TestData_05"].map((item, key) => (
          <MenuItem key={key} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const ResultCard = ({ result, isSelected, onClose }) => {
  return (
    <Box
      sx={{
        position: "relative",
        backgroundColor: isSelected ? "#66A2EE" : "#DBECFF",
        borderRadius: "3px",
        padding: "8px 16px",
        margin: "4px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        boxShadow: isSelected ? "0px 4px 4px 0px #00000026" : "none",
        cursor: "pointer",
      }}>
      <Typography
        variant="body2"
        sx={{ fontWeight: "bold", marginRight: "8px", color: isSelected && "#fff", whiteSpace: "nowrap" }}>
        {result.date} | {result.time} | <span style={{ color: `${statusStyles[result.status]}` }}>{result.status}</span>
      </Typography>
      <IconButton size="small" color="error" onClick={() => onClose(result)} className="CloseIcon preCloseIcon">
        <CloseIcon fontSize="small" />
      </IconButton>
    </Box>
  );
};

const StatusChip = ({ label }) => {
  return <Chip size="small" className="custom-chip" label={label} sx={{ ...statusStyles[label] }} />;
};

const PreviousResultsMenu = ({ previousResults, handleOnChangePreviewResult }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <CustomButton
        sx={{ marginRight: "12px" }}
        className="no-shadow"
        variant="contained"
        size="small"
        color="warning"
        onClick={handleClick}>
        Previous Result
      </CustomButton>
      <Menu anchorEl={anchorEl} value={previousResults} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={() => handleOnChangePreviewResult("All")}>All</MenuItem>
        <MenuItem onClick={() => handleOnChangePreviewResult("Last 5 Results")}>Last 5 Results</MenuItem>
        <MenuItem onClick={() => handleOnChangePreviewResult("Last 4 Results")}>Last 4 Results</MenuItem>
        <MenuItem onClick={() => handleOnChangePreviewResult("Last 3 Results")}>Last 3 Results</MenuItem>
      </Menu>
    </>
  );
};

const PreviewTestData = ({ setPreviewTestData }) => {
  const [searchParams] = useSearchParams();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [previousResults, setPreviousResults] = useState("");
  const [testResults, setTestResults] = useState(initialTestResults);
  const [exeInstanceDetails, setExeInstanceDetails] = useState({});
  const [loadingResults, setLoadingResults] = useState(false);

  const [selStatus, setSelStatus] = useState("All");
  const exeInstanceId = searchParams.get("exeInstanceId");

  const handleOnChangePreviewResult = (previousResults) => {
    previousResults && setPreviousResults(previousResults);
    if (previousResults === "All") {
      setTestResults(initialTestResults);
    } else {
      const count = Number.parseInt(previousResults.split(" ")[1]);
      setTestResults(initialTestResults.slice(0, count));
    }
  };

  const handleClose = (result) => {
    setTestResults(testResults.filter((r) => r !== result));
  };

  const toggleFullScreenDialog = () => {
    setIsFullScreen((prevState) => !prevState);
  };

  const renderTestDataPreview = () => {
    const itemsToShow = 5;
    return (
      <Box className="previewTestData" component={Paper}>
        <DialogTitle className="previewTitleContainer" component="div">
          <Box className="space-between">
            {!isFullScreen && <CustomExpandIcon size="small" onClick={toggleFullScreenDialog} />}
            <Box>
              <PreviousResultsMenu
                previousResults={previousResults}
                handleOnChangePreviewResult={handleOnChangePreviewResult}
              />
              <CustomLoadingButton sx={{ marginRight: "25px" }} startIcon={<ExportIcon size="small" />}>
                Export Result
              </CustomLoadingButton>
            </Box>
          </Box>
          {!isFullScreen && (
            <IconButton
              onClick={() => setPreviewTestData(false)}
              size="small"
              color="error"
              sx={{ position: "absolute", top: 0, right: 0 }}>
              <CloseIcon />
            </IconButton>
          )}
        </DialogTitle>
        <Grid container sx={{ padding: "10px 12px" }}>
          {!testResults.length > 0 && (
            <>
              <Grid item xs={12}>
                <CustomCarousel itemsToShow={itemsToShow} totalCount={testResults?.length}>
                  {testResults.map((item, index) => (
                    <Box
                      key={index}
                      sx={{ flex: `0 0 ${100 / itemsToShow}%`, maxWidth: `${100 / itemsToShow}%`, p: 1 }}>
                      <ResultCard result={item} isSelected={index === testResults.length - 1} onClose={handleClose} />
                    </Box>
                  ))}
                </CustomCarousel>
              </Grid>
              <Grid item xs={12} mb={1}>
                <Divider />
              </Grid>
            </>
          )}
          <Grid item sm={9.5} className="border-right" pr={2}>
            <Box className="v-center" gap={14}>
              <Typography>
                {"Execution At"}&nbsp;: &nbsp;<strong>{formatEpochTimeLocale(exeInstanceDetails?.startedAt)}</strong>
              </Typography>
              <Typography>
                {"Duration "}&nbsp;: &nbsp;
                <strong>
                  {getTimeDifference(exeInstanceDetails?.startedAt, exeInstanceDetails?.finishedAt)?.readableFormat}
                </strong>
              </Typography>
              <Typography>
                {"Test Case Name"}&nbsp;: &nbsp;<strong>{exeInstanceDetails?.testCaseName}</strong>
              </Typography>
            </Box>
            <Box className="space-between" pt={1.6}>
              <StatusButtons selectedStatusBtn={selStatus} setSelectedStatusBtn={setSelStatus} />

              <Box sx={{ display: "none" }} className="v-center">
                <FormLabel sx={{ whiteSpace: "nowrap", marginRight: "8px" }}>Test data Name:</FormLabel>
                <SelectTestData />
                <StatusChip label="Fail" />
              </Box>
            </Box>
          </Grid>
          {exeInstanceDetails?.enableSelfHealing && (
            <Grid item sm={2.5} className="center space-between">
              <Box className="relative center" ml={3}>
                <Box width="60px" height="60px">
                  <Doughnut data={chartData} options={chartOptions} />
                </Box>
                <Box className="absolute column center">
                  <Typography>
                    <span style={{ color: "#61C5F6" }}>{exeInstanceDetails?.healingAppliedCount || 0}</span>/
                    {exeInstanceDetails?.testStepResultResponseDtoList?.length}
                  </Typography>
                </Box>
              </Box>
              <Typography variant="body2">Errors Fixed by Auto Healing</Typography>
            </Grid>
          )}
        </Grid>
        {loadingResults ? (
          <SkeletonLoader />
        ) : (
          <PreviewDataContainer exeInstanceDetails={exeInstanceDetails} selStatus={selStatus} />
        )}
      </Box>
    );
  };

  const getExeInstanceDetails = async () => {
    if (exeInstanceId) {
      setLoadingResults(true);
      try {
        const { data } = await testcaseServices.getExeInstanceStepWiseResult(exeInstanceId);
        setExeInstanceDetails(data);
      } catch (error) {
        console.log(error);
      }
      setLoadingResults(false);
    }
  };

  useEffect(() => {
    getExeInstanceDetails();
  }, []);

  return (
    <Dialog
      fullWidth={true}
      maxWidth="xl"
      open={true}
      onClose={() => {
        setPreviewTestData(false);
      }}
      sx={{ "& .MuiDialog-paper": { borderRadius: "10px", position: "relative" } }}>
      {isFullScreen ? (
        <FullScreenDialog open={isFullScreen} setOpen={toggleFullScreenDialog}>
          {renderTestDataPreview()}
        </FullScreenDialog>
      ) : (
        renderTestDataPreview()
      )}
    </Dialog>
  );
};

export default PreviewTestData;
