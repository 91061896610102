import { Close } from "@mui/icons-material";
import { Box, Typography, IconButton, Drawer, Button } from "@mui/material";
import { useSearchParams } from "react-router-dom";
const HelpTextCard = ({ data }) => {
  return (
    <>
      <Box className="flex column" sx={{ p: 1 }}>
        <Typography variant="bold">{data.title}</Typography>
        <Typography variant="bold" color={"primary"}>
          {data.syntax}
        </Typography>
        <ul>
          {data.description?.map((el) => (
            <li key={el}>{el}</li>
          ))}
        </ul>
      </Box>
    </>
  );
};

const helpDataTExtObj = [
  {
    title: "Random integer or decimal value",
    syntax: "[[RANDOM_NUMBER(n)]]",
    description: [
      "(n) specifies the number of digits.",
      "[[RANDOM_NUMBER(n)]].[[RANDOM_NUMBER(n)]] can be used to generate random decimal number.",
      "-[[RANDOM_NUMBER(n)]] indicates negative number with specified number of digits).",
    ],
  },
  {
    title: "Random text value",
    syntax: "[[RANDOM_TEXT(n)]]",
    description: ["(n) specifies the number of alphabets."],
  },
  {
    title: "Random date in specified format",
    syntax: "[[DATE(f)]]",
    description: [
      "Evaluates to a valid random date between Jan 1, 1970 - current date(f) specifies the date format. ",
      "Here are the possible valid date formats:",
      "MM-DD-YYYY",
      "DD-MM-YYYY",
      "YYYY-MM-DD",
      "*Other variations of the above 3 formats with '/' as the separator, with '.' as the separator.",
    ],
  },
  {
    title: "Current date in specified format",
    syntax: "[[TODAY(f)]]",
    description: [
      "(f) specifies the date format.",
      "Here are the possible valid date formats:",
      "MM-DD-YYYY",
      "DD-MM-YYYY",
      "YYYY-MM-DD",
      "*Other variations of the above 3 formats with '/' as the separator, with '.' as the separator.",
    ],
  },
  {
    title: "Next date in specified format",
    syntax: "[[TOMORROW(f)]]",
    description: [
      "(f) specifies the date format. ",
      "Here are the possible valid date formats:",
      "MM-DD-YYYY",
      "DD-MM-YYYY",
      "YYYY-MM-DD",
      "*Other variations of the above 3 formats with '/' as the separator, with '.' as the separator.",
    ],
  },
  {
    title: "Last date in specified format",
    syntax: "[[YESTERDAY(f)]]",
    description: [
      "(f) specifies the date format.",
      "Here are the possible valid date formats:",
      "MM-DD-YYYY",
      "DD-MM-YYYY",
      "YYYY-MM-DD",
      "*Other variations of the above 3 formats with '/' as the separator, with '.' as the separator.",
    ],
  },
  {
    title: "Random time in specified format",
    syntax: "[[TIME(f)]]",
    description: [
      "(f) specifies the time format. Time will be hh:mm",
      "Here are the possible valid time formats:",
      "24 // this indicates that the time will be in 24hr format as in 23:10",
      "12 // this indicates that the time will be in 12hr format as in 11:10PM",
    ],
  },
  {
    title: "Current time in specified format",
    syntax: "[[NOW(f)]]",
    description: [
      "(f) specifies the time format. Time will be hh:mm",
      "Here are the possible valid time formats:",
      "24 // this indicates that the time will be in 24hr format as in 23:10",
      "12 // this indicates that the time will be in 12hr format as in 11:10PM",
    ],
  },
  {
    title: "Current time in specified format",
    syntax: "[[NOW_PLUS_MINUTES(f, n)]]",
    description: [
      "(f) specifies the time format. Time will be hh:mm",
      "Here are the possible valid time formats:",
      "24 // this indicates that the time will be in 24hr format as in 23:10",
      "12 // this indicates that the time will be in 12hr format as in 11:10PM",
      "(n) specifies the minutes to be added to the current time. This value can be 1 - 60",
    ],
  },
];
const HelpTextTestData = () => {
  const [searchParams, setSearchParams] = useSearchParams({ showTestDataHelpText: false });
  const showTestDataHelpText = JSON.parse(searchParams.get("showTestDataHelpText"));

  const handleClose = () => {
    setSearchParams((prevParams) => {
      const newParams = new URLSearchParams(prevParams);
      newParams.set("showTestDataHelpText", false);
      return newParams;
    });
  };

  return (
    <Drawer
      anchor={"right"}
      open={showTestDataHelpText}
      sx={{
        height: "100vh",
        "& .MuiDrawer-paper": { width: "350px !important", p: "0px !important" },
      }}
      onClose={handleClose}>
      <Box sx={{ p: 1, borderBottom: "1px solid #ccc", position: "relative" }}>
        <IconButton
          color="error"
          size="small"
          onClick={handleClose}
          sx={{ position: "absolute", top: -1, right: "8px", p: 1 }}>
          <Close />
        </IconButton>
        <Typography variant="bold"> Understanding special cases</Typography>
      </Box>
      {helpDataTExtObj?.map((el) => (
        <HelpTextCard key={el} data={el} />
      ))}
      <Button
        variant="contained"
        size="small"
        sx={{ ml: "120px", mt: 2, position: "fixed", bottom: 10, right: 10 }}
        onClick={handleClose}>
        OK
      </Button>
    </Drawer>
  );
};

export default HelpTextTestData;
