import { Box, Button, Divider, Grid, IconButton, InputBase, Tooltip, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Search, QuestionMarkRounded } from "@mui/icons-material";
import { useLocation, useSearchParams } from "react-router-dom";
import { testcaseServices } from "../../Redux/services/testcase.services";

import { useState } from "react";

const BACKGROUND_COLORS = { Poor: "#FFCFCF", Avg: "#E1E7FF", Good: "#D7FFBE" };

const TEXT_COLORS = { Poor: "#F9594B", Avg: "#66A2EE", Good: "#00C163" };

const ResultBox = ({ type, count, title }) => (
  <Box className="v-center" gap={0.5}>
    <Typography
      className="center"
      sx={{
        backgroundColor: BACKGROUND_COLORS[type],
        borderRadius: "2px",
        maxHeight: "16px",
        minWidth: "16px",
        color: TEXT_COLORS[type],
        fontSize: 10,
        boxSizing: "border-box",
        textAlign: "center",
        p: 1,
      }}>
      {count}
    </Typography>
    {title && <Typography sx={{ color: TEXT_COLORS[type], fontSize: 12 }}>{title}</Typography>}
  </Box>
);

const APP_COUNT_KEYS = { macros: "noOfMacros", testcases: "noOfTestCases", configs: "noOfExecutionConfigs" };

const AutItem = ({ aut, currentPath, onClick }) => {
  const { id, type, name } = aut;
  const countKey = APP_COUNT_KEYS[currentPath];
  return (
    <Grid item xs={12}>
      <Box
        className="space-between"
        sx={{
          p: 1,
          cursor: "pointer",
          borderRadius: "4px",
          transition: "background 0.3s ease, transform 0.3s ease",
          "&:hover": { background: "#f0f0f0", transform: "translateY(-1px)" },
        }}
        onClick={() => {
          onClick(name, id);
        }}>
        <Box className="v-center" gap={2}>
          <img height={28} width={28} src={`./applicationTypes/${type?.toLowerCase()}.svg`} alt={type} />
          <Typography>{name}</Typography>
          {currentPath === "testcases" && <ResultBox type="Poor" count="Poor" />}
        </Box>
        <Button variant="outlined" sx={{ minWidth: 28, p: 0 }}>
          {aut[countKey] || 0}
        </Button>
      </Box>

      <Divider />
    </Grid>
  );
};

const SelectedAutList = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = location;
  const currentPath = pathname.split("/").pop();
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedAutType = searchParams.get("autType");
  const [searchTerm, setSearchTerm] = useState("");

  const { aut_list: listOfAuts } = useSelector((state) => state.aut);

  const handleHelperTextClick = () => {
    setSearchParams((params) => {
      const newParams = new URLSearchParams(params);
      newParams.delete("autType");
      return newParams;
    });
  };

  const handleAutClick = (name, id) => {
    setSearchParams((params) => {
      params.set("autName", name);
      params.set("autId", id);
      return params;
    });
    if (id) {
      dispatch(testcaseServices.fetchTestCases(id));
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredAuts = listOfAuts.filter((aut) => aut.name.toLowerCase().includes(searchTerm.toLowerCase()));

  return (
    <Box
      sx={{
        width: "25%",
        position: "fixed",
        right: 0,
        top: 0,
        bottom: 0,
        overflowY: "auto",
        bgcolor: "background.paper",
        pt: "120px",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      }}>
      {listOfAuts.length > 0 ? (
        <Box>
          <Box sx={{ px: 2, py: 1, gap: 1 }} className="space-between">
            <Typography variant="h6" sx={{ opacity: 0.9 }}>
              {selectedAutType} Aut List
            </Typography>
            <IconButton color="warning" size="small" sx={{ p: 0, opacity: 0.9 }} onClick={handleHelperTextClick}>
              <Tooltip title="Help Text" placement="top" arrow>
                <QuestionMarkRounded sx={{ fontSize: "large" }} />
              </Tooltip>
            </IconButton>
          </Box>
          <Divider />
          <Grid container gap={0.5} sx={{ p: 2 }}>
            <Grid item xs={12}>
              <Box
                className="v-center"
                sx={{
                  "& input": { p: "0px !important" },
                  p: "6px",
                  color: "#666",
                  background: "#f0f0f0",
                  borderRadius: "4px",
                }}>
                <InputBase
                  fullWidth
                  size="small"
                  placeholder="Search aut"
                  sx={{ p: 0 }}
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
                <IconButton size="small" sx={{ p: 0 }}>
                  <Search fontSize="1rem" />
                </IconButton>
              </Box>
            </Grid>
            {filteredAuts.map((aut, index) => (
              <AutItem key={index} aut={aut} currentPath={currentPath} onClick={handleAutClick} />
            ))}
          </Grid>
        </Box>
      ) : (
        <Box
          sx={{ textAlign: "center", opacity: 0.7, mt: "50px", cursor: "pointer", height: "250px" }}
          onClick={handleHelperTextClick}>
          <Typography variant="h6">No auts found in {selectedAutType}</Typography>
          <Typography>Click to View Help Text...?</Typography>
        </Box>
      )}
    </Box>
  );
};

export default SelectedAutList;
