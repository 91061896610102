import { useCallback, useMemo } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import {
  Box,
  Checkbox,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useSelector, useDispatch } from "react-redux";
import CustomButton from "../../../CustomComponents/CustomButtons/CustomButton";
import TestStepRow from "./TestStepRow";
import { setSelectedStepsData, setSelectedStep, setTestStepsData } from "../../../../Redux/reducers/testcaseSlice";
import { useDrop } from "react-dnd";
import CustomTablePagination from "../../../CustomComponents/CustomTablePagination";
import { BulkEditButton, CustomDeleteIcon, CustomEditIcon } from "../../../CustomComponents/Icons/IconButtons";
import { InfoOutlined as InfoIcon } from "@mui/icons-material";
import { useCheckAccess } from "../../../../CustomHooks/useCheckAccess";

const ItemTypes = {
  STEP: "STEP",
  MACRO: "MACRO",
};

const useStepViewType = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const stepViewType = searchParams.get("stepViewType");

  const updateStepViewType = (type) => {
    setSearchParams((prev) => {
      const newState = new URLSearchParams(prev);
      newState.set("stepViewType", type);
      return newState;
    });
  };

  return { stepViewType, updateStepViewType };
};

const ListOfSteps = ({ page, rowsPerPage, setPage, setRowsPerPage, setRowData }) => {
  const { checkAccess } = useCheckAccess();
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = location;
  const { stepViewType, updateStepViewType } = useStepViewType();
  const [, setSearchParams] = useSearchParams();
  const { test_steps_data: stepsData, selected_steps_data: selectedStepsData } = useSelector((state) => state.testcase);
  const isCreatingOrEditing = stepViewType === "add";

  const formattedData = useMemo(
    () => stepsData?.slice((page - 1) * rowsPerPage, page * rowsPerPage),
    [stepsData, page, rowsPerPage],
  );

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const newStepsData = [...stepsData];
      const [draggedStep] = newStepsData.splice(dragIndex, 1);
      newStepsData.splice(hoverIndex, 0, draggedStep);
      dispatch(setTestStepsData(newStepsData));
    },
    [stepsData, dispatch],
  );

  const handleDrop = useCallback(
    (item, hoverIndex) => {
      if (item.type === ItemTypes.MACRO) {
        const newStepsData = [...stepsData];
        newStepsData.splice(
          hoverIndex,
          0,
          item.row?.steps ? item.row : { ...item.row, steps: [], sequenceNumber: hoverIndex + 1 },
        );
        dispatch(setTestStepsData(newStepsData));
      }
    },
    [stepsData, dispatch],
  );

  const [, drop] = useDrop({
    accept: [ItemTypes.MACRO],
    drop: (item, monitor) => {
      if (monitor.didDrop()) {
        handleDrop(item, item.hoverIndex);
      }
    },
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (selectedStepsData.length) {
      dispatch(setSelectedStepsData([]));
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(Number(event.target.value));
    setPage(1);
    if (selectedStepsData.length) {
      dispatch(setSelectedStepsData([]));
    }
  };

  const handleSelectAllCheckboxChange = (event) => {
    dispatch(setSelectedStepsData(event.target.checked ? stepsData : []));
  };

  const handleAddStep = () => {
    const newStep = { isNewStep: true, sequenceNumber: stepsData?.length + 1 };
    const newStepsData = [...stepsData, newStep];
    dispatch(setTestStepsData(newStepsData));
    updateStepViewType("add");
    dispatch(setSelectedStep(newStep));

    const newPage = Math.ceil(newStepsData.length / rowsPerPage);
    setPage(newPage);
  };

  const handleEditStep = () => {
    updateStepViewType("add");
    dispatch(setSelectedStep(selectedStepsData[0]));
  };

  const handleDeleteSteps = () => {
    const stepsIdsToDelete = selectedStepsData.map((step) => step.sequenceNumber);
    const filteredData = stepsData.filter((step) => !stepsIdsToDelete.includes(step.sequenceNumber));
    dispatch(setTestStepsData(filteredData));
    dispatch(setSelectedStepsData([]));
  };

  const onClickMacros = () => {
    updateStepViewType("macros");
    dispatch(setSelectedStep(null));
  };

  const onClickCreateMacros = () => {
    updateStepViewType("createMacros");
    dispatch(setSelectedStep(null));
  };

  const toggleBulkEdit = () => {
    setRowData([...stepsData]?.filter((each) => !each?.isNewStep));
    setSearchParams((prev) => {
      const newState = new URLSearchParams(prev);
      newState.set("isBulkEdit", true);
      newState.delete("stepViewType");
      return newState;
    });
  };

  const canEdit = selectedStepsData.length === 1;
  const canDelete = selectedStepsData.length > 0;

  return (
    <Grid item xs={stepViewType ? 8 : 12} ref={drop}>
      <Box className="space-between" sx={{ background: "#F4F1FF", px: 1, height: 42 }}>
        <Box className="v-center" ml={1} gap={1}>
          <Typography variant="bold">
            List of Steps -{" "}
            <Typography variant="bold" sx={{ color: "#E68200" }} component="span">
              [ {stepsData?.length} ]
            </Typography>
          </Typography>

          <BulkEditButton onClick={toggleBulkEdit} disabled={stepViewType === "add"}>
            Bulk Edit
          </BulkEditButton>
        </Box>
        <Box className="v-center">
          <Tooltip
            title='"single" click to enter preview mode and "double" click to enter edit mode directly'
            placement="top-end"
            arrow>
            <IconButton size="small">
              <InfoIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <CustomEditIcon
            onClick={handleEditStep}
            size="small"
            disabled={!canEdit || isCreatingOrEditing}
            height="20"
            width="18"
          />
          <CustomDeleteIcon
            onClick={handleDeleteSteps}
            color="error"
            disabled={!canDelete || isCreatingOrEditing}
            size="small"
            height="20"
            width="20"
          />

          {!pathname?.includes("macros") && (
            <>
              {checkAccess("Macros", "Create") && (
                <CustomButton
                  sx={{ mr: 1, py: 0.2 }}
                  onClick={onClickCreateMacros}
                  disabled={isCreatingOrEditing}
                  color="warning"
                  variant="outlined">
                  Create Macro
                </CustomButton>
              )}
              <CustomButton
                disabled={isCreatingOrEditing}
                sx={{ mr: 1, py: 0.2 }}
                onClick={onClickMacros}
                color="error"
                variant="outlined">
                Macros
              </CustomButton>
            </>
          )}
          <CustomButton
            disabled={isCreatingOrEditing}
            sx={{ py: 0.2 }}
            onClick={handleAddStep}
            color="success"
            variant="outlined"
            startIcon={<AddOutlinedIcon />}>
            Add Step
          </CustomButton>
        </Box>
      </Box>
      <TableContainer sx={{ minHeight: 440, maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="left">
                <Checkbox
                  disabled={formattedData?.length === 0}
                  checked={selectedStepsData.length === formattedData?.length && formattedData?.length !== 0}
                  onChange={handleSelectAllCheckboxChange}
                />
              </TableCell>
              <TableCell align="left">S.No</TableCell>
              <TableCell align="left" sx={{ width: "35%" }}>
                Name
              </TableCell>
              <TableCell align="left" sx={{ width: "50%" }}>
                Description
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {formattedData?.map((row, index) => (
              <TestStepRow // eslint-disable-next-line no-mixed-operators
                index={(page - 1) * rowsPerPage + index}
                key={row.sequenceNumber}
                moveRow={moveRow}
                row={row}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {stepsData?.length !== 0 && (
        <CustomTablePagination
          currentPage={page}
          rowsLength={stepsData?.length}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </Grid>
  );
};

export default ListOfSteps;
