import dayjs from "dayjs";

export const formatDateLocale = (dateString) => {
  const date = new Date(dateString);
  const options = {
    weekday: "short",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: false,
  };
  return date.toLocaleString("en-US", options);
};

export const formatDateTime = (date) => {
  return dayjs(date || new Date()).format("DD-MM-YYYY hh:mm A");
};
export const formatISODateTime = (date) => {
  return dayjs(date || new Date()).format("YYYY-MM-DDTHH:mm");
};
