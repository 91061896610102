import { Box, IconButton, InputBase, Typography } from "@mui/material";
import { Search } from "@mui/icons-material";
import InnerHeader from "../Layout/InnerHeader";
import MacrosList from "./MacrosList";
import { useState } from "react";
import CustomButton from "../CustomComponents/CustomButtons/CustomButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import CustomBackButton from "../CustomComponents/CustomButtons/CustomBackButton";
import AddIcon from "@mui/icons-material/Add";
import SelectAutType from "../SelectAutType";
import SelectedAutDetails from "../TestCases/SelectedAutDetails";
import { useCheckAccess } from "../../CustomHooks/useCheckAccess";

const Macros = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { checkAccess } = useCheckAccess();
  const [searchInput, setSearchInput] = useState("");
  const autId = searchParams.get("autId");
  const autType = searchParams.get("autType");
  const autName = searchParams.get("autName");

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  const onClickCreateMacro = () => {
    navigate(`create?autName=${autName}&autId=${autId}&autType=${autType}&isBulkEdit=true`);
  };

  const handleBack = () => {
    // navigate(-1);
    navigate(`/?appType=${autType}`);
  };

  return (
    <>
      <InnerHeader>
        <Box className="v-center" width="100%" sx={{ gap: 1 }}>
          {autName && <CustomBackButton onClick={handleBack}>Back</CustomBackButton>}
          <Box className="v-center" gap={1}>
            <Typography variant="h6">MACROS</Typography>

            {autName && <SelectedAutDetails />}
          </Box>

          <Box className="v-center" sx={{ ml: "auto", gap: 1 }}>
            {autId && checkAccess("Macros", "Create") && (
              <CustomButton
                onClick={onClickCreateMacro}
                startIcon={<AddIcon />}
                color="success"
                variant="contained"
                size="small"
                className="no-shadow">
                Create Macro
              </CustomButton>
            )}
            <Box
              className="v-center"
              sx={{ pt: "4px", pb: "1px", px: 1, color: "#CCCCCC", background: "#EEEEEE", borderRadius: "4px" }}>
              <InputBase
                fullWidth
                size="small"
                className="searchInput"
                placeholder="Search Macro"
                value={searchInput}
                onChange={handleSearchChange}
              />
              <IconButton sx={{ p: 0 }} size="small">
                <Search fontSize="0.75rem" />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </InnerHeader>
      <Box className="pt74">{autType && autId ? <MacrosList /> : <SelectAutType />}</Box>
    </>
  );
};

export default Macros;
