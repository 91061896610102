import { useEffect, useState } from "react";
import { Box, Checkbox, Grid, IconButton, Typography } from "@mui/material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { testcaseServices } from "../../../Redux/services/testcase.services";
import { setConfigTestCases } from "../../../Redux/reducers/testcaseSlice";
import SkeletonLoader from "../../SkeletonLoader";
import PreviewTestData from "./PreviewTestData";

// Extend dayjs with the relativeTime plugin
dayjs.extend(relativeTime);

const TestDataCard = ({ testData, configTestCases, testCaseId }) => {
  const dispatch = useDispatch();
  const [, setSearchParams] = useSearchParams();

  const isTestDataSelected = configTestCases
    ?.find((testCase) => testCase?.testCaseId === testCaseId)
    ?.testDataIds?.some((data) => data?.id === testData?.id);
  const handleSelectTestData = (id, version) => {
    const updatedConfigTestCases = configTestCases.map((testCase) =>
      testCase.testCaseId === testCaseId
        ? {
            ...testCase,
            testDataIds: testCase.testDataIds.some((data) => data.id === id)
              ? testCase.testDataIds.filter((data) => data.id !== id)
              : [...testCase.testDataIds, { id, version }],
          }
        : testCase,
    );

    dispatch(setConfigTestCases(updatedConfigTestCases));
  };

  const handlePreviewTestData = () => {
    setSearchParams((params) => {
      params.set("testDataId", testData?.id);
      return params;
    });
  };

  return (
    <Grid item xs={6}>
      <Box sx={{ border: "1px solid #DCD4FB", p: 1 }} className="v-center" gap={0.5}>
        <Checkbox
          checked={isTestDataSelected}
          onChange={() => handleSelectTestData(testData?.id, testData?.version)}
          sx={{ p: 0 }}
          size="small"
        />
        <Box className="space-between" width="100%">
          <Box className="flex column">
            <Typography variant="bold" sx={{ fontSize: "10px" }}>
              {testData.name}
            </Typography>
            <Typography sx={{ fontSize: "10px", color: "#9A9A99" }}>{dayjs(testData.createdAt).fromNow()}</Typography>
          </Box>
          <IconButton onClick={handlePreviewTestData} color="primary" size="small" title="Preview">
            <VisibilityOutlinedIcon />
          </IconButton>
        </Box>
      </Box>
    </Grid>
  );
};

const ExecutionTestDataList = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const testCaseId = Number.parseInt(searchParams.get("testCaseId"));
  const testDataId = Number.parseInt(searchParams.get("testDataId"));
  const version = Number.parseInt(searchParams.get("version"));
  const { config_test_cases: configTestCases } = useSelector((state) => state.testcase);
  const [testDataList, setTestDataList] = useState([]);
  const [isLoadingTestData, setIsLoadingTestData] = useState(false);

  const fetchTestDataList = async () => {
    setIsLoadingTestData(true);
    try {
      const { data } = await testcaseServices.getTestData(testCaseId, version);
      setTestDataList(data?.testDataResponseList || []);
    } catch (error) {
      console.error("Failed to fetch test data list", error);
    }
    setIsLoadingTestData(false);
  };

  useEffect(() => {
    if (testCaseId && version) {
      fetchTestDataList();
    } else {
      setTestDataList([]);
    }
  }, [testCaseId]);

  const handleClosePreview = () => {
    setSearchParams((params) => {
      params.delete("testDataId");
      return params;
    });
  };

  const message = !testCaseId
    ? "You have not selected any test case. Please select a test case."
    : "Selected test case has no test data.";

  return (
    <>
      <Grid item xs={9} sx={{ borderRight: "1px solid #ccc" }}>
        <Box className="space-between" sx={{ background: "#F4F1FF", px: 1, height: 42 }}>
          <Typography variant="bold">Test Data List</Typography>
        </Box>
        {isLoadingTestData ? (
          <SkeletonLoader />
        ) : (
          <Box sx={{ minHeight: "50vh", maxHeight: "65vh" }}>
            <Grid sx={{ p: 1, overflowY: "auto" }} container spacing={1}>
              {testDataList.length ? (
                testDataList.map((testData, index) => (
                  <TestDataCard
                    key={testData.id || index}
                    configTestCases={configTestCases}
                    testData={testData}
                    testCaseId={testCaseId}
                  />
                ))
              ) : (
                <Grid item xs={12} sx={{ height: 300 }}>
                  <Box height="100%" textAlign="center" className="center">
                    <Typography variant="h6" sx={{ opacity: 0.6 }}>
                      {message}
                    </Typography>
                  </Box>
                </Grid>
              )}
            </Grid>
          </Box>
        )}
      </Grid>
      {Boolean(testDataId) && <PreviewTestData testDataList={testDataList} handleClose={handleClosePreview} />}
    </>
  );
};

export default ExecutionTestDataList;
