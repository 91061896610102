import { Link, useNavigate } from "react-router-dom";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { Box, Button, IconButton, Tooltip } from "@mui/material";
import { useCheckAccess } from "../../../CustomHooks/useCheckAccess";

// Component for linking to test cases
function LinkComponentTestCases({ data, value }) {
  const navigate = useNavigate();
  const { checkAccess } = useCheckAccess();

  const handleViewTestCases = () => {
    navigate(`/testcases?&autType=${data?.type}&autName=${data?.name}&autId=${data.id}`);
  };

  const handleCreateTestCase = () => {
    navigate(`/testcases/create?&autType=${data?.type}&autName=${data?.name}&autId=${data.id}`);
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center", gap: 1 }}>
      <Button
        onClick={checkAccess("testCases", "Preview") ? handleViewTestCases : null}
        sx={{ p: 0, minWidth: 28 }}
        variant="outlined"
        size="small">
        {value}
      </Button>
      {checkAccess("testCases", "Create") && (
        <IconButton size="small" onClick={handleCreateTestCase}>
          <AddOutlinedIcon sx={{ color: "#381FD1" }} />
        </IconButton>
      )}
    </Box>
  );
}

// Component for linking to test suites
function LinkComponentMacros({ data, value }) {
  const navigate = useNavigate();
  const { checkAccess } = useCheckAccess();

  const handleViewTestMacros = () => {
    navigate(`/macros?autType=${data?.type}&autName=${data?.name}&autId=${data.id}`);
  };

  const handleCreateMacro = () => {
    navigate(`/macros/create?autName=${data?.name}&autId=${data.id}&autType=${data?.type}`);
  };
  return (
    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center", gap: 1 }}>
      <Button
        onClick={checkAccess("Macros", "Preview") ? handleViewTestMacros : null}
        sx={{ p: 0, minWidth: 28 }}
        variant="outlined"
        size="small">
        {value}
      </Button>
      {checkAccess("Macros", "Create") && (
        <IconButton size="small" onClick={handleCreateMacro}>
          <AddOutlinedIcon sx={{ color: "#381FD1" }} />
        </IconButton>
      )}
    </Box>
  );
}

function LinkComponentExecutionConfigs({ data, value }) {
  const navigate = useNavigate();
  const { checkAccess } = useCheckAccess();

  const handleViewTestConfig = () => {
    navigate(`/configs?autType=${data?.type}&autName=${data?.name}&autId=${data.id}`);
  };

  const handleCreateMacroConfig = () => {
    navigate(`/configs/create?autName=${data?.name}&autId=${data.id}&autType=${data?.type}`);
  };
  return (
    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center", gap: 1 }}>
      <Button
        onClick={checkAccess("Macros", "Preview") ? handleViewTestConfig : null}
        sx={{ p: 0, minWidth: 28 }}
        variant="outlined"
        size="small">
        {value}
      </Button>
      {checkAccess("Macros", "Create") && (
        <IconButton size="small" onClick={handleCreateMacroConfig}>
          <AddOutlinedIcon sx={{ color: "#381FD1" }} />
        </IconButton>
      )}
    </Box>
  );
}

const ProgressBar = ({ data }) => {
  if (!data) {
    return null; // Return null if no data is provided
  }

  const { passCount, failCount, otherCount } = data;
  const totalCount = passCount + failCount + otherCount;

  // Calculate the percentages
  const greenPercentage = totalCount === 0 ? 0 : (passCount / totalCount) * 100;
  const redPercentage = totalCount === 0 ? 0 : (failCount / totalCount) * 100;
  const greyPercentage = totalCount === 0 ? 0 : (otherCount / totalCount) * 100;

  if (totalCount === 0) {
    return (
      <Box
        className="progressBar"
        sx={{ position: "relative", width: "100%", "& .progress-item": { position: "absolute", height: "8px" } }}>
        <Tooltip title="Other: 0" arrow>
          <Box
            className="progress-item"
            sx={{
              width: "100%",
              backgroundColor: "#DDE0E5",
              borderRadius: "5px",
            }}
          />
        </Tooltip>
      </Box>
    );
  }

  return (
    <Box
      className="progressBar"
      sx={{ position: "relative", width: "100%", "& .progress-item": { position: "absolute", height: "8px" } }}>
      <Tooltip title={`Pass: ${passCount}`} arrow>
        <Box
          className="progress-item"
          sx={{
            width: `${greenPercentage}%`,
            backgroundColor: "#00C163",
            borderRadius: greenPercentage > 0 && redPercentage === 0 && greyPercentage === 0 ? "5px" : "5px 0 0 5px",
            left: "0",
          }}
        />
      </Tooltip>
      <Tooltip title={`Fail: ${failCount}`} arrow>
        <Box
          className="progress-item"
          sx={{
            width: `${redPercentage}%`,
            backgroundColor: "#F9594B",
            borderRadius: greenPercentage === 0 && redPercentage > 0 && greyPercentage === 0 ? "5px" : "0",
            left: `${greenPercentage}%`,
          }}
        />
      </Tooltip>
      <Tooltip title={`Other: ${otherCount}`} arrow>
        <Box
          className="progress-item"
          sx={{
            width: `${greyPercentage}%`,
            backgroundColor: "#DDE0E5",
            borderRadius: greyPercentage > 0 && greenPercentage === 0 && redPercentage === 0 ? "5px" : "0 5px 5px 0",
            left: `${greenPercentage + redPercentage}%`,
          }}
        />
      </Tooltip>
    </Box>
  );
};

// Component for linking to agent test cases
function LinkComponentAgent({ data, value }) {
  return <Link to={`/testcases/agent/${data?.agentId}/${data?.id}`}>{value}</Link>;
}

// Column definitions for the main AUT list
export const AutListHeadCells = [
  {
    headerCheckboxSelection: true,
    checkboxSelection: true,
    lockPosition: "left",
    cellClass: "locked-col",
    suppressColumnsToolPanel: true,
    sortable: false,
    filter: false,
    width: 50,
    maxWidth: 50,
  },
  {
    field: "name",
    headerName: "Application Name",
    headerTooltip: "Application Name",
    suppressColumnsToolPanel: true,
    sortable: true,
    filter: true,
    flex: 2,
  },
  {
    field: "description",
    headerName: "Description",
    headerTooltip: "Description",
    filter: false,
    sortable: false,
    flex: 2,
  },
  {
    field: "noOfTestCases",
    headerName: "Test Cases",
    cellRenderer: LinkComponentTestCases,
    headerTooltip: "Test Cases",
    sortable: false,
    filter: false,
    flex: 1,
  },
  {
    field: "noOfMacros",
    headerName: "Macros",
    cellRenderer: LinkComponentMacros,
    headerTooltip: "Macros",
    sortable: false,
    filter: false,
    flex: 0.8,
  },
  {
    field: "noOfExecutionConfigs",
    headerName: "Execution Configs",
    cellRenderer: LinkComponentExecutionConfigs,
    headerTooltip: "Execution Configs",
    sortable: false,
    filter: false,
    flex: 1.5,
  },
  {
    field: "scoreCard",
    headerName: "Score Card",
    cellRenderer: ProgressBar,
    headerTooltip: "Score Card",
    sortable: false,
    filter: false,
    flex: 2,
  },
];

// Column definitions for the agent-specific AUT list
export const AutListHeadCellsAgent = [
  {
    field: "name",
    headerName: "Name",
    suppressColumnsToolPanel: true,
    filter: false,
    sortable: false,
    headerTooltip: "Name",
  },
  {
    field: "description",
    headerName: "Description",
    suppressColumnsToolPanel: true,
    filter: true,
    sortable: true,
    headerTooltip: "Description",
  },
  {
    field: "url",
    headerName: "URL/Executable File Path",
    sortable: true,
    filter: false,
    headerTooltip: "URL/Executable File Path",
  },
  {
    field: "noOfTestCases",
    headerName: "Test Cases",
    sortable: true,
    cellRenderer: LinkComponentAgent,
    filter: false,
    headerTooltip: "Test Cases",
  },
  {},
];
