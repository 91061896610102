import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputBase,
  Slide,
  Typography,
} from "@mui/material";
import { forwardRef, useEffect, useState } from "react";
import { Search } from "@mui/icons-material";
import CancelIcon from "@mui/icons-material/Cancel";

import DeviceCard from "./DeviceCard";
import CustomLoadingButton from "../../CustomComponents/CustomButtons/CustomLoadingButton";
import SkeletonLoader from "../../SkeletonLoader";
import { enginesServices } from "../../../Redux/services/engines.services";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SelectDevices = ({ engineId, mobileDeviceIdList, handleClose }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [loadingDevices, setLoadingDevices] = useState(false);
  const [devicesList, setDevicesList] = useState([]);
  const [loadingLinkDevices, setLoadingLinkDevices] = useState(false);

  const [selectedDevices, setSelectedDevices] = useState(mobileDeviceIdList || []);

  const fetchDevicesList = async () => {
    setLoadingDevices(true);
    try {
      const res = await enginesServices.getAllDevicesList();
      setDevicesList(res.data);
    } catch (e) {
      console.error(e);
    }
    setLoadingDevices(false);
  };

  const handleSelectDevice = (selectedDevice) => {
    setSelectedDevices((prevState) => {
      const isDeviceSelected = prevState.some((deviceId) => deviceId === selectedDevice.id);
      if (isDeviceSelected) {
        return prevState.filter((deviceId) => deviceId !== selectedDevice.id);
      }
      return [...prevState, selectedDevice?.id];
    });
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleAddEngineDevices = async () => {
    setLoadingLinkDevices(true);
    try {
      await enginesServices.linkDevices({
        engineId: engineId,
        deviceIdList: selectedDevices,
      });
      handleClose(true);
    } catch (e) {
      console.log(e);
    }
    setLoadingLinkDevices(false);
  };
  const filteredDevices = devicesList.filter((device) => device.name.toLowerCase().includes(searchTerm.toLowerCase()));

  useEffect(() => {
    fetchDevicesList();
  }, []);

  return (
    <Dialog
      open={true}
      fullWidth={true}
      maxWidth="lg"
      TransitionComponent={Transition}
      keepMounted
      onClose={() => handleClose(null)}
      sx={{ "& .MuiPaper-root": { overflow: "visible" } }}>
      <DialogTitle textAlign="center" sx={{ py: 1 }}>
        <Box className="space-between">
          <Typography variant="h6">Select Devices</Typography>
          <Box
            className="v-center"
            sx={{ p: "4px", px: 1, color: "#CCCCCC", background: "#EEEEEE", borderRadius: "4px", "& input": { p: 0 } }}>
            <InputBase placeholder="Search Device" value={searchTerm} onChange={handleSearchChange} />
            <IconButton sx={{ p: 0 }}>
              <Search fontSize="small" />
            </IconButton>
          </Box>
          <IconButton
            onClick={() => handleClose(null)}
            sx={{ position: "absolute", top: -12, right: -12 }}
            size="small"
            color="error">
            <CancelIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        {loadingDevices && <SkeletonLoader />}
        {filteredDevices?.length > 0 ? (
          <Box sx={{ display: "grid", gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))", gap: 2 }}>
            {filteredDevices.map((device) => (
              <DeviceCard
                key={device.id}
                device={device}
                selectedDevices={selectedDevices}
                mobileDeviceIdList={mobileDeviceIdList}
                handleSelectDevice={() => handleSelectDevice(device)}
              />
            ))}
          </Box>
        ) : (
          <Box className="center column">
            <img src="./placeholderImages/no-mobile.svg" width={140} alt="" />

            <Typography variant="h6" sx={{ opacity: 0.6, my: 2 }}>
              No Devices Found
            </Typography>
          </Box>
        )}
        <DialogContentText sx={{ mt: 2 }}>
          NOTE: Test case can be executed only after providing test data. You can provide the test data at a later point
          of time also from the Test Case Management page.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <CustomLoadingButton
          loading={loadingLinkDevices}
          disabled={!filteredDevices?.length || loadingLinkDevices}
          onClick={handleAddEngineDevices}>
          Link Devices
        </CustomLoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default SelectDevices;
