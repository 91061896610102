import { Box, Grid, IconButton, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import CancelIcon from "@mui/icons-material/Cancel";
import TestDataList from "../../TestCases/CreateTestData/TestDataList";
import CreateTestDataForm from "../../TestCases/CreateTestData/CreateTestDataForm";
import { useSelector } from "react-redux";
import { forwardRef, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { isDataRequiredForStep } from "../../../utilities/defaultData";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const PreviewTestData = ({ testDataList, handleClose }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const testDataId = Number.parseInt(searchParams.get("testDataId"));

  const { test_steps_data: stepsData, step_action_data: stepActionData } = useSelector((state) => state.testcase);
  const [selectedTestData, setSelectedTestData] = useState([]);
  const [testDataItem, setTestDataItem] = useState(testDataList?.[0]);

  const onClickTestData = (testDataItem) => {
    const clonedStepsData = JSON.parse(JSON.stringify(stepsData));
    setTestDataItem(testDataItem);
    setSearchParams((params) => {
      params.set("testDataId", testDataItem?.id);
      return params;
    });

    const testDataDetails = clonedStepsData.map((el) => ({
      ...el,
      value: testDataItem?.data[el.id] || "",
      testDataId: testDataItem?.id,
    }));
    setSelectedTestData(isDataRequiredForStep(testDataDetails, stepActionData));
  };

  useEffect(() => {
    const clonedStepsData = JSON.parse(JSON.stringify(stepsData));

    const selTestData = testDataList?.find((each) => each?.id === testDataId);
    const testDataDetails = clonedStepsData.map((el) => ({
      ...el,
      value: selTestData?.data[el.id] || "",
      testDataId: selTestData?.id,
    }));
    setSelectedTestData(isDataRequiredForStep(testDataDetails, stepActionData));
    setTestDataItem(selTestData);
  }, []);
  return (
    <Dialog
      fullWidth={true}
      maxWidth={"lg"}
      open={true}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}>
      <DialogContent sx={{ p: 0 }}>
        <Box px={1} className="space-between">
          <Typography variant="h6">Preview </Typography>
          <IconButton onClick={handleClose} sx={{ float: "right" }} size="small" color="error">
            <CancelIcon fontSize="small" />
          </IconButton>
        </Box>

        <Grid container sx={{ borderBottom: "1px solid #ccc" }}>
          <TestDataList onClickTestData={onClickTestData} testDataList={testDataList} isPreview={true} />
          <CreateTestDataForm
            testDataItem={testDataItem}
            stepActionData={stepActionData}
            selectedTestData={selectedTestData}
            isPreview={true}
          />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default PreviewTestData;
