import { Box, Typography } from "@mui/material";
import InnerHeader from "../Layout/InnerHeader";
import AgentsList from "./AgentsList";

export default function Agents() {
  return (
    <Box sx={{ width: "100%" }}>
      <InnerHeader>
        <Typography variant="h6">AGENT MANAGEMENT</Typography>
      </InnerHeader>
      <AgentsList />
    </Box>
  );
}
