import { IconButton, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import DonutSmallOutlinedIcon from "@mui/icons-material/DonutSmallOutlined";

export function ActionsCellOfAgentAutList(props) {
  const row = props?.data;
  return (
    <>
      <Tooltip title="Reports" placement="top-end" arrow>
        <Link to={`/testreports/${row.id}`}>
          <IconButton size="small">
            <DonutSmallOutlinedIcon fontSize="12px" />
          </IconButton>
        </Link>
      </Tooltip>
    </>
  );
}
