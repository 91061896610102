import { IconButton, Modal, Box } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

export default function ModalPopup(props) {
  const { open, setOpen, data, width } = props;

  return (
    <Box>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box className="MuiBox-scroll customModel" sx={{ width: width || "auto" }}>
          <IconButton
            className="cPopup"
            onClick={() => {
              setOpen(false);
            }}
            color="error"
            aria-label="Close Popup">
            <HighlightOffIcon />
          </IconButton>
          {data}
        </Box>
      </Modal>
    </Box>
  );
}
