import { useState } from "react";
import {
  Box,
  TextField,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Tabs,
  Tab,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const FormTabsSF = ({ label, formData, register, errors }) => {
  const [tabValue, setTabValue] = useState("one");
  const [showPassword, setShowPassword] = useState(false);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Box className="formTabs" sx={{ width: "100%" }}>
      <Tabs value={tabValue} onChange={handleTabChange}>
        <Tab value="one" label={label?.tab1} />
        <Tab value="two" label={label?.tab2} />
      </Tabs>

      {tabValue === "one" && (
        <Box className="authFields" sx={{ mt: 2 }}>
          <Typography>
            <strong>{label?.tab1}:</strong>
          </Typography>

          <Grid item xs={12}>
            {label?.type === "password" && (
              <FormControl size="small" fullWidth variant="outlined">
                <InputLabel required htmlFor="outlined-adornment-password">
                  {label?.tab1}
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  defaultValue={formData?.autProperties?.password}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={togglePasswordVisibility}
                        onMouseDown={handleMouseDownPassword}
                        edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label={label?.tab1}
                  {...register("password", {
                    required: true,
                    minLength: {
                      value: 4,
                      message: "Minimum length 4 characters",
                    },
                  })}
                  error={Boolean(errors?.password)}
                  helperText={errors?.password?.message || null}
                />
              </FormControl>
            )}

            {label?.type === "text" && (
              <TextField
                fullWidth
                size="small"
                label={label?.tab1}
                defaultValue={formData?.autProperties?.securitytoken}
                {...register("securitytoken", {
                  minLength: {
                    value: 4,
                    message: "Minimum length 4 characters",
                  },
                })}
                error={Boolean(errors?.securitytoken)}
                helperText={errors?.securitytoken?.message || null}
              />
            )}
          </Grid>
        </Box>
      )}

      {tabValue === "two" && (
        <Box className="authFields" sx={{ mt: 2 }}>
          <Typography>
            <strong>{label?.tab2}:</strong>
          </Typography>

          <Grid item xs={12}>
            <TextField
              fullWidth
              size="small"
              label={label?.tab2}
              defaultValue={formData?.autProperties?.azurekeyvault}
            />
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default FormTabsSF;
