import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {
  Button,
  IconButton,
  Grid,
  TextField,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Divider,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomLoadingButton from "../../CustomComponents/CustomButtons/CustomLoadingButton";
import CustomButton from "../../CustomComponents/CustomButtons/CustomButton";
import CustomSwitchButton from "../../CustomComponents/CustomSwitchButton";
import { enginesServices } from "../../../Redux/services/engines.services";
import { showToast } from "../../../Redux/reducers/toastSlice";
import { useDispatch } from "react-redux";

const ReusableTextField = ({ name, control, label, placeholder, size, rules, defaultValue, ...rest }) => (
  <Box p={1}>
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field, fieldState }) => (
        <TextField
          {...field}
          label={label}
          required
          placeholder={placeholder}
          size={size}
          error={Boolean(fieldState.error)}
          helperText={fieldState.error ? fieldState.error.message : null}
          sx={{ width: "100%" }}
          {...rest}
        />
      )}
    />
  </Box>
);

const AddDeviceForm = ({ handleClose, defaultEditData, fetchDevicesList }) => {
  const dispatch = useDispatch();
  const { handleSubmit, control, setValue, getValues } = useForm();
  const [capabilities, setCapabilities] = useState(defaultEditData?.capabilities || []);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const { t } = useTranslation();

  const onSubmit = async (data) => {
    setLoadingCreate(true);
    try {
      const modifiedData = {
        ...(defaultEditData?.id && { id: defaultEditData.id }),
        name: data.name,
        mobileOs: data.mobileOs,
        osVersion: data.osVersion,
        udid: data.udid,
        isDisabled: data.isDisabled,
        environmentProvider: "KITAP",
        capabilities: data.capabilities?.reduce((acc, curr) => ({ ...acc, [curr.key]: curr.value }), {}) || {},
      };

      await (defaultEditData?.id
        ? enginesServices.editEngineDevices(modifiedData)
        : enginesServices.addEngineDevices(modifiedData));

      fetchDevicesList();
      handleClose();
    } catch (error) {
      dispatch(showToast({ message: error?.response?.data?.message || error.message, type: "error" }));
    } finally {
      setLoadingCreate(false);
    }
  };

  const handleDeleteCapability = (index) => {
    setCapabilities((prevState) => prevState.filter((_, i) => i !== index));
    const updatedCapabilities = getValues("capabilities").filter((_, i) => i !== index);
    setValue("capabilities", updatedCapabilities);
  };

  const handleAddCapability = () => {
    setCapabilities([...capabilities, { key: "", value: "" }]);
  };

  const validationRules = { required: "This field is required" };

  return (
    <Box
      sx={{
        width: "25%",
        position: "fixed",
        right: 0,
        top: 0,
        bottom: 0,
        overflowY: "auto",
        bgcolor: "background.paper",
        pt: "114px",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      }}
      component="form"
      onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ px: 2 }} className="space-between">
        <Typography variant="h6">{t("text_add_device")}</Typography>
        <Controller
          name="isDisabled"
          control={control}
          defaultValue={defaultEditData?.isDisabled || false}
          render={({ field }) => (
            <CustomSwitchButton
              labels={["Enable", "Disable"]}
              checked={field.value}
              onChange={(e) => field.onChange(e.target.checked)}
            />
          )}
        />
      </Box>
      <Divider />
      <Box className="flex column" sx={{ px: 1 }}>
        <Grid container>
          <Grid item xs={12}>
            <ReusableTextField
              name="name"
              control={control}
              label="Name"
              placeholder="Name"
              size="small"
              defaultValue={defaultEditData?.name}
              rules={validationRules}
            />
          </Grid>
          <Grid item xs={12}>
            <Box p={1}>
              <Controller
                name="mobileOs"
                control={control}
                defaultValue={defaultEditData?.mobileOs || ""}
                render={({ field }) => (
                  <FormControl fullWidth size="small">
                    <InputLabel id="mobileOs-label">{t("OS")}</InputLabel>
                    <Select {...field} label="OS" placeholder="OS">
                      <MenuItem value="ANDROID">{t("text_android")}</MenuItem>
                      <MenuItem value="IOS">{t("text_ios")}</MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <ReusableTextField
              name="osVersion"
              control={control}
              label="OS Version"
              placeholder="OS Version"
              defaultValue={defaultEditData?.osVersion}
              size="small"
              rules={validationRules}
            />
          </Grid>
          <Grid item xs={12}>
            <ReusableTextField
              name="udid"
              control={control}
              label="UDID"
              placeholder="UDID"
              defaultValue={defaultEditData?.udid}
              size="small"
              rules={validationRules}
            />
          </Grid>
          <Grid item xs={6}>
            <Button
              disabled={capabilities?.length > 0}
              onClick={handleAddCapability}
              color="warning"
              size="small"
              startIcon={<AddIcon />}>
              {t("text_add_capabilities")}
            </Button>
          </Grid>
          <Grid item xs={6}>
            {capabilities?.length > 0 && (
              <Button
                size="small"
                sx={{ ml: "auto", float: "right" }}
                onClick={handleAddCapability}
                color="warning"
                startIcon={<AddIcon fontSize="small" />}>
                {t("btn_text_add")}
              </Button>
            )}
          </Grid>
          {capabilities?.map((capability, index) => (
            <Grid container alignItems="center" px={1.5} key={index}>
              <Grid item xs={5}>
                <ReusableTextField
                  name={`capabilities[${index}].key`}
                  control={control}
                  label="Key"
                  placeholder="Key"
                  size="small"
                  rules={validationRules}
                  defaultValue={capability?.key}
                />
              </Grid>
              <Grid item xs={5}>
                <ReusableTextField
                  name={`capabilities[${index}].value`}
                  control={control}
                  label="Value"
                  placeholder="Value"
                  size="small"
                  rules={validationRules}
                  defaultValue={capability?.value}
                />
              </Grid>
              <Grid item xs={2}>
                <IconButton color="error" onClick={() => handleDeleteCapability(index)}>
                  <DeleteOutlineOutlinedIcon fontSize="small" />
                </IconButton>
              </Grid>
            </Grid>
          ))}
        </Grid>
        <Box sx={{ mt: 2, px: 1 }} className="space-between">
          <CustomButton onClick={handleClose} color="error" variant="outlined">
            Cancel
          </CustomButton>
          <CustomLoadingButton disabled={loadingCreate} variant="contained" type="submit">
            {t("btn_text_save_changes")}
          </CustomLoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

export default AddDeviceForm;
