import { tokens } from "./tokens";
import InnerHeaderBg from "../images/InnerHeader_Bg.png";
import { oldStyles } from "./oldstyles";

const themeSettings = (mode) => {
  const colors = tokens(mode);
  const {
    gray,
    primary,
    white,
    // success,
    heading,
    strip,
    // gridheader,
    border,
    darktext,
    cardbg,
    spcltext,
    lighttext,
  } = colors;

  const getMuiTableStyles = () => ({
    "& table.MuiTable-root": {
      border: `1px solid ${gray[500]}80`,
      "& tr": {
        "& th": {
          padding: "4px 10px",
          borderLeft: `1px solid ${gray[500]}80`,
          backgroundColor: "#DCD4FB",
          color: "#666666",
          fontWeight: 600,
          zIndex: 1,
        },
        "& td": {
          padding: "0px 10px",
          borderLeft: `1px solid ${gray[500]}80`,
          "& .MuiTablePaginationUnstyled-root": {
            border: "0px",
          },
          "& .MuiIconButton-root": {
            padding: "3px",
            fontSize: "1rem",
          },
        },
        "& .MuiSvgIcon-root": {
          fontSize: "20px",
        },
      },
      "& tr.active": {
        backgroundColor: "#e5f6fd",
      },
    },
  });

  const getHeaderStyles = () => ({
    "& .notItems": {
      "& .MuiMenu-paper": {
        overflow: "visible",
        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
        marginTop: "12px",
        width: "460px",
        maxWidth: "460px",
        padding: "8px 16px",
        "& .MuiAvatar-root": {
          width: "32px",
          height: "32px",
          marginRight: "8px",
          fontSize: "16px",
        },
        "&:before": {
          display: "block",
          position: "absolute",
          top: "0",
          right: "14px",
          width: "10px",
          height: "10px",
          backgroundColor: white,
          transform: "translateY(-50%) rotate(45deg)",
          zIndex: 0,
        },
        "& ul": {
          "& li": {
            padding: "8px 0px",
            whiteSpace: "normal",
            "& p": {
              paddingRight: "35px",
              "& span": {
                color: "#ccc",
                fontWeight: "200",
                fontSize: "10px",
              },
            },
          },
        },
        "& .MuiTabs-root": {
          minHeight: "30px",
        },
        "& .MuiTabs-flexContainer": {
          borderBottom: `2px solid ${gray[200]}`,
          "& .MuiTab-root": {
            padding: "0px 16px 0px 0px",
            minWidth: "auto",
            minHeight: "30px",
          },
        },
        "& .listBox": {
          paddingTop: "16px",
          "& .timeNot": {
            color: "#ccc",
            textTransform: "uppercase",
          },
        },
      },
      "& .notOnline": {
        position: "absolute",
        right: "8px",
        "& .MuiCheckbox-root": {
          padding: "5px",
          "& .MuiSvgIcon-root": {
            fontSize: "10px",
          },
          "& .MuiSvgIcon-root[data-testid='RadioButtonUncheckedIcon']": {
            color: white,
          },
        },
      },
      "&.statusItems": {
        "& .MuiPaper-root": {
          width: "auto",
          "& ul": {
            "& li": {
              backgroundColor: "rgba(0, 0, 0, 0.04)",
              padding: "8px",
              marginBottom: "2px",
            },
          },
        },
        "& .webSerStatus": {
          paddingTop: "8px",
          "& .MuiAvatar-root": {
            width: "15px",
            height: "15px",
          },
          "& svg": {
            fontSize: "6px",
          },

          "& .statusOnline": {
            backgroundColor: "rgb(0 128 0 / 19%)",
            "& .MuiAvatar-root": {
              backgroundColor: "green",
            },
            "& span": {
              color: "green",
            },
          },
        },
      },
    },
  });

  const getSidemenuStyles = () => ({
    "& .sidemenuCus": {
      "& a": {
        color: darktext,
        "& span.MuiTypography-root": {
          fontWeight: "300",
        },
      },
    },
  });

  const getApplicationStyles = () => ({
    "& .applications": {
      height: "100%",

      "& .applicationTile": {
        flex: "0 0 auto",
        borderRadius: "6px",
        border: "1px solid #FFF",
        background: "#FFF",
        boxShadow: "0px 12px 16px -4px rgba(12, 26, 36, 0.04)",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
        gap: 2,
        flexShrink: 0,
        height: "140px",
        padding: "16px",
        transition: "transform 0.5s ease , box-shadow 0.5s ease",
        cursor: "pointer",
        backdropFilter: "blur(13.600000381469727px)",
        "& .type": {
          color: "#495057",
          fontSize: "12px",
          fontWeight: 700,
        },
        "&:hover": {
          boxShadow: "0 0.5em 0.5em -0.4em #096eb6",
          transform: "translateY(-0.25em)",
        },
        "& .addApplication": {
          padding: "8px",
          borderRadius: "2px",
          border: "0.5px solid  #66A2EE",
          height: "20px",
          width: "20px",
          color: "#66A2EE",
          "&:hover": { boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)" },
        },
        "& img": {
          display: "inline-flex",
          padding: "4px",
          width: "55px",
        },

        "& .center": {
          "& p:first-of-type": {
            // color: gray[700],
          },
          "& p:nth-of-type(2)": {
            // color: gray[700],
          },
        },
      },

      "& .progressBar": {
        position: "relative",
        width: "100%",
        "& .progress-item": {
          position: "absolute",
          height: "8px",
        },
      },

      "& .selectedApp": {
        borderRadius: "8px",
        border: "1px solid #66A2EE",
        background: "linear-gradient(90deg, #D9D7FF 0%, #EBD2FF 100%)",
        boxShadow: " 0px 4px 4px 0px rgba(10, 10, 10, 0.25)",
        "&:hover": {
          boxShadow: " 0px 4px 4px 0px rgba(10, 10, 10, 0.25)",
          transform: "none",
        },
      },

      "& .autTile": {
        borderRadius: "8px",
        border: "1px solid #E0E0E0",
      },
    },
    "& .disabledapplicationTile": {
      cursor: "not-allowed",
      opacity: 0.6,
      backdropFilter: "none",
      transition: "none",
      "&:hover": {
        border: "1px solid #e0e0e0",
        boxShadow: "none !important",
        transform: "none !important",
        cursor: "not-allowed",
      },
    },
    "& .disabledapplicationTile2": {
      cursor: "not-allowed",
      opacity: 0.6,
      backdropFilter: "none",
      transition: "none",
    },
  });

  const getAgGridStyles = () => ({
    "& .ag-theme-quartz": {
      height: "100%",
      width: "100%",
      background: white,
      boxShadow: "none",
      // border: `1px solid ${border}`,
      borderRadius: "0px 0px 12px 12px",
      "& .ag-ltr ": { borderRadius: "0 0 12px 12px" },
      // borderRadius: "12px",
      "--ag-header-height": "48px",
      "--ag-header-foreground-color": "#64748B",
      "--ag-header-background-color": "#DCD4FB",
      "--ag-background-color": "#F9F9FF",
      "--ag-foreground-color": "#64748B",
      // "--ag-odd-row-background-color": "rgb(0, 0, 0, 0.03)",
      "--ag-header-column-resize-handle-color": "#D2CDCD",

      "--ag-header-column-separator-display": "block",
      "--ag-header-column-separator-height": "100%",
      "--ag-header-column-separator-width": "1px",
      "--ag-header-column-separator-color": "#D2CDCD",

      "--ag-header-column-resize-handle-display": "block",
      "--ag-header-column-resize-handle-height": "0%",
      "--ag-header-column-resize-handle-width": "0px",

      "& .ag-root-wrapper-body": {
        height: "auto",
      },
      "& .ag-cell": {
        borderRight: "1px solid #D2CDCD",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
      },
      " .ag-header": {},
      ".ag-header-group-cell": {
        fontWeight: "normal",
        fontSize: "12px",
      },
      ".ag-header-cell": {
        fontSize: "14px",
        fontWeight: 600,
      },

      "& .pointer-cursor:hover": {
        cursor: "pointer",
      },

      "& .paging-panel": {
        height: "50px",
        display: "flex",
        justifyContent: "right",
        alignItems: "center",
        "& .Mui-disabled": {
          color: "rgba(0, 0, 0, 0.26) !important",
        },
        "& .MuiButton-outlined": {
          color: darktext,
          transition: "0.5s ease-in-out",
        },
        "& .MuiButton-contained": {
          background: "linear-gradient(90deg, #611EB6 0%, #66A2EE 100%)",
          color: "#F9F9FF",
          transition: "05.s ease-in-out",
        },
        "& .MuiButtonBase-root": {
          minHeight: "34px",
          height: "34px",
          width: "34px",
          minWidth: "34px",
          boxShadow: "none",
          textTransform: "none",
          fontSize: "12px",
          border: `1px solid ${border}`,
          borderRadius: "19.36px",
        },
      },
    },

    "& .ag-theme-quartz .highlight-first-row": {
      backgroundColor: "#97c7c3 !important",
      borderRadius: "2px",
    },
    "& .noBorderRadiusAggrid": {
      borderRadius: "0px 0px 0px 0px",
      "& .ag-ltr": { borderRadius: "0px" },
    },

    "& .add-step-name-column": {
      width: "216px !important",
      height: "32px",
      margin: "4px",
      border: "none !important",
    },
  });

  const getCustomButtonStyle = () => ({
    "& .custom-button.MuiButton-outlinedSuccess": {
      color: "#00C163",
      border: "1px solid #00C163",
      "&:hover": {
        color: "#fff",
        backgroundColor: "#00C163",
        borderColor: "#00C163",
      },
      "&:active": {
        backgroundColor: "#00C163",
        borderColor: "#00C163",
      },
      "&.Mui-disabled": {
        color: "#00C16380",
        borderColor: "#00C16380",
      },
    },
    "& .custom-button.MuiButton-containedSuccess": {
      backgroundColor: "#00C163",

      "&:hover": {
        color: "#fff",
        backgroundColor: "#00C163",
      },
      "&:active": {
        backgroundColor: "#00C163",
      },
      "&.Mui-disabled": {
        color: "rgba(0, 0, 0, 0.26)",
        backgroundColor: "rgba(0, 0, 0, 0.12)",
        border: "none",
      },
    },
    "& .custom-button.MuiButton-containedWarning": {
      backgroundColor: "#E68200",
      "&:hover": {
        color: "#fff",
        backgroundColor: "#E68200",
      },
      "&:active": {
        backgroundColor: "#E68200",
      },
      "&.Mui-disabled": {
        color: "rgba(0, 0, 0, 0.26)",
        backgroundColor: "rgba(0, 0, 0, 0.12)",
        border: "none",
      },
    },
    "& .custom-button.MuiButton-containedError": {
      backgroundColor: "#F9594B",
      border: "1px solid #F9594B",
      "&:hover": {
        color: "#fff",
        backgroundColor: "#F9594B",
        borderColor: "#F9594B",
      },
      "&:active": {
        backgroundColor: "#F9594B",
        borderColor: "#F9594B",
      },
      "&.Mui-disabled": {
        color: "rgba(0, 0, 0, 0.26)",
        backgroundColor: "rgba(0, 0, 0, 0.12)",
        border: "none",
      },
    },
    "& .custom-button.MuiButton-outlinedError": {
      color: "#F9594B",
      border: "1px solid #F9594B",
      "&:hover": {
        color: "#fff",
        backgroundColor: "#F9594B",
        borderColor: "#F9594B",
      },
      "&:active": {
        backgroundColor: "#F9594B",
        borderColor: "#F9594B",
      },
      "&.Mui-disabled": {
        color: "#F9594B80",
        borderColor: "#F9594B80",
      },
    },
    "& .custom-button.Mui-disabled": {
      color: "#cccccc",
      borderColor: "#cccccc",
    },
  });

  const getExecutionReportStyles = () => ({
    "& .passed-color": { color: "#4CAF50" },
    "& .failed-color": { color: "#F9594B" },
    "& .not-exe-color": { color: "#61C5F6" },
    "& .status-buttons-container": {
      display: "flex",
      alignItems: "center",
      gap: "10px",

      "& .MuiChip-root": {
        height: 15,
        minWidth: 15,
        color: "#fff",
        borderRadius: "3px",
        "& .MuiChip-label": { padding: "1px", fontSize: "12px" },
      },
    },
    "& .lineargradient-formcontrol": {
      minWidth: 200,
      position: "relative",
      "& .MuiOutlinedInput-root": {
        backgroundColor: "transparent",
        "& fieldset": {
          border: "1px solid #611EB6",
        },
        "&:hover fieldset": {
          border: "1px solid #611EB6",
        },
        "&.Mui-focused fieldset": {
          border: "1px solid #611EB6",
        },
      },
      "& .MuiSelect-select": {
        borderImageSource: "transparent",
        background: "linear-gradient(90deg, #611EB6 0%, #66A2EE 100%)",
        "-webkit-background-clip": "text",
        color: "transparent",
      },
      "& .MuiInputLabel-root": {
        color: lighttext,
      },
    },
    "& .subheader-bg": {
      backgroundColor: cardbg,
    },
    "& .test-case-container": {
      borderBottom: "1px solid",
      borderColor: border,
      borderRadius: "4px",
      cursor: "pointer",
      "& .MuiChip-root": {
        borderRadius: "3px",
        color: "#fff",
        width: 53,
        height: 20,
        "& .MuiChip-label": { paddingX: "3px", fontSize: "10px" },
      },
    },
    "& .testcase-donut-report-container": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      borderTop: "1px dashed",
      borderColor: border,
      background: cardbg,
      padding: "10px",
    },
    "& .testData-card": {
      borderRadius: "4px",
      color: "#64748B",
      "& .MuiChip-root": {
        background: "#FFCFE0",
        color: "#64748B",
        height: 18,
        minWidth: "69px",
        marginRight: "3px",
        "& .MuiChip-label": { padding: "1px", fontSize: "12px" },
      },
    },
    "& .border-right": {
      borderRight: "1px solid",
      borderColor: border,
    },
    "& .border-bottom": {
      borderBottom: "1px solid",
      borderColor: border,
    },
    "& .colored-text": {
      color: spcltext,
    },
    "& .full-border": {
      border: "1px solid",
      borderColor: border,
    },
    "& .small-text": {
      fontSize: "11px",
    },
    "& .light-text": {
      color: lighttext,
    },
    "& .CloseIcon": {
      position: "absolute",
      top: 0,
      right: 0,
      "&.preCloseIcon": {
        top: "-12px",
        right: "-12px",
      },
    },
    "& .previewTestData": {
      color: "#71787e",
      "& .previewTitleContainer": {
        borderRadius: "10px 10px 0px 0px",
        background: cardbg,
        padding: "10px 12px",
      },
      "& .custom-chip": {
        borderRadius: "2px",
        fontSize: "11px",
        height: 18,
        minwidth: 40,
      },
    },
    "& .no-shadow": {
      boxShadow: "none",
    },
    "& .overlaybase": {
      position: "relative",
      "&:hover .overlay": {
        opacity: 1,
      },
      "& .overlayIcon": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        opacity: 0,
        transition: "opacity 0.3s ease",
      },
    },
  });

  const getExecutionsStyles = () => ({
    "& .executionsListContainer": {
      "& .executionDetails": {
        borderRadius: "12px 12px 0px 0px",
        border: `1px solid ${border}`,
        background: white,
        boxShadow: "2px 0px 16px 0px rgba(104, 107, 228, 0.16)",
        padding: "0.7rem 1rem",
      },
      "& .resultActionsToolbar": {
        background: strip,
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        alignItems: "center",
        padding: "0.5rem 1rem",
        "& .executionSortBy": {
          minWidth: 80,
          "& .MuiInputBase-input": {
            padding: "0.45rem 0.5rem",
          },
        },
      },

      "& .gridExecutionList": {
        marginLeft: "-8px",
        marginRight: "-8px",

        "& .executionCard": {
          borderRadius: "0px 0px 12px 12px",
          border: `1px solid ${gray[300]}`,
          background: "rgba(255, 255, 255, 0.75)",
          boxShadow: " 2px 0px 16px 0px rgba(104, 107, 228, 0.16)",
          color: "#424242",
          padding: "8px 16px",
          margin: "8px",
          fontSize: "0.875rem",
          position: "relative",
          transition: "transform 0.5s ease , box-shadow 0.5s ease",
          cursor: "pointer",

          "&:hover": {
            border: "1px solid #096eb6",
            boxShadow: "0 0.5em 0.5em -0.4em #096eb6",
            transform: "translateY(-0.25em)",
          },
          "& .tile-divider": {
            margin: "5px 0px 5px 0px",
          },
          "& .resultLabelTitle": {
            color: gray[700],
            fontSize: "14px",
            fontWeight: 500,
          },
          "& .resultLabelValue": {
            color: gray[700],
            fontSize: "12px",
            fontWeight: 400,
          },
          "& .resultLabelHightlight": {
            fontSize: "14px",
            fontWeight: 500,
          },
        },

        "& .disableExecutioncard": {
          height: "calc(100% - 16px)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          cursor: "not-allowed",

          "&:hover": {
            border: "1px solid transparent",
            boxShadow: "none",
            transform: "none",
          },
        },
      },
      "& .noExecutionsFound": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: white,
        padding: "1rem",
        height: "40vh",
      },
      /* Customize the tooltip */
      "&.chartjs-tooltip": {
        backgroundColor: "rgba(255, 255, 255, 0.9) !important",
        border: "1px solid #e5e5e5 !important",
        borderRadius: "4px !important",
        color: "#333 !important",
        fontSize: "12px !important",
        padding: "10px !important",
      },

      "& .chartjs-tooltip .tooltip-title": {
        color: "#4CAF50 !important",
        fontWeight: "bold !important",
      },

      "& .chartjs-tooltip .tooltip-body": {
        color: "#333 !important",
      },
    },
  });

  const powerBiReportStyles = () => ({
    // "& .displayAreaViewport": { background: "red", display: "none !important" },
    "& .report-style-class-modal": {
      height: "78vh",
      margin: "auto",
      width: "100%",
      "& iframe": {
        border: "1px solid #ccc",
        padding: "0.5rem",
        borderRadius: "4px",
      },
    },
    "& .report-style-class-card": {
      height: "30vh",
      margin: "auto",
      width: "100%",
      "& iframe": {
        border: "1px solid #ccc",
        padding: "0.5rem",
        borderRadius: "4px",
      },
    },
  });

  const palette = {
    mode,
    // primary: { main: "#1976d2" },
    // // secondary: { main: gray[500] },
    // neutral: {
    //   dark: gray[700],
    //   main: gray[500],
    //   light: gray[100],
    // },
    background: {
      default: primary[500],
      paper: mode === "dark" ? primary[500] : "white",
    },
  };

  const typography = {
    allVariants: {
      fontSize: 12,
    },
    h5: {
      fontSize: 18,
      fontWeight: 600,
    },
    h6: {
      fontSize: 14,
      fontWeight: 600,
    },
    bold: {
      fontWeight: 600,
    },
    sectionHeading: {
      color: heading,
      fontSize: "14px",
      fontWeight: 600,
      textTransform: "uppercase",
    },
    detailTitle: {
      color: gray[700],
      fontSize: "14px",
      fontWeight: 600,
      textTransform: "capitalize",
    },
    detailValue: {
      color: gray[700],
      fontSize: "12px",
      fontWeight: 500,
    },
  };

  const components = {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          color: "#71787e",
          fontFamily: "Poppins, Helvetica, 'sans-serif' ",
          "& .flex": {
            display: "flex",
          },
          "& .relative": {
            position: "relative",
          },
          "& .absolute": {
            position: "absolute",
          },
          "& .column": {
            flexDirection: "column",
          },
          "& .row": {
            flexDirection: "row",
          },
          "& .flex-end": {
            display: "flex",
            alignItems: "flex-end",
          },
          "& .display-inline": {
            display: "inline-flex",
          },
          "& .center": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
          "& .v-center": {
            display: "flex",
            alignItems: "center",
          },
          "& .h-center ": {
            display: "flex",
            justifyContent: "center",
          },
          "& .space-between": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          },
          "& .space-around": {
            display: "flex",
            justifyContent: "space-around",
          },
          "& .float-right": {
            float: "right",
          },
          "& .upper-case": {
            textTransform: "uppercase",
          },
          "& .bold-text": {
            fontWeight: 600,
          },
          "& .MuiPaper-root": {
            boxShadow: "none",
          },
          "& .MuiPaper-root.MuiPaper-elevation0": {
            boxShadow: "none",
          },
          "& .innerHeader": {
            height: "58px",
            display: "flex",
            alignItems: "center",
            width: "100%",
            zIndex: 2,
            boxShadow: "0px 4px 8px 0px rgba(46, 45, 125, 0.16)",
            background: `url(${InnerHeaderBg})`,
            backgroundSize: "cover",
            color: "#F9F9FF",
            padding: "12px 16px 12px 16px",
            boxSizing: "border-box",
          },
          "& .innerHeaderContainer": {
            position: "fixed",
            zIndex: 2,
            marginLeft: "-16px",
            marginRight: "-16px",
            borderRadius: 0,
            transition: "width 0.3s ease-in-out, max-width 0.3s ease-in-out",
          },
          "& .pt74": {
            paddingTop: "74px",
            width: "100%",
          },
          "& .paging-panel": {
            height: "50px",
            display: "flex",
            justifyContent: "right",
            alignItems: "center",
            "& .Mui-disabled": {
              color: "rgba(0, 0, 0, 0.26) !important",
            },
            "& .MuiButton-outlined": {
              color: darktext,
              transition: "0.5s ease-in-out",
            },
            "& .Mui-selected": {
              background: "linear-gradient(90deg, #611EB6 0%, #66A2EE 100%)",
              color: "#F9F9FF",
              transition: "05.s ease-in-out",
            },
            "& .MuiButtonBase-root": {
              minHeight: "34px",
              height: "34px",
              width: "34px",
              minWidth: "34px",
              boxShadow: "none",
              textTransform: "none",
              fontSize: "12px",
              border: `1px solid ${border}`,
              borderRadius: "19.36px",
            },
          },
          "& .carouselContainer": {
            display: "flex",
            overflow: "hidden",
            width: "100%",
          },

          "& .accordionArrow": {
            height: "20px",
            width: "20px",
            background: white,
            color: "#4C73FA",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            float: "right",
            borderRadius: "3px",
            cursor: "pointer",
            "&:hover": {
              cursor: "pointer",
              background: "#4C73FA",
              color: white,
            },
          },
          "& .ellipsis": {
            display: "inline-block",
            maxWidth: 260,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
          "& .ellipsisValidation": {
            display: "inline-block",
            maxWidth: 460,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
          "& .ellipsisSql": {
            display: "-webkit-box",
            maxWidth: 260,
            webkitLineClamp: 4,
            webkitBoxOrient: "vertical",
            overflow: "hidden",
          },

          "& .disabled-icon": {
            opacity: 0.5,
            pointerEvents: "none",
          },
          "& .backButton": {
            display: "inline-flex",
            padding: "0.25rem 0.5rem",
            alignItems: "center",
            borderRadius: "0.25rem",
            border: "1px solid #F9594B",
            background: "#F9594B",
            fontSize: "0.75rem",
            fontWeight: 600,
            transition: "background-color 0.3s ease",
            "&:hover": { background: "#ff7d6f", cursor: "pointer" },
          },
          "& .container": {
            borderRadius: "12px",
            border: `1px solid ${border}`,
            background: white,
            boxShadow: "2px 0px 16px 0px rgba(104, 107, 228, 0.16)",
            width: "100%",
            "& .subcontainer": {
              background: cardbg,
              textAlign: "center",
              color: darktext,
            },
          },

          "& .noborder-container": {
            width: "100%",
            background: white,
            boxShadow: "2px 0px 16px 0px rgba(104, 107, 228, 0.16)",
          },
          "& .left-border": {
            borderRadius: "12px 0 0 12px",
            border: `1px solid ${border}`,
          },
          "& .right-border": {
            borderRadius: "0 12px 12px 0",
            border: `1px solid ${border}`,
          },
          "& .outlet": {
            padding: "0px 16px 16px",
            borderRadius: "16px 16px 0 0",
            width: "100%",
          },

          "& .addButtonContained": {
            boxShadow: "none",
            textTransform: "none",
            transition: "backgroundColor 0.3s, box-shadow 0.3s",
            "&:hover": {
              backgroundColor: "#00a854",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            },
            "&.active": {
              boxShadow: "inset 0 2px 4px rgba(0, 0, 0, 0.1)",
            },
          },

          "& .addButtonOutlined": {
            boxShadow: "none",
            textTransform: "none",
            transition: "backgroundColor 0.3s, box-shadow 0.3s",
            "&:hover": {
              backgroundColor: "#00C163",
            },
          },

          "& .PreviewButton": {
            backgroundColor: "#E69434",
            boxShadow: "none",
            transition: "backgroundColor 0.3s, box-shadow 0.3s",
            padding: "5px 8px",
            "&:hover": {
              backgroundColor: "#E69434",
            },
            "&:disabled": {
              background: "rgba(0, 0, 0, 0.12)",
            },
            "& .MuiLoadingButton-startIconLoadingStart": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            },
            "& .MuiLoadingButton-loadingIndicator": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            },
          },
          "& .UploadButton": {
            backgroundColor: strip,
            borderRadius: "0px 4px 4px 0px",
            color: heading,
            boxShadow: "none",
            textTransform: "none",
            transition: "backgroundColor 0.3s, box-shadow 0.3s",
            "&:hover": {
              backgroundColor: "#c8c1e3",
              boxShadow: "none",
            },
          },
          // "& .MuiSvgIcon-root": {
          //   fontSize: "20px",
          // },
          "& .createBtn": {
            float: "right",
            padding: "4px 0",
            marginRight: "10px",
            "& .MuiToolbar-root": {
              backgroundColor: "transparent",
              padding: 0,
            },

            "& .MuiIconButton-root": {
              borderRadius: "4px",
              background: "linear-gradient(90deg, #4840C5 0%, #6334C6 100%)",
              color: white,
              marginLeft: "4px",
              "&:hover": {
                backgroundColor: "#1565c0",
                boxShadow:
                  "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
              },
            },
          },
          "& .refreshBtn": {
            float: "left",
            padding: "10px 0",
            "& .MuiButtonBase-root": {
              border: `1px solid ${gray[300]}`,
              borderRadius: "4px",
            },
          },
          "& .loader-button": {
            position: "relative",
            display: "inline-flex",
            color: white,
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "none",
            background: "linear-gradient(90deg, #611EB6 0%, #66A2EE 100%)",
            "&:disabled": {
              background: "rgba(0, 0, 0, 0.12)",
            },
            "& .MuiLoadingButton-startIconLoadingStart": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            },
            "& .MuiLoadingButton-loadingIndicator": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            },
          },
          "& .customRadioGroup": {
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "row",
            "& span": {
              color: "#6363D3",
              "&.Mui-checked": {
                color: "#6363D3",
              },
            },
          },
          "& .customSelect": {
            display: "flex",
            width: "250px",
            padding: "0px 16px",
            alignItems: "center",
            gap: "7px",
            flexShrink: 0,
            boxShadow: "none",
            borderRadius: "6px",
            border: 0,
            background: "rgba(245, 245, 245, 0.70)",
            backdropFilter: "blur(1.3600000143051147px)",
            ".MuiOutlinedInput-notchedOutline": { border: 0 },
            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
              border: 0,
            },
            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
              border: 0,
            },
            "& .MuiOutlinedInput-input": {
              display: "flex",
              alignItems: "center",
              padding: "0px",
              gap: "0.5rem",
              height: "34px",
              borderRadius: 0,
            },
            "& .MuiListItemIcon-root": {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minWidth: "40px !important",
              "& img": {
                width: "25px",
                height: "auto",
                margin: "auto",
              },
            },
          },
          ...getCustomButtonStyle(),
          ...oldStyles(),
          ...getMuiTableStyles(),
          ...getSidemenuStyles(),
          ...getHeaderStyles(),
          ...getApplicationStyles(),
          ...getAgGridStyles(),
          ...getExecutionReportStyles(),
          ...getExecutionsStyles(),
          ...powerBiReportStyles(),
        },
      },
    },
  };

  return {
    palette,
    typography,
    components,
  };
};

export default themeSettings;
