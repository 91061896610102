import { Box, Grid, Typography, IconButton } from "@mui/material";
import { CustomAgGrid } from "../AgGrid";
import { PreviewHeadCell } from "./PreviewHeadCell";
import { useNavigate, useParams } from "react-router-dom";
import { useRef } from "react";
import { formatDateTime } from "../../utils/formatDate";

const ActionsCell = (props) => {
  return (
    <>
      <IconButton
        title="Result"
        size="small"
        color="secondary"
        onClick={() => props?.colDef.viewTestReports(props?.data?.id)}>
        View Result
      </IconButton>
    </>
  );
};

const PreviewPipeLineTest = ({ previewCICDData }) => {
  const navigate = useNavigate();
  const params = useParams();
  const gridRef = useRef();
  const source = "all";
  const viewTestReports = (rowData) => {
    navigate(`/executionsreport/${params?.id}/${rowData}?source=${source}`);
  };
  const action = {
    headerName: "Actions",
    sortable: false,
    cellRenderer: ActionsCell,
    viewTestReports: viewTestReports,
    lockPosition: "right",
    cellClass: "locked-col",
    suppressColumnsToolPanel: true,
    filter: false,
    headerTooltip: "Actions",
    gridRef: gridRef,
  };
  PreviewHeadCell[PreviewHeadCell.length - 1] = action;
  return (
    <Box my={1} className="TCReports">
      <Typography variant="h5">Preview CICD Pipe Line Test</Typography>
      <Box my={1} className="dateSec">
        <Grid container alignItems={"center"} spacing={3}>
          <Grid item md={3}>
            <Box display="flex">
              <Typography variant="bold"> Name : </Typography>
              <Typography> {previewCICDData?.name}</Typography>
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box display="flex">
              <Typography variant="bold">Description : </Typography>
              <Typography>{previewCICDData?.description}</Typography>
            </Box>
          </Grid>
          {/* <Grid item md={3}>
            <Box display="flex">
              <Typography variant="bold">Frequency : </Typography>
              <Typography> {previewCICDData?.frequency}</Typography>
            </Box>
          </Grid> */}
          {/* <Grid item md={3}>
            <Box display="flex">
              <Typography variant="bold">Scheduled At : </Typography>
              <Typography>
                {formatDateTime(previewCICDData?.scheduledAt)}
              </Typography>
            </Box>
          </Grid> */}
          {/* <Grid item md={3}>
            <Box display="flex">
              <Typography variant="bold">Active : </Typography>
              <Typography>{previewCICDData?.enabled ? "Yes" : "No"}</Typography>
            </Box>
          </Grid> */}
          {/* <Grid item md={3}>
            <Box display="flex">
              <Typography variant="bold"> No Of Times : </Typography>
              <Typography> {previewCICDData?.noOfTimes}</Typography>
            </Box>
          </Grid> */}
          <Grid item md={3}>
            <Box display="flex">
              <Typography variant="bold">Created By : </Typography>
              <Typography>{previewCICDData?.createdBy}</Typography>
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box display="flex">
              <Typography variant="bold">Created At : </Typography>
              <Typography>{formatDateTime(previewCICDData?.createdAt)}</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <CustomAgGrid
        gridRef={gridRef}
        headCells={PreviewHeadCell}
        setSelected={() => null}
        rows={previewCICDData?.executionListResponse?.executionResponseList}
      />
    </Box>
  );
};

export default PreviewPipeLineTest;
