import { memo, useEffect } from "react";
import { attributes, definePropertiesBasedOnAttributes } from "../CreateTestStepsContainer/DataTypeSelector/DataTypes";
import { Box, Button, Dialog, DialogActions, DialogContent, Grid } from "@mui/material";
import DataTypeSelector from "../CreateTestStepsContainer/DataTypeSelector";
import { useForm } from "react-hook-form";

const AdvanceSettings = memo(({ tdmAttributeType, row, setTdmAttributeType, onSubmit }) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    trigger,
    setValue,
  } = useForm({
    defaultValues: {
      tdmAttributeType: tdmAttributeType || "",
      tdmAttributeProperties: row?.tdmAttributeProperties || [],
    },
  });

  const handleClose = () => {
    setTdmAttributeType(false);
  };

  useEffect(() => {
    const item = attributes.find((attr) => attr.attributeName === tdmAttributeType);
    if (item) {
      const newAttribute = {
        ...item,
        tdmAttributeProperties: definePropertiesBasedOnAttributes(item.attributeName, false),
      };
      setValue("tdmAttributeProperties", newAttribute.tdmAttributeProperties);
    }
  }, []);

  return (
    <Dialog open={true} onClose={handleClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={1.5}>
            <Grid item xs={6}>
              <Box className="v-center" gap={1}>
                <Button
                  color="warning"
                  size="small"
                  className="flex-end"
                  //   startIcon={<SettingsSuggestOutlinedIcon sx={{ mt: "-22px" }} fontSize="small" />}
                >
                  Advance options
                </Button>
              </Box>
            </Grid>
            <DataTypeSelector control={control} errors={errors} watch={watch} trigger={trigger} setValue={setValue} />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Save</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
});
AdvanceSettings.displayName = "AdvanceSettings";

export default AdvanceSettings;
