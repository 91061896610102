import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Button, Grid, IconButton, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

export const AdditionalConfigInputs = ({ environments, activeEnvIndex, setValue }) => {
  const handleKeyChange = (index, newKey) => {
    const capabilities = { ...environments[activeEnvIndex]?.environmentProperties };

    const keys = Object.keys(capabilities);
    const values = Object.values(capabilities);

    keys[index] = newKey;

    const updatedCapabilities = Object.fromEntries(keys.map((key, i) => [key, values[i]]));

    setValue(`autEnvironmentResponseList.${activeEnvIndex}.environmentProperties`, updatedCapabilities);
  };

  const handleValueChange = (index, newValue) => {
    // Get the current capabilities object
    const capabilities = { ...environments[activeEnvIndex]?.environmentProperties };

    // Get the keys and values as arrays
    const keys = Object.keys(capabilities);
    const values = Object.values(capabilities);

    // Update the value at the specified index
    values[index] = newValue;

    // Create a new object with the updated values
    const updatedCapabilities = Object.fromEntries(keys.map((key, i) => [key, values[i]]));

    // Update the state with the new capabilities object
    setValue(`autEnvironmentResponseList.${activeEnvIndex}.environmentProperties`, updatedCapabilities);
  };

  const handleAddCapability = () => {
    const existingCapabilities = environments[activeEnvIndex]?.environmentProperties || {};

    // Use Object.keys(existingCapabilities).length to get the number of existing capabilities
    const newCapabilityKey = `specificKeyName${Object.keys(existingCapabilities).length}`;
    const newCapabilityValue = `specificValue${Object.keys(existingCapabilities).length}`;

    // Create the updated capabilities object
    const updatedCapabilities = { ...existingCapabilities, [newCapabilityKey]: newCapabilityValue };
    console.log(updatedCapabilities, "updatedCapabilities");

    // Update the form value with the new capabilities object
    setValue(`autEnvironmentResponseList.${activeEnvIndex}.environmentProperties`, updatedCapabilities);
  };

  const handleDeleteCapability = (capabilityKey) => {
    const capabilities = { ...environments[activeEnvIndex]?.environmentProperties };
    delete capabilities[capabilityKey];
    setValue(`autEnvironmentResponseList.${activeEnvIndex}.environmentProperties`, capabilities);
  };

  return (
    <>
      <Grid item xs={6}>
        <Button
          disabled={Object.keys(environments[activeEnvIndex]?.environmentProperties || {})?.length > 0}
          onClick={handleAddCapability}
          color="warning"
          size="small"
          startIcon={<AddIcon />}>
          Additional Configs
        </Button>
      </Grid>
      <Grid item xs={6}>
        {Object.keys(environments[activeEnvIndex]?.environmentProperties || {})?.length > 0 && (
          <Button
            size="small"
            sx={{ ml: "auto", float: "right" }}
            onClick={handleAddCapability}
            color="warning"
            startIcon={<AddIcon fontSize="small" />}>
            Add New
          </Button>
        )}
      </Grid>
      <Grid item xs={12}>
        {Object.entries(environments[activeEnvIndex]?.environmentProperties || {}).map(
          ([capabilityKey, capabilityValue], index) => (
            <Grid container mb={1} spacing={2} key={capabilityKey} alignItems="center">
              <Grid item xs={5}>
                <TextField
                  label="Key"
                  placeholder="Capability Key"
                  defaultValue={capabilityKey}
                  size="small"
                  // value={capabilityKey}
                  onBlur={(e) => handleKeyChange(index, e.target.value)}
                  autoFocus={true}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  label="Value"
                  size="small"
                  placeholder="Capability Value"
                  defaultValue={capabilityValue}
                  value={capabilityValue}
                  onChange={(e) => handleValueChange(index, e.target.value)}
                />
              </Grid>
              <Grid item xs={2}>
                <IconButton color="error" onClick={() => handleDeleteCapability(capabilityKey)} size="small">
                  <DeleteOutlineOutlinedIcon />
                </IconButton>
              </Grid>
            </Grid>
          ),
        )}
      </Grid>
    </>
  );
};
