import {
  Abc,
  AccountCircle,
  CalendarToday,
  Call,
  Email,
  Flag,
  LocationCity,
  MyLocation,
  Numbers,
  Person,
  Person2,
  PlaylistAdd,
  Public,
} from "@mui/icons-material";

export const attributes = [
  {
    entityId: "",
    attributeName: "String",
    type: "string",
    attributeCustomName: "",
    IsPrimaryKey: false,
    isValid: false,
    icon: <Abc />,
  },
  {
    entityId: "",
    attributeName: "Number",
    type: "number",
    attributeCustomName: "",
    isPrimaryKey: false,
    isValid: false,
    icon: <Numbers />,
  },
  {
    entityId: "",
    attributeName: "Date",
    type: "date",
    attributeCustomName: "",
    isValid: false,
    icon: <CalendarToday />,
  },
  {
    entityId: "",
    attributeName: "First Name",
    attributeCustomName: "",
    type: "firstName",
    isValid: false,
    icon: <Person />,
  },
  {
    entityId: "",
    attributeName: "Last Name",
    attributeCustomName: "",
    type: "lastName",
    isValid: false,
    icon: <Person2 />,
  },
  {
    entityId: "",
    attributeName: "Full Name",
    attributeCustomName: "",
    type: "fullName",
    isValid: false,
    icon: <AccountCircle />,
  },
  {
    entityId: "",
    attributeName: "Country",
    attributeCustomName: "",
    type: "countries",
    isValid: false,
    icon: <Public />,
  },
  {
    entityId: "",
    attributeName: "State",
    type: "states",
    attributeCustomName: "",
    isValid: false,
    icon: <Flag />,
  },
  {
    entityId: "",
    attributeName: "City",
    type: "cities",
    isValid: false,
    icon: <LocationCity />,
    attributeCustomName: "",
  },
  {
    entityId: "",
    type: "zipCodes",
    attributeName: "Zip Code",
    attributeCustomName: "",
    isValid: false,
    icon: <MyLocation />,
  },
  {
    entityId: "",
    attributeName: "Email",
    type: "email",
    attributeCustomName: "",
    isValid: false,
    icon: <Email />,
  },
  {
    entityId: "",
    type: "phoneNumber",
    attributeName: "Phone",
    attributeCustomName: "",
    isValid: false,
    icon: <Call />,
  },
  {
    entityId: "",
    type: "choiceList",
    attributeName: "Choice List",
    attributeCustomName: "",
    isValid: false,
    icon: <PlaylistAdd />,
  },
];

export const definePropertiesBasedOnAttributes = (attributeType, isNullable = false) => {
  switch (attributeType?.toLowerCase()) {
    case "string":
      return [
        {
          key: "isFixedLength",
          value: false,
          type: "toggle",
          keyDisplayName: "Fixed Length",
        },
        {
          key: "min",
          value: 4,
          type: "number",
          keyDisplayName: "Minimum Length",
          validationFuncName: "minValidation",
          dependsOn: [{ property: "isFixedLength", value: false }],
          validationRules: {
            required: true,
            min: {
              value: 4,
              message: "Minimum value should be 4",
            },
          },
        },
        {
          key: "max",
          value: 10,
          type: "number",
          keyDisplayName: "Maximum Length",
          dependsOn: [{ property: "isFixedLength", value: false }],
          validationFuncName: "maxValidation",
          validationRules: { required: true },
        },
        {
          key: "fixed",
          value: 4,
          type: "number",
          keyDisplayName: "Fixed Length",
          dependsOn: [{ property: "isFixedLength", value: true }],
          validationRules: {
            required: true,
            min: {
              value: 4,
              message: "Minimum value should be 4",
            },
          },
        },
        {
          key: "useCustomRules",
          value: false,
          type: "toggle",
          keyDisplayName: "Use Custom Rules",
          dependsOn: [{ property: "isFixedLength", value: true }],
        },
        {
          key: "customFormattingRules",
          value: [],
          type: "customRuleArray",
          keyDisplayName: "Custom Formatting Rules",
          dependsOn: [
            { property: "isFixedLength", value: true },
            { property: "useCustomRules", value: true },
          ],
        },
        {
          key: "startsWith",
          value: "",
          type: "text",
          keyDisplayName: "Starts With",
          dependsOn: [{ property: "useCustomRules", value: false }],
        },
        {
          key: "endsWith",
          value: "",
          type: "text",
          keyDisplayName: "Ends With",
          dependsOn: [{ property: "useCustomRules", value: false }],
        },
        {
          key: "caps",
          value: "initCaps",
          type: "radio",
          keyDisplayName: "Letter case",
          options: [
            { value: "initCaps", key: "Initial caps" },
            { value: "upperCase", key: "Uppercase" },
          ],
        },
        {
          key: "alphaNumeric",
          value: false,
          type: "toggle",
          keyDisplayName: "Alpha numeric",
          dependsOn: [{ property: "useCustomRules", value: false }],
        },
        {
          key: "hasEmpty",
          value: isNullable,
          type: "toggle",
          keyDisplayName: "Has Empty",
        },
      ];
    case "number":
      return [
        {
          key: "min",
          value: 0,
          type: "number",
          validationFuncName: "minValidation",
          validationRules: {
            required: true,
            min: {
              value: 0,
              message: "Minimum value cannot be negative",
            },
          },
          keyDisplayName: "Minimum Value",
        },
        {
          key: "max",
          value: 2147483647,
          type: "number",
          keyDisplayName: "Maximum Value",
          validationFuncName: "maxValidation",
          validationRules: { required: true },
        },
        {
          key: "isDecimal",
          value: false,
          type: "toggle",
          keyDisplayName: "Is a decimal?",
        },
        {
          key: "noOfDecimals",
          value: "",
          type: "number",
          keyDisplayName: "No of decimals",
          dependsOn: [{ property: "isDecimal", value: true }],
          validationRules: {
            required: true,
            min: {
              value: 1,
              message: "Minimum value should be 1",
            },
          },
        },
        // {
        // 	key: "hasEmpty",
        // 	value: isNullable,
        // 	type: "toggle",
        // 	keyDisplayName: "Has Empty",
        // },
      ];
    case "date":
      return [
        {
          key: "dateFormat",
          keyDisplayName: "Date Format",
          value: "%m/%d/%Y",
          selectedFormat: "MM/DD/YYYY",
          type: "select",
          dropdownType: "simple",
          options: [
            { label: "MM/DD/YYYY", value: "%m/%d/%Y" },
            { label: "DD/MM/YYYY", value: "%d/%m/%Y" },
            { label: "YYYY/MM/DD", value: "%Y/%m/%d" },
          ],
        },
        {
          keyDisplayName: "Minimum Date",
          key: "min",
          value: "",
          type: "select",
          dropdownType: "date",
          validationRules: {
            required: true,
          },
          // validationFuncName: "minDateValidation",
        },
        {
          keyDisplayName: "Maximum Date",
          key: "max",
          value: "",
          type: "select",
          dropdownType: "date",
          validationRules: {
            required: true,
          },
          validationFuncName: "maxDateValidation",
        },
        {
          key: "hasEmpty",
          value: isNullable,
          type: "toggle",
          keyDisplayName: "Has Empty",
        },
      ];
    case "first name":
    case "last name":
    case "full name":
      return [
        {
          keyDisplayName: "Democratic Region",
          key: "democraticRegion",
          value: "Indian",
          type: "select",
          dropdownType: "simple",
          options: [
            { label: "American", value: "American" },
            { label: "Indian", value: "Indian" },
          ],
        },
        {
          keyDisplayName: "Unique",
          key: "unique",
          value: false,
          type: "toggle",
        },
        {
          key: "hasEmpty",
          value: isNullable,
          type: "toggle",
          keyDisplayName: "Has Empty",
        },
      ];
    case "country":
      return [
        {
          keyDisplayName: "Continent",
          key: "continent",
          type: "select",
          value: "Asia",
          dropdownType: "simple",
          options: [
            { label: "Asia", value: "Asia" },
            { label: "Europe", value: "Europe" },
            { label: "Africa", value: "Africa" },
            { label: "Oceania", value: "Oceania" },
            { label: "North America", value: "North America" },
            { label: "South America", value: "South America" },
          ],
        },
        {
          key: "hasEmpty",
          value: isNullable,
          type: "toggle",
          keyDisplayName: "Has Empty",
        },
      ];
    case "state":
      return [
        {
          key: "continent",
          value: "Asia",
          type: "select",
          keyDisplayName: "Continent",
          dropdownType: "simple",
          options: [
            { label: "Asia", value: "Asia" },
            { label: "Europe", value: "Europe" },
            { label: "Africa", value: "Africa" },
            { label: "Oceania", value: "Oceania" },
            { label: "North America", value: "North America" },
            { label: "South America", value: "South America" },
          ],
        },
        {
          key: "country",
          value: "",
          type: "select",
          keyDisplayName: "Country",
          dropdownType: "simple",
          validationRules: {
            required: true,
          },
        },
        {
          key: "hasEmpty",
          value: isNullable,
          type: "toggle",
          keyDisplayName: "Has Empty",
        },
      ];
    case "city":
      return [
        {
          key: "continent",
          value: "Asia",
          type: "select",
          keyDisplayName: "Continent",
          dropdownType: "simple",
          options: [
            { label: "Asia", value: "Asia" },
            { label: "Europe", value: "Europe" },
            { label: "Africa", value: "Africa" },
            { label: "Oceania", value: "Oceania" },
            { label: "North America", value: "North America" },
            { label: "South America", value: "South America" },
          ],
        },
        {
          key: "country",
          value: "",
          type: "select",
          keyDisplayName: "Country",
          dropdownType: "simple",
          validationRules: {
            required: true,
          },
        },
        {
          key: "state",
          value: "",
          type: "select",
          keyDisplayName: "State",
          dropdownType: "simple",
          validationRules: {
            required: true,
          },
        },
        {
          key: "hasEmpty",
          value: isNullable,
          type: "toggle",
          keyDisplayName: "Has Empty",
        },
      ];
    case "zip code":
      return [
        {
          keyDisplayName: "Continent",
          key: "continent",
          type: "select",
          value: "Asia",
          dropdownType: "simple",
        },
        {
          key: "country",
          value: "",
          type: "select",
          keyDisplayName: "Country",
          dropdownType: "simple",
          validationRules: {
            required: true,
          },
        },
        {
          key: "state",
          value: "",
          type: "select",
          keyDisplayName: "State",
          dropdownType: "simple",
          validationRules: {
            required: true,
          },
        },
        {
          key: "city",
          value: "",
          type: "select",
          keyDisplayName: "City",
          dropdownType: "simple",
          validationRules: {
            required: true,
          },
        },
      ];
    case "email":
      return [
        {
          keyDisplayName: "Domain",
          key: "domain",
          value: "",
          type: "text",
          validationRules: {
            required: true,
            pattern: {
              value: /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/,
              message: "Invalid domain format",
            },
          },
          note: "Note: Example format 'xyz.com', 'xyz.co.in'",
        },
        // {
        // 	keyDisplayName: "Minimum Length",
        // 	key: "min",
        // 	value: "4",
        // 	type: "number",
        // },
        // {
        // 	keyDisplayName: "Maximum Length",
        // 	key: "max",
        // 	value: "10",
        // 	type: "number",
        // },
        {
          keyDisplayName: "Unique",
          key: "unique",
          value: false,
          type: "toggle",
        },
        {
          key: "hasEmpty",
          value: isNullable,
          type: "toggle",
          keyDisplayName: "Has Empty",
        },
      ];
    case "phone":
      return [
        {
          keyDisplayName: "Continent",
          key: "continent",
          type: "select",
          value: "Asia",
          dropdownType: "simple",
          options: [
            { label: "Asia", value: "Asia" },
            { label: "Europe", value: "Europe" },
            { label: "Africa", value: "Africa" },
            { label: "Oceania", value: "Oceania" },
            { label: "North America", value: "North America" },
            { label: "South America", value: "South America" },
          ],
        },
        {
          keyDisplayName: "Country",
          key: "country",
          type: "select",
          value: "",
          dropdownType: "simple",
          validationRules: {
            required: true,
          },
        },
        {
          keyDisplayName: "Phone Number",
          key: "numberFormat",
          value: "nnn-nnn-nnnn",
          type: "text",
          note: "Note: Allowed format 'nnn-nnn-nnnn'",
        },
        {
          keyDisplayName: "Append Country Code",
          key: "appendCountryCode",
          value: false,
          type: "toggle",
        },
        {
          keyDisplayName: "Unique",
          key: "unique",
          value: false,
          type: "toggle",
        },
      ];
    case "choice list":
      return [
        {
          keyDisplayName: "Custom option",
          key: "custom",
          value: [],
          type: "select",
          dropdownType: "autocomplete",
          note: "Note: Add custom options",
          validationRules: {
            required: true,
          },
        },
      ];
    default:
      return [];
  }
};
