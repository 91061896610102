import { useEffect, useRef, useState, useCallback } from "react";
import { Box, Typography } from "@mui/material";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";

import { testcaseServices } from "../../Redux/services/testcase.services";
import KiTAPServer from "../../services/KiTAPServer";
// import ImportTestCases from "../TestCaseImports/ImportTestCases";
import SkeletonLoader from "../SkeletonLoader";
import CustomBackButton from "../CustomComponents/CustomButtons/CustomBackButton";
import CustomReactAgGrid from "../CustomComponents/CustomReactAgGrid";
import TestCaseListActionsToolbar from "./TestCaseListActionsToolbar";
import CustomDeleteModal from "../ReusableComponents/CustomDeleteModal";
import InnerHeader from "../Layout/InnerHeader";
import CustomButton from "../CustomComponents/CustomButtons/CustomButton";
import { testCaseListHeadCells } from "./testCaseListHeadCells";
import ExecutionStatus from "../ExecutionConfigs/ExecutionStatus";
import SelectAutType from "../SelectAutType";
import { autServices } from "../../Redux/services/aut.services";
import Executions from "./Executions";
import { dateRangeObj } from "../../utilities/defaultData";
import SelectedAutDetails from "./SelectedAutDetails";
import { setTestcasesList } from "../../Redux/reducers/testcaseSlice";
import { useCheckAccess } from "../../CustomHooks/useCheckAccess";
import { showToast } from "../../Redux/reducers/toastSlice";

const TestCaseListHeader = () => {
  const navigate = useNavigate();

  const { checkAccess } = useCheckAccess();
  const [searchParams] = useSearchParams();
  const autType = searchParams.get("autType");
  const autName = searchParams.get("autName");
  const autId = searchParams.get("autId");
  // const { aut_details: autDetails } = useSelector((state) => state.testcase);

  const handleBack = () => {
    // navigate(-1);
    navigate(`/?appType=${autType}`);
  };

  const isAutSelected = autType && autId;

  return (
    <InnerHeader>
      {isAutSelected ? (
        <Box className="v-center" width="100%">
          <Box className="v-center" gap={1}>
            <CustomBackButton onClick={handleBack}>Back</CustomBackButton>
            <Typography variant="h6">TEST CASE LIST</Typography>
            <SelectedAutDetails />
          </Box>
          <Box className="v-center" sx={{ ml: "auto", gap: 2, mr: 1 }}>
            {/* {autDetails && <ImportTestCases autDetails={autDetails} />} */}
            {checkAccess("testCases", "Create") && (
              <Link to={`/testcases/create?autId=${autId}&autType=${autType}&autName=${autName}`}>
                <CustomButton
                  className="no-shadow"
                  color="success"
                  size="small"
                  variant="contained"
                  sx={{ height: "32px" }}>
                  <AddIcon fontSize="small" sx={{ marginRight: "8px" }} /> Add Test Case
                </CustomButton>
              </Link>
            )}
          </Box>

          <ExecutionStatus />
        </Box>
      ) : (
        <Typography variant="h6">TEST CASE LIST</Typography>
      )}
    </InnerHeader>
  );
};

const TestCasesList = () => {
  const gridRef = useRef();
  const scrollRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const autType = searchParams.get("autType");
  const autName = searchParams.get("autName");
  const autId = searchParams.get("autId");
  const testCaseId = searchParams.get("testCaseId");

  const { testcase_list: testCaseRows, testcase_list_loading: testCasesLoading } = useSelector(
    (state) => state.testcase,
  );
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [selectedTestCases, setSelectedTestCases] = useState([]);

  useEffect(() => {
    dispatch(autServices.fetchAppTypes());
    if (autType) {
      dispatch(autServices.fetchListOfAuts(autType));
    }
    if (autId) {
      fetchTestCases();
    }
    return () => {
      dispatch(setTestcasesList([]));
    };
  }, []);

  const fetchTestCases = async () => {
    try {
      dispatch(testcaseServices.fetchTestCases(autId));
    } catch (error) {
      dispatch(showToast({ message: error?.response?.data?.message || error.message, type: "error" }));
    }
  };

  const handlePreviewTestCase = () => {
    // navigate(`/testcases/manage/${autId}/${row.id}`);
  };

  const handleExecuteTestCase = () => {
    const filterData = selectedTestCases?.map((each) => ({
      testCaseId: each?.id,
      testCaseVersion: each?.version,
      name: each?.name,
      noOfSteps: each?.noOfSteps,
      createdBy: each?.noOfSteps,
      testDataIds: [],
    }));
    navigate(`/configs/create?autType=${autType}&autName=${autName}&autId=${autId}`, {
      state: { defaultTestCases: filterData },
    });
  };

  const handleViewExecutionReports = (row) => {
    setSearchParams((prev) => {
      const newState = new URLSearchParams(prev);
      newState.set("testCaseId", row?.id);
      newState.set("tcName", row?.name);
      newState.set("startDate", dateRangeObj.startDate);
      newState.set("endDate", dateRangeObj.endDate);
      return newState;
    });

    autoScroll();
  };

  const handleEditTestCase = (row) => {
    navigate(
      `/testcases/create?autId=${autId}&testCaseId=${row.id}&testCaseVersion=${row.version}&autType=${autType}&autName=${autName}`,
    );
  };

  const handleFileImport = async (event, fileInputRef) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    try {
      if (file) {
        await KiTAPServer.importTestCase(formData, autId);
        fetchTestCases();
      }
    } catch (error) {
      dispatch(showToast({ message: error?.response?.data?.message || error.message, type: "error" }));
    }
    fileInputRef.current.value = null;
  };

  const handleDeleteTestCases = async () => {
    setDeleteLoading(true);
    const testCaseIdsToDelete = selectedTestCases.map((testCase) => testCase.id);
    try {
      await KiTAPServer.deleteTestCase({ deleteIdList: testCaseIdsToDelete });
      if (gridRef?.current?.api) {
        gridRef.current.api.applyTransaction({ remove: selectedTestCases });
      }
      setSelectedTestCases([]);
      dispatch(showToast({ message: "Selected test cases are deleted", type: "success" }));
    } catch (error) {
      dispatch(showToast({ message: error?.response?.data?.message || error.message, type: "error" }));
    }
    setDeleteLoading(false);
    setIsDeleteModalOpen(false);
  };

  const handleDeleteButtonClick = () => {
    setIsDeleteModalOpen(true);
  };

  const handleSelectionChange = useCallback(() => {
    const gridApi = gridRef.current?.api;
    if (gridApi) {
      const selectedNodes = gridApi.getSelectedNodes();
      const selectedData = selectedNodes.map((node) => node.data);
      setSelectedTestCases(selectedData);
    }
  }, []);

  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
  };

  if (!autType || !autName) {
    return (
      <>
        <TestCaseListHeader />
        <Box className="pt74" sx={{ width: "100%" }}>
          <SelectAutType />
        </Box>
      </>
    );
  }
  const autoScroll = () => {
    setTimeout(() => {
      scrollRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }, 600);
  };
  return (
    <>
      <TestCaseListHeader />
      <Box className="pt74" sx={{ width: "100%" }}>
        {testCasesLoading ? (
          <SkeletonLoader />
        ) : (
          <Box sx={{ mt: 2, pb: 6, "& .ag-root-wrapper": { borderRadius: "0px", border: "0px", boxShadow: "none" } }}>
            <TestCaseListActionsToolbar
              selectedTestCases={selectedTestCases}
              gridRef={gridRef}
              onClickDelete={handleDeleteButtonClick}
              onClickEditTestCase={handleEditTestCase}
              executeTestCase={handleExecuteTestCase}
              previewTestCase={handlePreviewTestCase}
              viewExecutionReports={handleViewExecutionReports}
              // cloneTestCase={handleCloneTestCase}
              handleFileChange={handleFileImport}
              fetchTestCases={fetchTestCases}
            />
            <CustomReactAgGrid
              gridRef={gridRef}
              columnDefs={testCaseListHeadCells}
              rowData={testCaseRows}
              onSelectionChanged={handleSelectionChange}
              rowHeight={52}
              context={{
                navigate,
                autId,
                autType,
                autName,
                scrollRef,
                gridRef,
              }}
            />
          </Box>
        )}
        {testCaseId && (
          <Box ref={scrollRef}>
            <Executions />
          </Box>
        )}

        {isDeleteModalOpen && (
          <CustomDeleteModal
            loading={deleteLoading}
            disabled={deleteLoading}
            onClickDelete={handleDeleteTestCases}
            handleClose={handleDeleteModalClose}
          />
        )}
      </Box>
    </>
  );
};

export default TestCasesList;
