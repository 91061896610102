import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { useEffect, useState, useCallback } from "react";
import { testcaseServices } from "../../../Redux/services/testcase.services";
import { autServices } from "../../../Redux/services/aut.services";
import SelectedTestCaseList from "./SelectedTestCaseList";
import ExecutionTestDataList from "./ExecutionTestDataList";
import CustomButton from "../../CustomComponents/CustomButtons/CustomButton";
import FullScreenDialog from "../../TestCases/Create/CreateTestStepsContainer/FullWidthScreen";
import ExecutionFormModal from "./ExecutionFormModal";
import AddTestCases from "./AddTestCases";
import CustomLoadingButton from "../../CustomComponents/CustomButtons/CustomLoadingButton";
import InnerHeader from "../../Layout/InnerHeader";
import { setConfigTestCases, setTestStepsData } from "../../../Redux/reducers/testcaseSlice";
import SkeletonLoader from "../../SkeletonLoader";
import CustomBackButton from "../../CustomComponents/CustomButtons/CustomBackButton";
import { DEFAULT_BROWSER, DEFAULT_LOCAL_EXECUTION, DEFAULT_SELF_HEALING } from "../../../utilities/defaultData";
import { showToast } from "../../../Redux/reducers/toastSlice";
import ConfigSettings from "./ConfigSettings";
import { formatISODateTime } from "../../../utils/formatDate";

const defaultScheduledData = {
  frequencyValue: 2,
  frequencyUnit: 1,
  startDate: formatISODateTime(),
  endDate: formatISODateTime(),
  isRepeat: false,
};

const CreateConfigExecution = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { defaultTestCases } = location.state || {};

  const [searchParams, setSearchParams] = useSearchParams();
  const autType = searchParams.get("autType");
  const autId = Number.parseInt(searchParams.get("autId"));
  const autName = searchParams.get("autName");
  // const testCaseId = parseInt(searchParams.get("testCaseId"));
  const configId = Number.parseInt(searchParams.get("testConfigId"));
  const configVersion = Number.parseInt(searchParams.get("configVersion"));
  const isFullScreen = JSON.parse(searchParams.get("isFullScreen"));
  const isSceduled = JSON.parse(searchParams.get("isScheduled"));
  const configTestCases = useSelector((state) => state.testcase.config_test_cases);
  const [selectedBrowsers, setSelectedBrowsers] = useState(configId ? [] : [DEFAULT_BROWSER]);
  const [selectedDevicesList, setSelectedDevicesList] = useState([]);
  const [selectedDeviceProvider, setSelectedDeviceProvider] = useState(false);
  const [loadingExecution, setLoadingExecution] = useState(false);
  const [showCreateTS, setShowCreateTS] = useState(false);
  const [autEnvList, setAutEnvList] = useState([]);
  const [selectedEnv, setSelectedEnv] = useState("");
  const [configInputs, setConfigInputs] = useState({ name: "", description: "" });

  const [loadingConfigDetails, setLoadingConfigDetails] = useState(false);
  const [showAddTestCase, setShowAddTestCase] = useState(false);
  const [scheduledData, setScheduledData] = useState(defaultScheduledData);
  const [executionSettings, setExecutionSettings] = useState({
    parallelExecution: false,
    dataParallelExecution: false,
    localExecution: configId ? false : DEFAULT_LOCAL_EXECUTION,
    enableSelfHealing: configId ? false : DEFAULT_SELF_HEALING,
  });
  const fetchData = async () => {
    if (!defaultTestCases?.length || configId) {
      if (!configId) {
        setShowAddTestCase(true);
      }
      await dispatch(testcaseServices.fetchTestCases(autId));

      if (configId) {
        getTestConfigDetails();
      }
    }
    if (defaultTestCases?.length) {
      dispatch(setConfigTestCases(defaultTestCases));
    }
  };

  const getAutDetails = useCallback(async () => {
    try {
      const data = await autServices.getAutDetailsById(autId);
      setAutEnvList(data?.autEnvironmentResponseList);
      !configId && setSelectedEnv(data?.autEnvironmentResponseList?.[0]?.id || "");
    } catch (error) {
      console.error(error);
    }
  }, [autId]);

  useEffect(() => {
    fetchData();
    getAutDetails();

    return () => {
      dispatch(setConfigTestCases([]));
      dispatch(setTestStepsData([]));
    };
  }, []);

  const toggleFullScreenDialog = useCallback(() => {
    setSearchParams((prev) => {
      const newState = new URLSearchParams(prev);
      newState.set("isFullScreen", !isFullScreen);
      return newState;
    });
  }, [setSearchParams]);

  const handleBrowserChange = (event) => {
    const browserName = event.target.name;
    setSelectedBrowsers((prev) =>
      event.target.checked ? [...prev, browserName] : prev.filter((name) => name !== browserName),
    );
  };

  const handleShowAddTestCases = () => setShowAddTestCase((prev) => !prev);

  const handleAddTestCases = (testCasesData) => {
    const filterData = testCasesData?.map((each) => {
      const isExist = configTestCases?.find((tc) => tc?.testCaseId === each?.id);
      if (isExist) {
        return isExist;
      }
      const newObj = {
        testCaseId: each?.id,
        testCaseVersion: each?.version,
        name: each?.name,
        noOfSteps: each?.noOfSteps,
        createdBy: each?.noOfSteps,
        testDataIds: [],
      };
      return newObj;
    });
    dispatch(setConfigTestCases(filterData));
    handleShowAddTestCases();
    setSearchParams((params) => {
      params.set("testCaseId", testCasesData[0]?.id);
      params.set("version", testCasesData[0]?.version);
      return params;
    });
  };

  const handleSettingsChange = (event) => {
    const { name, checked } = event.target;
    setExecutionSettings((prev) => ({ ...prev, [name]: checked }));

    if (name === "localExecution") {
      setSelectedDevicesList([]);
      setSelectedDeviceProvider(null);
    }
  };

  const executeTestcase = async (type) => {
    setLoadingExecution(true);
    try {
      const testCaseExeDetails = configTestCases.map((el) => ({
        id: null,
        testCaseId: el?.testCaseId,
        testDataIdAndVersionList: el?.testDataIds.map((data) => ({ id: data?.id, version: data?.version })),
        testCaseVersion: el?.testCaseVersion,
        dataParallelExecution: executionSettings.dataParallelExecution,
      }));
      let platformList = [];
      if (["WEB", "SALESFORCE", "SAP"].includes(autType)) {
        platformList = selectedBrowsers?.map((browser) => {
          return {
            browserPlatform: { browserName: browser, browserVersion: null, browserOs: null, browserProvider: null },
          };
        });
      } else if (["ANDROID", "IOS"].includes(autType)) {
        platformList = selectedDevicesList?.map((device) => {
          return {
            devicePlatform: {
              deviceId: device?.deviceId,
              deviceName: device?.deviceName,
              deviceProvider: device?.deviceProvider,
            },
          };
        });
      }

      const timeValues = { 1: "MINUTE", 2: "HOUR", 3: "DAY", 4: "WEEK" };

      const scheduledObj = isSceduled
        ? {
            startDateAndTime: new Date(scheduledData?.startDate).getTime(),
            endDateAndTime: new Date(scheduledData?.endDate).getTime(),
            repeatInterval: scheduledData?.frequencyUnit,
            repeatFrequencyUnit: timeValues[scheduledData?.frequencyValue],
            isRepeated: scheduledData?.isRepeat,
          }
        : {};
      const obj = {
        id: configId || null,
        version: configVersion || null,
        name: configInputs?.name || null,
        description: configInputs?.description || null,
        autId: autId,
        platformList: platformList,
        parallelExecution: executionSettings.parallelExecution,
        localExecution: executionSettings.localExecution,
        testCaseExecutionRequestList: testCaseExeDetails,
        enableSelfHealing: executionSettings.enableSelfHealing,
        isActive: isSceduled || false,
        ...scheduledObj,
      };

      const actions = {
        create: async () => {
          await testcaseServices.createExecutionConfig(obj, selectedEnv);
          dispatch(showToast({ message: "Execution config created successfully", type: "success" }));
        },
        update: async () => {
          await testcaseServices.updateExecutionConfig(obj, selectedEnv);
          dispatch(showToast({ message: "Execution config updated successfully", type: "success" }));
        },
        execute: async () => {
          await testcaseServices.executeTestCase(obj, selectedEnv);
          dispatch(showToast({ message: "Test case accepted for execution successfully", type: "success" }));
        },
        createAndExecute: async () => {
          await testcaseServices.createExecutionConfig(obj, selectedEnv);
          await testcaseServices.executeTestCase(obj, selectedEnv);
          dispatch(
            showToast({
              message: "Execution config created and test case accepted for execution successfully ",
              type: "success",
            }),
          );
        },
        updateAndExecute: async () => {
          await testcaseServices.updateExecutionConfig(obj, selectedEnv);
          await testcaseServices.executeTestCase(obj, selectedEnv);
          dispatch(
            showToast({
              message: "Execution config updated and test case accepted for execution successfully ",
              type: "success",
            }),
          );
        },
      };

      await actions[type]();
      dispatch(setConfigTestCases([]));
      const basePath = configId || type !== "execute" ? "/configs?" : "/testcases?";
      navigate(`${basePath}autType=${autType}&autName=${autName}&autId=${autId}`);
    } catch (error) {
      dispatch(showToast({ message: error?.response?.data?.message || error.message, type: "error" }));
    } finally {
      setLoadingExecution(false);
    }
  };

  const handleChangeEnv = (event) => setSelectedEnv(event.target.value);

  const getTestConfigDetails = async () => {
    setLoadingConfigDetails(true);
    try {
      const valuesToTime = { MINUTE: 1, HOUR: 2, DAY: 3, WEEK: 4 };
      const { data } = await testcaseServices.getExecutionConfigDetails(configId, configVersion);
      const filteredTestData = data?.testCaseExecutionConfigResponseList.map((each) => ({
        testCaseId: each?.testCaseResponse?.id,
        testCaseVersion: each?.testCaseResponse?.version,
        name: each?.testCaseResponse?.name,
        noOfSteps: each?.testCaseResponse?.noOfSteps,
        createdBy: each?.testCaseResponse?.createdBy,
        testDataIds: each?.executionInstanceConfigResponseList.map((item) => ({
          id: item?.testDataResponse?.id,
          version: item?.testDataResponse?.version,
        })),
      }));

      setSelectedEnv(data.autEnvironmentResponse?.id);
      dispatch(setConfigTestCases(filteredTestData));
      setConfigInputs({ name: data?.name, description: data?.description || "" });
      setSelectedBrowsers(data?.platformList.map((el) => el?.browserPlatform?.browserName));
      setSelectedDevicesList(data?.platformList.map((el) => el?.devicePlatform));
      if (autType === "ANDROID" || autType === "IOS") {
        setSelectedDeviceProvider(data?.platformList?.[0]?.devicePlatform?.deviceProvider);
      }
      setExecutionSettings((prev) => ({
        ...prev,
        parallelExecution: data?.parallelExecution,
        localExecution: data?.localExecution,
        enableSelfHealing: data?.enableSelfHealing,
        dataParallelExecution: data?.testCaseExecutionConfigResponseList?.[0]?.dataParallelExecution || false,
      }));
      setSearchParams((params) => {
        params.set("isScheduled", data?.scheduleResponse?.isActive || false);
        return params;
      });

      setScheduledData((prev) => {
        return {
          ...prev,
          frequencyValue:
            valuesToTime[data?.scheduleResponse?.repeatFrequencyUnit] || defaultScheduledData?.frequencyValue,
          frequencyUnit: data?.scheduleResponse?.repeatInterval || defaultScheduledData?.frequencyUnit,
          startDate: formatISODateTime(data?.scheduleResponse?.startDateAndTime) || defaultScheduledData?.startDate,
          endDate: formatISODateTime(data?.scheduleResponse?.endDateAndTime) || defaultScheduledData?.endDate,
          isRepeat: data?.scheduleResponse?.isRepeated || defaultScheduledData?.isRepeat,
        };
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingConfigDetails(false);
    }
  };

  const handleBack = () => navigate(`/configs?autType=${autType}&autName=${autName}&autId=${autId}`);

  const handleDeviceProvider = (event, value) => {
    setSelectedDeviceProvider(value);
    setSelectedDevicesList([]);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setConfigInputs((prev) => ({ ...prev, [name]: value }));
  };

  const renderComponent = () => (
    <>
      <ConfigSettings
        selectedEnv={selectedEnv}
        autEnvList={autEnvList}
        selectedBrowsers={selectedBrowsers}
        selectedDevicesList={selectedDevicesList}
        setSelectedDevicesList={setSelectedDevicesList}
        handleDeviceProvider={handleDeviceProvider}
        selectedDeviceProvider={selectedDeviceProvider}
        executionSettings={executionSettings}
        scheduledData={scheduledData}
        setScheduledData={setScheduledData}
        handleChangeEnv={handleChangeEnv}
        handleSettingsChange={handleSettingsChange}
        handleBrowserChange={handleBrowserChange}
      />
      <Grid container sx={{ borderBottom: "1px solid #ccc" }}>
        <SelectedTestCaseList configTestCases={configTestCases} handleShowAddTestCases={handleShowAddTestCases} />
        <ExecutionTestDataList />
      </Grid>
      <Box className="v-center" sx={{ p: 2, gap: 2 }}>
        <Box sx={{ ml: "auto" }}>
          <CustomLoadingButton
            disabled={(loadingExecution && !showCreateTS) || !selectedEnv}
            loading={loadingExecution && !showCreateTS}
            onClick={() => executeTestcase("execute")}>
            Execute
          </CustomLoadingButton>
          <CustomButton
            disabled={!selectedEnv}
            onClick={() => setShowCreateTS(true)}
            sx={{ ml: 1 }}
            color="success"
            size="small"
            variant="contained"
            className="no-shadow">
            {configId ? "Update Config" : "Create Config"}
          </CustomButton>
        </Box>
      </Box>
    </>
  );

  return (
    <>
      <InnerHeader>
        <Box className="v-center" width="100%" sx={{ gap: 1 }}>
          <CustomBackButton onClick={handleBack}>Back</CustomBackButton>
          <Typography variant="h6"> {configId ? "EDIT CONFIGURATION" : "CREATE CONFIGURATION"} </Typography>
        </Box>
      </InnerHeader>
      <Box className="pt74">
        {loadingConfigDetails ? (
          <SkeletonLoader />
        ) : (
          <Box sx={{ border: "1px solid #D2CDCD", borderRadius: "12px", background: "#F9F9FF" }}>
            {isFullScreen ? (
              <FullScreenDialog open={isFullScreen} setOpen={toggleFullScreenDialog}>
                <Paper>{renderComponent()}</Paper>
              </FullScreenDialog>
            ) : (
              renderComponent()
            )}
          </Box>
        )}
        {showCreateTS && (
          <ExecutionFormModal
            configInputs={configInputs}
            loadingExecution={loadingExecution}
            handleInputChange={handleInputChange}
            onClickExecute={executeTestcase}
            handleClose={() => setShowCreateTS(false)}
          />
        )}
        {showAddTestCase && (
          <AddTestCases
            selectedTestCases={configTestCases}
            handleAddTestCases={handleAddTestCases}
            handleShowAddTestCases={handleShowAddTestCases}
          />
        )}
      </Box>
    </>
  );
};

export default CreateConfigExecution;
