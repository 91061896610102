import { Box, Typography } from "@mui/material";
import { customComparator } from "../../AgGrid/CustomSort";
import { formatDateTime } from "../../../utils/formatDate";

// const CustomExecutionBox = ({ title, value }) => {
//   const backgroundColors = {
//     Executed: "rgba(72, 64, 197, 0.16)",
//     Pass: "rgba(0, 193, 99, 0.16)",
//     "Skip / Abort": "rgba(255, 153, 0, 0.16)",
//     Fail: " rgba(255, 21, 0, 0.16)",
//   };

//   return (
//     <Box
//       className="center column"
//       sx={{
//         p: 1,
//         width: "60px",
//         height: "40px",
//         boxSizing: "border-box",
//         flexShrink: 0,
//         background: backgroundColors[title],
//       }}>
//       <Typography
//         sx={{
//           fontSize: "8px",
//           color: "#64748B",
//         }}>
//         {title}
//       </Typography>
//       <Typography>{value}</Typography>
//     </Box>
//   );
// };

// const Executions = () => {
//   return (
//     <Box sx={{ py: 1, width: "100%", boxSizing: "border-box" }} className="space-between" gap={1}>
//       <CustomExecutionBox title="Executed" value={2} />
//       <CustomExecutionBox title="Pass" value={2} />
//       <CustomExecutionBox title="Skip / Abort" value={2} />
//       <CustomExecutionBox title="Fail" value={2} />
//     </Box>
//   );
// };

const AuthorDetails = (props) => {
  return (
    <Box className="flex column" gap={0.5}>
      <Typography
        sx={{
          color: "#64748B",
          fontSize: "12px",
          fontWeight: 600,
        }}>
        {props?.data?.createdBy}
      </Typography>

      <Typography
        sx={{
          color: "#6C757D",
          fontSize: "10px",
          fontWeight: 300,
        }}>
        {formatDateTime(props?.data?.createdAt)}
      </Typography>
    </Box>
  );
};

export const ConfigListHeadcells = [
  {
    sortable: false,
    headerCheckboxSelection: true,
    checkboxSelection: true,
    lockPosition: "left",
    cellClass: "locked-col",
    suppressColumnsToolPanel: true,
    suppressHeaderMenuButton: true,
    width: 50,
    maxWidth: 50,
    filter: false,
  },

  {
    headerName: "S.No",
    sortable: true,
    valueGetter: "node.rowIndex + 1",
    suppressColumnsToolPanel: true,
    filter: false,
    suppressHeaderMenuButton: true,
    headerTooltip: "S.No",
    width: 75,
    maxWidth: 75,
  },

  {
    field: "name",
    headerName: "Config Name",
    sortable: true,
    suppressColumnsToolPanel: true,
    filter: true,
    comparator: customComparator,
    headerTooltip: "Config Name",
    flex: 1.5,
  },

  // {
  //   field: "version",
  //   headerName: "Version",
  //   sortable: true,
  //   filter: false,
  //   suppressHeaderMenuButton: true,
  //   headerTooltip: "Version",
  //   cellClass: "non-editable",
  // },

  {
    field: "noOfTestCases",
    headerName: "Test Cases",
    sortable: true,
    filter: false,
    suppressHeaderMenuButton: true,
    headerTooltip: "Test Cases",
    cellClass: "non-editable",
  },
  {
    field: "noOfPlatforms",
    headerName: "Platforms",
    sortable: true,
    filter: false,
    suppressHeaderMenuButton: true,
    headerTooltip: "No Of Platforms",
    cellClass: "non-editable",
  },
  {
    field: "parallelExecution",
    headerName: "Parallel Execution",
    sortable: true,
    filter: false,
    suppressHeaderMenuButton: true,
    headerTooltip: "Parallel Execution",
    cellClass: "non-editable",
  },
  {
    field: "autEnvironmentName",
    headerName: "Environment",
    sortable: true,
    filter: false,
    suppressHeaderMenuButton: true,
    headerTooltip: "Environment",
    cellClass: "non-editable",
  },
  {
    field: "scheduled",
    headerName: "Scheduled",
    sortable: true,
    filter: false,
    suppressHeaderMenuButton: true,
    headerTooltip: "Scheduled",
    cellClass: "non-editable",
  },
  {
    field: "createdBy",
    headerName: "Author",
    sortable: true,
    filter: true,
    headerTooltip: "Author",
    flex: 1.5,
    cellRenderer: AuthorDetails,
    cellStyle: { padding: 8 },
  },
  // {
  //   field: "",
  //   headerName: "Executions",
  //   sortable: true,
  //   suppressColumnsToolPanel: true,
  //   filter: true,
  //   headerTooltip: "Created Date",
  //   cellRenderer: Executions,
  //   minWidth: 280,
  //   maxWidth: 280,
  //   flex: 2.5,
  //   cellStyle: { padding: 8 },
  // },
];
