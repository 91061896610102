import { memo, useState } from "react";
import {
  Box,
  Divider,
  // IconButton,
  Popover,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
// import SettingsIcon from "@mui/icons-material/Settings";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const TestItem = memo(({ index }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleAccordionChange = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <Accordion
      expanded={isExpanded}
      onChange={handleAccordionChange}
      sx={{
        "& .MuiAccordionSummary-root": { minHeight: "40px !important" },
        "& .Mui-expanded": { minHeight: "40px !important", m: "0px !important" },
        "&:before": { display: "none" },
        border: "1px solid #F3F3F3",
        borderRadius: "4px",
        paddingRight: "5px",
        boxShadow: "none !important",
      }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${index}-content`}
        id={`panel${index}-header`}
        sx={{
          border: "none",
          p: 0,
          "& .MuiPaper-root": { m: "0px !important" },
          "& .MuiAccordionSummary-root": { minHeight: "40px !important" },
          "& .MuiAccordionSummary-content": { m: 0, py: 0, px: 1 },
          "& .MuiAccordion-root": { p: 0, m: "0px !important" },
          "& .Mui-expanded": { m: 0, pt: 0.5, alignItems: "center", "& svg": { width: "auto" } },
        }}>
        <Box width={"100%"} className="space-between">
          <Typography sx={{ opacity: 0.7 }} variant="bold">
            TestSuite_01
          </Typography>
          <Typography>Total TestCases: 10</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 1 }}></AccordionDetails>
    </Accordion>
  );
});

TestItem.displayName = "TestItem";

const ExecutionStatus = memo(() => {
  const [anchorEl, setAnchorEl] = useState(null);

  // const handlePopoverOpen = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const isPopoverOpen = Boolean(anchorEl);
  const popoverId = isPopoverOpen ? "settings-popover" : undefined;

  return (
    <>
      {/* <IconButton color="primary" onClick={handlePopoverOpen} aria-describedby={popoverId} size="small">
        <SettingsIcon fontSize="small" />
      </IconButton> */}

      <Popover
        id={popoverId}
        open={isPopoverOpen}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "&::before": {
              // eslint-disable-next-line quotes
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}>
        <Box sx={{ width: "400px" }}>
          <Box p={1} className="space-between">
            <Typography sx={{ opacity: 0.7 }} variant="bold">
              Run Test
            </Typography>
            <Typography>No. of Runs: 2</Typography>
          </Box>
          <Divider />
          <Box py={1}>
            {[1].map((test, index) => (
              <TestItem key={index} index={index} test={test} />
            ))}
          </Box>
        </Box>
      </Popover>
    </>
  );
});

ExecutionStatus.displayName = "ExecutionStatus";

export default ExecutionStatus;
