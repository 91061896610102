import { DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Backdrop, Card } from "@mui/material";
import { useTranslation } from "react-i18next";

const ConfirmModel = ({ confirmModel, setConfirmModel, returnValue }) => {
  const { t } = useTranslation();
  const handleClose = () => {
    setConfirmModel(false);
    returnValue({ logedInHere: false, confirmModel: false });
  };
  const logedInHere = () => {
    setConfirmModel(false);
    returnValue({ logedInHere: true, confirmModel: false });
  };

  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={confirmModel}
      onClose={handleClose}>
      <Card>
        <DialogTitle>{t("Terminate Active Login Session ?")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("There is already an active user session. You want to terminate it?")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" style={{ backgroundColor: "#AAAAAA" }} onClick={handleClose}>
            {t("Cancel")}
          </Button>
          <Button variant="contained" onClick={logedInHere}>
            {t("Confirm")}
          </Button>
        </DialogActions>
      </Card>
    </Backdrop>
  );
};

export default ConfirmModel;
