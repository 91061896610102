import axios from "axios";
import { ApiServerUrlV1 } from "../../constants";

const getMutliLineChartGraph = (id, fromDate, toDate) => {
  return axios.get(`${ApiServerUrlV1}/dashboards/time-series?zonedId=${id}&fromDate=${fromDate}&toDate=${toDate}`);
};

export const reportsServices = {
  getMutliLineChartGraph,
};
