import { Box, Grid, Paper, IconButton, InputBase, TextField, MenuItem } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";

const agentFilter = [
  {
    value: "All",
    label: "All",
  },
  {
    value: "Online",
    label: "Online",
  },
  {
    value: "Offline",
    label: "Offline",
  },
];

export default function Filters() {
  return (
    <Box>
      <Grid container>
        <Grid sm={6}>
          <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: 400,
            }}>
            <IconButton sx={{ p: "10px" }} aria-label="menu">
              <FilterListOutlinedIcon />
            </IconButton>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search.."
              inputProps={{ "aria-label": "search google maps" }}
            />
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Paper>
        </Grid>
        <Grid sm={6} textAlign="right">
          <TextField select defaultValue="All" variant="standard">
            {Array.isArray(agentFilter) &&
              agentFilter.map((option) => (
                <MenuItem key={option?.value} value={option?.value}>
                  {option?.label}
                </MenuItem>
              ))}
          </TextField>
        </Grid>
      </Grid>
    </Box>
  );
}
