import { Box, Button, Checkbox, Divider, Grid, IconButton, Typography } from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useNavigate } from "react-router-dom";

function ProgressBar({ greenPercentage1, redPercentage1 }) {
  console.log(greenPercentage1, redPercentage1);
  const greenPercentage = 0.3,
    redPercentage = 0.5,
    greyPercentage = 0.2;
  const greenWidth = Math.max(0, Math.min(100 * greenPercentage, 100));
  const redWidth = Math.max(0, Math.min(100 * redPercentage, 100));
  const greyWidth = Math.max(0, Math.min(100 * greyPercentage, 100));

  return (
    <Box height={"100%"} className="center column">
      <Box
        className="progress-item"
        style={{
          width: "8px",
          height: `${greenWidth}%`,
          backgroundColor: "#00C163",
          //   borderRadius: "4px 4px 0px 0px",
        }}></Box>
      <Box
        className="progress-item"
        style={{
          width: "8px",
          height: `${redWidth}%`,
          //   left: `${greenWidth}%`,
          backgroundColor: "#F9594B",
          borderRadius: 0,
        }}></Box>
      <Box
        className="progress-item"
        style={{
          width: "8px",
          height: `${greyWidth}%`,
          //   left: `${redWidth + greenWidth}%`,
          backgroundColor: "#DDE0E5",
          //   borderRadius: "0px 0px 4px 4px",
        }}></Box>
    </Box>
  );
}

const RenderDetailRow = ({ label, value }) => (
  <Box display="flex">
    <Typography
      sx={{
        width: "180px",
        color: "#495057",
        fontSize: "12px",
        fontWeight: 500,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
      }}>
      {label}
      <Typography sx={{ float: "right" }} component={"span"}>
        &nbsp;&nbsp;&nbsp;: &nbsp;&nbsp;&nbsp;
      </Typography>
    </Typography>
    <Typography
      sx={{
        color: "#64748B",
        fontSize: "12px",
        fontWeight: 500,
        wordBreak: "break-all",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: 1,
        WebkitBoxOrient: "vertical",
      }}
      width="100%">
      {value || "--"}
    </Typography>
  </Box>
);

const LinkComponentTestSuites = ({ title, count, onClickAdd, onClickList }) => (
  <Box className="flex column" gap={0.5}>
    <Typography
      variant="bold"
      sx={{
        color: "#64748B",
        fontSize: "12px",
      }}>
      {title}
    </Typography>
    <Box
      sx={{
        display: "flex",
        alignItems: "center",

        gap: 1,
        px: 1,
      }}>
      <Button onClick={onClickList} sx={{ p: 0, minWidth: 28 }} variant="outlined" size="small">
        {count}
      </Button>
      <IconButton size="small" onClick={onClickAdd}>
        <AddOutlinedIcon sx={{ color: "#381FD1" }} />
      </IconButton>
    </Box>
  </Box>
);

const AutTile = ({ aut, checked, onChangeCheckbox }) => {
  const navigate = useNavigate();

  const navigateToAddTestSuite = () => {
    navigate(`/testsuites/${aut.id}`);
  };

  const navigateToAddTestCase = () => {
    navigate(`/testsuites/${aut.id}`);
  };

  const navigateToTestCasesList = () => {
    navigate(`/testsuites/${aut.id}`);
  };

  const navigateToTestSuitesList = () => {
    navigate(`/testsuites/${aut.id}`);
  };

  return (
    <Grid item md={4} lg={3} p={1}>
      <Grid container className="autTile">
        <Grid item md={9.5} p={1} borderRight="1px solid #ccc" className="flex column" gap={0.5}>
          <RenderDetailRow label="Name" value={aut.name} />
          <RenderDetailRow label="Description" value={aut.description} />
          <RenderDetailRow label="URL/PATH" value={aut.url} />

          <Box sx={{ mt: 2 }} className="space-between">
            <LinkComponentTestSuites
              title="TEST CASES"
              count={aut.noOfTestCases}
              onClickAdd={navigateToAddTestSuite}
              onClickList={navigateToTestCasesList}
            />
            <Divider orientation="vertical" flexItem variant="middle" />
            <LinkComponentTestSuites
              title="TEST SUITES"
              count={aut.noOfTestSuites}
              onClickAdd={navigateToAddTestCase}
              onClickList={navigateToTestSuitesList}
            />
          </Box>
        </Grid>

        <Grid item md={2.5} position="relative" py={1}>
          <Checkbox
            checked={checked}
            onChange={() => onChangeCheckbox(aut)}
            size="small"
            sx={{
              position: "absolute",
              top: 3,
              right: 2,
              p: 0,
              color: "#ADB5BD",
            }}
          />

          <Box height="100%" className="center column">
            <ProgressBar />
            <Typography sx={{ textAlign: "center", fontSize: "8px", fontWeight: 600 }}>Score Card</Typography>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AutTile;
