import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { formatDateLocale, formatISODateTime } from "../../../utils/formatDate";

// Define schedule frequencies
const SCHEDULE_FREQUENCIES = {
  1: { unit: "minutes", label: "Minute(s)" },
  2: { unit: "hours", label: "Hour(s)" },
  3: { unit: "days", label: "Day(s)" },
  4: { unit: "weeks", label: "Week(s)" },
  // 5: { unit: "months", label: "Month(s)" },
};

// Helper function to format date and time
const formatDateTime = (date) => formatISODateTime(date);

// Helper function to get minimum end date based on start date, frequency unit, and value
const calculateMinToDate = (startDate, frequencyUnit, frequencyValue) => {
  if (!startDate || !SCHEDULE_FREQUENCIES[frequencyValue]) {
    return "";
  }

  const { unit } = SCHEDULE_FREQUENCIES[frequencyValue];

  const toDate =
    unit === "weeks" ? dayjs(startDate).add(frequencyUnit * 7, "days") : dayjs(startDate).add(frequencyUnit, unit);

  return toDate.isValid() ? formatDateTime(toDate) : "";
};

const SelectScheduledDate = ({ scheduledData = {}, setScheduledData }) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const testConfigId = Number.parseInt(searchParams.get("testConfigId"));

  // Calculate initial min date values
  const minFromDate = scheduledData.startDate ? formatDateTime(scheduledData.startDate) : "";
  const minToDate = calculateMinToDate(
    scheduledData.startDate,
    scheduledData.frequencyUnit,
    scheduledData.frequencyValue,
  );

  // Effect to initialize dates if no testConfigId is present
  useEffect(() => {
    if (!testConfigId) {
      const now = dayjs().add(5, "minutes").format("YYYY-MM-DDTHH:mm");
      setScheduledData((prevState) => ({
        ...prevState,
        startDate: now,
        endDate: calculateMinToDate(now, prevState.frequencyUnit, prevState.frequencyValue),
      }));
    }
  }, [testConfigId, setScheduledData]);

  // Handle frequency change
  const handleFrequencyChange = (e) => {
    const frequencyValue = Number.parseInt(e.target.value, 10);
    setScheduledData((prevState) => ({
      ...prevState,
      frequencyValue,
      endDate: calculateMinToDate(prevState.startDate, prevState.frequencyUnit, frequencyValue),
    }));
  };

  // Handle start date change
  const handleStartDateChange = (event) => {
    const selectedDate = dayjs(event.target.value);
    const formattedDate = selectedDate.isValid() ? formatDateTime(selectedDate) : "";

    setScheduledData((prevState) => ({
      ...prevState,
      startDate: formattedDate,
      endDate: calculateMinToDate(formattedDate, prevState.frequencyUnit, prevState.frequencyValue),
    }));
  };

  // Handle end date change
  const handleEndDateChange = (event) => {
    const selectedDate = dayjs(event.target.value);
    const formattedDate = selectedDate.isValid() ? formatDateTime(selectedDate) : "";
    setScheduledData((prevState) => ({ ...prevState, endDate: formattedDate }));
  };

  // Handle frequency unit change
  const handleEveryChange = (e) => {
    const frequencyUnit = Number.parseInt(e.target.value, 10);
    setScheduledData((prevState) => ({
      ...prevState,
      frequencyUnit,
      endDate: calculateMinToDate(prevState.startDate, frequencyUnit, prevState.frequencyValue),
    }));
  };

  return (
    <Grid container spacing={2}>
      <Grid item sm={6}>
        <Grid container spacing={1}>
          <Grid item sm={9}>
            <TextField
              fullWidth
              size="small"
              inputProps={{ min: minFromDate }}
              value={scheduledData.startDate || ""}
              label={t("Schedule Date & Time")}
              type="datetime-local"
              InputLabelProps={{ shrink: true }}
              onChange={handleStartDateChange}
            />
          </Grid>
          <Grid item sm={3}>
            <FormControlLabel
              sx={{ float: "right", mr: 0 }}
              control={
                <Switch
                  checked={scheduledData.isRepeat || false}
                  onChange={(e) => setScheduledData((prevState) => ({ ...prevState, isRepeat: e.target.checked }))}
                />
              }
              label={t("Repeat")}
            />
          </Grid>
          {scheduledData.isRepeat && (
            <>
              <Grid item sm={6} mb={1} className="v-center">
                <FormLabel sx={{ mr: "10px" }}>{t("Every")}</FormLabel>
                <TextField
                  type="number"
                  size="small"
                  style={{ width: "120px" }}
                  InputLabelProps={{ shrink: true }}
                  value={scheduledData.frequencyUnit || 1}
                  onChange={handleEveryChange}
                />
                <FormControl size="small" fullWidth sx={{ display: "inline-flex", ml: "10px" }}>
                  <Select value={scheduledData.frequencyValue || ""} onChange={handleFrequencyChange}>
                    {Object.entries(SCHEDULE_FREQUENCIES).map(([key, { label }]) => (
                      <MenuItem value={key} key={key}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={6}>
                <TextField
                  fullWidth
                  size="small"
                  inputProps={{ min: minToDate }}
                  value={scheduledData.endDate || ""}
                  label={t("End Date & Time")}
                  type="datetime-local"
                  InputLabelProps={{ shrink: true }}
                  onChange={handleEndDateChange}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>

      {scheduledData.isRepeat && (
        <Grid item sm={6} px={2}>
          <Box sx={{ border: "1px solid #ccc" }} height="100%" className="center">
            <Typography className="bold-text italic-text word-spacing">
              {t("Start on")} {formatDateLocale(scheduledData.startDate)}, {t("repeats every")}{" "}
              {scheduledData.frequencyUnit} {SCHEDULE_FREQUENCIES[scheduledData.frequencyValue]?.label},{" "}
              {t("and ends on")} {formatDateLocale(scheduledData.endDate)}.
            </Typography>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default SelectScheduledDate;
