import { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Box, Paper, Typography, FormControl, InputLabel, Select, MenuItem, OutlinedInput } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import SelectDateRange from "../../CustomComponents/SelectDateRange";
import { reportsServices } from "../../../Redux/services/reports.services";
import { dateRangeObj } from "../../../utilities/defaultData";
import dayjs from "dayjs";

// Register ChartJS components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);
const generateColor = (index) => {
  const hue = (index * 137.508) % 360; // Using golden angle approximation
  return `hsl(${hue}, 65%, 50%)`;
};

// Utility function to generate datasets
const generateDatasets = (data, allDates) => {
  return data.map((item, index) => {
    const dataMap = new Map(item?.timeSeriesList.map((point) => [point.localDate, point.passPercentage]));
    const alignedData = allDates.map((date) => dataMap.get(date) ?? Number.NaN);
    const color = generateColor(index);
    return {
      label: item?.autName,
      autName: item?.autName,
      autType: item?.autType,
      data: alignedData,
      borderColor: color,
      backgroundColor: color,
      borderWidth: 1,
      pointBackgroundColor: color,
      hoverBackgroundColor: color,
      spanGaps: true,
      pointStyle: "circle",
      pointRadius: 3,
      hoverRadius: 6,
    };
  });
};

// Utility function to get unique dates
const getAllUniqueDates = (data) => {
  const dates = data.flatMap((item) => item.timeSeriesList).map((point) => point.localDate);
  const uniqueDates = [...new Set(dates)];
  uniqueDates.sort((a, b) => new Date(a) - new Date(b));
  return uniqueDates;
};

// Utility function to format dates as "DD MMM"
const formatDatesAsDetailed = (dates) => {
  return dates.map((date) => dayjs(date).format("DD MMM"));
};

const AUTsLineChart = () => {
  const [selectedDataset, setSelectedDataset] = useState("All");
  const [resData, setResData] = useState([]);
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  const [searchParams, setSearchParams] = useSearchParams();
  const startDate = new Date(searchParams.get("startDate"));
  const endDate = new Date(searchParams.get("endDate"));

  const handleChange = (event) => {
    setSelectedDataset(event.target.value);
  };

  const fetchGraphData = async () => {
    try {
      const sartDataImMillies = startDate.getTime();
      const endDataInMillies = endDate.getTime();
      const zonedId = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const res = await reportsServices.getMutliLineChartGraph(zonedId, sartDataImMillies, endDataInMillies);
      setResData(res?.data?.autPassPercentageTimeSeriesList || []);
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    setSearchParams((prev) => {
      const newState = new URLSearchParams(prev);
      newState.set("startDate", dateRangeObj?.startDate);
      newState.set("endDate", dateRangeObj?.endDate);
      return newState;
    });
    fetchGraphData();
  }, [selectedDataset]);

  useEffect(() => {
    const filteredData =
      selectedDataset === "All" ? resData : resData.filter((item) => item?.autName === selectedDataset);
    const allUniqueDates = getAllUniqueDates(resData);
    const formattedDates = formatDatesAsDetailed(allUniqueDates);
    const Data = {
      labels: formattedDates,
      datasets: generateDatasets(filteredData, allUniqueDates),
    };
    setChartData(Data);
  }, [resData, selectedDataset]);

  const options = {
    responsive: true,
    plugins: {
      legend: { display: false },
      title: { display: false },
      // tooltip: {
      //   callbacks: {
      //     title: function (tooltipItem) {
      //       console.log(tooltipItem);
      //       return `${tooltipItem[0].dataset.autName}`;
      //     },
      //     label: function (context) {
      //       const date = context.label;
      //       const passPercentage = `Pass % - <span style="color: #4CAF50;">${context.parsed.y}</span>`;
      //       const month = `Month - <span style="color: #FF5733;">${dayjs(date, "DD MMM").format("DD MMMM")}</span>`;
      //       return `${passPercentage}<br>${month}`;
      //     },
      //   },
      //   backgroundColor: "rgba(255, 255, 255, 0.9)",
      //   titleFont: { size: 11, weight: "bold" },
      //   bodyFont: { size: 11 },
      //   bodyColor: "#333",
      //   borderColor: "#e5e5e5",
      //   borderWidth: 1,
      //   cornerRadius: 4,
      //   padding: 10,
      //   titleColor: "#64748B",
      //   displayColors: false,
      //   useHTML: true,
      // },
    },
    scales: {
      x: {
        grid: { display: false },
        ticks: { display: true },
      },
      y: {
        grid: { borderColor: "rgba(0,0,0,0.1)" },
        border: { dash: [5, 5] },
        ticks: { display: true },
        title: { display: true, text: "Pass %", color: "#4CAF50", font: { size: 14 }, padding: { top: 20, bottom: 0 } },
      },
    },
    elements: {
      point: {
        radius: 3,
        hoverRadius: 6,
      },
    },
    onHover: (event, chartElement) => {
      event.native.target.style.cursor = chartElement[0] ? "pointer" : "default";
    },
  };

  const handleOnSubmitDateRange = () => {
    fetchGraphData();
  };

  const onChangeDateRange = (data) => {
    setSearchParams((prev) => {
      const newState = new URLSearchParams(prev);
      newState.set("startDate", data?.startDate);
      newState.set("endDate", data?.endDate);
      return newState;
    });
    fetchGraphData();
  };

  return (
    <Paper elevation={2} sx={{ borderRadius: "8px", color: "#64748B", p: "12px" }}>
      <Box className="space-between v-center" my={2}>
        <Typography variant="h6">Trends</Typography>
        <Box className="v-center" gap={2}>
          <Box className="v-center" gap={2}>
            <Typography variant="body1">
              <strong>Start Date : </strong>
              {startDate.toDateString()}
            </Typography>
            <Typography variant="body1">
              <strong> End Date : </strong>
              {endDate.toDateString()}
            </Typography>
            <SelectDateRange
              setSelectedDateRange={onChangeDateRange}
              selectedDateRange={{ startDate, endDate, key: "selection" }}
              onClickSubmit={handleOnSubmitDateRange}
            />
          </Box>

          <FormControl className="lineargradient-formcontrol" fullWidth size="small" sx={{ maxWidth: "150px" }}>
            <InputLabel>AUT Name</InputLabel>
            <Select
              size="small"
              value={selectedDataset}
              onChange={handleChange}
              label="AUT Name"
              input={<OutlinedInput label="AUT Name" />}>
              <MenuItem value="All">All</MenuItem>
              {resData.map((item) => (
                <MenuItem key={item.autId} value={item.autName}>
                  {item.autName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Line data={chartData} options={options} />
    </Paper>
  );
};

export default AUTsLineChart;
