import { useEffect, useState, useCallback, memo } from "react";
import {
  Box,
  MenuItem,
  Typography,
  Menu,
  IconButton,
  InputBase,
  Grid,
  AvatarGroup,
  Avatar,
  Switch,
  Tooltip,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { AddCircleOutlineOutlined } from "@mui/icons-material";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { ELEMENT_LOCATOR_BACKGROUND_COLORS } from "../../../../utilities/defaultData";
const isArrayList = ["relativeXpathList", "cssSelectorList"];
const UIElementsMenuList = memo(({ defaultValues, anchorEl, setAnchorEl, setUIElements }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const autType = searchParams.get("autType") || false;
  const { element_locator_details: elementLocatorDetails } = useSelector((state) => state.testcase);
  const [isOpenEL, setIsOpenedEL] = useState(false);
  const [formValues, setFormValues] = useState({});

  const handleSaveClick = useCallback(() => {
    const cleanRelativeElementLocator =
      formValues.relativeElementLocator &&
      Object.values(formValues.relativeElementLocator).some((value) => value !== null)
        ? formValues.relativeElementLocator
        : null;

    const cleanedFormValues = {
      ...formValues,
      relativeElementLocator: cleanRelativeElementLocator,
      relativeXpathList: formValues?.relativeXpathList?.length ? formValues.relativeXpathList : null,
      cssSelectorList: formValues?.cssSelectorList?.length ? formValues.cssSelectorList : null,
    };

    setUIElements(cleanedFormValues);
    setAnchorEl(null);

    setSearchParams((params) => {
      params.delete("elementError");
      return params;
    });
  }, [formValues, setUIElements, setSearchParams, setAnchorEl]);

  useEffect(() => {
    const defaultLocators = elementLocatorDetails[autType]?.reduce((acc, key) => {
      acc[key] =
        key === "relativeElementLocator"
          ? Object.fromEntries(
              elementLocatorDetails[autType]
                .filter((newLoc) => newLoc !== "relativeElementLocator")
                .map((key) => [key, null]),
            )
          : null;
      return acc;
    }, {});

    setFormValues({ ...defaultLocators, ...defaultValues });
  }, []);

  const handleRelativeLocatorChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      relativeElementLocator: {
        ...prevFormValues.relativeElementLocator,
        [name]: isArrayList?.includes(name) ? value.split("||").map((item) => item.trim()) : value,
      },
    }));
  };

  const handleInputChange = useCallback((event) => {
    const { name, value } = event.target;
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: isArrayList?.includes(name) ? value.split("||").map((item) => item.trim()) : value,
    }));
  }, []);

  const handleToggleShadow = () => {
    setFormValues((prev) => ({ ...prev, isShadow: !prev.isShadow }));
  };

  return (
    <Menu
      sx={{ maxHeight: 300, "& .MuiPopover-paper": { p: 1, minWidth: "390px", maxWidth: "390px" } }}
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleSaveClick}>
      {Object.keys(formValues).map((key, keyIndex) => {
        if (key === "id") {
          return null;
        }

        const isRelativeElementLocator = key === "relativeElementLocator";
        const isShadow = key === "isShadow";

        if (isShadow) {
          return (
            <MenuItem
              key={key}
              onKeyDown={(e) => e.stopPropagation()}
              onClick={(e) => e.stopPropagation()}
              sx={{
                py: 0.5,
                px: 1,
                mb: 0.2,
                backgroundColor: ELEMENT_LOCATOR_BACKGROUND_COLORS[keyIndex],
                borderRadius: "4px",
                "&:hover": {
                  backgroundColor: ELEMENT_LOCATOR_BACKGROUND_COLORS[keyIndex],
                },
              }}>
              <Grid key={keyIndex} container alignItems="center">
                <Grid item xs={5}>
                  <Box className="space-between">
                    <Typography>{key}</Typography>
                    <Typography sx={{ mr: 1 }}>:</Typography>
                  </Box>
                </Grid>
                <Grid item xs={7}>
                  <Switch name={key} onChange={handleToggleShadow} checked={formValues[key] || false} size="small" />
                </Grid>
              </Grid>
            </MenuItem>
          );
        }

        return (
          <MenuItem
            key={key}
            onKeyDown={(e) => e.stopPropagation()}
            onClick={(e) => e.stopPropagation()}
            sx={{
              py: 0.5,
              px: isRelativeElementLocator ? 0 : 1,
              mb: 0.2,
              backgroundColor: isRelativeElementLocator ? "#fff" : ELEMENT_LOCATOR_BACKGROUND_COLORS[keyIndex],
              borderRadius: "4px",
              "&:hover": {
                backgroundColor: isRelativeElementLocator ? "#fff" : ELEMENT_LOCATOR_BACKGROUND_COLORS[keyIndex],
              },
            }}>
            {isRelativeElementLocator ? (
              <Box sx={{ border: "1px solid #ccc", px: 1, borderRadius: "4px" }} width="100%" className="flex column">
                <Box onClick={() => setIsOpenedEL((prev) => !prev)} width="100%" className="space-between">
                  <Typography>{key}</Typography>
                  <IconButton color="primary" size="small">
                    {isOpenEL ? <ExpandLessIcon fontSize="small" /> : <ExpandMoreIcon fontSize="small" />}
                  </IconButton>
                </Box>
                {isOpenEL &&
                  formValues.relativeElementLocator &&
                  Object.keys(formValues.relativeElementLocator).map((relativeKey, relativeIndex) => {
                    if (["id", "isShadow"].includes(relativeKey)) {
                      return null;
                    }

                    return (
                      <MenuItem
                        key={relativeKey}
                        onKeyDown={(e) => e.stopPropagation()}
                        onClick={(e) => e.stopPropagation()}
                        sx={{
                          py: 0.5,
                          mb: 0.2,
                          backgroundColor: ELEMENT_LOCATOR_BACKGROUND_COLORS[relativeIndex],
                          borderRadius: "4px",
                          "&:hover": { backgroundColor: ELEMENT_LOCATOR_BACKGROUND_COLORS[relativeIndex] },
                        }}>
                        <Grid container alignItems="center">
                          <Grid item xs={5}>
                            <Box className="space-between">
                              <Typography>{relativeKey}</Typography>
                              <Typography sx={{ mr: 1 }}>:</Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={7}>
                            <Tooltip
                              arrow
                              disableInteractive
                              placement="top-start"
                              title={
                                relativeKey === "relativeXpathList"
                                  ? formValues?.relativeElementLocator[relativeKey]
                                  : ""
                              }>
                              <InputBase
                                placeholder={relativeKey}
                                name={relativeKey}
                                value={
                                  isArrayList?.includes(relativeKey)
                                    ? formValues.relativeElementLocator[relativeKey]?.join("|| ")
                                    : formValues.relativeElementLocator[relativeKey]
                                }
                                onChange={handleRelativeLocatorChange}
                                fullWidth
                                sx={{
                                  width: "100%",
                                  px: 1,
                                  fontSize: "10px",
                                  backgroundColor: "#fff",
                                  borderRadius: "4px",
                                }}
                              />
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </MenuItem>
                    );
                  })}
              </Box>
            ) : (
              <Grid container alignItems="center">
                <Grid item xs={5}>
                  <Box className="space-between">
                    <Typography>{key}</Typography>
                    <Typography sx={{ mr: 1 }}>:</Typography>
                  </Box>
                </Grid>
                <Grid item xs={7}>
                  <Tooltip
                    arrow
                    disableInteractive
                    placement="top-start"
                    title={key === "relativeXpathList" ? formValues[key] : ""}>
                    <InputBase
                      placeholder={key}
                      name={key}
                      value={isArrayList?.includes(key) ? formValues[key]?.join("|| ") : formValues[key]}
                      onChange={handleInputChange}
                      fullWidth
                      sx={{ width: "100%", px: 1, fontSize: "10px", backgroundColor: "#ffffff", borderRadius: "4px" }}
                    />
                  </Tooltip>
                </Grid>
              </Grid>
            )}
          </MenuItem>
        );
      })}
      <IconButton
        onClick={handleSaveClick}
        size="small"
        sx={{ background: "#fff", boxShadow: "0 0 8px #00000052", position: "sticky", float: "right" }}>
        <AddCircleOutlineOutlined color="primary" fontSize="small" />
      </IconButton>
    </Menu>
  );
});
UIElementsMenuList.displayName = UIElementsMenuList;
const SelectUIElement = ({ disabled = false, defaultValues = {}, setUIElements }) => {
  const [searchParams] = useSearchParams();
  const elementError = searchParams.get("elementError") || false;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = useCallback(
    (event) => {
      if (!disabled) {
        setAnchorEl(event.currentTarget);
      }
    },
    [disabled],
  );

  const selectedKeys = defaultValues
    ? Object.keys(defaultValues).filter((key) => key !== "id" && defaultValues[key])
    : [];

  return (
    <>
      <Box
        sx={{
          width: "100%",
          mx: "auto",
          px: 1,
          border: `1px solid ${elementError ? "#d32f2f" : "#ccc"}`,
          borderRadius: 1,
          backgroundColor: disabled ? "#f5f5f5" : "#fff",
          pointerEvents: disabled ? "none" : "auto",
        }}>
        <Box
          onClick={handleClick}
          className="space-between"
          sx={{ cursor: disabled ? "not-allowed" : "pointer", opacity: disabled ? 0.5 : 1 }}>
          {selectedKeys.length ? (
            <AvatarGroup sx={{ "& .MuiAvatar-circular": { width: 20, height: 20, fontSize: "10px" } }}>
              {selectedKeys.map((key, i) => (
                <Avatar key={key} sx={{ bgcolor: ELEMENT_LOCATOR_BACKGROUND_COLORS[i], color: "#495057" }}>
                  {key.slice(0, 2)}
                </Avatar>
              ))}
            </AvatarGroup>
          ) : (
            <Typography sx={{ ...(elementError && { color: "#d32f2f" }) }}>UI Element</Typography>
          )}
          <IconButton size="small" sx={{ px: 0 }}>
            <ArrowDropDownIcon sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
          </IconButton>
        </Box>
        {anchorEl && (
          <UIElementsMenuList
            defaultValues={defaultValues}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            setUIElements={setUIElements}
          />
        )}
      </Box>
      {elementError && (
        <Typography variant="caption" sx={{ color: "#d32f2f" }}>
          At least one UI Element is required
        </Typography>
      )}
    </>
  );
};

export default memo(SelectUIElement);
