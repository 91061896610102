import { useEffect, useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import InnerHeader from "../../Layout/InnerHeader";
import CreateTestStepsContainer from "./CreateTestStepsContainer";
import CreateTestData from "../CreateTestData";
import SuccessTestcaseCreate from "./CreateTestStepsContainer/SuccessTestcaseCreate";
import { clearAllTestcaseData, setTestStepsData } from "../../../Redux/reducers/testcaseSlice";
import { testcaseServices } from "../../../Redux/services/testcase.services";
import TestCaseFormInputs from "./TestCaseFormInputs";
import CustomBackButton from "../../CustomComponents/CustomButtons/CustomBackButton";
import { showToast } from "../../../Redux/reducers/toastSlice";
import SkeletonLoader from "../../SkeletonLoader";

const validateForm = (inputs) => {
  const errors = {};
  if (!inputs.name) {
    errors.name = "Test Case Name is required.";
  }
  if (inputs.errorThreshold < 0 || inputs.errorThreshold > 100) {
    errors.errorThreshold = "Error Threshold must be between 0 and 100.";
  }
  if (!inputs.businessImportance) {
    errors.businessImportance = "Business Criticality is required";
  }
  return errors;
};

const CreateOrEditTestCase = () => {
  const dispatch = useDispatch();
  const scrollRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const testCaseId = Number.parseInt(searchParams.get("testCaseId"));
  const testCaseVersion = Number.parseInt(searchParams.get("testCaseVersion"));
  const autId = Number.parseInt(searchParams.get("autId"));
  const autType = searchParams.get("autType");
  const autName = searchParams.get("autName");
  const [loadingCreateTC, setLoadingCreateTC] = useState(false);

  const [loading, setLoading] = useState(false);
  const [formInputs, setFormInputs] = useState({ errorThreshold: 1, businessImportance: "LOW" });
  const [errors, setErrors] = useState({});
  const [successDetails, setSuccessDetails] = useState("");
  const [isExpanded, setIsExpanded] = useState(true);

  useEffect(() => {
    setSearchParams((prev) => {
      const newState = new URLSearchParams(prev);
      newState.set("isBulkEdit", true);
      return newState;
    });
    if (testCaseId) {
      fetchTestcaseDetails();
    }

    return () => {
      dispatch(clearAllTestcaseData());
    };
  }, []);

  const fetchTestcaseDetails = async () => {
    setLoading(true);
    try {
      const { data } = await testcaseServices.getTestcaseDetailsById(testCaseId, testCaseVersion);
      setFormInputs({
        errorThreshold: data.errorThreshold,
        businessImportance: data.businessImportance || "LOW",
        name: data.name,
        requirementReference: data.requirementReference,
        description: data.description,
      });
      const { macroBinderResponseList, testStepResponseList } = data;
      const macroItems = macroBinderResponseList.map((each) => {
        const { id, sequenceNumber, macroWithStepsResponse } = each;
        const { testStepResponseList, ...rest } = macroWithStepsResponse;
        return {
          binderId: id,
          sequenceNumber,
          ...rest,
          steps: testStepResponseList?.sort((a, b) => a.sequenceNumber - b.sequenceNumber),
        };
      });
      const updatedSteps = [...testStepResponseList, ...macroItems].sort((a, b) => a.sequenceNumber - b.sequenceNumber);
      dispatch(setTestStepsData(updatedSteps));
    } catch (error) {
      dispatch(showToast({ message: error?.response?.data?.message || error.message, type: "error" }));
    }
    setLoading(false);
  };

  const validateStepsData = (stepsData) => {
    const uniqueNames = new Set();
    const invalidStepNumbers = [];
    stepsData.forEach((step) => {
      if (!step.name || (!step.action && !step.version) || (uniqueNames.has(step.name) && !step.version)) {
        invalidStepNumbers.push(step.sequenceNumber); // Collect invalid step numbers
      } else {
        uniqueNames.add(step.name); // Add unique name
      }
    });
    return invalidStepNumbers;
  };

  const handleSaveTestCase = async (stepsData) => {
    const invalidStepNumbers = validateStepsData(stepsData);

    if (invalidStepNumbers.length > 0) {
      const message = `Fill all required data in the following steps: ${invalidStepNumbers.join(", ")}`;
      dispatch(showToast({ message, type: "error" }));
      return;
    }

    const validationErrors = validateForm(formInputs);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      scrollToTop();
      return;
    }
    setLoadingCreateTC(true);
    const payload = {
      ...(testCaseId ? { id: testCaseId } : {}),
      ...(testCaseVersion ? { version: testCaseVersion } : {}),
      name: formInputs.name,
      description: formInputs.description,
      errorThreshold: formInputs.errorThreshold,
      autId,
      importedFrom: null,
      requirementReference: formInputs.requirementReference,
      importance: formInputs.businessImportance.toUpperCase(),
      testStepRequestList: stepsData.filter((step) => !step.steps),
      importedTestStepRequestList: [],
      testCaseMacroBinderRequests: stepsData
        .filter((step) => step.steps)
        .map((step) => ({
          id: step?.binderId || null,
          sequenceNumber: step.sequenceNumber,
          macroId: step.id,
          macroVersion: step.version,
        })),
    };
    try {
      const { data } = testCaseId
        ? await testcaseServices.updateTestCase(payload)
        : await testcaseServices.createTestCase(payload);
      const { macroBinderResponseList, testStepResponseList } = data;
      const macroItems = macroBinderResponseList.map((each) => {
        const { id, sequenceNumber, macroWithStepsResponse } = each;
        const { testStepResponseList, ...rest } = macroWithStepsResponse;
        return {
          binderId: id,
          sequenceNumber,
          ...rest,
          steps: testStepResponseList?.sort((a, b) => a.sequenceNumber - b.sequenceNumber),
        };
      });
      const updatedSteps = [...testStepResponseList, ...macroItems].sort((a, b) => a.sequenceNumber - b.sequenceNumber);
      dispatch(setTestStepsData(updatedSteps));
      setSuccessDetails({ name: formInputs.name, status: testCaseId ? "updated" : "created" });
      setSearchParams((prev) => {
        const newState = new URLSearchParams(prev);
        newState.set("testCaseId", data.id);
        newState.set("testCaseVersion", data.version);
        return newState;
      });
    } catch (error) {
      dispatch(showToast({ message: error?.response?.data?.message || error.message, type: "error" }));
    }
    setLoadingCreateTC(false);
    setErrors({});
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormInputs((prev) => ({ ...prev, [name]: value }));
    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const handleBackButton = () => {
    // navigate(-2);
    navigate(`/testcases/?autType=${autType}&autName=${autName}&autId=${autId}`);
  };

  const handleCreateTestData = () => {
    setSuccessDetails(null);
    setIsExpanded(false);
  };

  const scrollToTop = () =>
    setTimeout(() => scrollRef.current.scrollIntoView({ behavior: "smooth", block: "start" }), 400);

  const handleCloseSuccessTC = () => {
    navigate(`/testcases?autType=${autType}&autName=${autName}&autId=${autId}`);
    setSuccessDetails(null);
  };

  const toggleAccordion = () => setIsExpanded((prev) => !prev);

  return (
    <>
      <InnerHeader>
        <CustomBackButton sx={{ mr: 1 }} onClick={handleBackButton}>
          Back
        </CustomBackButton>
        <Typography variant="h6">{testCaseId ? "EDIT TEST CASE" : "CREATE TEST CASE"}</Typography>
      </InnerHeader>
      <Box ref={scrollRef} className="pt74">
        <TestCaseFormInputs formInputs={formInputs} handleChange={handleChange} errors={errors} />
        {loading ? (
          <SkeletonLoader />
        ) : (
          <CreateTestStepsContainer
            loadingSaveButton={loadingCreateTC}
            isExpanded={isExpanded}
            toggleAccordion={toggleAccordion}
            handleSaveTestCase={handleSaveTestCase}
          />
        )}
        {!loading && !isExpanded && Boolean(testCaseId) && <CreateTestData />}
      </Box>
      {successDetails?.name && (
        <SuccessTestcaseCreate
          successDetails={successDetails}
          handleClose={handleCloseSuccessTC}
          onClickCreateTestData={handleCreateTestData}
        />
      )}
    </>
  );
};

export default CreateOrEditTestCase;
