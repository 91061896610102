import {
  Box,
  Grid,
  IconButton,
  Typography,
  Chip,
  InputLabel,
  MenuItem,
  Select,
  OutlinedInput,
  FormControl,
  Tooltip,
} from "@mui/material";
import { InfoOutlined as InfoIcon } from "@mui/icons-material";
import { useEffect, useMemo, useState } from "react";
import FullScreenDialog from "../../TestCases/Create/CreateTestStepsContainer/FullWidthScreen";
// import { CustomSearch } from "../../CustomComponents/CustomSearch";
import { ExecutionStatusCodes, StatusButtons } from "./ExecutionConstants";
import TestResultsChart from "./TestResultsChart";
// import TestExecutionBarReport from "./TestExecutionBarReport";
import TestDataReportCard from "./TestDataReportCard";
import PreviewTestData from "./PreviewTestData";
import { testcaseServices } from "../../../Redux/services/testcase.services";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { formatEpochTimeLocale, getTimeDifference } from "../../../utilities/ReusableFunctions";
import { CustomExpandIcon } from "../../CustomComponents/Icons/IconButtons";
import { InnerHeader } from "k-labs-kit";
import CustomBackButton from "../../CustomComponents/CustomButtons/CustomBackButton";

const BrowserSelect = ({ browsersList, handleBrowserChange, selectedBrowser }) => {
  return (
    <FormControl className="lineargradient-formcontrol" fullWidth variant="outlined" size="small">
      <InputLabel id="demo-simple-select-label">Browser</InputLabel>
      <Select
        size="small"
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectedBrowser}
        onChange={handleBrowserChange}
        label="Browser"
        input={<OutlinedInput label="Browser" />}>
        {browsersList?.map((el) => (
          <MenuItem key={el} value={el}>
            {el}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const DeviceSelect = ({ devicessList, handleDeviceChange, selectedDevice }) => {
  return (
    <FormControl className="lineargradient-formcontrol" fullWidth variant="outlined" size="small">
      <InputLabel id="demo-simple-select-label">Device</InputLabel>
      <Select
        size="small"
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectedDevice}
        onChange={handleDeviceChange}
        label="Device"
        input={<OutlinedInput label="Device" />}>
        {devicessList?.map((el) => (
          <MenuItem key={el} value={el}>
            {el}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const ReportOverview = () => {
  const [searchParams] = useSearchParams();
  const [open, setOpen] = useState(false);
  const [selectedTestCase, setSelectedTestCase] = useState(false);
  const [previewTestData, setPreviewTestData] = useState(false);
  const [executionData, setExecutionData] = useState({});
  const [testCaseExeResultList, setTestCaseExeResultList] = useState([]);
  const [allbrowsers, setAllbrowsers] = useState([]);
  const [allDevices, setAllDevices] = useState([]);
  const [selectedStatusBtn, setSelectedStatusBtn] = useState("All");
  const [selectedBrowser, setSelectedBrowser] = useState("");
  const [selectedDevice, setSelectedDevice] = useState("");

  const { exeId } = useParams();
  const navigate = useNavigate();
  const handleBack = () => navigate("/executionsreport");

  const autType = searchParams.get("autType");
  const toggleFullScreenDialog = () => {
    setOpen((prevState) => !prevState);
  };
  const fetchExecutionDataById = async () => {
    const { data } = await testcaseServices.getExecutionDetailsById(exeId);
    setExecutionData(data);
    const envResData = data?.platformExecutionResponseList[0];
    if (envResData?.testCaseExecutionResponseList?.length) {
      setTestCaseExeResultList(envResData?.testCaseExecutionResponseList);
      setSelectedTestCase(envResData?.testCaseExecutionResponseList[0]);
    }
    autType === "WEB" || autType === "SALESFORCE" ? getAllBrowsers(data) : getAllDevices(data);
  };

  const filteredTestCaseResList = useMemo(() => {
    const filteredData = testCaseExeResultList.filter((el) => {
      if (selectedStatusBtn === "All") {
        return true;
      }
      return el.result === selectedStatusBtn;
    });
    setSelectedTestCase(filteredData?.[0] || {});
    return filteredData;
  }, [testCaseExeResultList, selectedStatusBtn]);
  const handleBrowserChange = (event) => {
    const newValue = event?.target?.value;
    setSelectedBrowser(newValue);
    const exeData = executionData?.platformExecutionResponseList;
    if (exeData?.length) {
      const envRes = exeData;
      envRes.forEach((ele) => {
        if (ele?.platform?.browserPlatform?.browserName === newValue) {
          if (ele?.testCaseExecutionResponseList?.length) {
            setTestCaseExeResultList(ele.testCaseExecutionResponseList);
            setSelectedTestCase(ele.testCaseExecutionResponseList[0]);
          }
        }
      });
    }
  };

  const handleDeviceChange = (event) => {
    const newValue = event?.target?.value;
    setSelectedDevice(newValue);
    const exeData = executionData?.platformExecutionResponseList;
    if (exeData?.length) {
      const envRes = exeData;
      envRes.forEach((ele) => {
        if (ele?.platform?.devicePlatform?.deviceName === newValue) {
          if (ele?.testCaseExecutionResponseList?.length) {
            setTestCaseExeResultList(ele.testCaseExecutionResponseList);
            setSelectedTestCase(ele.testCaseExecutionResponseList[0]);
          }
        }
      });
    }
  };

  const getAllBrowsers = (res) => {
    const browsers = [];
    if (res?.platformExecutionResponseList?.length) {
      res?.platformExecutionResponseList.forEach((ele) => {
        if (ele?.platform && ele?.platform?.browserPlatform) {
          browsers.push(ele.platform.browserPlatform?.browserName);
        }
      });
      setAllbrowsers(browsers);
      setSelectedBrowser(browsers?.[0]);
    }
  };

  const getAllDevices = (res) => {
    const devices = [];
    if (res?.platformExecutionResponseList?.length) {
      res?.platformExecutionResponseList.forEach((ele) => {
        if (ele?.platform && ele?.platform?.devicePlatform) {
          devices.push(ele.platform.devicePlatform?.deviceName);
        }
      });
      setAllDevices(devices);
      setSelectedDevice(devices?.[0]);
    }
  };
  useEffect(() => {
    fetchExecutionDataById();
  }, []);

  const renderReport = () => (
    <Box p={0.5} className="container">
      <Box className="space-between" width="100%" py={0.5}>
        <Typography variant="bold" pl={1}>
          Result
        </Typography>
        {!open && <CustomExpandIcon size="small" onClick={toggleFullScreenDialog} />}
      </Box>
      <Grid container item>
        <Grid item sm={12} px={1} className="space-between">
          <Typography>
            {"Execution Name"}&nbsp;: &nbsp;<strong>{executionData?.name}</strong>
          </Typography>
          <Typography>
            {"Environment"}&nbsp;: &nbsp;<strong>{"KiTAP"}</strong>
          </Typography>
          <Typography>
            {"Executed At"}&nbsp;: &nbsp;<strong>{formatEpochTimeLocale(executionData?.startedAt)}</strong>
          </Typography>
          <Typography>
            {"Execution Duration"}&nbsp;: &nbsp;
            <strong>{getTimeDifference(executionData?.startedAt, executionData?.finishedAt)?.readableFormat}</strong>
          </Typography>
          <Typography>
            {"Executed By"}&nbsp;: &nbsp;<strong>{executionData?.executedBy}</strong>
          </Typography>
        </Grid>
        <Grid item sm={9.5} py={2} className="v-center" pl={1}>
          <StatusButtons selectedStatusBtn={selectedStatusBtn} setSelectedStatusBtn={setSelectedStatusBtn} />
        </Grid>
        <Grid item sm={2.5} py={2} className="v-center" pr={1}>
          {autType === "ANDROID" || autType === "IOS" ? (
            <DeviceSelect
              handleDeviceChange={handleDeviceChange}
              devicessList={allDevices}
              selectedDevice={selectedDevice}
            />
          ) : (
            <BrowserSelect
              handleBrowserChange={handleBrowserChange}
              browsersList={allbrowsers}
              selectedBrowser={selectedBrowser}
            />
          )}
        </Grid>
      </Grid>
      <Grid container item mb={5}>
        <Grid item sm={3} py={1} className="v-center subheader-bg border-right">
          <Typography variant="bold" pl={1}>
            Test Case List
          </Typography>
        </Grid>

        <Grid item sm={9} p={1} className="space-between subheader-bg">
          <Typography variant="bold" className="colored-text">
            Testcase_4/2 TestData/10 Steps
            <Tooltip title="Sample Tooltip" placement="top-end" arrow>
              <IconButton size="small">
                <InfoIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Typography>
          {/* will enable in feature */}
          {/* <CustomSearch placeholder="Search Test Data Name" className="full-border" props={{ width: 280 }} /> */}
        </Grid>
        <Grid item sm={3} className="border-right border-bottom">
          <Grid container item>
            <Grid xs={12} item className="border-bottom">
              {/* <CustomSearch placeholder="Search Test Case Name" props={{ background: "transparent", py: "5px" }} /> */}
            </Grid>
            {filteredTestCaseResList?.map((testcase, i) => (
              <Grid
                xs={12}
                p={1}
                item
                key={`${testcase?.testCaseName}_${i}`}
                className="space-between test-case-container"
                sx={{
                  boxShadow: (theme) => (testcase?.id === selectedTestCase?.id ? `${theme.shadows[4]}` : "none"),
                }}
                onClick={() => setSelectedTestCase(testcase)}>
                <Box>
                  <Typography variant="bold">{testcase?.testCaseName}</Typography>
                  <Typography className="small-text light-text">{testcase?.noOfSteps} Steps</Typography>
                </Box>
                <Chip
                  size="small"
                  label={ExecutionStatusCodes[testcase?.result]?.status}
                  sx={{ backgroundColor: ExecutionStatusCodes[testcase?.result]?.color }}
                />
              </Grid>
            ))}
            <Grid item xs={12} mt={10}>
              <TestResultsChart testcasesList={testCaseExeResultList} />
            </Grid>
          </Grid>
        </Grid>
        {Object.keys(selectedTestCase).length !== 0 && (
          <Grid item sm={9} py={1} pl={1}>
            <Grid container item>
              {/* <Grid item xs={12} sm={5.5} p={1}>
                <TestExecutionBarReport testData={selectedTestCase?.executionInstanceResponseList} />
              </Grid> */}

              <Grid item xs={12} sm={6.5}>
                <Grid container item>
                  {selectedTestCase?.executionInstanceResponseList.map((data, i) => (
                    <Grid item xs={12} key={i} p={1}>
                      <TestDataReportCard
                        testCase={selectedTestCase}
                        testData={data}
                        setPreviewTestData={setPreviewTestData}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      {previewTestData && <PreviewTestData setPreviewTestData={setPreviewTestData} />}
    </Box>
  );
  return (
    <Box>
      <InnerHeader>
        <Box className="v-center" gap={1}>
          {<CustomBackButton onClick={handleBack}>Back</CustomBackButton>}
          <Typography variant="h6">EXECUTION REPORTS</Typography>
        </Box>
      </InnerHeader>
      <Box className="pt74" sx={{ width: "100%" }}>
        {open ? (
          <FullScreenDialog open={open} setOpen={toggleFullScreenDialog}>
            {renderReport()}
          </FullScreenDialog>
        ) : (
          renderReport()
        )}
      </Box>
    </Box>
  );
};

export default ReportOverview;
