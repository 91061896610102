import { Box, Typography } from "@mui/material";

const DropZone = ({ style, drag, drop }) => {
  return (
    <Box
      style={style}
      ref={(node) => drag(drop(node))}
      className="center"
      sx={{
        height: "50px",
        border: "1px dashed gray",
        // backgroundColor: isOver ? "lightgreen" : "white",
        mt: "10px",

        // mx: 1,
        // backgroundColor: isOver && canDrop ? "lightyellow" : "inherit",
        // ...(!row.steps && { "&:hover": { backgroundColor: "#E7E9EB" } }),
      }}>
      <Typography variant="h6">Drop here Macros</Typography>
    </Box>
  );
};

export default DropZone;
