import ApplicationTile from "./ApplicationTile";
import CustomCarousel from "../../CustomComponents/CustomCarousel";
import { Box } from "@mui/material";
import useDeviceType from "../../../CustomHooks/useDeviceType";

const ApplicationsList = ({ appTypes = [], onClickAppTile, onClickAddAut }) => {
  const md = useDeviceType("md");
  const itemsToShow = md ? 4 : 5;

  return (
    <Box sx={{ pb: 2 }}>
      <CustomCarousel itemsToShow={itemsToShow} totalCount={appTypes.length}>
        {appTypes?.map((item, index) => (
          <Box
            key={index}
            sx={{ flex: `0 0 ${100 / itemsToShow}%`, maxWidth: `${100 / itemsToShow}%`, pr: 1.5, pb: 2 }}>
            <ApplicationTile applicationDetails={item} onClickAppTile={onClickAppTile} onClickAddAut={onClickAddAut} />
          </Box>
        ))}
      </CustomCarousel>
    </Box>
  );
};

export default ApplicationsList;
