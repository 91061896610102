import { useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Navigate, useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Box, Grid, IconButton, InputAdornment, Paper, TextField, Typography } from "@mui/material";
import Logo from "../../images/kitap-black.png";
import LoginBg from "../../images/login_bg.jpg";
import ConfirmModel from "./ConfirmModel";

import { useTranslation } from "react-i18next";
import { decryptData, encryptData, fetchDecryptedSecrets } from "../../helpers/encryptionUtils";
import { PublicClientApplication } from "@azure/msal-browser";
import ADLoginLoading from "./ADLoginLoading";
import CustomLoadingButton from "../CustomComponents/CustomButtons/CustomLoadingButton";
import { azureLogin, LoginRedirectUrl } from "../../constants";
import { authServices } from "../../Redux/services/auth.services";
import { setUserDetails } from "../../Redux/reducers/authSlice";
import { showToast } from "../../Redux/reducers/toastSlice";

const Copyright = () => {
  const { t } = useTranslation();
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {t("Copyright")} ©
      <a href="https://kairostech.com/" target="_blank" rel="noopener noreferrer">
        {t("Kairos Technologies")}
      </a>
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};
const handleLoginError = async (error, dispatch) => {
  const dc = typeof error?.response?.data === "string" && (await decryptData(error?.response?.data));
  const errMessage =
    dc?.Message ||
    dc?.statusText ||
    (error.message.includes("Network Error") && "AuthNZ Server is currently down! Please contact IT support.");
  dispatch(showToast({ message: errMessage, type: "error" }));
};

const fetchKeys = async () => {
  const data = await fetchDecryptedSecrets(["DQG-AppClientId", "DQG-AppTenantId"]);
  const azureClientId = data?.find((item) => item.SecretKey === "DQG-AppClientId")?.SecretValue;
  const azureTenentId = data?.find((item) => item.SecretKey === "DQG-AppTenantId")?.SecretValue;
  return { azureClientId, azureTenentId };
};
const SignIn = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const token = useSelector((state) => state.auth.token);

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmModel, setConfirmModel] = useState(false);
  const [loadingADLogin, setLoadingADLogin] = useState(false);
  const [userData, setUserData] = useState({});

  const PwIcon = showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />;

  const handleShowPassword = () => setShowPassword(!showPassword);

  const onSubmitLogin = async (data) => {
    setLoading(true);
    try {
      const response = await authServices.login(data.email, data.password);
      if (response?.ResponseObject?.IsSessionExists) {
        const requestBody = data;
        setUserData({ requestBody, response: response?.ResponseObject });
        setConfirmModel(true);
      } else if (response?.ResponseObject?.Token) {
        localStorage.setItem(
          "kitapUser",
          JSON.stringify(encryptData(JSON.stringify(response.ResponseObject))?.encryptedData),
        );
        dispatch(setUserDetails(response.ResponseObject));
        setLoadingADLogin(false);
        navigate("/");
      }
    } catch (error) {
      setLoadingADLogin(false);
      handleLoginError(error, dispatch);
    }
    setLoading(false);
  };

  const Confirm = async (data) => {
    setConfirmModel(false);
    if (data.logedInHere) {
      try {
        const reqData = {
          UserId: userData?.response?.UserId,
          SessionId: userData?.response?.SessionId,
        };
        const response = await authServices.logout(reqData);
        if (response?.ApiStatusCode === 200) {
          await onSubmitLogin(userData?.requestBody);
        }
      } catch (error) {
        handleLoginError(error, dispatch);
      }
    } else {
      setLoadingADLogin(false);
      setUserData({});
    }
  };

  const handleLoginButtonClick = async () => {
    setLoading(true);
    try {
      const { azureClientId, azureTenentId } = await fetchKeys();
      const msalConfig = {
        auth: {
          clientId: azureClientId,
          authority: `https://login.microsoftonline.com/${azureTenentId}`,
          redirectUri: `${LoginRedirectUrl}login`,
        },
      };

      const pca = new PublicClientApplication(msalConfig);
      await pca.initialize();
      await pca.loginRedirect({ scopes: ["User.Read"] });
    } catch (error) {
      dispatch(showToast({ message: error?.response?.data?.message || error.message, type: "error" }));
    }
    setLoading(false);
  };

  useLayoutEffect(() => {
    const handleRedirect = async () => {
      setLoadingADLogin(true);
      try {
        const { azureClientId, azureTenentId } = await fetchKeys();
        const msalConfig = {
          auth: {
            clientId: azureClientId,
            authority: `https://login.microsoftonline.com/${azureTenentId}`,
            redirectUri: `${LoginRedirectUrl}login`,
          },
        };

        const pca = new PublicClientApplication(msalConfig);
        await pca.initialize();
        const response = await pca.handleRedirectPromise();
        if (response) {
          const userEmail = response.account.username;
          const loginReq = {
            email: userEmail,
            password: response?.account?.authorityType,
          };
          await onSubmitLogin(loginReq);
        }
      } catch (error) {
        dispatch(showToast({ message: error?.response?.data?.message || error.message, type: "error" }));

        setLoadingADLogin(false);
      }
      setLoading(false);
    };

    if (window.location.hash) {
      handleRedirect();
    }
  }, []);

  if (token) {
    return <Navigate to="/" />;
  }

  return (
    <>
      {loadingADLogin ? (
        <ADLoginLoading loading={loading} />
      ) : (
        <Grid container component="main" sx={{ height: "100vh" }}>
          <Grid
            item
            md={7}
            lg={8}
            sx={{
              backgroundImage: `url(${LoginBg})`,
              backgroundRepeat: "no-repeat",
              backgroundColor: (b) => (b.palette.mode === "light" ? b.palette.grey[50] : b.palette.grey[900]),
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
          <Grid item md={5} lg={4} component={Paper} elevation={6} square>
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}>
              <img src={Logo} width="150" alt={"logo-notavailable"} />
              <Typography component="h1" variant="h5" sx={{ mt: "15px" }}>
                {t("Sign In")}
              </Typography>
              {!azureLogin ? (
                <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmitLogin)}>
                  <Box sx={{ display: "flex", flexDirection: "column", mt: 1 }}>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label={t("Email Address")}
                      name="email"
                      autoComplete="email"
                      size="small"
                      autoFocus
                      error={errors.email}
                      {...register("email", { required: true })}
                    />

                    <TextField
                      required
                      fullWidth
                      margin="normal"
                      label={t("Password")}
                      variant="outlined"
                      size="small"
                      error={errors.password}
                      {...register("password", { required: true })}
                      type={showPassword ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleShowPassword}>{PwIcon}</IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <CustomLoadingButton
                      type="submit"
                      sx={{ mt: 3, mb: 2, mx: "auto" }}
                      disabled={loading}
                      loading={loading}>
                      {t("Sign In")}
                    </CustomLoadingButton>
                  </Box>
                </form>
              ) : (
                <CustomLoadingButton
                  sx={{ mt: 3, mb: 2 }}
                  disabled={loading}
                  loading={loading}
                  onClick={handleLoginButtonClick}>
                  Login with Azure AD
                </CustomLoadingButton>
              )}
            </Box>
            <Copyright sx={{ mt: 8, mb: 4 }} />
          </Grid>
        </Grid>
      )}
      {confirmModel && (
        <ConfirmModel confirmModel={confirmModel} setConfirmModel={setConfirmModel} returnValue={Confirm} />
      )}
    </>
  );
};

export default SignIn;
