import { Toolbar, Dialog, DialogTitle, DialogActions, Button, DialogContent } from "@mui/material";

import { alpha } from "@mui/material/styles";
import CustomLoadingButton from "../CustomComponents/CustomButtons/CustomLoadingButton";

export function DeleteAlertPopup(props) {
  const { loadingUpload, open, setOpen, delFunction, alertTitle, delInd } = props;
  const handleCloseDialog = () => {
    setOpen(false);
  };
  return (
    <Toolbar
      sx={{
        minHeight: { xs: 35 },
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...{
          bgcolor: (theme) => alpha(theme?.palette?.primary?.main, theme.palette.action.activatedOpacity),
        },
      }}>
      <Dialog
        className="deleteDialogBox"
        open={open}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle variant="h6" id="alert-dialog-title">
          {alertTitle}
        </DialogTitle>

        <DialogActions>
          <CustomLoadingButton loading={loadingUpload} size="small" onClick={() => delFunction(delInd)}>
            Yes
          </CustomLoadingButton>
          <Button variant="outlined" color="error" size="small" onClick={handleCloseDialog}>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </Toolbar>
  );
}

export function CustomAlertPopup(props) {
  const { open, setOpen, alertTitle } = props;

  return (
    <Toolbar
      sx={{
        minHeight: { xs: 35 },
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...{
          bgcolor: (theme) => alpha(theme?.palette?.primary?.main, theme.palette.action.activatedOpacity),
        },
      }}>
      <Dialog
        className="deleteDialogBox"
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle variant="h6" id="alert-dialog-title">
          {alertTitle}
        </DialogTitle>

        <DialogActions>
          <Button variant="outlined" size="small" onClick={() => setOpen(false)} autoFocus>
            OKAY
          </Button>
        </DialogActions>
      </Dialog>
    </Toolbar>
  );
}

export function PopupDialog({ open, onClose, message }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle variant="h5" id="alert-dialog-title">
        Headers Match Error
      </DialogTitle>
      <DialogContent variant="h6">{message}</DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" size="small" onClick={onClose} autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
