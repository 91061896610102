import { Grid } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";

import AddStepForm from "./AddStepForm";
import PreviewStep from "./PreviewStep";
import CreateMacros from "./Macros/CreateMacros";
import Macros from "./Macros/Macros";

const StepManagementContainer = () => {
  const [searchParams] = useSearchParams();
  const stepViewType = searchParams.get("stepViewType");
  const { selected_step: selectedStep } = useSelector((state) => state.testcase);

  return (
    <Grid sx={{ borderLeft: "1px solid #ccc", overflowY: "auto" }} item xs={stepViewType ? 4 : 0}>
      {stepViewType === "add" && <AddStepForm defaultValues={selectedStep} />}
      {stepViewType === "preview" && <PreviewStep selectedStepData={selectedStep} />}
      {stepViewType === "macros" && <Macros />}
      {stepViewType === "createMacros" && <CreateMacros />}
    </Grid>
  );
};

export default StepManagementContainer;
