import { createSlice } from "@reduxjs/toolkit";
import { testcaseServices } from "../services/testcase.services";

const initialState = {
  testcase_list: [],
  testcase_list_loading: false,
  aut_details: {},
  test_steps_data: [],
  selected_step: null,
  selected_steps_data: [],
  macros_list: [],
  step_action_data: [],
  config_test_cases: [],
  element_locator_details: [],
};

const testcaseSlice = createSlice({
  name: "testcase",
  initialState,
  reducers: {
    setTestcasesList: (state, action) => {
      state.testcase_list = action.payload;
    },
    setTestStepsData: (state, action) => {
      state.test_steps_data = action.payload?.map((step, index) => ({ ...step, sequenceNumber: index + 1 }));
    },
    setConfigTestCases: (state, action) => {
      state.config_test_cases = action.payload;
    },

    updateTestStepsData: (state, action) => {
      const data = state.test_steps_data
        .map((each) => {
          if (each.sequenceNumber === action.payload.sequenceNumber || each.isNewStep) {
            delete action.payload.isNewStep;
            return action.payload;
          }
          return each;
        })
        ?.map((step, index) => ({ ...step, sequenceNumber: index + 1 }));
      state.test_steps_data = data;
      state.selected_step = data?.find((each) => each?.id === action.payload.id);
    },

    deleteTestStepsData: (state, action) => {
      state.test_steps_data = state.test_steps_data
        .filter((each) => each.sequenceNumber !== action.payload.sequenceNumber)
        ?.map((step, index) => ({ ...step, sequenceNumber: index + 1 }));
    },

    setSelectedStepsData: (state, action) => {
      state.selected_steps_data = action.payload;
    },
    setSelectedStep: (state, action) => {
      state.selected_step = action.payload;
    },
    updateMacrosList: (state, action) => {
      state.macros_list.push(action.payload);
    },
    setMacrosList: (state, action) => {
      state.macros_list = action.payload;
    },
    clearAllTestcaseData: (state) => {
      state.testcase_list = [];
      state.test_steps_data = [];
      state.selected_step = null;
      state.selected_steps_data = [];
      state.macros_list = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(testcaseServices.fetchTestCases.pending, (state) => {
        state.testcase_list_loading = true;
      })
      .addCase(testcaseServices.fetchTestCases.fulfilled, (state, action) => {
        state.testcase_list_loading = false;
        // const { testCaseResponseDtoList,agentDetails, ...autDetails } = action.payload;
        state.testcase_list = action.payload?.testCaseResponseList;
        state.aut_details = action.payload?.autDetails;
      })
      .addCase(testcaseServices.fetchTestCases.rejected, (state) => {
        state.testcase_list_loading = false;
        state.testcase_list = [];
      })
      .addCase(testcaseServices.fetchAgentTestCasesForAut.pending, (state) => {
        state.testcase_list_loading = true;
      })
      .addCase(testcaseServices.fetchAgentTestCasesForAut.fulfilled, (state, action) => {
        state.testcase_list_loading = false;
        const { testCaseResponseDtoList, ...autDetails } = action.payload;
        state.testcase_list = testCaseResponseDtoList;
        state.aut_details = autDetails;
      })
      .addCase(testcaseServices.fetchAgentTestCasesForAut.rejected, (state) => {
        state.testcase_list_loading = false;
      })
      .addCase(testcaseServices.getAllActionData.fulfilled, (state, action) => {
        state.step_action_data = action.payload;
      })
      .addCase(testcaseServices.getElementLocatorDetails.fulfilled, (state, action) => {
        state.element_locator_details = action.payload;
      });
  },
});

export const {
  setTestcasesList,
  setTestStepsData,
  updateTestStepsData,
  deleteTestStepsData,
  setSelectedStepsData,
  updateMacrosList,
  setMacrosList,
  setSelectedStep,
  clearAllTestcaseData,
  setConfigTestCases,
} = testcaseSlice.actions;

export default testcaseSlice.reducer;
