import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import InnerHeader from "../Layout/InnerHeader";
import ApplicationsList from "./ApplicationsList";
import AutList from "./Auts/AutList";
import CreateAUT from "./CreateAUT";
import SkeletonLoader from "../SkeletonLoader";
import { useDispatch, useSelector } from "react-redux";
import { autServices } from "../../Redux/services/aut.services";
import { setAppTypes } from "../../Redux/reducers/autSlice";

const Applications = () => {
  const dispatch = useDispatch();
  const {
    aut_list: listOfAuts,
    application_types: appTypes,
    aut_list_loading: loadingAuts,
  } = useSelector((state) => state.aut);
  const [searchParams, setSearchParams] = useSearchParams();

  const [createAutDetails, setCreateAutDetails] = useState(null);
  const [editAutDetails, setEditAutDetails] = useState(null);
  const selectedAppType = searchParams.get("appType");

  const handleAppTileClick = (app) => {
    setSearchParams({ appType: app.type });
    dispatch(autServices.fetchListOfAuts(app.type));
  };

  const handleAddAutClick = (app) => {
    setCreateAutDetails(app);
  };

  const handleEditAutClick = (aut) => {
    setEditAutDetails(aut);
  };

  const handleAutCreationSuccess = (aut) => {
    const updatedAuts = [...listOfAuts];
    const index = updatedAuts.findIndex((item) => item.id === aut.id);

    if (index !== -1) {
      // updatedAuts[index] = aut;
    } else {
      const appTypeIndex = appTypes.findIndex((type) => type?.type === aut?.type);
      if (appTypeIndex !== -1) {
        const updatedAppTypeItem = { ...appTypes[appTypeIndex], noOfAuts: appTypes[appTypeIndex].noOfAuts + 1 };
        const updatedAppTypes = [...appTypes];
        updatedAppTypes[appTypeIndex] = updatedAppTypeItem;
        dispatch(setAppTypes(updatedAppTypes));
      }
    }

    dispatch(autServices.fetchListOfAuts(aut?.type));
    setSearchParams({ appType: aut?.type });
    handleCloseAut();
  };

  const handleCloseAut = () => {
    createAutDetails && setCreateAutDetails(null);
    editAutDetails && setEditAutDetails(null);
  };

  useEffect(() => {
    dispatch(autServices.fetchAppTypes());
    if (selectedAppType) {
      dispatch(autServices.fetchListOfAuts(selectedAppType));
    }
  }, []);

  return (
    <>
      <InnerHeader>
        <Typography variant="h6">APPLICATIONS</Typography>
      </InnerHeader>
      <Box className="pt74 applications">
        <ApplicationsList appTypes={appTypes} onClickAppTile={handleAppTileClick} onClickAddAut={handleAddAutClick} />
        {loadingAuts ? (
          <SkeletonLoader />
        ) : (
          selectedAppType && (
            <AutList
              autTypes={appTypes}
              listOfAuts={listOfAuts}
              onClickEditAut={handleEditAutClick}
              onClickAUTTile={handleAppTileClick}
            />
          )
        )}
      </Box>
      {(createAutDetails || editAutDetails) && (
        <CreateAUT
          autDetails={createAutDetails}
          editAutDetails={editAutDetails}
          onClose={handleCloseAut}
          onSuccessCreateAut={handleAutCreationSuccess}
        />
      )}
    </>
  );
};

export default Applications;
