import { useState, forwardRef } from "react";
import {
  Box,
  Card,
  // CardActionArea,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Slide,
  Typography,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import PowerBIReport from "./PowerBIReport";
import AspectRatioOutlinedIcon from "@mui/icons-material/AspectRatioOutlined";
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ReportCard = ({ title, reportData }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Grid md={6} item>
      <Card sx={{ p: 0 }} onClick={handleOpen}>
        {/* <CardActionArea> */}
        <CardContent sx={{ py: 1 }}>
          <Box className="space-between" pb={1}>
            <Typography sx={{ fontSize: 14 }}>{title}</Typography>
            <IconButton sx={{ p: 0 }} aria-label="view Expand">
              <AspectRatioOutlinedIcon />
            </IconButton>
          </Box>
          {reportData?.embedToken?.length > 0 && (
            <PowerBIReport className="report-style-class-card" reportData={reportData} filter={false} />
          )}
        </CardContent>
        {/* </CardActionArea> */}
      </Card>

      <Dialog open={open} TransitionComponent={Transition} keepMounted fullWidth maxWidth="lg" onClose={handleClose}>
        <DialogTitle sx={{ px: 2, py: 1 }}>
          <Typography variant="h6">{title}</Typography>
          <IconButton onClick={handleClose} sx={{ position: "absolute", top: 0, right: 0 }} size="small" color="error">
            <CancelIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ px: 2, pt: 0 }}>
          {reportData?.embedToken?.length > 0 && (
            <PowerBIReport className="report-style-class-modal" reportData={reportData} />
          )}
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default ReportCard;
