import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputBase,
  Paper,
  Popover,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import CustomSwitchButton from "../../CustomComponents/CustomSwitchButton";
import { useState } from "react";
import { Search } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import { useSearchParams } from "react-router-dom";

import { useDispatch } from "react-redux";
import CustomLoadingButton from "../../CustomComponents/CustomButtons/CustomLoadingButton";
import { showToast } from "../../../Redux/reducers/toastSlice";
import { testcaseServices } from "../../../Redux/services/testcase.services";
import { setTestStepsData } from "../../../Redux/reducers/testcaseSlice";

const CloneTestCase = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const [anchorEl, setAnchorEl] = useState(null);
  const [showPopover, setShowPopover] = useState(false);
  const [cloneTestCase, setCloneTestCase] = useState(false);
  const [testCasesLoading, setTestCasesLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [testCases, setTestCases] = useState([]);
  const [selectedTestCase, setSelectedTestCase] = useState({});
  const [isCloned, setIscloned] = useState(false);
  const [loadingCloneTestcase, setLoadingCloneTestcase] = useState(false);
  const autId = searchParams.get("autId");
  const open = Boolean(anchorEl);
  const popoverId = open ? "simple-popover" : undefined;

  const handleCloneTestCase = (e) => {
    setCloneTestCase(e.target.checked);
    if (e.target.checked) {
      openTestCaseList(e);
    }
  };
  const openTestCaseList = (e) => {
    !testCases?.length && fetchTestCases();
    setAnchorEl(e.currentTarget);
    setShowPopover(true);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
    setShowPopover(false);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchInput(e.target.value);
  };

  const fetchTestCases = async () => {
    setTestCasesLoading(true);
    try {
      const response = await dispatch(testcaseServices.fetchTestCases(autId));
      const responseData = response?.payload?.testCaseResponseList;
      setTestCases(responseData);
    } catch (error) {
      dispatch(showToast({ message: error?.response?.data?.message || error.message, type: "error" }));
    } finally {
      setTestCasesLoading(false);
    }
  };

  const handleOnClickClone = async () => {
    setLoadingCloneTestcase(true);
    try {
      const { data } = await testcaseServices.getTestcaseDetailsById(selectedTestCase?.id, selectedTestCase?.version);
      const { macroBinderResponseList, testStepResponseList } = data;
      const macroItems = macroBinderResponseList.map((each) => {
        const { id, sequenceNumber, macroWithStepsResponse } = each;
        const { testStepResponseList, ...rest } = macroWithStepsResponse;
        return {
          binderId: id,
          sequenceNumber,
          ...rest,
          steps: testStepResponseList?.sort((a, b) => a.sequenceNumber - b.sequenceNumber),
        };
      });
      const updatedSteps = [...testStepResponseList, ...macroItems].sort((a, b) => a.sequenceNumber - b.sequenceNumber);
      dispatch(setTestStepsData(updatedSteps));
      setIscloned(true);
      handlePopoverClose();
    } catch (error) {
      dispatch(showToast({ message: error?.response?.data?.message || error.message, type: "error" }));
    }
    setLoadingCloneTestcase(false);
  };
  const onChangeTestCase = (testCase) => {
    setSelectedTestCase(testCase);
  };

  const handleCancel = () => {
    if (!isCloned) {
      setCloneTestCase(false);
      setSelectedTestCase({});
    }
    handlePopoverClose();
  };

  return (
    <Box>
      <Box className="v-center" gap={1}>
        <CustomSwitchButton
          aria-describedby={popoverId}
          onChange={handleCloneTestCase}
          checked={cloneTestCase}
          disabled={isCloned}
          labels={["", "Clone Test Case"]}
        />
        {Boolean(selectedTestCase?.name) && isCloned && (
          <>
            <Typography variant="bold" color="primary">
              {selectedTestCase?.name}
            </Typography>
            <IconButton onClick={openTestCaseList} sx={{ p: 0 }} size="small">
              <EditIcon color="warning" fontSize="10px" />
            </IconButton>
          </>
        )}
      </Box>

      <Popover
        id={popoverId}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        open={showPopover}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        sx={{ left: -16, "& .MuiPopover-paper": { boxShadow: 3 } }}>
        <Paper className="flex column" sx={{ p: 2, gap: 1 }}>
          <Box className="v-center" sx={{ p: "4px", color: "#CCCCCC", background: "#EEEEEE", borderRadius: "4px" }}>
            <InputBase
              fullWidth
              size="small"
              className="searchInput"
              placeholder={"Search Test Case"}
              value={searchInput}
              onChange={handleSearch}
            />

            <IconButton sx={{ p: 0 }} size="small">
              <Search fontSize="0.75rem" />
            </IconButton>
          </Box>

          <TableContainer
            sx={{
              "& table.MuiTable-root tr td": { border: "none !important" },
              maxHeight: 444,
              width: 350,
              overflowY: "auto",
            }}
            component={Paper}>
            <Table sx={{ border: "none !important" }} stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ border: "none !important" }} align="left">
                    Test Case Name
                  </TableCell>
                  <TableCell sx={{ border: "none !important" }} align="center">
                    No. of Steps
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow>
                  <TableCell align="right">
                    {testCasesLoading && <CircularProgress sx={{ my: 2 }} size={20} />}
                  </TableCell>
                </TableRow>
                {testCases
                  ?.filter((each) => each?.name?.toLocaleLowerCase()?.includes(searchInput?.toLocaleLowerCase()))
                  ?.map((row) => (
                    <TableRow key={row.name}>
                      <TableCell align="left">
                        <Box className="v-center" gap={1}>
                          <Radio
                            sx={{ px: 0.5 }}
                            checked={selectedTestCase?.id === row?.id}
                            onChange={() => onChangeTestCase(row)}
                            className="customRadioGroup"
                          />
                          <Typography
                            sx={{ maxWidth: 100, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                            {row.name}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell align="center">{row.noOfSteps}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Box sx={{ borderTop: "1px solid #ccc", pt: 1 }} className="space-between">
            <Button onClick={handleCancel} size="small" color="error" variant="contained">
              Cancel
            </Button>
            <CustomLoadingButton
              disabled={!selectedTestCase?.id || loadingCloneTestcase}
              loading={loadingCloneTestcase}
              onClick={handleOnClickClone}
              color="success">
              Clone
            </CustomLoadingButton>
          </Box>
        </Paper>
      </Popover>
    </Box>
  );
};

export default CloneTestCase;
