// Background colors for different element locators
export const ELEMENT_LOCATOR_BACKGROUND_COLORS = [
  "#FFF3DB",
  "#E9FFDB",
  "#DBFFF0",
  "#DBECFF",
  "#DDDBFF",
  "#DFCFFF",
  "#EDCFFF",
  "#FFCFFA",
  "#FFCFE0",
  "#FFCFCF",
];

export const ELEMENT_LOCATOR_DEFAULT_DATA = {
  elementId: null,
  elementName: null,
  className: null,
  tagName: null,
  linkText: null,
  partialLinkText: null,
  cssSelector: null,
  relativeXpathList: null,
  relativeLocator: null,
  labelText: null,
};

export const DEFAULT_BROWSER = "Chrome";
export const DEFAULT_LOCAL_EXECUTION = false;
export const DEFAULT_SELF_HEALING = true;

export const isDataRequiredForStep = (data, stepActionData) => {
  return data
    ?.flatMap((each, i) => {
      if (!each?.steps) {
        return each;
      }
      return each.steps?.map((step, index) => ({
        ...step,
        sequenceNumber: `${i + 1}.${String(index + 1).padStart(2, "0")}`,
      }));
    })
    ?.filter((el) => {
      const action = stepActionData.find((action) => action.actionCode === el.action);
      return action?.isInputDataRequired;
    });
};
const currentDate = new Date();

// Calculate start date (30 days before today)
const startDate = new Date(currentDate);
startDate.setDate(currentDate.getDate() - 30);
startDate.setHours(0, 0, 0, 0); // Set time to 00:00:00.000

// Calculate end date (today)
const endDate = new Date(currentDate);
endDate.setHours(23, 59, 59, 999); // Set time to 23:59:59.999

export const dateRangeObj = { startDate: startDate, endDate: endDate, key: "selection", isSelected: false };
