export const FileUploadListHeadCells = [
  // {
  //   sortable: false,
  //   headerCheckboxSelection: true,
  //   checkboxSelection: true,
  //   lockPosition: "left",
  //   cellClass: "locked-col",
  //   suppressColumnsToolPanel: true,
  // },
  {
    headerName: "S.No",
    sortable: false,
    suppressColumnsToolPanel: true,
    filter: false,
    headerTooltip: "S.No",
    cellClass: "non-editable",
    valueGetter: "node.rowIndex + 1",
  },
  {
    field: "originalFileName",
    headerName: "File Name",
    sortable: true,
    suppressColumnsToolPanel: true,
    filter: true,
    headerTooltip: "File Name",
    cellClass: "non-editable",
  },
  {
    field: "fileSizeInKb",
    headerName: "File Size In Kb",
    sortable: true,
    filter: false,
    headerTooltip: "File Size In Kb",
    cellClass: "non-editable",
  },
  {
    field: "createdBy",
    headerName: "Created By",
    sortable: true,
    suppressColumnsToolPanel: true,
    filter: true,
    headerTooltip: "Created By",
    cellClass: "non-editable",
  },
  {
    field: "createdAt",
    headerName: "Created Date",
    sortable: true,
    headerTooltip: "Created Date",
    cellClass: "non-editable",
  },
  {
    field: "module",
    headerName: "Module",
    sortable: true,
    filter: true,
    headerTooltip: "Module",
    cellClass: "non-editable",
    hide: true,
  },
  {
    field: "filePath",
    headerName: "File Path",
    sortable: true,

    headerTooltip: "File Path",
    cellClass: "non-editable",
  },
  {},
];
