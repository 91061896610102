import { useContext } from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Route, Routes, Navigate, Outlet } from "react-router-dom";
import DashboardCustomizeOutlinedIcon from "@mui/icons-material/DashboardCustomizeOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import DevicesOutlinedIcon from "@mui/icons-material/DevicesOutlined";
// import SupportOutlinedIcon from "@mui/icons-material/SupportOutlined";
import PlaylistAddCircleOutlinedIcon from "@mui/icons-material/PlaylistAddCircleOutlined";
import SignIn from "./components/Login/";
import TestCasesList from "./components/TestCases/TestCasesList";
import Agents from "./components/AgentManagement/Index";
import License from "./components/License/index";
import ScheduledList from "./components/ScheduledTests/ScheduledList";

// import TestSuiteList from "./components/TestSuitsList";
// import ExecutionsCommonPage from "./components/Reports/ExecutionsCommonPage";
import CICDPipeLine from "./components/CICDPipeLine";
import Engine from "./components/Engine";
import { Header, SideMenu } from "k-labs-kit";
import "k-labs-kit/dist/style.css";
import lightLogo from "../src/images/kitap-white.png";
import darkLogo from "../src/images/kitap-black.png";
import logoIcon from "../src/images/logoIcon.png";
import clientLogo from "../src/images/kairos-logo-dark.png";

import { ColorModeContext } from "./theme/theme";
import AccountSettings from "./components/Layout/AccountSettings";

import Applications from "./components/Applications";
import CreateOrEditTestCase from "./components/TestCases/Create/CreateOrEditTestCase";
import Macros from "./components/Macros";
import CreateMacro from "./components/Macros/CreateMacro";
import ExecutionReports from "./components/Reports/ExecutionReports";
import ReportsHomePage from "./components/PowerBI/Dashboard";
// import ExecutionsCommonPage from "./components/Reports/ExecutionsCommonPage";
import ExecutionConfigs from "./components/ExecutionConfigs";
// import ExecutionsCommonPage from "./components/Reports/ExecutionsCommonPage";
import CreateConfigExecution from "./components/ExecutionConfigs/Create";

// import ServiceStatusPopup from "./components/Layout/ServiceStatus/ServiceStatusPopup";
// import AutoAwesomeMosaicOutlinedIcon from "@mui/icons-material/AutoAwesomeMosaicOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import FileUploads from "./components/Fileuploads";
import ReportOverview from "./components/Reports/ExecutionReports/ReportOverview";
import ExpiryNotification from "./components/CustomComponents/ExpiryNotification";
import { useSelector } from "react-redux";
import { useCheckAccess } from "./CustomHooks/useCheckAccess";

// Configuration for menu items
const menuItemsConfig = (t, checkAccess) => [
  {
    to: "/",
    text: t("side_bar_applications"),
    Icon: DashboardCustomizeOutlinedIcon,
    status: !checkAccess("Applications", "Hide"),
  },
  {
    to: "/testcases",
    text: t("side_bar_testcases"),
    Icon: ListAltOutlinedIcon,
    status: !checkAccess("testCases", "Hide"),
  },
  {
    to: "/macros",
    text: t("Macros"),
    Icon: PlaylistAddCircleOutlinedIcon,
    status: !checkAccess("Macros", "Hide"),
  },
  {
    to: "/configs",
    text: t("Execution Configs"),
    Icon: TuneOutlinedIcon,
    status: !checkAccess("configs", "Hide"),
  },
  {
    to: "/executionsreport",
    text: t("Execution Reports"),
    Icon: AssessmentOutlinedIcon,
    status: !checkAccess("reports", "Hide"),
  },
  {
    to: "/engines",
    text: t("Engines"),
    Icon: DevicesOutlinedIcon,
    status: !checkAccess("engines", "Hide"),
  },
  // {
  //   to: "/agents",
  //   text: t("side_bar_agents"),
  //   Icon: SupportOutlinedIcon,
  //   status: !checkAccess("agents", "Hide"),
  // },

  // {
  //   to: "/powerbi",
  //   text: t("side_bar_dashboard"),
  //   Icon: AutoAwesomeMosaicOutlinedIcon,
  //   status: !checkAccess("applications", "Hide"),
  // },
  {
    to: "/fileUploads",
    text: t("File Uploads"),
    Icon: FileUploadOutlinedIcon,
    status: !checkAccess("fileUploads", "Hide"),
  },
];

const LayoutComponent = () => {
  const { t } = useTranslation();
  const colorMode = useContext(ColorModeContext);
  const { checkAccess } = useCheckAccess();

  const menuItems = menuItemsConfig(t, checkAccess).filter((item) => item.status);

  return (
    <>
      <Header colorMode={colorMode} clientLogo={clientLogo} accountSettings={<AccountSettings />}>
        <ExpiryNotification />
        {/* <ServiceStatusPopup /> */}
      </Header>
      <SideMenu menuItems={menuItems} lightLogo={lightLogo} darkLogo={darkLogo} logoIcon={logoIcon} />
    </>
  );
};

const routesData = [
  { path: "/", element: <Applications /> },
  { path: "/testcases", element: <TestCasesList /> },
  { path: "/macros", element: <Macros /> },
  { path: "/macros/create", element: <CreateMacro /> },
  { path: "/configs", element: <ExecutionConfigs /> },
  { path: "/configs/create", element: <CreateConfigExecution /> },
  { path: "/testcases/create", element: <CreateOrEditTestCase /> },
  { path: "/cicdpipeline", element: <CICDPipeLine /> },
  { path: "/scheduledtests", element: <ScheduledList /> },
  { path: "/agents", element: <Agents /> },
  { path: "/engines", element: <Engine /> },
  { path: "/license/:info", element: <License /> },
  { path: "/powerbi", element: <ReportsHomePage /> },
  { path: "/executionsreport", element: <ExecutionReports /> },
  { path: "/executionsreport/:exeId", element: <ReportOverview /> },
  { path: "/fileUploads", element: <FileUploads /> },
];

const PrivateRoute = ({ children }) => {
  const token = useSelector((state) => state.auth.token);

  return token ? children : <Navigate to="/login" />;
};

const SidebarLayout = () => {
  const token = useSelector((state) => state.auth.token);
  if (!token) {
    return <Navigate to="/login" />;
  }
  return (
    <Box
      sx={{
        display: "flex",
        marginTop: "52px",
        "& .headerIcons": { "& hr": { display: "none" }, "& .MuiIconButton-root": { display: "none" } },
      }}>
      <LayoutComponent />
      <Box className="outlet pt74">
        <Outlet />
      </Box>
    </Box>
  );
};

const RoutesManager = () => {
  return (
    <Routes>
      <Route path="/login" element={<SignIn />} />

      <Route element={<SidebarLayout />}>
        {routesData.map(({ path, element }, index) => (
          <Route key={index} path={path} element={<PrivateRoute>{element}</PrivateRoute>} />
        ))}
      </Route>
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
};

export default RoutesManager;
