import { useState, useRef, useEffect } from "react";
import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import { DeleteAlertPopup } from "../ReusableComponents/CustomAlerts";
import { headCells } from "./Model";
import { CustomAgGrid } from "../AgGrid";
import InnerHeader from "../Layout/InnerHeader.js";
import KiTAPServer from "../../services/KiTAPServer";
import CreateCICD from "./CreateCICD.js";
import PreviewPipeLineTest from "./PreviewPipeLineTest.js";
import PipelineActions from "./PipelineActions.js";
import SkeletonLoader from "../SkeletonLoader.js";
import { useSearchParams } from "react-router-dom";

const CICDPipeLine = () => {
  const gridRef = useRef();
  // const scrollRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const previewId = searchParams.get("previewId");
  const [pipeLineListData, setPipeLineListData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [showCreateCICDTest, setShowCreateCICDTest] = useState(false);
  const [previewCICDData, setPreviewCICDData] = useState({});
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [delFun, setDelFun] = useState(false);
  const [alertTitle, setAlertTitle] = useState("");
  const [singleDeleteId, setSingleDeleteId] = useState(false);
  const [pipeLineLoading, setPipeLineLoading] = useState(false);

  // const autoScroll = () => {
  //   setTimeout(() => {
  //     scrollRef.current.scrollIntoView({
  //       behavior: "smooth",
  //       block: "end",
  //       inline: "nearest",
  //     });
  //   }, 600);
  // };
  const onClickPreview = async (pipeLineId) => {
    const { data } = await KiTAPServer.getPipeLineTestDetails(pipeLineId);
    setSearchParams({ previewId: pipeLineId });
    setPreviewCICDData(data);
  };
  const closePreview = () => {
    setPreviewCICDData({});
  };
  const fetchCICDList = async () => {
    setPipeLineLoading(true);
    try {
      const { data } = await KiTAPServer.getCICDPipeLineList();
      setPipeLineListData(data?.pipelineResponseList || []);
    } catch (error) {
      console.log(error);
    }

    setPipeLineLoading(false);
  };
  const deleteOption = (rowId, type) => {
    if (type?.toLowerCase() === "multiple") {
      setDeleteAlertOpen(true);
      setAlertTitle("Are you sure! want to delete selected pipelines?");
      setDelFun(true);
    } else {
      setDeleteAlertOpen(true);
      setAlertTitle("Are you sure! want to delete selected pipeline?");
      setSingleDeleteId(rowId);
    }
  };
  const onClickDelete = async () => {
    setDeleteAlertOpen(false);
    setPipeLineListData((prevState) => {
      return prevState;
    });
    await KiTAPServer?.deleteCICDPipeLine(selected);
    const seledtedRows = pipeLineListData?.filter((each) => !selected?.includes(each?.id));
    setPreviewCICDData({});
    setPipeLineListData(seledtedRows);
  };

  const singleDelete = async () => {
    const rowId = singleDeleteId;
    const sel = [];
    setDeleteAlertOpen(false);
    rowId && sel.push(rowId);
    if (sel?.length) {
      await KiTAPServer?.deleteCICDPipeLine(sel);
      setPipeLineListData((prevState) => prevState?.filter((each) => !sel?.includes(each?.id)));
    }
    setPreviewCICDData({});
  };

  const action = {
    headerName: "Actions",
    sortable: false,
    cellRenderer: PipelineActions,
    lockPosition: "right",
    cellClass: "locked-col",
    setPipeLineListData: setPipeLineListData,
    onClickPreview: onClickPreview,
    closePreview: closePreview,
    deleteOption: deleteOption,
    suppressColumnsToolPanel: true,
    filter: false,
    headerTooltip: "Actions",
    gridRef: gridRef,
  };

  useEffect(() => {
    fetchCICDList();
    headCells[headCells.length - 1] = action;
    if (previewId) {
      onClickPreview(previewId);
    }
  }, []);

  const handleCloseTest = (newRow) => {
    newRow && setPipeLineListData((prevState) => [newRow, ...prevState]);
    setShowCreateCICDTest(false);
  };
  return (
    <Box sx={{ width: "100%" }}>
      <InnerHeader>
        <Typography variant="h6">CICD PIPELINE TESTS</Typography>
      </InnerHeader>
      <Box className="pt74" sx={{ width: "100%", mb: 2 }}>
        {pipeLineLoading && <SkeletonLoader />}

        <Box component="form" noValidate autoComplete="off">
          <Box className="createBtn">
            {selected?.length === 0 ? (
              <Button onClick={() => setShowCreateCICDTest(true)} size="small" variant="contained">
                Create New Execution CICD
              </Button>
            ) : (
              <Tooltip title="Delete">
                <IconButton size="small" onClick={() => deleteOption(null, "multiple")}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
          <CustomAgGrid
            gridRef={gridRef}
            headCells={headCells}
            setSelected={setSelected}
            rows={pipeLineListData}
            columnsAutoFit={true}
          />
        </Box>
      </Box>

      {showCreateCICDTest && <CreateCICD handleClose={handleCloseTest} />}

      {Object.keys(previewCICDData)?.length > 0 && <PreviewPipeLineTest previewCICDData={previewCICDData} />}
      {deleteAlertOpen && (
        <DeleteAlertPopup
          open={deleteAlertOpen}
          setOpen={setDeleteAlertOpen}
          delFunction={delFun ? onClickDelete : singleDelete}
          alertTitle={alertTitle}
        />
      )}
    </Box>
  );
};

export default CICDPipeLine;
