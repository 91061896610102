import { useState } from "react";
import { DialogContent, Grid, Typography } from "@mui/material";
import ExecutionListOfSteps from "./ExecutionListOfSteps";
import StepResultPreview from "./StepResultPreview";
import CustomOutlinedButton from "../../../CustomComponents/CustomButtons/CustomOutlinedButton";
import { fileStorageServices } from "../../../../Redux/services/fileStorage.services";

const PreviewDataContainer = ({ exeInstanceDetails, selStatus }) => {
  const [previewShot, setPreviewShot] = useState(null);
  const [selectedRow, setSelectedRow] = useState({});
  const [loadingPreviewImage, setLoadingPreviewImage] = useState();

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const handleOnClickStep = async (step) => {
    console.log(step, "step");
    setSelectedRow(step);
    setLoadingPreviewImage(true);
    const fileObj = [{ fileProvider: step?.fileProvider, fileName: step?.fileName }];
    try {
      const base64Image = await fileStorageServices.getFileFromStorage(fileObj);
      setSelectedRow({ ...step, screenshotPath: base64Image });
    } catch (error) {
      console.log(error);
    }
    setLoadingPreviewImage(false);
  };
  return (
    <DialogContent sx={{ padding: 0 }}>
      <Grid container>
        <Grid item sm={12} p="4px 12px" className="space-between subheader-bg border-right">
          <Typography variant="bold">List Of Steps</Typography>
        </Grid>
        <Grid
          sx={{ display: "none !important" }}
          item
          sm={4}
          p="4px 12px"
          className="v-center subheader-bg space-between">
          <Typography variant="bold">Step result Preview</Typography>
          <CustomOutlinedButton label="Debug" color="#6593E8" props={{ display: "none" }} />
        </Grid>
        <ExecutionListOfSteps
          page={page}
          rowsPerPage={rowsPerPage}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          previewShot={previewShot}
          selectedRow={selectedRow}
          setPreviewShot={setPreviewShot}
          handleOnClickStep={handleOnClickStep}
          exeInstanceResults={exeInstanceDetails}
          selStatus={selStatus}
        />
        <StepResultPreview
          selectedRow={selectedRow}
          loadingPreviewImage={loadingPreviewImage}
          setPreviewShot={setPreviewShot}
        />
      </Grid>
    </DialogContent>
  );
};

export default PreviewDataContainer;
