export const customComparator = (valueA, valueB) => {
  return valueA?.toLowerCase().localeCompare(valueB?.toLowerCase());
};

export const customCellRenderNumberComparator = (valueA, valueB) => {
  if (valueA?.length === valueB?.length) {
    return 0;
  }
  return valueA?.length < valueB?.length ? -1 : 1;
};

export const customNumberComparator = (valueA, valueB) => {
  if (Number.parseInt(valueA) === Number.parseInt(valueB)) {
    return 0;
  }
  return Number.parseInt(valueA) < Number.parseInt(valueB) ? -1 : 1;
};
