import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement } from "chart.js";
import { Box, Divider, Paper, Typography, Button } from "@mui/material";
import { ExecutionStatusCodes, truncateText } from "./ExecutionConstants";
import { CustomPreviewIcon } from "../../CustomComponents/Icons/IconButtons";
import { useSearchParams } from "react-router-dom";
import JiraBugCreate from "./JiraBugCreate";
import ModalPopup from "../../ReusableComponents/ModalPopup";
import { useState } from "react";

// Register required components
ChartJS.register(ArcElement);

const TestDataReportCard = ({ testData, setPreviewTestData, testCase }) => {
  const summary = { PASSED: testData?.passCount, FAILED: testData?.failCount, OTHERS: testData?.otherCount };
  const [, setSearchParams] = useSearchParams();
  const [createBugData, setCreateBugData] = useState(false);
  const chartData = {
    labels: ["Passed", "Failed", "Others"],
    datasets: [
      {
        data: [testData?.passCount, testData?.failCount, testData?.otherCount],
        backgroundColor: ["#4CAF50", "#F9594B", "#61C5F6"],
        hoverBackgroundColor: ["#4CAF50", "#F9594B", "#61C5F6"],
        borderWidth: 0,
      },
    ],
  };

  const openPreviewTestData = (instanceId) => {
    setPreviewTestData(true);
    setSearchParams((prev) => {
      const newState = new URLSearchParams(prev);
      newState.set("exeInstanceId", instanceId);
      return newState;
    });
  };

  const createJira = () => {
    setCreateBugData(true);
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      datalabels: {
        display: false,
      },
    },
    cutout: "75%",
  };
  return (
    <Box
      component={Paper}
      elevation={0}
      className="testData-card"
      sx={{
        border: "1px solid",
        borderColor: ExecutionStatusCodes[testData?.result]?.color,
        px: 1,
      }}>
      <Box className="space-between">
        <Typography variant="h6" align="left">
          {testData?.testDataName}{" "}
          <span style={{ color: ExecutionStatusCodes[testData?.result]?.color, paddingLeft: "2px" }}>
            {ExecutionStatusCodes[testData?.result]?.status}
          </span>
        </Typography>
        <Box className="v-center">
          {ExecutionStatusCodes[testData?.result]?.status === "Failed" && (
            <Button
              size="small"
              sx={{ fontSize: "10px" }}
              variant="contained"
              color="primary"
              className="jiraTktBtn"
              onClick={createJira}>
              Create Bug
            </Button>
          )}
          <CustomPreviewIcon title={"Preview"} testData={testData} onClick={openPreviewTestData} />
        </Box>
      </Box>
      <Box className="v-center row">
        <Box className="relative center">
          <Box width="100px" height="120px">
            <Doughnut data={chartData} options={chartOptions} />
          </Box>
          <Box className="absolute column center">
            <Typography>
              {testData?.passCount !== 0 && (
                <>
                  {" "}
                  <span className="passed-color">{testData?.passCount}</span> {testData?.failCount !== 0 && <>|</>}{" "}
                </>
              )}
              {testData?.failCount !== 0 && (
                <>
                  {" "}
                  <span className="failed-color">{testData?.failCount}</span> {testData?.otherCount !== 0 && <>|</>}{" "}
                </>
              )}
              {testData?.otherCount !== 0 && <span className="not-exe-color">{testData?.otherCount}</span>}
            </Typography>
            <Divider variant="middle" width="80%" my={1} />
            <Typography>{testCase?.noOfSteps}</Typography>
          </Box>
        </Box>
        <Box className="space-between" pl="8px">
          {Object.entries(summary).map(([label, count]) => (
            <>
              <Box
                key={`${label}_${count}`}
                className="v-center"
                marginRight="8px"
                sx={{ color: ExecutionStatusCodes[label]?.color }}>
                <Box width={12} height={12} bgcolor={ExecutionStatusCodes[label]?.color} marginRight={0.5} />
                <Typography className="small-text">
                  {truncateText(ExecutionStatusCodes[label]?.status, 7)} - {count}
                </Typography>
              </Box>
            </>
          ))}
        </Box>
      </Box>

      {createBugData && (
        <ModalPopup
          open={true}
          width={"40%"}
          setOpen={setCreateBugData}
          data={<JiraBugCreate setCreateBugData={setCreateBugData} />}
        />
      )}
      {/* {summary?.FAILED !== 0 && (
        <Box mb={1}>
          <Typography variant="subtitle" display={"block"} mb="8px">
            Failed Steps
          </Typography>
          {testData?.failedSteps.map((step, i) => (
            <Chip label={step} size="small" variant="contained" key={`${step}_${i}`} />
          ))}
        </Box>
      )} */}
    </Box>
  );
};

export default TestDataReportCard;
