import { useEffect, useState } from "react";
import { IconButton, Box } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const CustomCarousel = ({ totalCount, initialIndex = 0, setPreviewScreen = () => null, itemsToShow = 5, children }) => {
  const [startIndex, setStartIndex] = useState(initialIndex <= 0 ? 0 : initialIndex - 1);

  useEffect(() => {
    if (initialIndex <= startIndex || initialIndex >= startIndex + itemsToShow) {
      setStartIndex(initialIndex);
    }
  }, [initialIndex, itemsToShow]);

  const handlePrevClick = () => {
    if (startIndex > 0) {
      const newIndex = startIndex - 1;
      setStartIndex(newIndex);
      setPreviewScreen(newIndex);
    }
  };

  const handleNextClick = () => {
    if (startIndex < totalCount - itemsToShow) {
      const newIndex = startIndex + 1;
      setStartIndex(newIndex);
      setPreviewScreen(newIndex);
    }
  };

  const translateX = -(startIndex * (100 / itemsToShow));

  return (
    <Box className="v-center">
      {totalCount > itemsToShow && (
        <IconButton color="primary" size="small" onClick={handlePrevClick} disabled={startIndex <= 0}>
          <ArrowBackIosIcon fontSize="small" />
        </IconButton>
      )}
      <Box className="carouselContainer">
        <Box
          className="flex"
          sx={{ transform: `translateX(${translateX}%)`, transition: "transform 0.5s ease", width: "100%" }}>
          {children}
        </Box>
      </Box>
      {totalCount > itemsToShow && (
        <IconButton
          color="primary"
          size="small"
          onClick={handleNextClick}
          disabled={startIndex >= totalCount - itemsToShow}>
          <ArrowForwardIosIcon fontSize="small" />
        </IconButton>
      )}
    </Box>
  );
};

export default CustomCarousel;
