import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Box, Chip, Grid, Paper, Typography } from "@mui/material";
import { CustomDeleteIcon, CustomEditIcon } from "../CustomComponents/Icons/IconButtons";
import { macrosServices } from "../../Redux/services/macros.services";
import SkeletonLoader from "../SkeletonLoader";
import { showToast } from "../../Redux/reducers/toastSlice";
import CustomDeleteModal from "../ReusableComponents/CustomDeleteModal";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import { useCheckAccess } from "../../CustomHooks/useCheckAccess";

const MacroCard = ({ macro, editMacro, handleDeleteMacro }) => {
  const { checkAccess } = useCheckAccess();
  return (
    <Box
      component={Paper}
      className="flex column"
      sx={{
        border: "1px solid #DBDFE0",
        borderRadius: "5px",
        cursor: "pointer",
        transition: "transform 0.3s ease",
        "&:hover": { transform: "translateY(-2px)" },
        height: "100%",
      }}
      gap={1}
      py={"10px"}
      px={2}>
      <Box>
        <Box className="space-between">
          <Typography sx={{ fontSize: "15px", color: "#64748B" }}>{macro?.name}</Typography>
          <Typography sx={{ fontSize: "12px", color: "#788D9A" }}>
            {dayjs(macro?.createdAt).format("MMMM Do YYYY, hA")}
          </Typography>
        </Box>
        <Typography component="span" sx={{ fontSize: "12px", color: "#788D9A" }}>
          {macro?.description}
        </Typography>
      </Box>
      <Box className="space-between ">
        <Box sx={{ display: "inline-flex" }} className="v-center">
          <Chip
            size="small"
            label={`${macro?.noOfTestSteps} Steps`}
            sx={{
              fontSize: "13px",
              color: "#4840C5",
              border: "0.5px solid #4840C5",
              backgroundColor: "#F1EEFF",
              borderRadius: "4px",
            }}
          />
          <Typography sx={{ color: "#788D9A", fontSize: "12px", p: "8px" }}>
            Used in{" "}
            <Chip
              size="small"
              label={macro?.numberOfTestCasesUsed || 0}
              sx={{
                color: "#E68200",
                border: "0.5px solid #E68200",
                backgroundColor: "transparent",
                width: "fit-content",
                height: 18,
                minWidth: 18,
                borderRadius: "4px",
              }}
            />
            Test Cases
          </Typography>
        </Box>
        <Box className="flex">
          {checkAccess("Macros", "Edit") && <CustomEditIcon onClick={() => editMacro(macro)} height="20" width="18" />}
          {checkAccess("Macros", "Delete") && (
            <CustomDeleteIcon onClick={() => handleDeleteMacro(macro)} height="20" width="20" />
          )}
        </Box>
      </Box>
    </Box>
  );
};

const MacrosList = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const autId = searchParams.get("autId");
  const autName = searchParams.get("autName");
  const autType = searchParams.get("autType");

  const [macrosList, setMacrosList] = useState([]);
  const [loadingMacros, setLoadingMacros] = useState(false);
  const [loadingDelete, setLoadingError] = useState(false);
  const [selectedMacro, setSelectedMacro] = useState(false);
  const fetchListMacros = async () => {
    setLoadingMacros(true);
    try {
      const { data } = await macrosServices.getMacrosList(autId);
      setMacrosList(data?.macroResponseList || []);
    } catch (error) {
      console.error("Error fetching macros list:", error);
    } finally {
      setLoadingMacros(false);
    }
  };
  useEffect(() => {
    fetchListMacros();
  }, []);

  const navigate = useNavigate();

  const editMacro = (macro) => {
    navigate(
      `create?macroId=${macro?.id}&macroVersion=${macro.version}&autId=${autId}&autName=${autName}&autType=${autType}`,
    );
  };

  if (loadingMacros) {
    return <SkeletonLoader />;
  }

  const handleDeleteMacro = async () => {
    setLoadingError(true);
    try {
      await macrosServices.deleteMacro({ deleteIdList: [selectedMacro?.id] });
      fetchListMacros();
      closeDeleteModal();
    } catch (error) {
      dispatch(showToast({ message: error?.response?.data?.message || error.message, type: "error" }));
    }
    setLoadingError(false);
  };

  const closeDeleteModal = () => {
    setSelectedMacro(false);
  };

  return (
    <Box>
      <Grid container spacing={1.5} mt={1}>
        {macrosList?.map((macro, index) => (
          <Grid item xs={4} key={index}>
            <MacroCard macro={macro} handleDeleteMacro={() => setSelectedMacro(macro)} editMacro={editMacro} />
          </Grid>
        ))}
      </Grid>

      {selectedMacro && (
        <CustomDeleteModal
          loading={loadingDelete}
          disabled={loadingDelete}
          onClickDelete={handleDeleteMacro}
          handleClose={closeDeleteModal}
        />
      )}
    </Box>
  );
};

export default MacrosList;
