import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Typography,
  IconButton,
} from "@mui/material";

import { CustomExpandIcon } from "../../CustomComponents/Icons/IconButtons";
import { useSearchParams } from "react-router-dom";
import Schedule from "../Schedule";
import DevicesList from "../../Applications/AndroidAUT/DevicesList";
import CustomAutoComplete from "../../CustomComponents/CustomAutoComplete";
// import CustomAutoComplete from "../../CustomComponents/CustomAutoComplete";
import { InfoOutlined as InfoIcon } from "@mui/icons-material";

const providerList = ["KITAP", "SAUCE_LABS", "BROWSER_STACK"];

const ConfigSettings = ({
  selectedEnv,
  autEnvList,
  selectedBrowsers,
  scheduledData,
  setScheduledData,
  executionSettings,
  handleChangeEnv,
  handleSettingsChange,
  handleBrowserChange,
  setSelectedDevicesList,
  selectedDevicesList,
  selectedDeviceProvider,
  handleDeviceProvider,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const autType = searchParams.get("autType");
  const isFullScreen = JSON.parse(searchParams.get("isFullScreen"));
  const isScheduled = JSON.parse(searchParams.get("isScheduled"));
  const { parallelExecution, dataParallelExecution, localExecution, enableSelfHealing } = executionSettings;

  const toggleFullScreenDialog = () => {
    setSearchParams((prev) => {
      const newState = new URLSearchParams(prev);
      newState.set("isFullScreen", !isFullScreen);
      return newState;
    });
  };

  const toggleScheduler = (e) => {
    setSearchParams((prev) => {
      const newState = new URLSearchParams(prev);
      newState.set("isScheduled", e.target.checked);
      return newState;
    });
  };

  return (
    <Box sx={{ p: 1 }}>
      <Box className="space-between">
        <Box className="v-center" gap={2}>
          <Typography variant="h6">Settings for Execution Config</Typography>
        </Box>
        <Box className="v-center">
          {/* <Box className="v-center">
            <Typography>Estimation Time:</Typography>
            <Box className="v-center" ml={1}>
              <Box textAlign="center" mr={0.5}>
                <Typography>01</Typography> <Typography>Sec</Typography>
              </Box>
              :
              <Box textAlign="center" ml={0.5}>
                <Typography>01</Typography> <Typography>Sec</Typography>
              </Box>
            </Box>
          </Box> */}
          <FormGroup width="100%" className="v-center row">
            <FormControlLabel
              control={<Switch name="parallelExecution" checked={parallelExecution} onChange={handleSettingsChange} />}
              label="Parallel Execution - Browser"
            />
            <FormControlLabel
              control={
                <Switch name="dataParallelExecution" checked={dataParallelExecution} onChange={handleSettingsChange} />
              }
              label="Parallel Execution - Datasets"
            />
            <FormControlLabel
              label="Local Execution"
              control={<Switch name="localExecution" checked={localExecution} onChange={handleSettingsChange} />}
            />
            {autType === "WEB" && (
              <FormControlLabel
                label="Self Healing"
                control={
                  <Switch name="enableSelfHealing" checked={enableSelfHealing} onChange={handleSettingsChange} />
                }
              />
            )}
          </FormGroup>
          {!isFullScreen && <CustomExpandIcon onClick={toggleFullScreenDialog} size="small" />}
        </Box>
      </Box>
      <Box className="space-between" gap={2}>
        <Box className="v-center" gap={1}>
          <FormControl size="small" sx={{ minWidth: 160 }}>
            <InputLabel required>Select environment</InputLabel>
            <Select
              value={selectedEnv}
              onChange={handleChangeEnv}
              label="Select environment"
              placeholder="Select environment"
              required>
              {autEnvList.map((env) => (
                <MenuItem key={env.id} value={env.id}>
                  {env.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {["ANDROID", "IOS"]?.includes(autType) ? (
            <>
              <Box width={200}>
                <CustomAutoComplete
                  disablePortal
                  options={providerList}
                  value={selectedDeviceProvider || ""}
                  label="Select Provider"
                  onChange={handleDeviceProvider}
                />
              </Box>
              <Box minWidth={200} maxWidth={400}>
                <DevicesList
                  setSelectedDevicesList={setSelectedDevicesList}
                  selectedDevicesList={selectedDevicesList}
                  selectedDeviceProvider={selectedDeviceProvider}
                  typeOfAut={autType}
                  localExecution={localExecution}
                />
              </Box>
              <IconButton size="small">
                <InfoIcon fontSize="small" />
              </IconButton>{" "}
              <span>Enable localExecution to get local devices and disable localExecution to get server devices </span>
            </>
          ) : (
            <FormGroup width="100%" className="v-center row">
              {["Firefox", "Chrome", "Edge"].map((browser) => (
                <FormControlLabel
                  key={browser}
                  label={browser}
                  control={
                    <Checkbox
                      checked={selectedBrowsers.includes(browser)}
                      name={browser}
                      onChange={handleBrowserChange}
                      defaultChecked={browser === "Chrome"}
                    />
                  }
                />
              ))}
            </FormGroup>
          )}
        </Box>

        <FormControlLabel control={<Switch checked={isScheduled} onChange={toggleScheduler} />} label="Schedule" />
      </Box>
      {isScheduled && <Schedule scheduledData={scheduledData} setScheduledData={setScheduledData} />}
    </Box>
  );
};

export default ConfigSettings;
