/* eslint-disable no-mixed-operators */
import { useState, memo, useCallback, useMemo } from "react";
import { useDrop, useDrag } from "react-dnd";
import {
  TableRow,
  TableCell,
  Checkbox,
  Box,
  Typography,
  IconButton,
  CircularProgress,
  TextField,
  Select,
  MenuItem,
  Tooltip,
  ListItemIcon,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import DragIndicatorOutlinedIcon from "@mui/icons-material/DragIndicatorOutlined";
import { Folder, KeyboardArrowRightOutlined, KeyboardArrowDownOutlined } from "@mui/icons-material";
import SelectUIElement from "../CreateTestStepsContainer/SelectUIElement";
import { CustomDeleteIcon } from "../../../CustomComponents/Icons/IconButtons";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import AdvanceSettings from "./AdvanceSettings";
import { attributes } from "../CreateTestStepsContainer/DataTypeSelector/DataTypes";
import DropZone from "../DropZone";

const ItemTypes = { STEP: "STEP", MACRO: "MACRO" };
const MacroItemRow = memo(({ style, row, filteredStepActionData }) => (
  <>
    {row.steps?.map((each, i) => (
      <TableRow key={i} style={{ ...style, top: style.top + 50 * (i + 1) }}>
        <TableCell width={40}>
          <Checkbox sx={{ visibility: "hidden" }} />
        </TableCell>
        <TableCell width={60} align="center">
          {(row.sequenceNumber * 100 + i + 1) / 100}
        </TableCell>
        <TableCell width="15%" align="center">
          <TextField
            sx={{
              border: "none",
              "& input": { px: "10px" },
              "& .MuiInputBase-root": { borderRadius: 1 },
              "& fieldset": { border: "1px solid #ccc" },
              "& p": { m: 0, fontSize: "0.5rem" },
            }}
            disabled
            value={each.name}
            size="small"
          />
        </TableCell>
        <TableCell width="15%" align="left">
          <FormControl fullWidth size="small">
            {!each.action && <InputLabel id="select-action-label">Select Action</InputLabel>}
            <Select disabled fullWidth size="small" value={each?.action}>
              {filteredStepActionData?.map((el) => (
                <MenuItem key={el.id} value={el.actionCode}>
                  {el?.displayName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </TableCell>
        <TableCell width="15%" align="center">
          <SelectUIElement disabled defaultValues={each?.elementLocator} />
        </TableCell>
        <TableCell width={90} align="center">
          <Checkbox disabled checked={each?.screenshot} />
        </TableCell>
        <TableCell width={90} align="center">
          <Checkbox disabled checked={each?.isDisable} />
        </TableCell>
        <TableCell width="15%">
          <FormControl disabled fullWidth size="small">
            {!each.tdmAttributeType && <InputLabel id="select-data-type-label">Select Data Type</InputLabel>}
            <Select
              disabled
              value={each.tdmAttributeType || ""}
              fullWidth
              size="small"
              renderValue={(selected) => {
                const selectedAttr = attributes.find((attribute) => attribute.attributeName === selected);
                return selectedAttr ? (
                  <Box className="v-center">
                    {selectedAttr.icon}
                    <Typography variant="body1" sx={{ ml: 1 }}>
                      {selectedAttr.attributeName}
                    </Typography>
                  </Box>
                ) : (
                  "Select Data Type"
                );
              }}>
              {attributes?.map((attribute) => (
                <MenuItem key={attribute.attributeName} value={attribute.attributeName}>
                  <ListItemIcon>{attribute.icon}</ListItemIcon>
                  <Typography variant="body1">{attribute.attributeName}</Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </TableCell>
        <TableCell />
      </TableRow>
    ))}
  </>
));

MacroItemRow.displayName = "MacroItemRow";

const CustomTableRow = memo(
  ({
    index,
    moveRow,
    handleDropMacro,
    rowData,
    row,
    isExpanded,
    isSelected,
    selectedMacroId,
    setSelectedMacroId,
    filteredStepActionData,
    handleCheckboxChange,
    handleMacroRow,
    onSelectUIElementsData,
    onChangeAction,
    onChangeDisabled,
    onChangeScreenShot,
    handleStepNameChange,
    handleDeleteStep,
    cloneStep,
    handleSubmitAdvanceSettings,
    style,
  }) => {
    const [tdmAttributeType, setTdmAttributeType] = useState(false);
    const [loadingSteps, setLoadingSteps] = useState(false);
    const [{ isDragging }, drag] = useDrag({
      type: ItemTypes.STEP,
      item: { type: ItemTypes.STEP, row, index },
      // canDrag: !selectedStepsData?.length,
      collect: (monitor) => ({ isDragging: monitor.isDragging() }),
    });

    const [{ isOver, canDrop }, drop] = useDrop({
      accept: [ItemTypes.STEP, ItemTypes.MACRO],
      hover: (item) => {
        if (item.type === ItemTypes.MACRO) {
          item.hoverIndex = index;
        }
      },
      drop: (item) => {
        if (item.type === ItemTypes.MACRO) {
          // item.index = index;
          handleDropMacro(item, item.hoverIndex);
        }
        if (item.type === ItemTypes.STEP && item.index !== index) {
          moveRow(item.index, index);
        }
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    });

    const opacity = isDragging ? 0 : 1;

    const isStepNameExists = useCallback(
      (name, currentIndex) => rowData?.some((each, index) => each?.name === name && index !== currentIndex),
      [rowData],
    );

    const actionObj = useMemo(
      () => filteredStepActionData?.find((each) => each?.actionCode === row?.action),
      [filteredStepActionData, row?.action],
    );

    const onChangeDataType = (e) => setTdmAttributeType(e.target.value);

    const onClickMacroRow = useCallback(
      (e) => {
        e.stopPropagation();
        setLoadingSteps(true);
        setTimeout(() => {
          if (!row?.steps?.length) {
            handleMacroRow(index);
          } else {
            setLoadingSteps(false);
            setSelectedMacroId(selectedMacroId ? null : row?.id);
          }
        }, 500);
      },
      [row, handleMacroRow, index, setSelectedMacroId, selectedMacroId],
    );

    if (row.name === "drop") {
      return <DropZone style={style} drag={drag} drop={drop} />;
    }

    return (
      <>
        <TableRow
          style={style}
          sx={{
            opacity,
            backgroundColor: isOver && canDrop ? "lightyellow" : "inherit",
            ...(!row.steps && { "&:hover": { backgroundColor: "#E7E9EB" } }),
          }}
          ref={(node) => drag(drop(node))}>
          <TableCell width={40} onClick={(e) => e.stopPropagation()} align="center">
            <Checkbox checked={isSelected} onChange={(e) => handleCheckboxChange(e, index)} />
          </TableCell>
          <TableCell width={60} align="center">
            <Box className="center">
              <DragIndicatorOutlinedIcon fontSize="small" color="primary" /> {row.sequenceNumber}
            </Box>
          </TableCell>

          {row.steps ? (
            <>
              <TableCell width={isExpanded ? "500px" : "15%"} align="center">
                <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                  <IconButton color="primary" disabled={isExpanded} size="small" onClick={onClickMacroRow}>
                    {selectedMacroId === row.id ? <KeyboardArrowDownOutlined /> : <KeyboardArrowRightOutlined />}
                  </IconButton>
                  <Box>
                    <Box className="v-center">
                      <Folder fontSize="small" sx={{ color: "#EAEAEA" }} />
                      {row.name}
                    </Box>
                    <Typography sx={{ fontSize: "10px" }}>{row?.description}</Typography>
                  </Box>
                  {loadingSteps && <CircularProgress size={20} />}
                </Box>
              </TableCell>
              <TableCell width={isExpanded ? "500px" : "15%"} align="center">
                <TextField sx={{ visibility: "hidden" }} size="small" />
              </TableCell>
              {!isExpanded && (
                <>
                  <TableCell width="15%" sx={{ borderLeft: "none !important" }} />
                  <TableCell width={90} sx={{ borderLeft: "none !important" }} />
                  <TableCell width={90} sx={{ borderLeft: "none !important" }} />
                  <TableCell width="15%" sx={{ borderLeft: "none !important" }} />
                  <TableCell align="left">
                    <Box className="v-center" gap={1}>
                      <CustomDeleteIcon onClick={() => handleDeleteStep(index)} height={18} width={18} />
                      <Tooltip title="Clone Step">
                        <IconButton onClick={() => cloneStep(row)} color="primary" size="small">
                          <ContentCopyOutlinedIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </TableCell>
                </>
              )}
            </>
          ) : (
            <>
              <TableCell width={isExpanded ? "400px" : "15%"} align="center">
                <Tooltip arrow disableInteractive placement="top-start" title={row.name || ""}>
                  <TextField
                    sx={{
                      border: "none",
                      "& input": { px: "10px" },
                      "& .MuiInputBase-root": { borderRadius: 1 },
                      "& fieldset": { border: "1px solid #ccc" },
                      "& p": { m: 0, fontSize: "0.5rem" },
                    }}
                    error={row.name === "" || isStepNameExists(row.name, index)}
                    helperText={
                      !row.name ? "Step Name Required" : isStepNameExists(row.name, index) && "Name already exists"
                    }
                    placeholder="Enter Step Name"
                    fullWidth
                    value={row.name || ""}
                    size="small"
                    onChange={(e) => handleStepNameChange(e, index)}
                  />
                </Tooltip>
              </TableCell>
              <TableCell width={isExpanded ? "400px" : "15%"} align="left">
                <FormControl error={!row?.action} fullWidth size="small">
                  {!row.action && <InputLabel id="select-action">Select Action</InputLabel>}
                  <Select
                    labelId="select-action"
                    fullWidth
                    size="small"
                    helperText="Action Required"
                    onChange={(e) => onChangeAction(e, index)}
                    value={row?.action}>
                    {filteredStepActionData?.map((el) => (
                      <MenuItem key={el.id} value={el.actionCode}>
                        {el?.displayName}
                      </MenuItem>
                    ))}
                  </Select>
                  {!row?.action && <FormHelperText sx={{ m: 0, fontSize: "0.5rem" }}>Action Required</FormHelperText>}
                </FormControl>
              </TableCell>

              {!isExpanded && (
                <>
                  <TableCell width="15%" align="center">
                    <SelectUIElement
                      disabled={!actionObj?.isElementRequired}
                      defaultValues={row?.elementLocator}
                      setUIElements={(elData) => onSelectUIElementsData(elData, index)}
                    />
                  </TableCell>
                  <TableCell width={90} align="center">
                    <Checkbox checked={row?.screenshot} onChange={(e) => onChangeScreenShot(e, index)} />
                  </TableCell>
                  <TableCell width={90} align="center">
                    <Checkbox checked={row?.isDisable} onChange={(e) => onChangeDisabled(e, index)} />
                  </TableCell>
                  <TableCell width="15%" align="left">
                    <FormControl disabled fullWidth size="small">
                      {!row.tdmAttributeType && <InputLabel id="select-data-type-label">Select Data Type</InputLabel>}
                      <Select
                        disabled
                        labelId="select-data-type-label"
                        value={row.tdmAttributeType || ""}
                        onChange={onChangeDataType}
                        fullWidth
                        // disabled={!actionObj?.isInputDataRequired}
                        renderValue={(selected) => {
                          const selectedAttr = attributes.find((attribute) => attribute.attributeName === selected);
                          return selectedAttr ? (
                            <Box className="v-center">
                              {selectedAttr.icon}
                              <Typography variant="body1" sx={{ ml: 1 }}>
                                {selectedAttr.attributeName}
                              </Typography>
                            </Box>
                          ) : (
                            "Select Data Type"
                          );
                        }}>
                        {attributes?.map((attribute) => (
                          <MenuItem key={attribute.attributeName} value={attribute.attributeName}>
                            <ListItemIcon>{attribute.icon}</ListItemIcon>
                            <Typography variant="body1">{attribute.attributeName}</Typography>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell align="left">
                    <Box className="v-center" gap={1}>
                      <CustomDeleteIcon onClick={() => handleDeleteStep(index)} height={18} width={18} />
                      <Tooltip title="Clone Step">
                        <IconButton onClick={() => cloneStep(row)} color="primary" size="small">
                          <ContentCopyOutlinedIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </TableCell>
                </>
              )}
            </>
          )}
        </TableRow>

        {selectedMacroId === row?.id && (
          <MacroItemRow style={style} row={row} filteredStepActionData={filteredStepActionData} />
        )}

        {tdmAttributeType && (
          <AdvanceSettings
            tdmAttributeType={tdmAttributeType}
            row={row}
            setTdmAttributeType={setTdmAttributeType}
            onSubmit={(data) => {
              handleSubmitAdvanceSettings(data, index);
              setTdmAttributeType(false);
            }}
          />
        )}
      </>
    );
  },
);

CustomTableRow.displayName = "CustomTableRow";

export default CustomTableRow;
