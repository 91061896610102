import { Box, Card, CardContent, Checkbox, Typography } from "@mui/material";
import { CustomDeleteIcon, CustomEditIcon } from "../../CustomComponents/Icons/IconButtons";

const DeviceCard = ({
  device,
  mobileDeviceIdList,
  selectedDevices,
  isDeviceList = false,
  handleSelectDevice,
  onClickEdit,
}) => {
  const isDisabled = device?.isMapped && !isDeviceList && !mobileDeviceIdList?.includes(device?.id);

  return (
    <Card sx={{ border: "1px solid #ccc", position: "relative", borderRadius: 2 }}>
      <CardContent
        sx={{
          p: isDeviceList ? "16px !important" : "24px 16px 16px 16px !important",
          opacity: isDisabled ? 0.7 : 1, // Adjust opacity for disabled state
          pointerEvents: isDisabled ? "none" : "auto", // Disable pointer events when card is disabled
          filter: isDisabled ? "grayscale(80%)" : "none", // Apply grayscale filter when disabled (optional)
        }}>
        {!isDeviceList && (
          <Checkbox
            disabled={isDisabled && !selectedDevices?.includes(device?.id)}
            checked={selectedDevices?.includes(device?.id)}
            onChange={handleSelectDevice}
            sx={{ position: "absolute", top: 0, left: "5px", "&.Mui-disabled": { opacity: 0.5 } }}
          />
        )}
        <Box className="space-between" sx={{ mt: isDeviceList ? 0 : 1.5 }}>
          <Box className="flex column" sx={{ gap: 1 }}>
            <Typography sx={{ color: "#64748B", fontSize: "0.8rem" }}>
              Name:{" "}
              <Typography component="span" sx={{ fontWeight: 600 }}>
                {device?.name || "N/A"}
              </Typography>
            </Typography>
            <Typography sx={{ color: "#64748B", fontSize: "0.8rem" }}>
              OS Name:{" "}
              <Typography component="span" sx={{ fontWeight: 600 }}>
                {device?.mobileOs || "N/A"}
              </Typography>
            </Typography>
            <Typography sx={{ color: "#64748B", fontSize: "0.8rem" }}>
              OS Version:{" "}
              <Typography component="span" sx={{ fontWeight: 600 }}>
                {device?.osVersion || "N/A"}
              </Typography>
            </Typography>
            <Typography sx={{ color: "#64748B", fontSize: "0.8rem" }}>
              UUID:{" "}
              <Typography component="span" sx={{ fontWeight: 600 }}>
                {device?.udid || "N/A"}
              </Typography>
            </Typography>
          </Box>

          <Box className="space-between column" sx={{ textAlign: "right", gap: 1, height: "100%" }}>
            <Typography color="#8FA1AA">2 hrs ago</Typography>
            <img
              src={`./devices/${device?.mobileOs?.toLowerCase()}.svg`}
              alt="Device Logo"
              style={{ width: "80px", height: "80px" }}
            />
          </Box>
        </Box>
        {isDeviceList && (
          <Box className="v-center">
            <CustomEditIcon sx={{ ml: "auto" }} onClick={() => onClickEdit(device)} color="#611EB6" />
            <CustomDeleteIcon disabled={device?.isMapped || true} color="#611EB6" />
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default DeviceCard;
