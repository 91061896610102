import { useState } from "react";
import {
  Box,
  Typography,
  Alert,
  Avatar,
  Chip,
  TextField,
  Button,
  InputAdornment,
  IconButton,
  CircularProgress,
  DialogContent,
  DialogTitle,
  Dialog,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import darkLogo from "../../images/kitap-black.png";
import { authServices } from "../../Redux/services/auth.services";
import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setUserDetails } from "../../Redux/reducers/authSlice";
import { showToast } from "../../Redux/reducers/toastSlice";
import { encryptData } from "../../helpers/encryptionUtils";

const PasswordVerification = ({ onVerify }) => {
  const userDetails = useSelector((state) => state.auth.user_details);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordChange = (event) => setPassword(event.target.value);
  const toggleShowPassword = () => setShowPassword((prev) => !prev);
  const handleNextClick = () => onVerify(true);

  return (
    <Box sx={{ width: 400, p: 4, borderRadius: 2, boxShadow: 3, textAlign: "center", my: 2, mx: "auto" }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Hi {userDetails?.FirstName}
      </Typography>
      <Typography variant="body1" sx={{ mb: 3 }}>
        To continue, please verify your identity
      </Typography>
      <TextField
        fullWidth
        sx={{ mb: 2 }}
        type={showPassword ? "text" : "password"}
        value={password}
        onChange={handlePasswordChange}
        label="Enter your password"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={toggleShowPassword} edge="end">
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Button variant="contained" disabled={!password} fullWidth onClick={handleNextClick} sx={{ mb: 2 }}>
        Next
      </Button>
      <Button variant="text" fullWidth sx={{ color: "primary.main" }}>
        Forgot password?
      </Button>
    </Box>
  );
};

const NewPasswordForm = ({ handleClose }) => {
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.auth.user_details);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const validatePassword = (password) => {
    const upperCaseRegex = /[A-Z]/;
    const numberRegex = /\d/;
    return password.length >= 8 && upperCaseRegex.test(password) && numberRegex.test(password);
  };

  const handleChangePassword = async () => {
    const oldPassword = userDetails?.password;

    if (newPassword === oldPassword) {
      setErrorMessage("New password cannot be the same as the old password.");
      return;
    }

    if (!validatePassword(newPassword)) {
      setErrorMessage("New password must contain at least 8 characters, 1 uppercase letter, and 1 number.");
      return;
    }

    if (newPassword !== confirmPassword) {
      setErrorMessage("Passwords do not match.");
      return;
    }

    setIsLoading(true);

    try {
      await authServices.changePassword({ Password: newPassword, UserId: userDetails?.UserId });

      dispatch(showToast({ message: "Password changed successfully", type: "success" }));
      // const reqData = { UserId: userDetails?.UserId, SessionId: userDetails?.SessionId };
      // await authServices.logout(reqData);
      const modifinedData = { ...userDetails, isULAAgreed: true };
      dispatch(setUserDetails(modifinedData));
      localStorage.setItem("kitapUser", JSON.stringify(encryptData(JSON.stringify(modifinedData))?.encryptedData));
      handleClose && handleClose();
    } catch (error) {
      setErrorMessage("An error occurred while changing your password. Please try again.");
      dispatch(showToast({ message: error?.response?.data?.message || error.message, type: "error" }));
    } finally {
      setIsLoading(false);
    }
  };

  const toggleShowPassword = () => setShowPassword((prev) => !prev);

  const isPasswordLengthValid = newPassword.length >= 8;
  const isUpperCaseValid = /[A-Z]/.test(newPassword);
  const isNumberValid = /\d/.test(newPassword);

  return (
    <Box sx={{ maxWidth: 480, margin: "0 auto", textAlign: "center" }}>
      <Typography variant="h5" sx={{ mb: 1 }}>
        Hi {userDetails?.FirstName}
      </Typography>
      <Typography sx={{ mb: 1 }} variant="body1">
        Choose a strong password and {"don't"} reuse it for other accounts.
        <Button variant="text" sx={{ fontSize: 12 }}>
          Learn more
        </Button>
      </Typography>

      <Box sx={{ border: "1px solid #ddd", borderRadius: "8px", p: 3, backgroundColor: "#fff", mb: 2 }}>
        <TextField
          fullWidth
          sx={{ mb: 2 }}
          type={showPassword ? "text" : "password"}
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          label="New Password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={toggleShowPassword} edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Box sx={{ textAlign: "left", mb: 2 }}>
          <Typography variant="subtitle1" sx={{ mb: 1 }}>
            Password Must Contain:
          </Typography>
          <Typography
            variant="body2"
            sx={{
              // textDecoration: isPasswordLengthValid ? "line-through" : "none",
              color: isPasswordLengthValid ? "success.main" : "error.main",
            }}>
            • At least 8 characters
          </Typography>
          <Typography
            variant="body2"
            sx={{
              // textDecoration: isUpperCaseValid ? "line-through" : "none",
              color: isUpperCaseValid ? "success.main" : "error.main",
            }}>
            • At least 1 uppercase letter (A-Z)
          </Typography>
          <Typography
            variant="body2"
            sx={{
              // textDecoration: isNumberValid ? "line-through" : "none",
              color: isNumberValid ? "success.main" : "error.main",
            }}>
            • At least 1 number (0-9)
          </Typography>
        </Box>

        <TextField
          fullWidth
          sx={{ mb: 2 }}
          type={showPassword ? "text" : "password"}
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          label="Confirm New Password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={toggleShowPassword} edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <Button
        variant="contained"
        fullWidth
        onClick={handleChangePassword}
        sx={{ mt: 1 }}
        disabled={isLoading}
        startIcon={isLoading ? <CircularProgress size={24} color="inherit" /> : null}>
        {isLoading ? "Changing..." : "Change Password"}
      </Button>

      {errorMessage && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {errorMessage}
        </Alert>
      )}
    </Box>
  );
};

const ChangePassword = ({ handleClose }) => {
  const userDetails = useSelector((state) => state.auth.user_details);
  const [isOldPasswordVerified, setIsOldPasswordVerified] = useState(true);

  if (!userDetails?.Token) {
    return <Navigate to="/login" />;
  }

  const handleNavigateHome = () => {
    // navigate("/");
  };

  return (
    <Dialog
      sx={{
        "& .MuiBackdrop-root": { backdropFilter: "blur(6px)" },
        "& .MuiPaper-root": {
          overflowY: "visible",
          boxShadow:
            "0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12) !important",
        },
      }}
      open={open}
      maxWidth="md">
      {userDetails?.isULAAgreed && (
        <IconButton onClick={handleClose} sx={{ position: "absolute", right: -16, top: -16 }} color="error">
          <CancelIcon />
        </IconButton>
      )}
      <DialogTitle className="space-between" sx={{ bgcolor: "background.default", borderBottom: "1px solid #ddd" }}>
        <Box onClick={handleNavigateHome} sx={{ cursor: "pointer", display: "flex", alignItems: "center" }}>
          <img width={90} src={darkLogo} alt="kitap" />
        </Box>
        <Chip avatar={<Avatar>{userDetails?.FirstName[0]}</Avatar>} label={userDetails?.Email} />
      </DialogTitle>
      <DialogContent sx={{ minWidth: 900, pb: 4 }} dividers>
        {isOldPasswordVerified ? (
          <NewPasswordForm handleClose={handleClose} />
        ) : (
          <PasswordVerification onVerify={setIsOldPasswordVerified} />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ChangePassword;
