/* eslint-disable no-mixed-operators */
import { useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import CustomTablePagination from "../../../CustomComponents/CustomTablePagination";
import DataObjectOutlinedIcon from "@mui/icons-material/DataObjectOutlined";

import { styled } from "@mui/system";
import { useNavigate, useSearchParams } from "react-router-dom";

const Container = styled(Box)(({ theme }) => ({
  background: "linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)",
  height: 400,
  padding: theme.spacing(4),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(2),
}));

const StyledLink = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: "none",
  fontWeight: theme.typography.fontWeightBold,
  cursor: "pointer",
  "&:hover": { textDecoration: "underline" },
}));

const TestDataCreated = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const autType = searchParams.get("autType");
  const autId = searchParams.get("autId");
  const autName = searchParams.get("autName");

  const navigateToTestCase = () => navigate(`/testcases/?autType=${autType}&autName=${autName}&autId=${autId}`);
  return (
    <Container>
      <StyledTypography variant="h6">Test Data Created Successfully</StyledTypography>
      <StyledTypography variant="body1">Would you like to see the test cases?</StyledTypography>
      <StyledLink onClick={navigateToTestCase} variant="body1">
        View Test Cases
      </StyledLink>
    </Container>
  );
};

const TooltipTitle = ({ stepActionData, step }) => {
  const actionsData = stepActionData?.find((each) => each?.actionCode === step.action);
  return (
    <Typography style={{ whiteSpace: "pre-line" }}>
      <strong style={{ fontSize: "12px" }}>{actionsData?.displayName}</strong> : {actionsData?.description}
    </Typography>
  );
};
const CreateTestDataForm = ({
  errors,
  selectedTestData,
  stepActionData,
  setSelectedTestData,
  isPreview,
  dataInputs,
  handleDataInputsChange,
  testDataItem,
}) => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(Number(event.target.value));
    setPage(1);
  };

  const handleTestDataChange = (index, value) => {
    setSelectedTestData((prevData) => prevData.map((data, i) => (i === index ? { ...data, value } : data)));
  };

  const paginatedData = selectedTestData.slice((page - 1) * rowsPerPage, page * rowsPerPage);
  return (
    <Grid item xs={9}>
      <Box className="space-between" sx={{ background: "#F4F1FF", px: 1, height: 42 }}>
        <Typography variant="bold">{isPreview ? "Preview Test Data" : "Create Step Data"}</Typography>
      </Box>
      {isPreview ? (
        <Box className="v-center" gap={2} p={1}>
          <Box className="v-center">
            <Typography sx={{ fontSize: "10px", fontWeight: 700 }}>Test Data Name: </Typography>
            <Typography sx={{ fontSize: "10px" }}>{testDataItem?.name}</Typography>
          </Box>
          <Box className="v-center">
            <Typography sx={{ fontSize: "10px", fontWeight: 700 }}>Description: </Typography>
            <Typography sx={{ fontSize: "10px" }}>{testDataItem?.description}</Typography>
          </Box>
        </Box>
      ) : (
        paginatedData?.length > 0 && (
          <Grid container spacing={1} p={1}>
            <Grid item xs={6}>
              <TextField
                name="name"
                value={dataInputs.name}
                onChange={handleDataInputsChange}
                label="Test Data Name"
                size="small"
                fullWidth
                sx={{ "& p": { m: 0, p: 0 } }}
                error={Boolean(errors.name)}
                helperText={errors.name}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="description"
                value={dataInputs.description}
                onChange={handleDataInputsChange}
                label="Description"
                size="small"
                fullWidth
              />
            </Grid>
          </Grid>
        )
      )}

      {paginatedData?.length > 0 && (
        <>
          <TableContainer sx={{ minHeight: 388, maxHeight: 388 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                    <Typography variant="bold" color="primary">
                      #
                    </Typography>
                  </TableCell>
                  <TableCell align="left" sx={{ width: "34%" }}>
                    Step Name
                  </TableCell>
                  <TableCell align="left" sx={{ width: "60%" }}>
                    Test Data
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedData.map((step, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ textAlign: "left" }}>
                      <Typography>{step?.sequenceNumber}</Typography>
                    </TableCell>
                    <TableCell sx={{ textAlign: "left" }}>
                      <Box className="space-between">
                        <Typography>{step.name}</Typography>
                        <Tooltip
                          placement="top-start"
                          title={<TooltipTitle stepActionData={stepActionData} step={step} />}
                          arrow>
                          <IconButton size="small" color="primary">
                            <DataObjectOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </TableCell>
                    <TableCell sx={{ textAlign: "center", p: "0px !important" }}>
                      {isPreview ? (
                        <Typography className="v-center" sx={{ height: 34, px: 1 }}>
                          {step.value || ""}
                        </Typography>
                      ) : (
                        <TextField
                          sx={{
                            border: "none",
                            "& input": { px: "10px" },
                            "& .MuiInputBase-root": { borderRadius: 0 },
                            "& fieldset": { border: 0 },
                            "& p": { m: 0, p: 0 },
                          }}
                          autoFocus={index === 0}
                          placeholder="Enter Test Data"
                          fullWidth
                          value={step.value || ""}
                          size="small"
                          onChange={(e) => handleTestDataChange(index + (page - 1) * rowsPerPage, e.target.value)}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <CustomTablePagination
            currentPage={page}
            rowsLength={selectedTestData?.length}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </>
      )}

      {Boolean(!paginatedData?.length) && <TestDataCreated />}
    </Grid>
  );
};

export default CreateTestDataForm;
