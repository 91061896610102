const CustomTriangleIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="29" height="16" viewBox="0 0 29 16" fill="none">
      <path d="M15 16L0 0H29L15 16Z" fill="url(#paint0_linear_2636_1527)" />
      <defs>
        <linearGradient id="paint0_linear_2636_1527" x1="0" y1="8" x2="29" y2="8" gradientUnits="userSpaceOnUse">
          <stop stopColor="#611EB6" />
          <stop offset="1" stopColor="#66A2EE" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default CustomTriangleIcon;
