import { Alert, Box, CircularProgress, Divider, Grid, Typography } from "@mui/material";
import CustomOutlinedButton from "../../../CustomComponents/CustomButtons/CustomOutlinedButton";
import { CustomPreviewOutlinedIcon } from "../../../CustomComponents/Icons/IconButtons";
import { getTimeDifference } from "../../../../utilities/ReusableFunctions";

const statusStyles = {
  PASSED: { backgroundColor: "#ebfceb", color: "#4CAF50" },
  FAILED: { backgroundColor: "#FFEAEB", color: "#F9594B" },
  SKIPPED: { backgroundColor: "#e6f2f7", color: "#61C5F6" },
};

const StepResultPreview = ({ loadingPreviewImage, selectedRow, setPreviewShot }) => {
  const hasSelectedRow = Object.keys(selectedRow).length !== 0;
  const itemsToShow = 2;
  return (
    <Grid item sm={4} p={1} sx={{ display: "none" }}>
      {hasSelectedRow ? (
        <>
          <Alert
            icon={false}
            severity="success"
            sx={{
              "& .MuiAlert-action": {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                p: 0,
              },
              py: 0,
              borderRadius: "4px",
              background: `${statusStyles[selectedRow?.result]?.backgroundColor} !important`,
              color: "#64748B",
            }}
            action={
              <CustomOutlinedButton label={selectedRow?.result} color={statusStyles[selectedRow?.result]?.color} />
            }>
            #S.No - {selectedRow?.sequenceNumber}
          </Alert>
          <Typography sx={{ my: 1 }} variant="body1">
            Test Data
          </Typography>
          <Typography variant="body2" sx={{ lineHeight: "1.8" }}>
            {selectedRow?.data}
          </Typography>
          <Box className="space-between">
            <Typography variant="body1">Duration</Typography>
            <Typography variant="body2">
              {getTimeDifference(selectedRow?.startedAt, selectedRow?.finishedAt)?.readableFormat}
            </Typography>
          </Box>
          <Divider sx={{ my: 1 }} />
          <Typography variant="body1" color="#F9594B">
            Remarks
          </Typography>
          <Typography variant="body2" sx={{ lineHeight: "1.8", py: 0.5 }}>
            {selectedRow?.errorDetails}
          </Typography>
          <Divider sx={{ my: 0.5 }} />
          <Box className="space-between">
            <Typography variant="body1">Element</Typography>
            <Typography variant="body2">{selectedRow?.element}</Typography>
          </Box>
          <Box>
            <Typography my={1} variant="body1">
              Screenshots
            </Typography>
            <Box
              className="overlaybase"
              sx={{
                border: "4px solid #E68200",
                flex: `0 0 ${100 / itemsToShow}%`,
                maxWidth: `${100 / itemsToShow}%`,
              }}>
              {loadingPreviewImage && <CircularProgress size={20} />}
              <img
                src={selectedRow?.screenshotPath}
                alt={`Screenshot ${selectedRow?.screenshotPath}`}
                height={120}
                style={{ objectFit: "contain" }}
                width="100%"
              />
              <Box className="overlay overlayIcon" onClick={() => setPreviewShot(selectedRow?.screenshotPath)}>
                <CustomPreviewOutlinedIcon title={"Preview"} />
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <Box height="100%" className="center">
          <Typography variant="body1">Please Select any Step to view Details..</Typography>
        </Box>
      )}
    </Grid>
  );
};

export default StepResultPreview;
