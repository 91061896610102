import { Box } from "@mui/material";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";

const PowerBIReport = ({ reportData, className, filter }) => {
  return (
    <Box sx={{ width: "100%" }}>
      <PowerBIEmbed
        embedConfig={{
          type: "report",
          id: reportData?.id,
          embedUrl: reportData?.embedUrl,
          accessToken: reportData?.accessToken,
          tokenType: models.TokenType.Aad,
          settings: {
            panes: {
              filters: { expanded: filter, visible: filter },
              pageNavigation: { visible: false },
              exploration: { visible: false },
              filterPaneEnabled: false,
            },
            background: models.BackgroundType.Transparent,
          },
        }}
        eventHandlers={
          new Map([
            ["loaded", () => console.log("Report loaded")],
            ["rendered", () => console.log("Report rendered")],
            ["error", (event) => console.error(event.detail)],
            ["visualClicked", () => console.log("Visual clicked")],
            ["pageChanged", (event) => console.log(event)],
          ])
        }
        cssClassName={className}
        getEmbeddedComponent={(embeddedReport) => {
          window.report = embeddedReport;
        }}
      />
    </Box>
  );
};

export default PowerBIReport;
