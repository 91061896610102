import { combineReducers } from "redux";
import autSlice from "./autSlice";
import toastSlice from "./toastSlice";
import testcaseSlice from "./testcaseSlice";
import authSlice from "./authSlice";
const rootReducer = combineReducers({
  aut: autSlice,
  toast: toastSlice,
  testcase: testcaseSlice,
  auth: authSlice,
});

export default rootReducer;
