// src/hooks/useCheckAccess.js
import { useSelector } from "react-redux";

export const useCheckAccess = () => {
  const userDetails = useSelector((state) => state.auth.user_details);

  const checkAccess = (eName, action) => {
    const user = userDetails?.RolePermissions?.find((e) => e?.EntityName === eName);
    return user?.[action] ?? false;
  };

  return { checkAccess };
};
