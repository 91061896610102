import { useState } from "react";
import KiTAPServer from "../../../services/KiTAPServer";
import CustomAutoComplete from "../../CustomComponents/CustomAutoComplete";
import { showToast } from "../../../Redux/reducers/toastSlice";
import { useDispatch } from "react-redux";

export default function DevicesList({
  selectedDevicesList,
  setSelectedDevicesList,
  selectedDeviceProvider,
  typeOfAut,
  isDisable = false,
  localExecution = false,
}) {
  const dispatch = useDispatch();
  const [deviceList, setDeviceList] = useState([]);
  const [loadingDevices, setLoadingDevices] = useState(false);

  const getDeviceList = async () => {
    if (!selectedDeviceProvider) {
      return;
    }

    setLoadingDevices(true);
    try {
      const { data } = await KiTAPServer.getDeviceList(selectedDeviceProvider, typeOfAut, isDisable, localExecution);
      setDeviceList(
        data?.map((device) => ({
          deviceId: device?.id,
          deviceName: device?.name,
          deviceProvider: device?.environmentProvider,
        })),
      );
    } catch (error) {
      dispatch(showToast({ message: error?.response?.data?.message || error.message, type: "error" }));
    } finally {
      setLoadingDevices(false);
    }
  };

  const handleDeviceChange = (e, v) => {
    setSelectedDevicesList(v);
  };

  const isOptionEqualToValue = (option, value) => {
    return Number.parseInt(option.deviceId, 10) === Number.parseInt(value.deviceId, 10);
  };

  return (
    <CustomAutoComplete
      size="small"
      multiple
      fullWidth
      disablePortal
      disableCloseOnSelect
      disabled={!selectedDeviceProvider}
      loading={loadingDevices}
      options={deviceList}
      value={selectedDevicesList}
      onChange={handleDeviceChange}
      onOpen={getDeviceList}
      required
      limitTags={2}
      labelChip
      label="Select Device"
      placeholder="Select Device"
      getOptionLabel={(option) => option.deviceName || option.name}
      isOptionEqualToValue={isOptionEqualToValue}
    />
  );
}
