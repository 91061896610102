import { styled } from "@mui/material/styles";
import { Box, Typography, Switch } from "@mui/material";

const SwitchButton = styled(Switch)(() => ({
  "& .MuiSwitch-thumb": {
    backgroundImage: "linear-gradient(to right, #611EB6, #66A2EE)",
  },
  //   "& .MuiSwitch-track": {
  //     backgroundColor: "rgba(0, 0, 0, 0.25)",
  //   },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundImage: "linear-gradient(to right, #611EB6, #66A2EE)",
  },
}));

const CustomSwitchButton = ({ labels, checked, disabled = false, onChange, ...props }) => {
  return (
    <Box display="flex" alignItems="center">
      {labels[0] && (
        <Typography
          sx={{
            fontWeight: !checked ? 700 : "normal",
            backgroundImage: !checked ? "linear-gradient(to right, #611EB6, #66A2EE)" : "none",
            backgroundClip: !checked ? "text" : "unset",
            WebkitBackgroundClip: !checked ? "text" : "unset",
            color: !checked ? "transparent" : "inherit",
          }}>
          {labels[0]}
        </Typography>
      )}
      <SwitchButton
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        {...props}
        // sx={{ mx: 1 }}
      />
      {labels[1] && (
        <Typography
          sx={{
            fontWeight: checked ? 700 : "normal",
            backgroundImage: checked ? "linear-gradient(to right, #611EB6, #66A2EE)" : "none",
            backgroundClip: checked ? "text" : "unset",
            WebkitBackgroundClip: checked ? "text" : "unset",
            color: checked ? "transparent" : "inherit",
          }}>
          {labels[1]}
        </Typography>
      )}
    </Box>
  );
};

export default CustomSwitchButton;
