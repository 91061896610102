const Messages = [
  {
    ExpiryType: "NoLicense",
    Header: "No License",
    Message: "You don't have a license, please contact Kairos Technologies for a license.",
  },
  {
    ExpiryType: "LicenseExpired",
    Header: "License Expired",
    Message: "Your license has expired, please contact Kairos Technologies for license subscription or renewal.",
  },
  {
    ExpiryType: "VolumeLimitExceeded",
    Header: "Volume Limit Exceeded",
    Message:
      "Your license cannot support this volume of data, please contact Kairos Technologies for a license upgrade.",
  },
];

const LoginRedirectUrl = window._env_?.REACT_APP_LOGIN_REDIRECT_URL;

const replaceHostnameInUrl = (url) => {
  const shouldChangeHostname = window._env_.REACT_APP_USE_CURRENT_WEB_HOST_FOR_BACKEND === "true";
  const urlObject = new URL(url);
  if (shouldChangeHostname) {
    const newHost = window.location.hostname;
    urlObject.hostname = newHost;
    return urlObject.toString();
  }
  return urlObject.toString();
};

const azureLogin = false;
const azureAppInsights = false;

const sessionTimeoutMinutes = window._env_?.REACT_APP_SESSION_TIMEOUT_MINUTES || 30;
const idealTimeout = sessionTimeoutMinutes * 60 * 1000;
const ApiServerUrl = `${replaceHostnameInUrl(window._env_?.REACT_APP_API_SERVER_URL)}`;
const KeyManagerUrl = `${replaceHostnameInUrl(window._env_?.REACT_APP_KEY_MANAGER_URL)}api/SecretManager/V1/MultiSecrets`;
const fileStorageUrl = `${replaceHostnameInUrl(window._env_?.REACT_APP_STORAGE_SERVICE)}/api/StorageService`;
const ApiServerUrlV1 = `${ApiServerUrl}v1`;
const ApiServerUrlV2 = `${ApiServerUrl}v2`;
const ApiServerUrlV3 = `${ApiServerUrl}v3`;
const LicenseManagementUrl = `${window._env_?.REACT_APP_LICENSE_MANAGEMENT_URL}/DQG-LicenseManagement`;
const AuthUrl = `${window._env_?.REACT_APP_AUTH_URL}/DQG-AuthNZ`;
const EngineUrl = window._env_?.REACT_APP_ENGINE_URL;
const ChatBotApi = `${EngineUrl}/api/Copilot/AskQuery`;

const kitapBot = window._env_?.REACT_APP_KITAP_BOT_URL;
const disabledAuts = [];
module.exports = {
  Messages,
  LoginRedirectUrl,
  ApiServerUrlV1,
  ApiServerUrlV2,
  ApiServerUrlV3,
  LicenseManagementUrl,
  AuthUrl,
  EngineUrl,
  KeyManagerUrl,
  ChatBotApi,
  azureLogin,
  azureAppInsights,
  idealTimeout,

  fileStorageUrl,
  kitapBot,
  disabledAuts,
  DATA3: `${ApiServerUrl}/v3/test-data`,
  EXECUTION: `${ApiServerUrl}/executions`,
  EXECUTION2: `${ApiServerUrl}/v2/low-code-execution`,
  EXECUTION3: `${ApiServerUrl}/v3/executions`,
  DASHBOARD: `${ApiServerUrl}/v1/test-result`,
  DASHBOARD2: `${ApiServerUrl}/v2/test-result`,
  SUITE: `${ApiServerUrl}/v1/test-suites`,
  SUITE2: `${ApiServerUrl}/v2/test-suites`,
  SUITE3: `${ApiServerUrl}/v1/upload-suites`,
  REPORTS: `${ApiServerUrl}power-bi/v1/get-single-report?reportType=`,
};
