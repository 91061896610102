import { Divider, Grid, Typography, Box } from "@mui/material";
import triangle from "../../images/triangle.svg";
export default function AutTile(props) {
  const { autType, onClickAUTTile, type, pageType, enableAdd } = props;

  return (
    <Box
      className={`${autType === "a" ? "DisabledDSListItem" : "DSListItem"} ${
        type?.toLowerCase() === autType?.type?.toLowerCase() ? "item-selected" : ""
      }`}>
      <Box className="DBList" onClick={() => `${onClickAUTTile(autType?.type)}`}>
        <Box textAlign="center">
          <img src={`./applicationTypes/${autType?.type?.toLowerCase()}.svg`} alt={type} />
        </Box>
        <Divider />
        <Grid container alignItems="center" item>
          <Grid md={7} item>
            <Box className="DBLeft" sx={{ "&:hover": { cursor: "pointer" } }}>
              <Typography>{autType?.type}</Typography>
              <Typography variant="h6">
                Apps: <span>{autType?.noOfAuts}</span>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {enableAdd && <Box textAlign="right">{pageType}</Box>}
      {type?.toLowerCase() === autType?.type?.toLowerCase() && (
        <Box sx={{ position: "absolute", bottom: -29, left: "50%", transform: "translateX(-50%)" }}>
          <img src={triangle} alt="triangle" />
        </Box>
      )}
    </Box>
  );
}
