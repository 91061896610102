import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiServerUrlV1, disabledAuts } from "../../constants";
import { showToast } from "../reducers/toastSlice";

// Async thunk to fetch the list of AUTs
const fetchListOfAuts = createAsyncThunk("auts/fetchListOfAuts", async (autType, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.get(`${ApiServerUrlV1}/auts?autType=${autType}`);
    return response.data?.autResponseList;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    dispatch(showToast({ message: "Failed to fetch list of auts", type: "error" }));
    rejectWithValue(errorMessage);
    throw new Error(errorMessage);
  }
});

const fetchAppTypes = createAsyncThunk("auts/fetchAppTypes", async (_, { rejectWithValue, dispatch }) => {
  try {
    const response = await axios.get(`${ApiServerUrlV1}/auts/count`);

    const filteredData = response?.data?.map((each) => {
      if (disabledAuts.includes(each.type)) {
        return { ...each, disable: true };
      }
      return each;
    });

    return filteredData;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    dispatch(showToast({ message: "Failed to fetch list of applications", type: "error" }));
    rejectWithValue(errorMessage);
    throw new Error(errorMessage);
  }
});

const getAutDetailsById = async (autId) => {
  try {
    const response = await axios.get(`${ApiServerUrlV1}/auts/${autId}`);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || error.message);
  }
};

const saveAut = (aut) => {
  // TODO on header user details should send
  return axios.post(`${ApiServerUrlV1}/auts`, aut);
};
const updateAut = (autDetails) => {
  return axios.put(`${ApiServerUrlV1}/auts`, autDetails);
};
const deleteAut = (autIds) => {
  return axios.delete(`${ApiServerUrlV1}/auts`, { data: autIds });
};

const getAutExecutionsList = (selectedAutId, selectedAUTType, type) => {
  let url = `${ApiServerUrlV1}/executions`;
  const params = [];
  if (selectedAutId) {
    params.push(`autId=${selectedAutId}`);
  }
  if (selectedAUTType && selectedAUTType !== "All") {
    params.push(`autType=${selectedAUTType}`);
  }
  if (type) {
    params.push(`status=${type}`);
  }
  if (params.length > 0) {
    url += `?${params.join("&")}`;
  }

  return axios.get(url);
};

export const autServices = {
  fetchListOfAuts,
  fetchAppTypes,
  getAutDetailsById,
  saveAut,
  updateAut,
  deleteAut,
  getAutExecutionsList,
};
