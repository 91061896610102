import { useState } from "react";
import { Box, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip } from "@mui/material";
import AddToPhotosOutlinedIcon from "@mui/icons-material/AddToPhotosOutlined";

import AddIcon from "@mui/icons-material/Add";

const CloneStep = ({ cloneBeforeStep, cloneAfterStep }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title="Clone step">
          <IconButton
            onClick={handleClick}
            size="small"
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}>
            <AddToPhotosOutlinedIcon color="primary" fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",

            "&::before": {
              // eslint-disable-next-line quotes
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
        <MenuItem
          sx={{ px: 1, py: 0.5, "& .MuiListItemIcon-root ": { minWidth: "26px" } }}
          onClick={() => {
            cloneBeforeStep();
            handleClose();
          }}>
          <ListItemIcon>
            <AddIcon color="primary" fontSize="small" />
          </ListItemIcon>
          <ListItemText>Clone Before</ListItemText>
        </MenuItem>
        <MenuItem
          sx={{ px: 1, py: 0.5, "& .MuiListItemIcon-root ": { minWidth: "26px" } }}
          onClick={() => {
            cloneAfterStep();
            handleClose();
          }}>
          <ListItemIcon>
            <AddIcon color="primary" fontSize="small" />
          </ListItemIcon>
          <ListItemText>Clone After</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default CloneStep;
