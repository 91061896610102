import Card from "@mui/material/Card";
import { Box, Tooltip, ClickAwayListener } from "@mui/material/";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
// import SuccessImage from "../../images/success.gif";
import { useState } from "react";

export default function GenerateKey(props) {
  const { generatedkey } = props;
  const [open, setOpen] = useState(false);
  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
    navigator.clipboard.writeText(generatedkey);
  };

  return (
    <Box width="100%">
      <Card className="genKeyMain" elevation={0}>
        <CardContent>
          <Box>
            <Box>{/* <img src={SuccessImage} width="150px" alt={"Success"} /> */}</Box>
            <Typography variant="h6">Key has been generated successfully</Typography>
            <Typography className="code">{generatedkey}</Typography>
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <div>
                <Tooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  onClose={handleTooltipClose}
                  open={open}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title="Key Copied"
                  arrow>
                  <Button onClick={handleTooltipOpen} variant="outlined" sx={{ mx: "auto", mt: "16px" }}>
                    Copy
                  </Button>
                </Tooltip>
              </div>
            </ClickAwayListener>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}
