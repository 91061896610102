import { Box, Typography } from "@mui/material";
import SelectAutType from "../SelectAutType";
import InnerHeader from "../Layout/InnerHeader";
import { useNavigate, useSearchParams } from "react-router-dom";
// import { useSelector } from "react-redux";
import CustomBackButton from "../CustomComponents/CustomButtons/CustomBackButton";
import CustomButton from "../CustomComponents/CustomButtons/CustomButton";
import ExecutionConfigList from "./ExecutionConfigList";
import AddIcon from "@mui/icons-material/Add";
import { useCheckAccess } from "../../CustomHooks/useCheckAccess";

const TestConfigListHeader = ({ executeTestCaseList = [] }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { checkAccess } = useCheckAccess();
  const autType = searchParams.get("autType");
  const autName = searchParams.get("autName");
  const autId = searchParams.get("autId");
  // const { aut_details: autDetails } = useSelector((state) => state.testcase);

  const handleBack = () => navigate(`/?appType=${autType}`);

  const isExecutionMode = executeTestCaseList.length > 0;
  const isAutSelected = autType && autId;

  const handleCreateConfig = () => {
    navigate(`create?autType=${autType}&autName=${autName}&autId=${autId}`);
  };

  return (
    <InnerHeader>
      {isAutSelected ? (
        <Box className="v-center" width="100%">
          <Box className="v-center" gap={1}>
            <CustomBackButton onClick={handleBack}>Back</CustomBackButton>
            <Typography variant="h6">EXECUTION CONFIGS</Typography>
            <Box
              sx={{
                backgroundColor: "#fff",
                display: "flex",
                width: "200px",
                height: "32px",
                padding: "0px 16px",
                alignItems: "center",
                gap: "8px",
                flexShrink: 0,
                borderRadius: "6px",
                border: "1px solid rgba(255, 255, 255, 0.45)",
                backdropFilter: "blur(1.36px)",
                color: "#495057",
              }}>
              <Typography>
                Aut Type: <strong>{autType}</strong>
              </Typography>
            </Box>
            <Box
              sx={{
                backgroundColor: "#fff",
                display: "flex",
                width: "200px",
                height: "32px",
                padding: "0px 16px",
                alignItems: "center",
                gap: "8px",
                flexShrink: 0,
                borderRadius: "6px",
                border: "1px solid rgba(255, 255, 255, 0.45)",
                backdropFilter: "blur(1.36px)",
                color: "#495057",
              }}>
              <Typography>
                Aut Name: <strong>{autName}</strong>
              </Typography>
            </Box>
          </Box>
          {!isExecutionMode && checkAccess("configs", "Create") && (
            <Box className="v-center" sx={{ ml: "auto", gap: 2 }}>
              <CustomButton
                onClick={handleCreateConfig}
                className="no-shadow"
                color="success"
                size="small"
                variant="contained">
                <AddIcon fontSize="small" sx={{ marginRight: "8px" }} />
                Create Test Config
              </CustomButton>
            </Box>
          )}
        </Box>
      ) : (
        <Typography variant="h6">EXECUTION CONFIGS</Typography>
      )}
    </InnerHeader>
  );
};

const ExecutionConfigs = () => {
  const [searchParams] = useSearchParams();
  const autType = searchParams.get("autType");
  const autName = searchParams.get("autName");
  // const autId = searchParams.get("autId");
  return (
    <>
      <TestConfigListHeader />
      {autType && autName ? (
        <Box className="pt74" sx={{ width: "100%" }}>
          <ExecutionConfigList />
        </Box>
      ) : (
        <Box className="pt74" sx={{ width: "100%" }}>
          <SelectAutType />
        </Box>
      )}
    </>
  );
};

export default ExecutionConfigs;
