import { Avatar, AvatarGroup, Box, Divider, IconButton, Tooltip, Typography } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import { setSelectedStep, setTestStepsData } from "../../../../../Redux/reducers/testcaseSlice";
import { useDispatch, useSelector } from "react-redux";
import CloneStep from "./CloneStep";
import AddNewStep from "./AddNewStep";
import { useSearchParams } from "react-router-dom";
import { ELEMENT_LOCATOR_BACKGROUND_COLORS } from "../../../../../utilities/defaultData";

const RenderDetailRow = ({ label, value, isComponent = false }) => (
  <Box display="flex" alignItems="flex-start" py={1}>
    <Typography sx={{ minWidth: "150px", color: "#666666", fontSize: "12px", fontWeight: 600 }}>{label} :</Typography>
    {isComponent ? (
      value
    ) : (
      <Typography sx={{ color: "#666666", fontSize: "12px", fontWeight: 400, wordBreak: "break-all" }}>
        {`${value}`}
      </Typography>
    )}
  </Box>
);

const PreviewStep = ({ selectedStepData }) => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const { test_steps_data: stepsData } = useSelector((state) => state.testcase);
  const { step_action_data: stepActionData } = useSelector((state) => state.testcase);
  const displayName = stepActionData.find((each) => each?.actionCode === selectedStepData?.action)?.displayName;
  const handleEditStep = () => {
    setSearchParams((prev) => {
      const newState = new URLSearchParams(prev);
      newState.set("stepViewType", "add");
      return newState;
    });
    dispatch(setSelectedStep(selectedStepData));
  };

  const handleDeleteStep = () => {
    const filteredData = stepsData.filter((step) => selectedStepData.sequenceNumber !== step.sequenceNumber);
    dispatch(setTestStepsData(filteredData));
    searchParams.delete("stepViewType");
    setSearchParams(searchParams);
    dispatch(setSelectedStep(null));
  };

  const cloneBeforeStep = () => {
    const newStep = {
      ...selectedStepData,
      name: `${selectedStepData?.name} (Cloned)`,
      sequenceNumber: selectedStepData?.sequenceNumber - 1 || 1,
      id: null,
    };
    const newStepsData = [
      ...stepsData.slice(0, selectedStepData.sequenceNumber - 1),
      newStep,
      ...stepsData.slice(selectedStepData.sequenceNumber - 1),
    ];
    dispatch(setTestStepsData(newStepsData));
    setSearchParams((prev) => {
      const newState = new URLSearchParams(prev);
      newState.set("stepViewType", "add");
      return newState;
    });
    dispatch(setSelectedStep(newStep));
  };

  const cloneAfterStep = () => {
    const newStep = {
      ...selectedStepData,
      name: `${selectedStepData?.name} (Cloned)`,
      sequenceNumber: selectedStepData?.sequenceNumber + 1,
      id: null,
    };
    const newStepsData = [
      ...stepsData.slice(0, selectedStepData.sequenceNumber),
      newStep,
      ...stepsData.slice(selectedStepData.sequenceNumber),
    ];
    dispatch(setTestStepsData(newStepsData));
    setSearchParams((prev) => {
      const newState = new URLSearchParams(prev);
      newState.set("stepViewType", "add");
      return newState;
    });
    dispatch(setSelectedStep(newStep));
  };

  const addBeforeStep = () => {
    const newStep = { id: "newStep", sequenceNumber: selectedStepData?.sequenceNumber - 1 || 1 };
    const newStepsData = [
      ...stepsData.slice(0, selectedStepData.sequenceNumber - 1),
      newStep,
      ...stepsData.slice(selectedStepData.sequenceNumber - 1),
    ];
    dispatch(setTestStepsData(newStepsData));
    setSearchParams((prev) => {
      const newState = new URLSearchParams(prev);
      newState.set("stepViewType", "add");
      return newState;
    });
    dispatch(setSelectedStep(newStep));
  };

  const addAfterStep = () => {
    const newStep = { id: "newStep", sequenceNumber: selectedStepData?.sequenceNumber + 1 };

    const newStepsData = [
      ...stepsData.slice(0, selectedStepData.sequenceNumber),
      newStep,
      ...stepsData.slice(selectedStepData.sequenceNumber),
    ];
    dispatch(setTestStepsData(newStepsData));
    setSearchParams((prev) => {
      const newState = new URLSearchParams(prev);
      newState.set("stepViewType", "add");
      return newState;
    });
    dispatch(setSelectedStep(newStep));
  };

  const selectedKeys = selectedStepData?.elementLocator
    ? Object.keys(selectedStepData?.elementLocator).filter((key) => selectedStepData?.elementLocator[key])
    : [];

  return (
    <Box>
      <Box className="v-center" sx={{ background: "#F4F1FF", px: 1, height: 42 }}>
        <Typography variant="h6">Preview</Typography>
      </Box>
      <Box className="space-between" px={1.5} py={1}>
        <Typography sx={{ background: "#E68200", borderRadius: "4px", color: "#fff", p: 0.5, fontWeight: 600 }}>
          Step - {selectedStepData?.sequenceNumber}
        </Typography>
        <Box className="v-center">
          <CloneStep cloneAfterStep={cloneAfterStep} cloneBeforeStep={cloneBeforeStep} />

          <IconButton color="primary" onClick={handleEditStep} size="small">
            <EditNoteOutlinedIcon fontSize="small" />
          </IconButton>
          <IconButton color="primary" onClick={handleDeleteStep} size="small">
            <DeleteOutlineOutlinedIcon fontSize="small" />
          </IconButton>
          <AddNewStep addAfterStep={addAfterStep} addBeforeStep={addBeforeStep} />
        </Box>
      </Box>

      <Box sx={{ px: 1.5 }}>
        <RenderDetailRow label="Name" value={selectedStepData?.name} />
        <Divider />
        <RenderDetailRow label="Description" value={selectedStepData?.description} />
        <Divider />
        <RenderDetailRow
          label="UI Element"
          value={
            <AvatarGroup sx={{ "& .MuiAvatar-circular": { width: 20, height: 20, fontSize: "10px" } }}>
              {selectedKeys.map((key, i) => (
                <Tooltip arrow placement="top" title={key} key={key}>
                  <Avatar sx={{ bgcolor: ELEMENT_LOCATOR_BACKGROUND_COLORS[i], cursor: "pointer", color: "#495057" }}>
                    {key.slice(0, 2)}
                  </Avatar>
                </Tooltip>
              ))}
            </AvatarGroup>
          }
          isComponent
        />
        <Divider />
        <RenderDetailRow label="Action" value={displayName} />
        <Divider />
        <RenderDetailRow label="Screenshot" value={selectedStepData?.screenshot} />
        <Divider />
        {/* <RenderDetailRow label="Consecutive Threshold" value={selectedStepData?.consecutiveThreshold} />
        <Divider /> */}
        {/* <RenderDetailRow label="Retry Duration" value={selectedStepData?.retryDuration} />
        <Divider /> */}
        <RenderDetailRow label="Disable" value={selectedStepData?.isDisable} />
      </Box>
    </Box>
  );
};

export default PreviewStep;
