import * as React from "react";
// import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
// import Typography from "@mui/material/Typography";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import Slide from "@mui/material/Slide";
import { Box, Tooltip } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FullScreenDialog = ({ open, setOpen, children }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      sx={{ "& .MuiDialog-paperFullScreen": { color: "inherit" } }}
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}>
      <AppBar className="relative">
        <IconButton sx={{ float: "right", width: 40, ml: "auto" }} color="inherit" onClick={handleClose}>
          <Tooltip title="Minimize">
            {" "}
            <CloseFullscreenIcon />
          </Tooltip>
        </IconButton>
      </AppBar>
      <Box sx={{ p: 2 }}>{children}</Box>
    </Dialog>
  );
};

export default FullScreenDialog;
