import { Button } from "@mui/material";

const CustomOutlinedButton = ({ label, color, props }) => {
  return (
    <Button
      variant="outlined"
      size="small"
      sx={{
        ...props,
        paddingX: "6px",
        fontSize: "12px",
        height: 20,
        width: "fit-content",
        minWidth: 18,
        maxWidth: 50,
        borderColor: color,
        color: color,
        "&:hover": {
          borderColor: color,
          color: color,
          backgroundColor: "transparent",
        },
      }}>
      {label}
    </Button>
  );
};

export default CustomOutlinedButton;
