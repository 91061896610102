import { useState, useRef, useCallback } from "react";
import dragImg from "../../images/Drag_Drop.png";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { Box, Grid, Button, CircularProgress, Typography, Divider } from "@mui/material";
import FileDownloadDoneOutlinedIcon from "@mui/icons-material/FileDownloadDoneOutlined";
import { fileStorageServices } from "../../Redux/services/fileStorage.services";
import { showToast } from "../../Redux/reducers/toastSlice";
import { useDispatch } from "react-redux";

const AutFileUpload = ({ onSuccessUploadFile, autType, executableFilePath }) => {
  const dispatch = useDispatch();
  const fileInputRef = useRef();
  const [fileName, setFileName] = useState(null);
  const [file, setFile] = useState(null);
  const [isUploadButtonDisabled, setIsUploadButtonDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [hasFileError, setHasFileError] = useState(false);
  const [isFileSuccess, setIsFileSuccess] = useState(false);
  const [borderStyle, setBorderStyle] = useState({ border: "1px dashed #096eb6" });

  const androidAllowedTypes = [
    "application/vnd.android.package-archive",
    "zip",
    "app",
    ".apk",
    "application/x-zip-compressed",
    "application/zip",
  ];

  const iosAllowedTypes = [
    "ipa",
    "zip",
    "app",
    "application/x-zip-compressed",
    "application/x-itunes-ipa",
    "application/zip",
  ];

  const allowedTypes = (type) => {
    switch (type) {
      case "ANDROID":
        return ".apk";
      case "IOS":
        return ".ipa, .zip, .app";
      default:
        return ".xlsx, .xls, .csv, .txt, .json, .xml";
    }
  };

  const handleFileChange = useCallback(
    (e) => {
      const selectedFile = e?.target?.files[0];
      if (!selectedFile) {
        return;
      }
      const fileType = selectedFile.type || selectedFile.name.split(".").pop();
      const isInvalidFileType =
        (autType === "ANDROID" && !androidAllowedTypes.includes(selectedFile.type)) ||
        (autType === "IOS" && !iosAllowedTypes.includes(fileType));
      if (isInvalidFileType) {
        setHasFileError(true);
        setIsUploadButtonDisabled(true);
        return;
      }
      setFile(selectedFile);
      setFileName(selectedFile.name);
      setIsFileSuccess(true);
      setHasFileError(false);
      setIsUploadButtonDisabled(false);
    },
    [autType, iosAllowedTypes],
  );

  const handleFileDrop = useCallback(
    (e) => {
      e.preventDefault();
      if (isLoading) {
        return;
      }

      setBorderStyle({ border: "1px dashed #096eb6" });
      handleFileChange(e);
    },
    [isLoading, handleFileChange],
  );

  const handleFileDragOver = useCallback((e) => {
    e.preventDefault();
    setBorderStyle({ border: "1px solid #1976d2" });
  }, []);

  const handleFileDragEnter = useCallback((e) => {
    e.preventDefault();
    setBorderStyle({ border: "1px solid #1976d2" });
  }, []);

  const handleFileDragLeave = useCallback((e) => {
    e.preventDefault();
    setBorderStyle({ border: "1px dashed #096eb6" });
  }, []);

  const uploadFilesToServer = useCallback(async () => {
    setIsLoading(true);
    setIsUploadButtonDisabled(true);

    if (file) {
      const formData = new FormData();
      formData.append("file", file);

      try {
        const res = await fileStorageServices.fileUploadToStorage(formData);
        const fileRes = {
          fileName: res.data?.fileName,
          filePath: res.data.filePath,
          originalFileName: res.data.originalFileName,
          fileSizeInKb: res.data.fileSizeInKB,
          fileProvider: res.data.fileProvider,
          status: "VALID",
          module: "AUT",
        };

        // setExecutableFilePath(res.data.FilePath);
        const serverRes = await fileStorageServices.uploadFileResponseToServer([fileRes]);
        dispatch(showToast({ message: `${file.name} is uploaded successfully`, type: "success" }));

        onSuccessUploadFile(serverRes.data[0].id, serverRes.data[0]?.originalFileName);
      } catch (error) {
        dispatch(showToast({ message: error?.response?.data?.message || error.message, type: "error" }));
      } finally {
        fileInputRef.current.value = null;
        setIsLoading(false);
        setIsUploadButtonDisabled(true);
      }
    }
  }, [file]);

  return (
    <Box
      style={borderStyle}
      sx={{ p: 1.25, mb: 1 }}
      onDrop={handleFileDrop}
      onDragOver={handleFileDragOver}
      onDragEnter={handleFileDragEnter}
      onDragLeave={handleFileDragLeave}>
      <Grid container spacing={2} alignItems="center">
        <Grid item sx={{ m: "auto" }} xs={12} md={12} className="fileUpGrid1">
          <Grid container>
            <Grid item xs={7}>
              <Box
                className="fileTextbox"
                onClick={() => (isLoading ? "" : fileInputRef.current.click())}
                title={executableFilePath || fileName || "Choose a file"}>
                {executableFilePath || fileName || "Choose a file"}
                {isFileSuccess && <FileDownloadDoneOutlinedIcon className="sucIcon" />}
              </Box>
              {hasFileError && (
                <Typography className="errorText">
                  <ErrorOutlineOutlinedIcon />
                  Allowed file types are {allowedTypes(autType)}
                </Typography>
              )}
            </Grid>
            <Grid item xs={2.5}>
              <Button
                fullWidth
                size="small"
                sx={{ borderRadius: 0 }}
                onClick={() => fileInputRef.current.click()}
                variant="outlined"
                disabled={isLoading}>
                Browse
              </Button>
              <input
                ref={fileInputRef}
                onChange={handleFileChange}
                type="file"
                style={{ display: "none" }}
                accept={allowedTypes(autType)}
              />
            </Grid>
            <Grid item xs={2.5} sx={{ px: 0.5, borderRadius: 0 }}>
              <Button
                disabled={isUploadButtonDisabled}
                size="small"
                fullWidth
                variant="contained"
                onClick={uploadFilesToServer}>
                {isLoading ? <CircularProgress size={20} /> : "Upload"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider sx={{ my: 1 }} />
      <Box sx={{ textAlign: "center" }}>
        <Typography sx={{ m: "auto" }}>
          <img alt="loading.." src={dragImg} width="200px" />
        </Typography>
      </Box>
    </Box>
  );
};

export default AutFileUpload;
