import axios from "axios";
import { ApiServerUrlV1, ApiServerUrlV2, fileStorageUrl } from "../../constants";
function arrayBufferToBase64(buffer) {
  let binary = "";
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

// Function to get file from storage and convert to base64
const getFileFromStorage = async (reportNames) => {
  try {
    const response = await axios.post(
      `${fileStorageUrl}/v1/DownloadReport`,
      { reportNames: reportNames },
      { responseType: "arraybuffer" },
    );

    const base64String = arrayBufferToBase64(response.data);
    const base64Image = `data:image/png;base64,${base64String}`;
    return base64Image;
  } catch (error) {
    console.error("Error fetching file from storage:", error);
    throw error;
  }
};
const fileUploadToStorage = (fileContent) => {
  return axios.post(`${fileStorageUrl}/UploadFile`, fileContent);
};

const uploadFileResponseToServer = (uploadedFile) => {
  return axios.post(`${ApiServerUrlV1}/file-manager/upload`, uploadedFile);
};

const getUploadedFiles = (moduleName) => {
  return axios.get(`${ApiServerUrlV1}/file-manager?moduleName=${moduleName}`);
};

const deleteFiles = (fileIds) => {
  return axios.delete(`${ApiServerUrlV2}/file-manager`, { data: fileIds });
};

export const fileStorageServices = {
  getFileFromStorage,
  fileUploadToStorage,
  uploadFileResponseToServer,
  getUploadedFiles,
  deleteFiles,
};
