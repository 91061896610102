import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement } from "chart.js";
import { Box, Divider, Grid, Paper, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { testcaseServices } from "../../../Redux/services/testcase.services";
import AUTsLineChart from "./AUTsLineChart";
import { useSearchParams } from "react-router-dom";

// Register required components
ChartJS.register(ArcElement);
const statusColors = {
  Good: "#4CAF50",
  Avg: "#9747FF",
  Poor: "#F9594B",
  Passed: "#4CAF50",
  Failed: "#F9594B",
  "Not Executed": "#61C5F6",
};

const transformApiResponse = (apiResponse) => {
  const autTypeScoreList = apiResponse.autTypeScoreList || [];
  const autTypeTestcaseExecutionsCountList = apiResponse.autTypeTestcaseExecutionsCountList || [];
  const autTypeTeststepExecutionsCountList = apiResponse.autTypeTeststepExecutionsCountList || [];

  const totalAUTs = autTypeScoreList.reduce((total, autType) => {
    return total + autType.goodAutList.length + autType.avgAutList.length + autType.poorAutList.length;
  }, 0);

  const qualityCountsAUT = {
    Good: {
      total: autTypeScoreList.reduce((total, autType) => total + autType.goodAutList.length, 0),
      percentage: totalAUTs
        ? `${Math.round((autTypeScoreList.reduce((total, autType) => total + autType.goodAutList.length, 0) / totalAUTs) * 100)}%`
        : "0%",
      applications: autTypeScoreList.reduce((apps, autType) => {
        apps[autType.autType] = (apps[autType?.autType] || 0) + autType.goodAutList.length;
        return apps;
      }, {}),
    },
    Avg: {
      total: autTypeScoreList.reduce((total, autType) => total + autType.avgAutList.length, 0),
      percentage: totalAUTs
        ? `${Math.round((autTypeScoreList.reduce((total, autType) => total + autType.avgAutList.length, 0) / totalAUTs) * 100)}%`
        : "0%",
      applications: autTypeScoreList.reduce((apps, autType) => {
        apps[autType.autType] = (apps[autType.autType] || 0) + autType.avgAutList.length;
        return apps;
      }, {}),
    },
    Poor: {
      total: autTypeScoreList.reduce((total, autType) => total + autType.poorAutList.length, 0),
      percentage: totalAUTs
        ? `${Math.round((autTypeScoreList.reduce((total, autType) => total + autType.poorAutList.length, 0) / totalAUTs) * 100)}%`
        : "0%",
      applications: autTypeScoreList.reduce((apps, autType) => {
        apps[autType.autType] = (apps[autType.autType] || 0) + autType.poorAutList.length;
        return apps;
      }, {}),
    },
  };

  const qualityCountsTestCases = autTypeTestcaseExecutionsCountList.reduce(
    (counts, autType) => {
      counts.Passed.total += autType.passCount;
      counts.Failed.total += autType.failCount;
      counts["Not Executed"].total += autType.otherCount;
      counts.Passed.applications[autType.autType] = autType?.passCount || 0;
      counts.Failed.applications[autType.autType] = autType?.failCount || 0;
      counts["Not Executed"].applications[autType.autType] = autType?.otherCount || 0;
      return counts;
    },
    {
      Passed: { total: 0, percentage: "0%", applications: {} },
      Failed: { total: 0, percentage: "0%", applications: {} },
      "Not Executed": { total: 0, percentage: "0%", applications: {} },
    },
  );

  const totalTestCases =
    qualityCountsTestCases.Passed.total +
    qualityCountsTestCases.Failed.total +
    qualityCountsTestCases["Not Executed"]?.total;

  qualityCountsTestCases.Passed.percentage = totalTestCases
    ? `${Math.round((qualityCountsTestCases?.Passed?.total / totalTestCases) * 100)}%`
    : "0%";
  qualityCountsTestCases.Failed.percentage = totalTestCases
    ? `${Math.round((qualityCountsTestCases?.Failed?.total / totalTestCases) * 100)}%`
    : "0%";
  qualityCountsTestCases["Not Executed"].percentage = totalTestCases
    ? `${Math.round((qualityCountsTestCases["Not Executed"].total / totalTestCases) * 100)}%`
    : "0%";
  const qualityCountsTestSteps = autTypeTeststepExecutionsCountList.reduce(
    (counts, autType) => {
      counts.Passed.total += autType.passCount;
      counts.Failed.total += autType.failCount;
      counts["Not Executed"].total += autType.otherCount;
      counts.Passed.applications[autType.autType] =
        (counts.Passed.applications[autType.autType] || 0) + autType.passCount;
      counts.Failed.applications[autType.autType] =
        (counts.Failed.applications[autType.autType] || 0) + autType.failCount;
      counts["Not Executed"].applications[autType.autType] =
        (counts["Not Executed"].applications[autType.autType] || 0) + autType.otherCount;
      return counts;
    },
    {
      Passed: { total: 0, percentage: "0%", applications: {} },
      Failed: { total: 0, percentage: "0%", applications: {} },
      "Not Executed": { total: 0, percentage: "0%", applications: {} },
    },
  );

  const totalTestSteps =
    qualityCountsTestSteps.Passed.total +
    qualityCountsTestSteps.Failed.total +
    qualityCountsTestSteps["Not Executed"].total;

  qualityCountsTestSteps.Passed.percentage = totalTestSteps
    ? `${Math.round((qualityCountsTestSteps.Passed.total / totalTestSteps) * 100)}%`
    : "0%";
  qualityCountsTestSteps.Failed.percentage = totalTestSteps
    ? `${Math.round((qualityCountsTestSteps.Failed.total / totalTestSteps) * 100)}%`
    : "0%";
  qualityCountsTestSteps["Not Executed"].percentage = totalTestSteps
    ? `${Math.round((qualityCountsTestSteps["Not Executed"].total / totalTestSteps) * 100)}%`
    : "0%";

  return {
    autTypeScoreList: {
      total: totalAUTs,
      title: "AUT'S",
      quality_counts: qualityCountsAUT,
    },
    autTypeTestcaseExecutionsCountList: {
      total: totalTestCases,
      title: "Test Cases",
      quality_counts: qualityCountsTestCases,
    },
    autTypeTeststepExecutionsCountList: {
      total: totalTestSteps,
      title: "Test Steps",
      quality_counts: qualityCountsTestSteps,
    },
  };
};

const AUTCard = ({ transformedData, dataKey, autType }) => {
  const selectedAUT = autType === "All";

  if (!transformedData) {
    return null;
  }

  const chartData = {
    labels: Object.keys(transformedData?.[dataKey]?.quality_counts),
    datasets: [
      {
        data: Object.values(transformedData?.[dataKey]?.quality_counts).map((item) => item.total),
        backgroundColor: Object.keys(transformedData?.[dataKey]?.quality_counts).map((key) => statusColors[key]),
        hoverBackgroundColor: Object.keys(transformedData?.[dataKey]?.quality_counts).map((key) => statusColors[key]),
        borderWidth: 1.5,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: { legend: { display: false }, tooltip: { enabled: false }, datalabels: { display: false } },
    cutout: "80%",
  };

  return (
    <Grid container component={Paper} elevation={2} sx={{ borderRadius: "8px", color: "#64748B" }} p={1}>
      <Grid item xs={12}>
        <Typography variant="h6" textAlign="center">
          {transformedData?.[dataKey]?.title === "AUT'S"
            ? "Applications Under Test"
            : transformedData?.[dataKey]?.title}{" "}
          {selectedAUT ? (
            ""
          ) : (
            <div style={{ display: "inline-flex", alignItems: "center" }}>
              -
              <span style={{ display: "inline-flex", alignItems: "center" }}>
                <img
                  src={`./applicationTypes/${autType?.toLowerCase()}.svg`}
                  alt={autType}
                  width={16}
                  height={16}
                  style={{ marginRight: 4, marginLeft: 4 }}
                />
                {autType}
              </span>
            </div>
          )}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container mt={1}>
          <Grid item xs={12} sm={5} className="relative center">
            <Box sx={{ width: "100%", height: 0, paddingBottom: "100%", position: "relative" }}>
              <Box className="absolute" sx={{ top: 0, left: 0, right: 0, bottom: 0, p: "4px" }}>
                <Doughnut data={chartData} options={chartOptions} />
              </Box>
            </Box>
            <Box className="absolute center column">
              <Typography variant="h5">{transformedData?.[dataKey]?.total}</Typography>
              <Typography variant="body2">{transformedData?.[dataKey]?.title}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={7} pl={1}>
            {transformedData?.[dataKey]?.quality_counts &&
              Object.entries(transformedData?.[dataKey]?.quality_counts).map(([status, details]) => (
                <Box key={status} className="v-center">
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    sx={{ backgroundColor: statusColors[status], width: "5px", height: "46px", mr: 1 }}
                  />
                  {selectedAUT ? (
                    <Box>
                      <Typography variant="bold" color={statusColors[status]}>
                        {status} - {details?.total} ({details?.percentage})
                      </Typography>
                      <Box className="flex" flexWrap="wrap">
                        {Object.entries(details?.applications).map(([app, count], index, array) => {
                          const applications = Array.from(
                            { length: count },
                            (_, i) => `App_${String(i + 1).padStart(2, "0")}`,
                          );
                          return (
                            <Box key={app} className="v-center">
                              <Tooltip
                                disableHoverListener={transformedData?.[dataKey]?.title !== "AUT'S"}
                                title={
                                  <React.Fragment>
                                    <Typography variant="body2">Application List:</Typography>
                                    {applications.map((appName, index) => (
                                      <div key={index}>{appName}</div>
                                    ))}
                                  </React.Fragment>
                                }
                                placement="top">
                                <Box alignItems="center" sx={{ cursor: "pointer" }}>
                                  <img
                                    src={`./applicationTypes/${app?.toLowerCase()}.svg`}
                                    alt={app}
                                    width={14}
                                    height={14}
                                  />
                                  <Typography variant="body2">{count < 10 ? `0${count}` : count}</Typography>
                                </Box>
                              </Tooltip>
                              {index < array.length - 1 && (
                                <Divider orientation="vertical" variant="middle" flexItem sx={{ mx: "12px" }} />
                              )}
                            </Box>
                          );
                        })}
                      </Box>
                    </Box>
                  ) : (
                    <Typography variant="bold" color={statusColors[status]}>
                      {status} - {details?.total} ({details?.percentage})
                    </Typography>
                  )}
                </Box>
              ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const AUTDashboard = () => {
  const [searchParams] = useSearchParams();
  const autType = searchParams.get("autType") || "All";
  const [dashBoardDetails, setDashBoardDetails] = useState({});

  const getDashBoardDeatils = async () => {
    try {
      const { data } = await testcaseServices.getDashBoardDetails();
      setDashBoardDetails(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDashBoardDeatils();
  }, []);

  const filterDataByAUTType = (data) => {
    if (autType === "All") {
      return data;
    }

    const filteredData = { ...data };

    filteredData.autTypeScoreList = filteredData.autTypeScoreList?.filter((item) => item.autType === autType);

    filteredData.autTypeTestcaseExecutionsCountList = filteredData.autTypeTestcaseExecutionsCountList?.filter(
      (item) => item.autType === autType,
    );

    filteredData.autTypeTeststepExecutionsCountList = filteredData.autTypeTeststepExecutionsCountList?.filter(
      (item) => item.autType === autType,
    );

    return filteredData;
  };
  const filteredDashBoardDetails = filterDataByAUTType(dashBoardDetails);
  const transformedData = transformApiResponse(filteredDashBoardDetails);

  return (
    <Grid container spacing={2}>
      {Object.keys(filteredDashBoardDetails).map((key, i) => (
        <Grid item sm={4} key={`${key}/${i}`}>
          <AUTCard transformedData={transformedData} dataKey={key} autType={autType} />
        </Grid>
      ))}
      <Grid item sm={12}>
        <AUTsLineChart />
      </Grid>
    </Grid>
  );
};

export default AUTDashboard;
