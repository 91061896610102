import { useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { LoadingButton } from "@mui/lab";
import CustomAutoComplete from "../../CustomComponents/CustomAutoComplete";
import KiTAPServer from "../../../services/KiTAPServer";
import { CustomAgGrid } from "../../AgGrid";
import SkeletonLoader from "../../SkeletonLoader";
import DevicesList from "../../Applications/AndroidAUT/DevicesList";
import Autocomplete from "@mui/material/Autocomplete";
import { showToast } from "../../../Redux/reducers/toastSlice";
import { useDispatch } from "react-redux";

const CICDTestForm = ({ defaultData, handleClose }) => {
  const dispatch = useDispatch();
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const gridRef = useRef();

  const [loadingStates, setLoadingStates] = useState({
    autTypes: false,
    autList: false,
    testCases: false,
    testData: false,
    submit: false,
  });

  const [autList, setAutList] = useState([]);
  const [testCasesList, setTestCasesList] = useState([]);
  const [selectedTestCase, setSelectedTestCase] = useState("");
  const [selectedAut, setSelectedAut] = useState("");
  const [testData, setTestData] = useState([]);
  const [customHeadCells, setCustomHeadCells] = useState([]);
  const [selectedBrowsers, setSelectedBrowsers] = useState(["Chrome"]);
  const [pipeLinesData, setPipeLinesData] = useState([]);
  const [autType, setAutType] = useState("");
  const [selectedDevicesList, setSelectedDevicesList] = useState([]);
  const [selectedDeviceProvider, setSelectedDeviceProvider] = useState(false);
  const [providerList] = useState(["KITAP", "SAUCE_LABS", "BROWSER_STACK"]);
  const [selectedTestData, setSelectedTestData] = useState(
    defaultData?.executionRequest?.testCaseExecutionRequestList[0]?.testDataIdList || [],
  );
  const [dropdownDefaultValues, setDropDownDefaultValues] = useState({
    aut: null,
    testCase: null,
  });
  const [loadingEdit, setLoadingEdit] = useState(false);

  const handleDeviceProvider = (data) => {
    setSelectedDeviceProvider(data?.target?.innerText);
  };
  const onSubmitCreateCICDTest = async (data) => {
    setLoadingStates((prev) => ({ ...prev, submit: true }));

    let envList;
    if (autType === "WEB" || autType === "SALESFORCE") {
      envList =
        selectedBrowsers?.length &&
        selectedBrowsers?.map((el) => {
          return {
            browser: {
              browserName: el ? el : null,
              browserVersion: el?.version ? el?.version : null,
              browserOs: null,
              browserProvider: null,
            },
          };
        });
    } else if (autType === "ANDROID" || autType === "IOS") {
      envList =
        selectedDevicesList?.length &&
        selectedDevicesList?.map((el) => {
          return {
            device: {
              deviceName: el?.deviceName ? el?.deviceName : null,
              deviceId: el?.deviceId ? el?.deviceId : null,
              deviceProvider: el?.environmentProvider ? el?.environmentProvider : "KITAP",
            },
          };
        });
    }
    const exeObj = {
      id: null,
      name: null,
      autId: selectedAut?.id,
      environmentList: envList,
      parallelExecution: data?.browserParallelExecution,
      testCaseExecutionRequestList: [
        {
          testCaseId: selectedTestCase?.id,
          testDataIdList: selectedTestData,
          dataParallelExecution: data?.dataParallelExecution,
        },
      ],
    };
    const formData = {
      ...(defaultData?.id && { id: defaultData.id }),
      name: data?.title,
      description: data?.description,
      executionRequest: exeObj,
    };

    const createOrUpdate = defaultData?.id ? "updateCICDPipeLine" : "createPileLine";

    try {
      const { data } = await KiTAPServer[createOrUpdate](formData);

      dispatch(showToast({ message: "CICD created", type: "success" }));

      handleClose(data);
    } catch (error) {
      dispatch(showToast({ message: error?.response?.data?.message || error.message, type: "error" }));
    } finally {
      setLoadingStates((prev) => ({ ...prev, submit: false }));
    }
  };

  const getTestCasesList = async (autId) => {
    setLoadingStates((prev) => ({ ...prev, testCases: true }));
    try {
      const { data } = await KiTAPServer.fetchTestCases(autId);
      setTestCasesList(data?.testCaseResponseDtoList);
      setLoadingStates((prev) => ({ ...prev, testCases: false }));
      return data;
    } catch (error) {
      console.error("Error fetching test cases list:", error);
      setLoadingStates((prev) => ({ ...prev, testCases: false }));
    }
    return "";
  };

  const getTestDataById = async (testCaseId) => {
    setLoadingStates((prev) => ({ ...prev, testData: true }));
    try {
      const { data } = await KiTAPServer.getTestData(testCaseId);
      setTestData(data);
      const keys = Object.keys(data?.testDataResponseList?.[0]?.data);
      const createdHeadCells = keys?.map((step) => ({
        field: step,
        headerName: step,
        sortable: true,
        suppressColumnsToolPanel: true,
        filter: true,
        editable: false,
        minWidth: 100,
        headerTooltip: `${step}`,
      }));
      setCustomHeadCells([{ checkboxSelection: true }, ...createdHeadCells]);
    } catch (error) {
      console.error("Error fetching test data by ID:", error);
    } finally {
      setLoadingStates((prev) => ({ ...prev, testData: false }));
    }
  };

  const onChangeAut = (newValue) => {
    setTestData([]);
    setTestCasesList([]);
    setValue("testCase", null);
    getTestCasesList(newValue?.id);
    setAutType(newValue?.type);
    setSelectedAut(newValue);
  };

  const onChangeTestCase = (newValue) => {
    getTestDataById(newValue?.id);
    setSelectedTestCase(newValue);
  };

  const checkUniqueName = (value) => {
    const valuetrim = value?.trim();
    if (valuetrim === "") {
      return "PipeLine name can not be empty";
    } else if (defaultData?.name?.toLowerCase() === valuetrim?.toLowerCase()) {
      return true;
    }
    let result;
    if (pipeLinesData?.length) {
      result = pipeLinesData.find((element) => element?.name?.trim().toLowerCase() === valuetrim?.toLowerCase());
    }
    if (result === undefined) {
      return true;
    }
    return "PipeLine name already existed";
  };

  const getAllBrowsers = (data) => {
    if (Object.keys(data)?.length) {
      const browsers = [];
      if (data?.executionRequest?.environmentList?.length > 0) {
        data.executionRequest.environmentList.forEach((ele) => {
          if (ele?.browser?.browserName) {
            browsers.push(ele?.browser?.browserName);
          }
        });
      }
      setSelectedBrowsers(browsers);
    }
  };
  const handleCheckboxChange = (e) => {
    if (selectedBrowsers?.includes(e.target.name)) {
      setSelectedBrowsers((prevState) => prevState.filter((each) => each !== e.target.name));
    } else {
      setSelectedBrowsers((prevState) => [...prevState, e.target.name]);
    }
  };
  const getListOfAuts = async () => {
    setLoadingStates((prev) => ({ ...prev, autList: true }));
    try {
      const { data } = await KiTAPServer.getListOfAutAllAuts();
      setAutList(data);
      setLoadingStates((prev) => ({ ...prev, autList: false }));
      return data;
    } catch {
      setLoadingStates((prev) => ({ ...prev, autList: false }));
    }
    return "";
  };
  const fechEditData = async () => {
    setLoadingEdit(true);

    try {
      const listOfTypes = await getListOfAuts();
      const { data } = await KiTAPServer.getTestCaseById(
        defaultData?.executionRequest?.testCaseExecutionRequestList[0]?.testCaseId,
      );
      const selectedAut = listOfTypes?.find((each) => each?.id === data?.autDetails?.id);

      const data2 = await getTestCasesList(selectedAut?.id);
      const selctedTestcase = data2?.testCaseResponseDtoList?.find(
        (each) => each?.id === defaultData?.executionRequest?.testCaseExecutionRequestList[0]?.testCaseId,
      );
      setSelectedAut(selectedAut);
      setSelectedTestCase(selctedTestcase);
      setDropDownDefaultValues({
        aut: selectedAut,
        testCase: selctedTestcase,
      });
      if (defaultData?.executionRequest?.environmentList?.length) {
        const browsers = [];
        defaultData?.executionRequest?.environmentList.forEach((each) => {
          if (each?.browser?.browserName) {
            browsers.push(each?.browser?.browserName);
          }
        });
        setSelectedBrowsers(browsers);
        const devices = [];
        defaultData?.executionRequest?.environmentList.forEach((each) => {
          if (each?.device?.deviceName) {
            devices.push(each?.device);
          }
        });
        setSelectedDevicesList(devices);
        if (devices?.length) {
          setSelectedDeviceProvider(devices[0]?.deviceProvider);
        }
      }
      setAutType(selectedAut?.type);

      await getTestDataById(selctedTestcase?.id);
    } finally {
      setLoadingEdit(false);
    }
  };

  useEffect(async () => {
    getAllBrowsers(defaultData);
    const { data } = await KiTAPServer.getCICDPipeLineList();
    setPipeLinesData(data?.pipelineResponseList || []);
    if (defaultData?.executionRequest?.testCaseExecutionRequestList[0]?.testCaseId) {
      fechEditData();
    }
  }, []);

  if (loadingEdit || (defaultData?.id && autList?.length === 0)) {
    return <SkeletonLoader />;
  }
  return (
    <Box component="form" noValidate autoComplete="off">
      <Paper className="" sx={{ p: "8px" }}>
        <Grid item container spacing={2}>
          <Grid sm={6} item>
            <TextField
              fullWidth
              size="small"
              name="title"
              label="CICD Pipeline Name"
              required
              variant="outlined"
              defaultValue={defaultData?.name}
              {...register("title", {
                required: true,
                minLength: {
                  value: 4,
                  message: "Minimum length 4 characters",
                },
                validate: {
                  validate: (value) => checkUniqueName(value) || "error message",
                },
              })}
              helperText={errors?.title?.message}
              error={Boolean(errors?.title)}
            />
          </Grid>
          <Grid sm={6} item>
            <TextField
              fullWidth
              size="small"
              name="description"
              label="Description"
              variant="outlined"
              defaultValue={defaultData?.description}
              {...register("description", {
                minLength: {
                  value: 4,
                  message: "Minimum length 4 characters",
                },
              })}
              helperText={errors?.description?.message}
              error={Boolean(errors?.description)}
            />
          </Grid>
          <Grid sm={6} item>
            <Controller
              name="aut"
              control={control}
              defaultValue={dropdownDefaultValues?.aut}
              render={({ field }) => (
                <CustomAutoComplete
                  {...field}
                  value={field.value}
                  errors={errors}
                  label="Select AUT"
                  getOptionLabel={(option) => (option?.name ? `${option?.name} (${option?.type})` : "")}
                  options={autList}
                  onOpen={getListOfAuts}
                  loading={loadingStates.autList}
                  onChange={(e, value) => {
                    field.onChange(value);
                    onChangeAut(value);
                  }}
                  required={true}
                  register={register}
                />
              )}
            />
          </Grid>
          <Grid sm={6} item>
            <Controller
              name="testCase"
              control={control}
              defaultValue={dropdownDefaultValues?.testCase}
              render={({ field }) => (
                <CustomAutoComplete
                  {...field}
                  value={field.value}
                  errors={errors}
                  label="Select Test Case"
                  getOptionLabel={(option) => (option?.name ? option?.name : "")}
                  options={testCasesList}
                  loading={loadingStates.testCases}
                  onChange={(e, value) => {
                    field.onChange(value);
                    onChangeTestCase(value);
                  }}
                  required={true}
                  register={register}
                />
              )}
            />
          </Grid>
          <Grid className="exePopBody" sm={12} item>
            <FormGroup display="flex" width="100%">
              {autType !== "ANDROID" && autType !== "IOS" && (
                <>
                  <FormControlLabel
                    label="Firefox"
                    control={
                      <Checkbox
                        name="Firefox"
                        onChange={handleCheckboxChange}
                        defaultChecked={selectedBrowsers?.includes("Firefox")}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Chrome"
                    control={
                      <Checkbox
                        name="Chrome"
                        onChange={handleCheckboxChange}
                        defaultChecked={selectedBrowsers?.includes("Chrome")}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Safari"
                    control={
                      <Checkbox
                        name="Safari"
                        onChange={handleCheckboxChange}
                        defaultChecked={selectedBrowsers?.includes("Safari")}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Edge"
                    control={
                      <Checkbox
                        name="Edge"
                        onChange={handleCheckboxChange}
                        defaultChecked={selectedBrowsers?.includes("Edge")}
                      />
                    }
                  />
                </>
              )}
              <FormControlLabel
                control={
                  <Switch
                    defaultChecked={defaultData?.executionRequest?.parallelExecution}
                    name="browserParallelExecution"
                    {...register("browserParallelExecution")}
                  />
                }
                label="Parallel Execution"
              />
              <FormControlLabel
                control={
                  <Switch
                    defaultChecked={
                      defaultData?.executionRequest?.testCaseExecutionRequestList[0]?.dataParallelExecution
                    }
                    name="dataParallelExecution"
                    {...register("dataParallelExecution")}
                  />
                }
                label="Datasets Parallel Execution"
              />
              {(autType === "ANDROID" || autType === "IOS") && (
                <>
                  <Grid align="center" ml={"auto"} sm={3}>
                    <Autocomplete
                      size="small"
                      fullWidth
                      disablePortal
                      id="combo-box-demo"
                      options={providerList}
                      value={selectedDeviceProvider ? selectedDeviceProvider : ""}
                      onChange={handleDeviceProvider}
                      renderInput={(params) => <TextField {...params} label="Select Environment" />}
                    />
                  </Grid>
                  <Grid align="center" ml={"auto"} sm={3}>
                    <DevicesList
                      setSelectedDevicesList={setSelectedDevicesList}
                      selectedDevicesList={selectedDevicesList}
                      selectedDeviceProvider={selectedDeviceProvider}
                      typeOfAut={autType}
                    />
                  </Grid>
                </>
              )}
            </FormGroup>
          </Grid>
          <Grid sm={12} item>
            {testData?.testDataResponseList?.length > 0 && (
              <CustomAgGrid
                setSelected={setSelectedTestData}
                gridRef={gridRef}
                headCells={customHeadCells}
                rows={testData?.testDataResponseList.map((each) => ({
                  ...each?.data,
                  id: each?.dbId,
                }))}
                defaultSelectedRows={
                  defaultData?.executionRequest?.testCaseExecutionRequestList[0]?.testDataIdList || []
                }
              />
            )}
          </Grid>
        </Grid>
      </Paper>
      <Box mt="8px">
        <Grid container>
          <Grid item xs={4} textAlign="left">
            <Button variant="contained" onClick={() => handleClose(false)} color="error" size="small">
              Cancel
            </Button>
          </Grid>
          <Grid item xs={4} textAlign="center"></Grid>
          <Grid item xs={4} textAlign="right">
            <LoadingButton
              disabled={
                !(
                  (selectedBrowsers?.length && (selectedAut?.type === "WEB" || selectedAut?.type === "SALESFORCE")) ||
                  (selectedDevicesList?.length && (selectedAut?.type === "ANDROID" || selectedAut?.type === "IOS"))
                )
              }
              onClick={handleSubmit(onSubmitCreateCICDTest)}
              variant="contained"
              className="loader-button"
              size="small"
              loading={loadingStates.submit}
              loadingPosition="start"
              startIcon={<></>}>
              {defaultData?.id ? "Update" : "Save"}
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

const CreateCICD = ({ defaultData = {}, handleClose }) => {
  return (
    <Dialog fullWidth={true} maxWidth={"lg"} open={true} onClose={() => handleClose(false)}>
      <DialogTitle>
        <Typography className="customHead" variant="h6">
          CICD Test
        </Typography>
      </DialogTitle>
      <DialogContent>
        <CICDTestForm defaultData={defaultData} handleClose={handleClose} />
      </DialogContent>
    </Dialog>
  );
};

export default CreateCICD;
