import { useCallback, useState } from "react";
import { useDrop, useDrag } from "react-dnd";
import { TableRow, TableCell, Checkbox, Box, Typography, IconButton, CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import DragIndicatorOutlinedIcon from "@mui/icons-material/DragIndicatorOutlined";
import { Folder, KeyboardArrowRightOutlined, KeyboardArrowDownOutlined } from "@mui/icons-material";
import { setSelectedStepsData, setSelectedStep, updateTestStepsData } from "../../../../Redux/reducers/testcaseSlice";
import { macrosServices } from "../../../../Redux/services/macros.services";

const ItemTypes = {
  STEP: "STEP",
  MACRO: "MACRO",
};

const highlightedStyle = {
  borderCollapse: "collapse",
  boxShadow: "0 0 10px #DCD4FB",
  border: "1px solid #DCD4FB",
  backgroundColor: "#fff",
  transform: "scale(1.00022)",
  transition: "transform 0.2s, box-shadow 0.2s",
  elevation: "above",
  "& td": {
    padding: "0px 10px",
    borderLeft: "none !important",
  },
};

const RenderNewStep = () => (
  <TableRow>
    <TableCell sx={{ textAlign: "center", ...highlightedStyle }} height={40} colSpan={12}>
      <Typography sx={{ opacity: 0.7 }}>Creating Step...</Typography>
    </TableCell>
  </TableRow>
);

const TestStepRow = ({ index, moveRow, row }) => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const stepViewType = searchParams.get("stepViewType");
  const { selected_steps_data: selectedStepsData, selected_step: selectedStep } = useSelector(
    (state) => state.testcase,
  );

  const [open, setOpen] = useState(false);
  const [loadingSteps, setLoadingSteps] = useState(false);
  const isCreatingOrEditing = stepViewType !== "add";
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.STEP,
    item: { type: ItemTypes.STEP, row, index },
    canDrag: isCreatingOrEditing && !selectedStepsData?.length,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: [ItemTypes.STEP, ItemTypes.MACRO],
    hover: (item) => {
      if (item.type === ItemTypes.STEP && item.index !== index) {
        moveRow(item.index, index);
        item.index = index;
      }
      if (item.type === ItemTypes.MACRO) {
        item.hoverIndex = index;
      }
    },
    drop: (item) => {
      if (item.type === ItemTypes.MACRO) {
        item.index = index;
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const handleCheckboxChange = useCallback(
    (event) => {
      const isChecked = event.target.checked;
      const newSelectedSteps = isChecked
        ? [...selectedStepsData, row]
        : selectedStepsData.filter((step) => step.sequenceNumber !== row.sequenceNumber);
      dispatch(setSelectedStepsData(newSelectedSteps));
    },
    [dispatch, selectedStepsData, row],
  );

  const handleRowClick = useCallback(() => {
    if (isCreatingOrEditing) {
      setSearchParams((prev) => {
        const newState = new URLSearchParams(prev);
        newState.set("stepViewType", "preview");
        return newState;
      });
      dispatch(setSelectedStep(row));
    }
  }, [dispatch, isCreatingOrEditing, row, setSearchParams]);

  const handleDoubleClick = useCallback(() => {
    if (isCreatingOrEditing) {
      setSearchParams((prev) => {
        const newState = new URLSearchParams(prev);
        newState.set("stepViewType", "add");
        return newState;
      });
      dispatch(setSelectedStep(row));
    }
  }, [dispatch, isCreatingOrEditing, row, setSearchParams]);

  const toggleRow = async (e) => {
    e.stopPropagation();
    setOpen(!open);
    if (!row?.steps?.length) {
      setLoadingSteps(true);
      try {
        const { data } = await macrosServices.getMacroById(row?.id, row?.version);
        dispatch(updateTestStepsData({ ...row, steps: data?.testStepResponseList }));
        setSearchParams((prev) => {
          const newState = new URLSearchParams(prev);
          newState.set("stepViewType", "preview");
          return newState;
        });
      } catch (error) {
        console.log(error);
      }
      setLoadingSteps(false);
    }
  };

  const opacity = isDragging ? 0 : 1;
  const isSelected = selectedStepsData.some((step) => step.sequenceNumber === row.sequenceNumber);
  const isRowSelected = selectedStep?.sequenceNumber === row?.sequenceNumber;

  return row?.isNewStep ? (
    <RenderNewStep />
  ) : (
    <>
      <TableRow
        sx={{
          ...(isRowSelected ? highlightedStyle : {}),
          cursor: "pointer",
          ...(!isRowSelected && {
            "&:hover": {
              backgroundColor: "#E7E9EB",
              transform: "scale(1.00022)",
              transition: "transform 0.2s, box-shadow 0.2s",
            },
          }),
          opacity,
          backgroundColor: isOver && canDrop ? "lightyellow" : "inherit",
        }}
        ref={isCreatingOrEditing ? (node) => drag(drop(node)) : null}
        onClick={handleRowClick}
        onDoubleClick={handleDoubleClick}
        selected={isSelected}>
        <TableCell onClick={(e) => e.stopPropagation()} align="left">
          <Checkbox disabled={!isCreatingOrEditing} checked={isSelected} onChange={handleCheckboxChange} />
        </TableCell>
        <TableCell>
          <Box className="v-center">
            <DragIndicatorOutlinedIcon fontSize="small" color="primary" /> {row.sequenceNumber}
          </Box>
        </TableCell>
        <TableCell align="left" sx={{ width: "35%" }}>
          {row.steps ? (
            <Box sx={{ display: "flex", alignItems: "flex-start" }}>
              <IconButton size="small" onClick={toggleRow}>
                {open ? <KeyboardArrowDownOutlined color="primary" /> : <KeyboardArrowRightOutlined color="primary" />}
              </IconButton>
              <Box>
                <Box className="v-center">
                  <Folder fontSize="small" sx={{ color: "#EAEAEA" }} />
                  {row.name}
                </Box>
                <Typography sx={{ fontSize: "10px" }}>{row?.description}</Typography>
              </Box>
            </Box>
          ) : (
            row.name
          )}
        </TableCell>
        <TableCell align="left" sx={{ width: "50%" }}>
          {row.description}
        </TableCell>
      </TableRow>
      {open && (
        <>
          {loadingSteps ? (
            <TableRow sx={{ minHeight: 42 }}>
              <TableCell>
                <Checkbox sx={{ visibility: "hidden" }} />
              </TableCell>
              <TableCell></TableCell>
              <TableCell>
                <CircularProgress size={20} />
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          ) : (
            row.steps?.map((each, i) => (
              <TableRow key={i} sx={{ minHeight: 42 }}>
                <TableCell>
                  <Checkbox sx={{ visibility: "hidden" }} />
                </TableCell>
                <TableCell></TableCell>
                <TableCell align="left" sx={{ width: "35%" }}>
                  {each.name}
                </TableCell>
                <TableCell align="left" sx={{ width: "50%" }}>
                  {each.description}
                </TableCell>
              </TableRow>
            ))
          )}
        </>
      )}
    </>
  );
};

export default TestStepRow;
