import { createSlice } from "@reduxjs/toolkit";
import { decryptData } from "../../helpers/encryptionUtils";

const encryptedUser = localStorage.getItem("kitapUser");
const userData = encryptedUser ? decryptData(JSON.parse(encryptedUser)) : {};

const initialState = { token: userData?.Token || null, user_details: userData };

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserDetails(state, action) {
      state.token = action.payload?.Token || null;
      state.user_details = action.payload;
    },
  },
});

export const { setUserDetails } = authSlice.actions;
export default authSlice.reducer;
