import { Box, Typography } from "@mui/material";
import Desktop from "../../images/desktop.png";
import RadioButtonCheckedRoundedIcon from "@mui/icons-material/RadioButtonCheckedRounded";
import { useTranslation } from "react-i18next";

export default function AgentTile(props) {
  const { onClickAgent, agent } = props;
  return (
    <Box
      className="AgentSingle"
      onClick={() => {
        onClickAgent(agent);
      }}>
      {agent?.isOnline ? (
        <Box className="Aonline">
          <RadioButtonCheckedRoundedIcon />
          Online{" "}
        </Box>
      ) : (
        <Box className="Aonline Aoffline">
          <RadioButtonCheckedRoundedIcon /> Offline{" "}
        </Box>
      )}
      <Box className="AgentInfo scrollbar">
        <Typography>
          Name: <Box component="span">{agent?.name}</Box>
        </Typography>
        <Typography>
          IP Address: <Box component="span">{agent?.ipAddress}</Box>
        </Typography>
        <Typography>
          Registered Date: <Box component="span">{agent?.createdAt}</Box>
        </Typography>
      </Box>
      <img src={Desktop} alt={"Desktop"} />
    </Box>
  );
}

export function NoAgentTile() {
  const { t } = useTranslation();
  return (
    <Box className="AgentSingle">
      <Box className="AgentInfo noAgents scrollbar">
        <Typography>{t("text_generate_a_key_from_kitap_server_for_agent_registration")}</Typography>
      </Box>
      <img src={Desktop} alt={"Desktop"} />
    </Box>
  );
}

export function NoEngineTile() {
  const { t } = useTranslation();
  return (
    <Box className="AgentSingle">
      <Box className="AgentInfo noAgents scrollbar">
        <Typography>{t("text_provide_list_of_test_execution_engine_nodes_in_properties_file")}</Typography>
      </Box>
      <img src={Desktop} alt={"Desktop"} />
    </Box>
  );
}
