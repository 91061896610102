import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloneTestCase from "./CloneTestCase";

const styles = {
  noMargin: { m: "0px !important", p: 0 },
  accordion: {
    "& .Mui-expanded": { minHeight: "40px !important", m: 0 },
    "&:before": { display: "none" },
    border: "none",
    borderRadius: "12px !important",
    paddingRight: "5px",
    boxShadow: "none !important",
    color: "#64748B",
  },
  accordionSummary: {
    border: "none",
    p: 0,
    m: "0px !important",
    "& .MuiPaper-root": { m: "0px !important" },
    "& .MuiAccordionSummary-root": { minHeight: "40px" },
    "& .MuiAccordionSummary-content": { m: 0, py: 0, px: 1 },
    "& .MuiAccordion-root": { p: 0, m: "0px !important" },
    "& .Mui-expanded": { pt: 0.5, alignItems: "center", m: "0px !important", p: 0, "& svg": { width: "auto" } },
  },
};

const TestCaseFormInputs = ({ formInputs, handleChange, errors }) => {
  const [searchParams] = useSearchParams();
  const testCaseId = searchParams.get("testCaseId");
  const autType = searchParams.get("autType");
  const autName = searchParams.get("autName");
  const [isExpanded, setIsExpanded] = useState(true);

  const handleAccordionChange = (e) => {
    e.stopPropagation();
    setIsExpanded((prev) => !prev);
  };

  return (
    <Box className="container">
      <Accordion sx={styles.accordion} expanded={isExpanded}>
        <AccordionSummary
          sx={styles.accordionSummary}
          expandIcon={
            <IconButton disabled={!testCaseId} onClick={handleAccordionChange}>
              <ExpandMoreIcon />
            </IconButton>
          }>
          <Box className="space-between" sx={{ pl: 1.5, width: "100%" }}>
            <Box className="v-center" sx={{ gap: 2 }}>
              <Typography>
                <strong>AUT Type</strong>:{" "}
                <Typography component="span" color="primary">
                  {autType}
                </Typography>
              </Typography>
              <Typography>
                <strong>AUT Name</strong>:{" "}
                <Typography component="span" color="primary">
                  {autName}
                </Typography>
              </Typography>
            </Box>
            {!testCaseId && <CloneTestCase />}
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={styles.noMargin}>
          <Paper sx={{ padding: 2, borderRadius: "0px 0px 12px 12px" }}>
            <Grid container spacing={2} alignItems="flex-start">
              <Grid item xs={6}>
                <Grid container spacing={1} direction="column">
                  <Grid item>
                    <TextField
                      name="name"
                      value={formInputs.name || ""}
                      onChange={handleChange}
                      required
                      label="Test Case Name"
                      size="small"
                      fullWidth
                      error={Boolean(errors.name)}
                      helperText={errors.name}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      name="requirementReference"
                      value={formInputs.requirementReference || ""}
                      onChange={handleChange}
                      label="Required Reference Id"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="description"
                  value={formInputs.description || ""}
                  onChange={handleChange}
                  label="Description"
                  size="small"
                  fullWidth
                  multiline
                  rows={3.5}
                  error={Boolean(errors.description)}
                  helperText={errors.description}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl size="small" fullWidth error={Boolean(errors.businessImportance)}>
                  <InputLabel required>Business Criticality</InputLabel>
                  <Select
                    name="businessImportance"
                    value={formInputs.businessImportance}
                    onChange={handleChange}
                    label="Business Criticality"
                    placeholder="Business Criticality"
                    size="small"
                    fullWidth
                    required>
                    <MenuItem value="MINIMAL">Minimal</MenuItem>
                    <MenuItem value="LOW">Low</MenuItem>
                    <MenuItem value="MODERATE">Moderate</MenuItem>
                    <MenuItem value="HIGH">High</MenuItem>
                    <MenuItem value="CRITICAL">Critical</MenuItem>
                    <MenuItem value="BLOCKER">Blocker</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  name="errorThreshold"
                  value={formInputs.errorThreshold || ""}
                  onChange={handleChange}
                  label="Error Threshold"
                  size="small"
                  type="number"
                  required
                  fullWidth
                  error={Boolean(errors.errorThreshold)}
                  helperText={errors.errorThreshold}
                  inputProps={{ min: 1, max: 100 }}
                />
              </Grid>
              <Grid item xs={3}>
                <Box className="v-center" sx={{ float: "right", color: "#64748B" }}>
                  <Typography>Maximum number of failed steps allowed.</Typography>
                  <IconButton size="small">
                    <Tooltip
                      // title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                      arrow>
                      <InfoOutlinedIcon />
                    </Tooltip>
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default TestCaseFormInputs;
