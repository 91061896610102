import { Divider, Typography, Box, IconButton, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { useSearchParams } from "react-router-dom";
import CustomTriangleIcon from "../../CustomComponents/Icons/CustomTriangleIcon";
import { useCheckAccess } from "../../../CustomHooks/useCheckAccess";

const ApplicationTile = ({ applicationDetails, onClickAppTile, onClickAddAut }) => {
  const [searchParams] = useSearchParams();
  const appType = searchParams.get("appType");
  const { noOfAuts, type, disable } = applicationDetails;
  const { checkAccess } = useCheckAccess();
  const isSelected = type === appType;
  return (
    <Box position="relative">
      <Box
        className={`applicationTile ${disable || checkAccess(type, "Hide") ? "disabledapplicationTile" : ""} ${
          isSelected ? "selectedApp" : ""
        }`}
        onClick={() => (!disable && checkAccess(type, "Preview") ? onClickAppTile(applicationDetails) : null)}>
        <Box height="100%" width="49%">
          <Box className="center" height="50%">
            <img src={`./applicationTypes/${type?.toLowerCase()}.svg`} alt={type} />
          </Box>

          <Box className="center column " height="50%">
            <Typography className="type" textAlign="center">
              {type}
            </Typography>
          </Box>
        </Box>
        <Divider orientation="vertical" flexItem sx={{ borderColor: isSelected ? "#66A2EE" : "DDD" }} />
        <Box height="100%" width="49%">
          <Box className="center column" height="50%">
            <Typography textAlign="center" variant="h5">
              {noOfAuts}
            </Typography>
            <Typography textAlign="center" sx={{ fontSize: "0.62rem" }}>
              {"Applications"}
            </Typography>
          </Box>
          <Box height="50%" className="center">
            <IconButton
              disabled={disable || !checkAccess(type, "Create")}
              className="addApplication"
              sx={{ "&:hover": { cursor: "pointer" }, mx: "auto" }}
              onClick={(e) => {
                e.stopPropagation();
                onClickAddAut(applicationDetails);
              }}>
              <Tooltip title="Add">
                <AddIcon />
              </Tooltip>
            </IconButton>
          </Box>
        </Box>
      </Box>
      {isSelected && (
        <Box
          sx={{
            position: "absolute",
            // bottom: "-0.86rem",
            left: "50%",
            transform: "translateX(-50%)",
          }}>
          <CustomTriangleIcon />
        </Box>
      )}
    </Box>
  );
};

export default ApplicationTile;
