import { useCallback, useRef, useState } from "react";
import { Box, Divider, IconButton, Tooltip } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";

// import SearchIcon from "@mui/icons-material/Search";
// import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import PlayCircleFilledWhiteOutlinedIcon from "@mui/icons-material/PlayCircleFilledWhiteOutlined";
// import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
// import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
// import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import CustomLoadingButton from "../CustomComponents/CustomButtons/CustomLoadingButton";
import CustomButton from "../CustomComponents/CustomButtons/CustomButton";

import { CustomDeleteIcon, CustomEditIcon } from "../CustomComponents/Icons/IconButtons";
import KiTAPServer from "../../services/KiTAPServer";
import { saveAs } from "file-saver";
import { useCheckAccess } from "../../CustomHooks/useCheckAccess";
import { showToast } from "../../Redux/reducers/toastSlice";
import { useDispatch } from "react-redux";

const TestCaseListActionsToolbar = ({
  selectedTestCases,
  gridRef,
  onClickDelete,
  onClickEditTestCase,
  executeTestCase,
  // previewTestCase,
  viewExecutionReports,
  handleFileChange,
  fetchTestCases,
}) => {
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const filter = JSON.parse(searchParams.get("filter")) || false;
  const { checkAccess } = useCheckAccess();
  const canEdit = selectedTestCases?.length === 1;
  const canDelete = selectedTestCases?.length > 0;
  const canClone = selectedTestCases?.length === 1;
  const canExport = selectedTestCases?.length === 1;
  // const canImport = selectedTestCases?.length === 1;
  const [loadingExport, setLoadingExport] = useState(false);
  const [loadingImport, setLoadingImport] = useState(false);

  const clearFilters = useCallback(() => {
    gridRef.current?.api.setFilterModel(null);
  }, [gridRef]);

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleExportTestCase = async (testCaseData) => {
    setLoadingExport(true);
    try {
      const { data } = await KiTAPServer.exportTestCase(testCaseData);
      if (data) {
        const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        saveAs(blob, `${testCaseData?.name}.xlsx`);
      } else {
        console.error("No data received in response");
      }
    } catch (error) {
      dispatch(showToast({ message: error?.response?.data?.message || error.message, type: "error" }));
    }
    setLoadingExport(false);
  };

  const handleCloneTestCase = async (testCaseData) => {
    setLoadingImport(true);
    try {
      await KiTAPServer.getCloneTestCase(testCaseData);

      fetchTestCases();
      if (gridRef.current && gridRef.current.api) {
        gridRef.current.api.deselectAll();
      }
    } catch (error) {
      dispatch(showToast({ message: error?.response?.data?.message || error.message, type: "error" }));
    }
    setLoadingImport(false);
  };

  return (
    <Box
      className="v-center"
      sx={{ borderRadius: "12px 12px 0 0", backgroundColor: "#fff", height: "48px", boxSizing: "border-box" }}>
      <Box className="center" width={50}>
        <Tooltip title="clear filter" placement="top" arrow>
          <IconButton onClick={clearFilters} size="small" disabled={!filter}>
            <FilterAltOutlinedIcon />
          </IconButton>
        </Tooltip>
      </Box>

      <Divider orientation="vertical" flexItem />

      <Box className="v-center" pl="4px">
        {checkAccess("testCases", "Edit") && (
          <CustomEditIcon
            onClick={() => onClickEditTestCase(selectedTestCases[0])}
            size="small"
            disabled={!canEdit}
            height="20"
            width="18"
          />
        )}
        {checkAccess("testCases", "Preview") && (
          <Tooltip title="Reports" placement="top" arrow>
            <IconButton
              onClick={() => viewExecutionReports(selectedTestCases[0])}
              size="small"
              disabled={!canEdit}
              sx={{ color: "#611EB6" }}>
              <AssessmentOutlinedIcon />
            </IconButton>
          </Tooltip>
        )}
        {checkAccess("testCases", "Execute") && (
          <Tooltip title="Execute" placement="top" arrow>
            <IconButton
              onClick={() => executeTestCase(selectedTestCases[0])}
              size="small"
              disabled={!canDelete}
              sx={{ color: "#611EB6" }}>
              <PlayCircleFilledWhiteOutlinedIcon />
            </IconButton>
          </Tooltip>
        )}
        {/* {checkAccess("testCases", "Preview") && (
          <Tooltip title="Preview" placement="top" arrow>
            <IconButton
              onClick={() => previewTestCase(selectedTestCases[0])}
              size="small"
              sx={{ color: "#611EB6" }}
              disabled={!canEdit}>
              <RemoveRedEyeOutlinedIcon />
            </IconButton>
          </Tooltip>
        )} */}
        {checkAccess("testCases", "Clone") && (
          <Tooltip title="Clone" placement="top" arrow>
            <IconButton
              onClick={() => handleCloneTestCase(selectedTestCases[0])}
              size="small"
              disabled={!canClone}
              sx={{ color: "#611EB6" }}>
              <FileCopyOutlinedIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
        {/* <Tooltip title="Import" placement="top" arrow>
          <IconButton
            onClick={() => exportTestCase(selectedTestCases[0])}
            size="small"
            sx={{ color: "#611EB6" }}
            disabled={!canExport}>
            <FileDownloadOutlinedIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Export" placement="top" arrow>
          <IconButton size="small" sx={{ color: "#611EB6" }} component="label">
            <FileUploadOutlinedIcon />
            <input
              type="file"
              accept=".xlsx"
              hidden
              onChange={(event) => {
                handleFileChange(event);
              }}
            />
          </IconButton>
        </Tooltip> */}
        {checkAccess("testCases", "Delete") && (
          <CustomDeleteIcon
            onClick={onClickDelete}
            size="small"
            color="error"
            disabled={!canDelete}
            height="20"
            width="20"
          />
        )}
      </Box>

      <Box className="v-center" ml="auto" gap={1} px={2}>
        {/* <IconButton size="small">
          <SearchIcon />
        </IconButton> */}
        {checkAccess("testCases", "Export") && (
          <CustomLoadingButton
            loading={loadingExport}
            disabled={!canExport || loadingExport}
            onClick={() => handleExportTestCase(selectedTestCases[0])}>
            BackUp
          </CustomLoadingButton>
        )}
        {checkAccess("testCases", "Import") && (
          <CustomButton
            className="no-shadow"
            disabled={canExport || loadingImport}
            loading={loadingImport}
            onClick={handleButtonClick}
            variant="contained"
            size="small"
            color="warning">
            Restore
            <input
              type="file"
              accept=".xlsx"
              hidden
              ref={fileInputRef}
              onChange={(e) => handleFileChange(e, fileInputRef)}
            />
          </CustomButton>
        )}

        <Tooltip
          title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
          placement="top-end"
          arrow>
          <IconButton size="small">{/* <InfoOutlinedIcon /> */}</IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default TestCaseListActionsToolbar;
