import { useSearchParams } from "react-router-dom";
import CustomReactAgGrid from "../../CustomComponents/CustomReactAgGrid";
import { ExecutionsHeadCells } from "./ExecutionsHeadCells";

const ResultsListView = ({ gridRef, executions }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const lastExecutedInstanceId = searchParams.get("lastExecutedInstanceId");
  const handleClickRow = (row) => {
    setSearchParams((prev) => {
      const newState = new URLSearchParams(prev);
      newState.set("exeInstanceId", row?.id);
      return newState;
    });
  };
  const formattedExecutions = executions?.map((execution) => ({
    ...execution,
    browser: execution?.platform?.browserPlatform?.browserName || null,
    isFirstRow: Number.parseInt(execution?.id) === Number.parseInt(lastExecutedInstanceId),
  }));
  return (
    <>
      <CustomReactAgGrid
        gridRef={gridRef}
        columnDefs={ExecutionsHeadCells}
        rowData={formattedExecutions}
        onSelectionChanged={() => null}
        context={{ handleClickRow }}
        rowClassRules={{
          "highlight-first-row": (params) => params.data.isFirstRow,
        }}
      />
    </>
  );
};

export default ResultsListView;
