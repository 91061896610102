import { Box, Typography } from "@mui/material";
import { useState } from "react";
import ComputerOutlinedIcon from "@mui/icons-material/ComputerOutlined";
import VpnKeyOutlinedIcon from "@mui/icons-material/VpnKeyOutlined";
import AddIcon from "@mui/icons-material/Add";
import InnerHeader from "../Layout/InnerHeader";
import CustomLoadingButton from "../CustomComponents/CustomButtons/CustomLoadingButton";
import CustomButton from "../CustomComponents/CustomButtons/CustomButton";
import CustomBackButton from "../CustomComponents/CustomButtons/CustomBackButton";
import SkeletonLoader from "../SkeletonLoader";
import DevicesList from "./DevicesList";
import AddDeviceForm from "./DevicesList/AddDeviceForm";
import EnginesList from "./EnginesList";
import EngineRegistrationKey from "./EngineRegistrationKey";
import EngineHelpInfo from "../SelectAutType/EnginHelpInfo";

import { enginesServices } from "../../Redux/services/engines.services";
import { useCheckAccess } from "../../CustomHooks/useCheckAccess";
import { showToast } from "../../Redux/reducers/toastSlice";
import { useDispatch } from "react-redux";

const EngineManagement = () => {
  const dispatch = useDispatch();
  const [loadingKey, setLoadingKey] = useState(false);
  const [generatedResponse, setGeneratedResponse] = useState(null);
  const [devicesList, setDevicesList] = useState(null);
  const [showAddDevice, setShowAddDevice] = useState(false);
  const [loadingDevices, setLoadingDevices] = useState(false);
  const [defaultEditData, setDefaultEditData] = useState(null);
  const [engines, setEngines] = useState([]);
  const { checkAccess } = useCheckAccess();

  const fetchDevicesList = async () => {
    setLoadingDevices(true);
    try {
      const response = await enginesServices.getAllDevicesList();
      setDevicesList(response.data);
    } catch (error) {
      dispatch(showToast({ message: error?.response?.data?.message || error.message, type: "error" }));
    } finally {
      setLoadingDevices(false);
    }
  };

  const generateRegistrationKey = async () => {
    setLoadingKey(true);
    try {
      const keyResult = await enginesServices.getLocalEngineRegisterKey();
      setGeneratedResponse(keyResult?.data);
    } catch (error) {
      dispatch(showToast({ message: error?.response?.data?.message || error.message, type: "error" }));
    } finally {
      setLoadingKey(false);
    }
  };

  const backButtonHandler = () => {
    setDevicesList(null);
  };

  const manageDevicesHandler = () => {
    fetchDevicesList();
  };

  const editDeviceHandler = (data) => {
    setDefaultEditData(data);
  };

  const transformedEditDeviceData = defaultEditData?.capabilities && {
    ...defaultEditData,
    capabilities: defaultEditData.capabilities
      ? Object.entries(defaultEditData.capabilities).map(([key, value]) => ({
          key,
          value,
        }))
      : [],
  };

  return (
    <Box sx={{ width: "100%" }}>
      <InnerHeader>
        {devicesList && (
          <CustomBackButton onClick={backButtonHandler} sx={{ mr: 1 }}>
            Back
          </CustomBackButton>
        )}
        <Typography variant="h6">{devicesList ? "DEVICES LIST" : "ENGINE MANAGEMENT"}</Typography>
        <Box className="v-center" sx={{ ml: "auto", gap: 2 }}>
          {!devicesList && (
            <>
              <Typography>
                Engines Limit: <strong>{engines?.length || 0}/08</strong>
              </Typography>
              {checkAccess("engines", "ManageDevices") && (
                <CustomLoadingButton onClick={manageDevicesHandler} startIcon={<ComputerOutlinedIcon />}>
                  Manage Devices
                </CustomLoadingButton>
              )}
              {checkAccess("engines", "RequestKey") && (
                <CustomButton
                  className="no-shadow"
                  startIcon={<VpnKeyOutlinedIcon fontSize="small" />}
                  onClick={generateRegistrationKey}
                  color="success"
                  disabled={loadingKey}>
                  Request Registration Key
                </CustomButton>
              )}
            </>
          )}

          {devicesList && checkAccess("engines", "AddDevice") && (
            <CustomButton
              className="no-shadow"
              startIcon={<AddIcon />}
              disabled={showAddDevice}
              sx={{ ml: "auto" }}
              color="success"
              onClick={() => setShowAddDevice(true)}>
              Add Device
            </CustomButton>
          )}
        </Box>
      </InnerHeader>
      <Box className="pt74">
        <Box sx={{ flexGrow: 1, mr: "30%", px: 1 }}>
          {loadingDevices && <SkeletonLoader />}

          {devicesList ? (
            <DevicesList devicesList={devicesList} onClickEdit={editDeviceHandler} />
          ) : (
            <EnginesList engines={engines} setEngines={setEngines} />
          )}
        </Box>
        {showAddDevice || defaultEditData ? (
          <AddDeviceForm
            defaultEditData={transformedEditDeviceData}
            handleClose={() => (defaultEditData ? setDefaultEditData(null) : setShowAddDevice(false))}
            fetchDevicesList={fetchDevicesList}
          />
        ) : (
          <EngineHelpInfo />
        )}
        {generatedResponse && (
          <EngineRegistrationKey handleClose={() => setGeneratedResponse(null)} generatedResponse={generatedResponse} />
        )}
      </Box>
    </Box>
  );
};

export default EngineManagement;
