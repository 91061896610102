import { forwardRef, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { Box, ClickAwayListener, Tooltip, Typography } from "@mui/material";
import CustomButton from "../CustomComponents/CustomButtons/CustomButton";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EngineRegistrationKey = ({ generatedResponse, handleClose }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  const handleTooltipOpen = () => {
    setTooltipOpen(true);
    navigator.clipboard.writeText(generatedResponse?.registrationKey);
  };

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { maxWidth: "350px" } }}
      open={true}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}>
      <DialogContent>
        <Box sx={{ alignItems: "center", gap: 2 }}>
          <Typography>
            <strong> Engine Name :</strong> {generatedResponse?.engineName}
          </Typography>
          <Typography color="#E68200" sx={{ fontWeight: 600, opacity: 0.6, fontSize: 10, mt: 0.5 }}>
            {generatedResponse?.message}
          </Typography>
          <Typography
            sx={{
              backgroundColor: "#F3EEFD",
              padding: "8px 16px",
              fontWeight: 600,
              color: "#3D008F",
              borderRadius: "4px",
              wordBreak: "break-all",
              textAlign: "center",
              m: 2,
            }}>
            {generatedResponse?.registrationKey}
          </Typography>
          <Box className="space-between" sx={{ gap: 2, mt: 1, width: "100%" }}>
            <CustomButton onClick={handleClose} variant="outlined" color="error">
              Cancel
            </CustomButton>
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <Tooltip
                PopperProps={{ disablePortal: true }}
                onClose={handleTooltipClose}
                open={tooltipOpen}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title="Key Copied"
                arrow>
                <CustomButton
                  disabled={generatedResponse?.isRegistered}
                  onClick={handleTooltipOpen}
                  variant="outlined"
                  color="primary">
                  Copy
                </CustomButton>
              </Tooltip>
            </ClickAwayListener>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default EngineRegistrationKey;
