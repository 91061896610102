import { Button } from "@mui/material";
import PropTypes from "prop-types";

const CustomButton = ({
  onClick = () => null,
  children,
  size = "small",
  variant = "contained",
  startIcon = <></>,
  className = "",
  ...rest
}) => {
  return (
    <Button
      className={`custom-button  ${className}`}
      size={size}
      variant={variant}
      startIcon={startIcon}
      onClick={onClick}
      {...rest}>
      {children}
    </Button>
  );
};

CustomButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  variant: PropTypes.oneOf(["contained", "outlined", "text"]),
  startIcon: PropTypes.node,
  color: PropTypes.string,
  className: PropTypes.string,
};

export default CustomButton;
