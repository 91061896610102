export const oldStyles = () => {
  return {
    "& strong , b": {
      fontWeight: 600,
    },
    // Paper UI
    "& .MuiPaper-root": {
      boxShadow: "0 0px 12px rgb(0 0 0 / 8%)",
    },
    "& .MuiPaper-root.MuiPaper-elevation0": {
      boxShadow: "none",
    },
    // Paper UI End
    // Side Menu

    // Header End

    // svg icon css
    // "& .MuiSvgIcon-root": {
    //   fontSize: "inherit",
    // },
    "& .infoButton": {
      padding: 0,
      paddingBottom: "4px",
      marginBottom: 0,
      display: "flex",
      alignItems: "flex-end",
      "& span": {
        display: "flex",
        alignItems: "center",
      },
    },
    "& .createBtn": {
      float: "right",
      padding: "4px 0",

      "& .MuiToolbar-root": {
        backgroundColor: "transparent",
        padding: 0,
      },

      "& .MuiIconButton-root": {
        borderRadius: "4px",
        backgroundColor: "#fff",
        color: "#808b9a",
        border: "1px solid #808b9a",
        marginLeft: "4px",
        "&:hover": {
          color: "#296df5",
        },
      },
      "& .MuiButtonBase-root svg": {
        fontSize: "22px",
      },
    },
    "& .clearFilters": {
      float: "left",
      padding: "8px 0",
      "& .MuiButtonBase-root": {
        border: "1px solid #bdbdbd",
        borderRadius: "4px",
      },
    },
    // "& .ag-theme-quartz": {
    //   height: "100%",
    //   width: "100%",
    //   "& .ag-root-wrapper-body": {
    //     height: "auto !important",
    //   },
    //   "& .ag-overlay": {
    //     position: "static",
    //   },
    //   "& .ag-ltr": {
    //     "& .ag-cell": {
    //       borderRight: "1px solid rgb(189 195 199 / 50%)",
    //     },
    //   },
    //   "& .ag-header": {
    //     backgroundColor: "#7a86a1",
    //     "& .ag-header-cell": {
    //       color: "#fff !important",
    //     },
    //   },
    //   "& .ag-body-viewport": {
    //     "& .ag-header": {
    //       backgroundColor: "#ccc",
    //     },
    //   },
    //   "& .rowsDisplay": {
    //     position: "absolute",
    //     bottom: "4px",
    //     width: "80px",
    //     left: "14px",
    //     "& .MuiSelect-select": {
    //       padding: "3.5px 14px",
    //     },
    //   },
    // },
    "& .logoSec": {
      display: "flex",
      alignItems: "center",
      "& .menuBtn": {
        marginLeft: "0px",
      },
      "& .MuiBox-root": {
        marginRight: "8px",
      },
      img: {
        display: "none",
        "&.logoDark": {
          display: "block",
        },
      },
    },
    "& .MuiToolbar-root": {
      padding: "0px 16px",
    },

    "& .selectVal": {
      marginTop: 16,
      "& .selValBody": {
        padding: "8px 8px",
        "& .MuiGrid-grid-md-6": {
          padding: "0px 8px",
          "&:nth-of-type(1) , &:nth-of-type(2)": {
            border: 0,
          },
        },
      },
    },
    "& .selColVal": {
      backgroundColor: "#f6f6f6",
      padding: "4px 8px",
      borderRadius: "4px",
    },
    // Radio Button
    "& .MuiRadio-root": {
      "& svg": {
        fontSize: "18px",
      },
    },
    // Radio Button
    // Table UI
    "& table.MuiTable-root": {
      border: "1px solid rgb(189 195 199 / 50%)",
      "& tr": {
        "& th": {
          padding: "4px 10px",
          borderLeft: "1px solid rgb(189 195 199 / 50%)",
          // backgroundColor: "#e0f3ff",
        },
        "& td": {
          padding: "4px 10px",
          borderLeft: "1px solid rgb(189 195 199 / 50%)",
          "& .MuiTablePaginationUnstyled-root": {
            border: "0px",
          },
          "& .MuiIconButton-root": {
            padding: "3px",
            fontSize: "1rem",
          },
        },
        "& .MuiSvgIcon-root": {
          fontSize: "20px",
        },
      },
      "& tr.active": {
        backgroundColor: "#e5f6fd",
      },
    },
    // End Table UI

    "& .MuiAccordionSummary-root": {
      // "& .MuiAccordionSummary-content": {
      //   margin: "10px 0",
      // },
    },
    "& .validations": {
      "& th, & td": {
        borderLeft: 0,
        padding: "3px 10px",
      },
    },
    // "& .MuiAccordion-root": {
    //   "& .MuiInputBase-input": {
    //     padding: "3px 10px",
    //     fontSize: "10px",
    //   },
    //   "& .MuiInputLabel-root": {
    //     top: "-6px",
    //     fontSize: "10px",
    //   },
    //   "& .MuiFormLabel-filled , .MuiInputLabel-shrink": {
    //     top: "2px",
    //   },
    //   "& .MuiButton-contained": {
    //     padding: "3px 16px",
    //     lineHeight: "13px",
    //     fontSize: "10px",
    //   },
    //   "& .MuiSvgIcon-root": {
    //     fontSize: "1rem",
    //   },
    // },
    "& .dialogCus": {
      "& .MuiDialogActions-spacing": {
        padding: "0px 0px 15px",
        justifyContent: "center",
      },
    },
    // "& .MuiAccordion-root": {
    //   "& .expRow": {
    //     "& .MuiInputBase-input": {
    //       padding: "3px 10px",
    //       fontSize: "10px",
    //     },
    //     "& .MuiInputLabel-root": {
    //       top: "-6px",
    //       fontSize: "10px",
    //     },
    //     "& .MuiFormLabel-filled , .MuiInputLabel-shrink": {
    //       top: "2px",
    //     },
    //   },

    //   "& .MuiButton-contained.expBtn": {
    //     padding: "3px 16px",
    //     lineHeight: "13px",
    //     fontSize: "10px",
    //   },
    //   "& .MuiSvgIcon-root": {
    //     fontSize: "1rem",
    //   },
    // },

    // Ag Grid

    // "& .ag-root-wrapper": {
    //   position: "static",
    // },

    // "& .ag-checkbox-input-wrapper": {
    //   fontSize: "12px",
    //   width: "12px",
    //   height: "12px",
    //   lineHeight: "12px",
    // },

    // Ag Grid End

    "& .MuiStepConnector-line": {
      borderColor: "#0077fe",
      borderTopStyle: "dashed",
      borderTopWidth: "1.5px",
    },
    // PDF
    "& .PDF": {
      "& .MuiCard-root": {
        boxShadow: "none",
        borderRadius: 0,
      },
    },

    // PDF End

    "& .passfailBtn": {
      color: "#333",
      fontSize: "12px!important",
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      "& span": {
        "&:nth-of-type(1)": {
          color: "green",
          marginRight: 4,
        },
        "&:nth-of-type(2)": {
          color: "#d32f2f",
          marginLeft: 4,
        },
      },
    },
    "& .expRow": {
      backgroundColor: "#fff",
    },
    // Single file
    "& .fileTextbox": {
      border: " 1px solid #ccc",
      position: "relative",
      padding: "5.4px",
      cursor: "pointer",
      whiteSpace: "nowrap",
      overflow: "hidden",
      display: "block",
      textOverflow: "ellipsis",
      paddingRight: "25px",
      "& p": {
        position: "absolute",
        right: "5px",
        top: "5px",
        alignItems: "center",
        display: "flex",
      },
      "& svg.sucIcon": {
        position: "absolute",
        right: "5px",
        top: "4px",
        color: "green",
        fontSize: "20px",
      },
      "& svg.errIcon": {
        fontSize: "13px",
        ml: 0.4,
      },
    },
    "& .drawerFile": {
      "& .fileUpGrid1": {
        flexBasis: "100%",
        maxWidth: "100%",
      },
      "& .gridCusmd": {
        flexBasis: "50%",
        maxWidth: "50%",
      },
      "& .MuiGrid-container": {
        "& .MuiGrid-grid-xs-8": {
          flexBasis: "50%",
          maxWidth: "50%",
        },
        "& .MuiGrid-grid-xs-2": {
          flexBasis: "25%",
          maxWidth: "25%",
        },
      },
    },
    // Single file End

    // Checkbox
    "& .MuiCheckbox-root": {
      "& .MuiSvgIcon-root": {
        fontSize: "20px",
      },
    },
    // Checkbox End

    // Stepper
    "& .MuiStepper-horizontal": {
      "& .MuiStepLabel-iconContainer": {
        "& svg": {
          fontSize: "20px",
        },
      },
    },

    // Stepper End

    // Expanded Row
    "& td.expandableRow": {
      padding: "8px 8px !important",
      background: "#fff",
      "& .MuiTabs-root": {
        minHeight: "auto",
        marginBottom: 8,
        "& .MuiButtonBase-root": {
          padding: "5px 16px",
          minHeight: "auto",
        },
        "& .MuiTabs-indicator": {
          display: "none",
        },
        "& .MuiButtonBase-root.Mui-selected": {
          backgroundColor: "#1976d2",
          color: "#fff",
        },
      },
    },
    "& .passfailStatus": {
      border: "0.5px solid #3a7d33",
      padding: "5px",
      color: "#3a7d33",
      textAlign: "center",
    },
    // Expanded Row End

    // Data Profiling Page
    "& .DPMain": {
      "& .DPDropDown": {
        marginBottom: 16,
        border: "1px dashed #1976d2",
        padding: "16px",
        "& .MuiGrid-grid-lg-12": {
          padding: 0,
        },
      },
    },
    "& .DPFilter": {
      marginBottom: 8,
      "& .MuiButton-root": {
        marginLeft: 4,
      },
    },
    "& .DPConName": {
      display: "flex",
      alignItems: "center",
      "& img": {
        height: 40,
        marginRight: 8,
      },
    },
    "& .colName": {
      textAlign: "center",
      "& h6": {
        color: "#7D9BF9",
      },
    },

    "& .DPToolTip": {
      position: "relative",
      "& .MuiButtonBase-root": {
        position: "absolute",
        top: "0",
        right: "4px",
      },
    },
    "& .DPGraphHead": {
      padding: "8px",
      borderBottom: "1px solid #ccc",
      position: "relative",
      "& .MuiBox-root": {
        textAlign: "center",
        border: "1px solid #e0e0e0",
        backgroundColor: "#fff",
        borderRadius: "4px",
        "& h6": {
          color: "#7D9BF9",
        },
      },
      "& .MuiIconButton-root": {
        position: "absolute",
        top: "-16px",
        right: "-8px",
      },
    },

    "& .pieChart": {
      "& svg": {
        margin: "auto",
      },
      "& .dxc-labels": {
        "& g": {
          cursor: "pointer",
          "& text": {
            cursor: "pointer",
          },
        },
      },
    },

    "& .DPGraphFooter": {
      backgroundColor: "#e7ebf0",
      textAlign: "center",
      padding: "3px 4px",
      "& p": {
        fontSize: "10px",
      },
    },
    "& .DPOuptlabel": {
      backgroundColor: "#e0f3ff",
      textAlign: "center",
      padding: "8px 0px",
    },

    // Data Profiling Page End

    "& .inputNumber": {
      "& .MuiInputBase-inputSizeSmall": {
        padding: "6.7px 14px",
      },
    },

    "& .commonHead": {
      padding: "4px 16px",
      backgroundColor: "#e5f6fd",
    },
    "& .conValHead": {
      backgroundColor: "#fff",
      padding: "4px 0px",
      border: "1px solid #ccc",
      marginBottom: "16px",
      borderRadius: "4px",
      "& .MuiTypography-bold": {
        color: "#7D9BF9",
      },
    },
    "& .conValSel": {
      marginBottom: "16px",
      "& .MuiButton-root": {
        marginLeft: "8px",
      },
    },

    "& .innerSubHead": {
      borderBottom: "1px solid #eee",
      padding: "4px 16px",
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
      "& .createBtn": {
        padding: 0,
      },
      "& .MuiTypography-bold": {
        color: "#e84738",
      },
    },

    "& .innerSubRight": {
      textAlign: "right",
    },

    // Chip UI
    "& .MuiFormControl-root": {
      "& .MuiChip-root": {
        fontSize: 12,
        "& .MuiChip-label": {
          height: 20,
        },
        "& svg": {
          fontSize: 14,
        },
      },
    },

    // Chip UI End

    // Error Message
    "& .errorText": {
      color: "#d32f2f",
      display: "flex",
      alignItems: "center",
      "& svg": {
        marginRight: "4px",
      },
    },
    "& .columnName.MuiTypography-root": {
      backgroundColor: "#e0f3ff",
      padding: "3px 8px",
      marginBottom: "4px",
      marginTop: "4px",
      borderRadius: "4px",
    },
    // Error Message End

    // Button UI
    "& button.MuiButtonBase-root": {
      textTransform: "none",
    },
    // Button UI

    // Comparative Result
    "& .compDSHead": {
      textAlign: "center",
      backgroundColor: "#eee",
      borderRight: "1px solid #fff",

      "& h6": {
        backgroundColor: "#1976d2",
        color: "#fff",
        padding: "0px 8px",
      },
      "& .MuiTypography-bold": {
        color: "#7D9BF9",
      },
    },
    "& .comValMain": {
      maxHeight: "calc(100vh - 192px)",
      overflow: "auto",
    },
    "& .compValBody": {
      border: "1px solid #eee",
      padding: 8,
      "& .compDSHead": {
        textAlign: "left",
        "& p": {
          backgroundColor: "#e0f3ff",
          color: "rgba(0, 0, 0, 0.87)",
          padding: "4px 8px",
        },
      },
    },
    "& .compHead1": {
      padding: "2px 16px",
      // backgroundColor: blue[200],
      textAlign: "center",
    },
    "& .compTiles": {
      padding: "8px 0px",

      "& .tile.MuiPaper-root": {
        padding: 8,
        alignItems: "center",
        display: "flex",
        "& .MuiSvgIcon-root": {
          // color: blue[500],
          fontSize: "18px",
          opacity: 0.8,
        },
        "& .MuiTypography-root": {
          marginLeft: 8,
          paddingLeft: 8,
          borderLeft: "1px solid #ccc",
          "& span": {
            opacity: 0.5,
          },
        },
        "& .MuiIconButton-root": {
          marginLeft: "auto",
        },
        "&:hover": {
          cursor: "pointer",
          // backgroundColor: blue[50],
        },
      },
      "& .MuiPaper-root.TableOne": {
        "& .MuiSvgIcon-root": {
          color: "#5cb85c",
        },
      },
      "& .MuiPaper-root.TableTwo": {
        "&  .MuiSvgIcon-root": {
          color: "#f2b561",
        },
      },
    },
    // Comparativee Result End

    // Data Sources
    "& .DBListMain": {
      marginLeft: "-8px",
      marginRight: "-8px",
      "& .DBList": {
        "& .MuiDivider-root": {
          width: "50px",
          marginBottom: "8px",
        },
        "& .DBLeft": {
          cursor: "pointer",
          h6: {
            fontWeight: 100,
            span: {
              fontWeight: 600,
            },
          },
        },

        "& img": {
          height: "70px",
        },
      },
    },
    // "& .DSListItem": {
    //   borderRadius: "6px",
    //   border: "1px solid #FFF",
    //   background: "#FFF",
    //   boxShadow: "0px 12px 16px -4px rgba(12, 26, 36, 0.04)",
    //   padding: "8px 16px",
    //   margin: "8px",
    //   // borderRadius: "4px",
    //   fontSize: "0.875rem",
    //   position: "relative",
    //   cursor: "pointer",
    //   transition: "all 400ms ease",
    //   "&:hover": {
    //     boxShadow: "0 0.5em 0.5em -0.4em #7D9BF9",
    //     transform: "translateY(-0.25em)",
    //     backgroundColor: "#fff",
    //   },
    //   "& .DBAdd": {
    //     fontSize: "25px",
    //     cursor: "pointer",
    //     color: "#fff",
    //     textAlign: "center",
    //     backgroundColor: "#1565c0",
    //     borderRadius: "4px",
    //     position: "absolute",
    //     bottom: "15px",
    //     right: "10px",
    //     width: "25px",
    //     height: "25px",
    //     lineHeight: "25px",
    //   },
    // },
    "& .ag-theme-balham": {
      height: "100%",
      width: "100%",
      "& .ag-root-wrapper-body": {
        height: "auto !important",
      },
      "& .ag-overlay": {
        position: "static",
      },
      "& .ag-ltr": {
        "& .ag-cell": {
          borderRight: "1px solid rgb(189 195 199 / 50%)",
        },
      },
      "& .ag-header": {
        backgroundColor: "#7a86a1",
        "& .ag-header-cell": {
          color: "#fff !important",
        },
      },
      "& .ag-body-viewport": {
        "& .ag-header": {
          backgroundColor: "#ccc",
        },
      },
      "& .rowsDisplay": {
        position: "absolute",
        bottom: "4px",
        width: "80px",
        left: "14px",
        "& .MuiSelect-select": {
          padding: "3.5px 14px",
        },
      },
    },
    "& .DisabledDSListItem": {
      color: "#424242",
      border: "1px solid",
      borderColor: "#e0e0e0",
      padding: "8px 16px",
      margin: "8px",
      borderRadius: "4px",
      fontSize: "0.875rem",
      position: "relative",
      transition: "transform 0.5s ease , box-shadow 0.5s ease",
      cursor: "not-allowed",
      opacity: 0.6,
      "& .DBAdd": {
        cursor: "not-allowed",
        marginTop: "-1rem",
        "&:hover": {
          boxShadow: "none",
        },
      },
      "&:hover": {
        border: "1px solid #e0e0e0",
        boxShadow: "none",
        transform: "none",
        cursor: "not-allowed",
      },
    },
    "& .item-selected": {
      transform: "translateY(-0.25em)",
      borderRadius: "8px",
      border: "1px solid #611EB6",
      background: "linear-gradient(90deg, #D9D7FF 0%, #EBD2FF 100%)",
      boxShadow: "0px 4px 4px 0px rgba(10, 10, 10, 0.25)",
    },
    "& .filterBtns": {
      padding: "16px 0px",
      textAlign: "center",
      "& a": {
        textDecoration: "none",
      },
      "& button": {
        borderColor: "#e0e0e0",
        marginRight: 8,
        borderRadius: "20px",
        color: "inherit",
        padding: "2px 8px",
        fontSize: 12,
        "&:hover": {
          backgroundColor: "#e0f2ff",
        },
        "&.Dev": {
          backgroundColor: "rgb(205 225 247)",
          color: "#0077f7",
        },
        "&.Ftest": {
          backgroundColor: "rgb(205 208 210)",
          color: "#697179",
        },
        "&.production": {
          backgroundColor: "rgb(253, 237, 237)",
          color: "rgb(95, 33, 32)",
        },
        "&.UAT": {
          backgroundColor: "rgb(229, 246, 253)",
          color: "rgb(1, 67, 97)",
        },
        "&.synthetic": {
          backgroundColor: "rgb(255, 244, 229)",
          color: "rgb(102, 60, 0)",
        },
        "&.gold": {
          backgroundColor: "rgb(237, 247, 237)",
          color: "rgb(30, 70, 32)",
        },
      },
    },

    // Data Sources End
    ".createNewMain": {
      ".toolBox": {
        height: "calc(100vh - 130px)",
        overflow: "auto",
        backgroundColor: "#e3e5f5",
      },
      ".TBHead": {
        "h6 , p": {
          backgroundColor: "#c8ceed",
          color: "#343c6a",
          padding: "8px",
        },
        p: {
          backgroundColor: "#aeb6e1",
          button: {
            float: "right",
            marginTop: "-5px",
          },
        },
        h6: {
          lineHeight: "17px",
          small: {
            fontWeight: "normal",
          },
        },
      },
      ".TBBody": {
        height: "calc(100vh - 203px)",
        overflow: "auto",
        ".MuiBox-root": {
          backgroundColor: "#e3e5f5",
          cursor: "move",
          borderBottom: "1px solid #aeb6e1",
          ".MuiGrid-grid-sm-2 , .MuiGrid-grid-sm-10 , .MuiGrid-item": {
            padding: "12px",
            color: "#343c6a",
          },
          ".MuiGrid-grid-sm-2": {
            backgroundColor: "#c8ceed",
            textAlign: "center",
            svg: {
              fontSize: "18px",
              display: "block",
              margin: "auto",
            },
          },
          "&:hover": {
            backgroundColor: "#7D9BF9",
            ".MuiGrid-grid-sm-2 ": {
              backgroundColor: "#2386cc",
            },
            ".MuiGrid-grid-sm-2 , .MuiGrid-grid-sm-10 , .MuiGrid-item": {
              color: "#fff",
            },
          },
        },
        ".TBBtn": {
          textAlign: "center",
          border: 0,
        },
      },
      ".toolBox.propBox": {
        ".TBBody, .MuiBox-root:hover": {
          backgroundColor: "#e3e5f5",
        },
        ".MuiInputBase-root , .MuiFormLabel-root": {
          backgroundColor: "transparent",
        },
        ".MuiInputBase-root .MuiOutlinedInput-notchedOutline": {
          borderColor: "#b4b7bd",
          color: "#b4b7bd",
        },
      },
      ".dropArea": {
        ".dropHead": {
          padding: "16px 8px",
          borderBottom: "1px solid #eee",
        },
        ".dropBody": {
          padding: "8px",
          textAlign: "center",
          minHeight: "373px",
          alignItems: "center",
          ".dropPalceholder": {
            margin: "auto",
            fontSize: "15px",
            opacity: "0.4",
            padding: "12px",
            border: "1px dashed #ccc",
            marginTop: "16px",
          },
          ".droppedItem": {
            border: "1px solid rgb(84 95 111 / 22%)",
            padding: "8px",
            borderRadius: "4px",
            boxShadow: "0 0px 12px rgb(0 0 0 / 8%)",
            p: {
              fontSize: "10px",
            },
            "&:hover, &.selected": {
              border: "1px dashed #7D9BF9",
              backgroundColor: "#eeeff0",
            },
          },
        },
      },
    },

    // Create Entity

    // Create Entity End

    // Text Field UI
    // "& .MuiInputBase-root": {
    //   backgroundColor: "#fff",
    // },

    // Text Field UI End

    // Image Upload
    "& .imageComparison": {
      width: "100%",
    },
    "& .headerImg": {
      "& .MuiFormControlLabel-root": {
        marginRight: "0px",
        "& .MuiRadio-root": {
          padding: "0 4px 0",
        },
      },
    },

    "& .corCount": {
      backgroundColor: "rgb(255 0 0 / 56%)",
      border: "1px solid red",
      fontSize: "8px",
      width: "18px",
      height: "18px",
      color: "#fff",
      borderRadius: "50%",
      lineHeight: "16.5px",
      textAlign: "center",
      position: "absolute",
      top: "-18px",
    },
    "& .ignoreCordinates": {
      backgroundColor: "#eaeff3",
      minHeight: "389px",
      borderRadius: "4px",
      "& .innerSubHead": {
        borderColor: "#ccc",
        "& h6": {
          fontSize: "12px",
        },
      },
    },
    "& .cordinatesBody": {
      padding: "8px 16px",
      height: "250px",
      overflow: "auto",
      "& .cordinatesSec": {
        "& .MuiBox-root": {
          display: "flex",
          alignItems: "center",
          marginBottom: 8,
          "& .MuiCheckbox-root": {
            padding: 0,
            marginRight: 2,
          },
          "& p": {
            fontSize: "10px",
            "& span": {
              marginLeft: 6,
              "&:nth-of-type(1)": {
                marginLeft: 0,
              },
            },
          },
        },
      },
    },
    "& .imageCordinates": {
      padding: "8px 16px",
    },

    "& .imageUpload": {
      "& .kiHXWb": {
        width: "100%",
        minWidth: "auto",
        maxWidth: "100%",
        display: "block",
        textAlign: "center",
        height: "auto",
        border: 0,
        "& .fVfcRr": {
          display: "block",
          "& span": {
            display: "block",
            margin: "auto",
          },
        },
      },
      "& p": {
        color: "rgb(5 87 194 / 70%)",
        marginTop: 0,
      },
    },
    "& .filterFields": {
      marginTop: "24px",
    },
    "& .imgComOutput": {
      marginTop: "30px",
      padding: "0px 16px 16px",
      "& .imgOutput": {
        marginTop: "16px",
        height: "330px",
        backgroundColor: "#eaeff3",
        display: "flex",
        alignItems: "center",
        padding: 8,
        borderRadius: "4px",
        "& canvas": {
          width: "auto",
          height: "auto",
          maxWidth: "100%",
          maxHeight: "330px",
          margin: "auto",
        },
      },
      "& .ignoreCordinates": {
        minHeight: "330px",
        "& .cordinatesBody": {
          height: "202px",
          overflow: "auto",
        },
      },
    },
    "& .imgUpMain": {
      border: "1.5px dashed #0658c2",
      borderRadius: "4px",
      textAlign: "center",
      backgroundColor: "#eaeff3",
      "& .imgDisplay": {
        height: "250px",
        backgroundColor: "#fff",
        position: "relative",
        "& .imgBox": {
          textAlign: "center",
          backgroundColor: "#f9fbfa",
          display: "flex",
          height: "250px",
          alignItems: "center",

          "& img": {
            width: "auto",
            height: "auto",
            maxWidth: "100%",
            maxHeight: "250px",
            margin: "auto",
          },
          "& canvas": {
            width: "auto",
            height: "auto",
            maxWidth: "100%",
            maxHeight: "250px",
            margin: "auto",
          },
        },
        "& .openCorBtn": {
          position: "absolute",
          width: "100%",
          background: "transparent",
          top: 0,
          left: 0,
          bottom: 0,
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          "& p": {
            display: "none",
            margin: "auto",
            color: "#fff",
            border: "1px solid #fff",
            padding: "2px 8px",
          },
          "&:hover": {
            backgroundColor: "rgb(0 0 0 / 28%)",
            "& p": {
              display: "block",
            },
          },
        },
      },
    },

    // Image Upload End

    // Chart Validation
    "& .chartOutput": {
      maxHeight: "358px",
      overflow: "auto",
    },
    "& .CDAOutputBox": {
      padding: "8px",
      borderLeft: "5px solid",
      borderRadius: "4px",
      backgroundColor: "#fff",
      boxShadow: "0 0px 12px rgb(0 0 0 / 8%)",
      "& .colCode": {
        width: "18px",
        height: "18px",
        lineHeight: "18px",
        textAlign: "center",
        float: "right",
        borderRadius: "50%",
        color: "#fff",
      },
    },
    // Chart Validation End

    // API Form
    "& .authFields": {
      padding: "16px 16px",
      border: "1px solid #ccc",
      marginTop: "16px",
      borderRadius: "4px",
      "& p": {
        marginBottom: "8px",
      },
    },
    "& .formTabs": {
      "& .MuiTabs-root": {
        minHeight: "30px",
      },
      "& .MuiTabs-flexContainer": {
        border: "1px solid #ccc",
        display: "inline-block",
        borderRadius: "30px",
      },
      "& .MuiTabs-indicator": {
        height: "30px",
        zIndex: 1,
        top: 1,
        borderRadius: "30px",
      },
      "& .MuiTab-root": {
        minHeight: "30px",
        height: "auto",
        padding: "0 16px",
        lineHeight: "30px",
        position: "relative",
        zIndex: 2,
      },
      "& .MuiTab-root.Mui-selected": {
        color: "#fff",
      },
      "& .authFields": {
        marginTop: 8,
      },
    },

    // progress bar

    // API Form End

    // Small Button
    "& .MuiButton-root.exSmall": {
      padding: "0 8px",
      minWidth: "auto",
      fontSize: "10px",
    },
    // Small Button End

    "& .DBsec": {
      boxShadow: "0 0px 12px rgb(0 0 0 / 8%)",
      position: "relative",
      background: "#fff",
      borderRadius: 4,
      marginBottom: "20px",
      padding: 10,

      "& .MuiSvgIcon-root": {
        fontSize: "2rem",
        marginRight: "5px",
        color: "#006dba",
      },

      "& .MuiTypography-root": {
        color: "#333",
      },
      "& .MuiTypography-h6": {
        fontSize: 16,
      },
      "& a": {
        textDecoration: "none",
      },
      "& .linkCus": {
        color: "#009df6",
      },
      "&:hover": {
        boxShadow: "0 0 10px 0 rgb(9 110 182 / 25%)",
      },
    },
    "& .PassFail": {
      "& .MuiBox-root , a": {
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        textDecoration: "none",
      },
      "& .MuiTypography-root": {
        fontSize: 10,
      },
      "& .passed": {
        "& .MuiSvgIcon-root , .MuiTypography-root": {
          color: "#00b104",
        },
      },
      "& .failed": {
        "& .MuiSvgIcon-root , .MuiTypography-root": {
          color: "#ff0b0e",
        },
      },
      "& .MuiSvgIcon-root": {
        fontSize: "0.7rem",
        marginRight: "2px",
      },
    },
    ".themeSwitch  .MuiSwitch-root": {
      top: "-5px",
      right: "8px",
    },
    ".selTabHead": {
      padding: "0px 8px",
      background: "#eee",
      borderRadius: "4px",
    },
    ".selTabCol": {
      padding: "5px 8px",
      "& .MuiTypography-root": {
        "&:hover": {
          background: "#eee",
          cursor: "pointer",
          paddingLeft: "8px",
          borderRadius: "4px",
        },
      },
    },
    ".previewDrawHead": {
      border: "1px solid #ccc",
      marginBottom: "10px",
      ".MuiGrid-container .MuiGrid-root": {
        backgroundColor: "#e5f6fd",
        border: "1px solid #fff",
        padding: "0px 8px",
      },
    },
    "& .TCName": {
      backgroundColor: "#fff",
      padding: "16px",
      textAlign: "center",
      position: "relative",
      h6: {
        borderBottom: "1px solid #fff",
        paddingBottom: "8px",
        marginBottom: "8px",
      },
      p: {
        width: "100%",
        textAlign: "center",
        span: {
          color: "#7D9BF9",
          fontWeight: "600",
        },
      },
      ".MuiFormControl-root": {
        marginBottom: "0px",
      },
      button: {},
    },
    // "& .ag-dnd-ghost.ag-theme-quartz": {
    //   with: "100px !important",
    // },
    "& .AUTHead": {
      fontWeight: "normal",
      span: {
        color: "#7D9BF9",
        fontWeight: "600",
      },
    },
    "& .dialogBox": {
      "& .MuiDialogContentText-root": {
        textAlign: "center",
        "& h6": {
          color: "#1b5e20",
          "& span": {
            color: "#7D9BF9",
          },
          svg: {
            fontSize: "40px",
          },
        },
        p: {
          fontSize: "16px",
        },
      },
      ".dialogNote": {
        padding: "10px 16px 16px",
        color: "#ed6c02",
        textAlign: "center",
        borderTop: "1px solid #ccc",
        marginTop: "16px",
      },
      ".MuiDialogActions-root": {
        justifyContent: "center",
        margin: "auto",
        a: {
          paddingLeft: "10px",
        },
      },
    },
    "& .manageTestDataDisabled": {
      ".locked-col, .ag-cell[col-id='sequenceNumber'] , .ag-cell[col-id='name'] , .ag-cell[col-id='description'] , .ag-cell[col-id='uielement'], .ag-cell[col-id='action'], .ag-cell[col-id='screenshot']":
        {
          backgroundColor: "#ccc",
          opacity: 0.5,
        },
    },
    "& .manageTestDataDisabledAll": {
      ".locked-col, .ag-cell[col-id='sequenceNumber'] , .ag-cell[col-id='name'] , .ag-cell[col-id='description'] , .ag-cell[col-id='uielement'], .ag-cell[col-id='action'], .ag-cell[col-id='screenshot'], .ag-cell[col-id='StoreVariable'], .ag-cell[col-id='retryDuration'], .ag-cell[col-id='isDisable'], .ag-cell[col-id='consecutiveThreshold']":
        {
          backgroundColor: "#ccc",
          opacity: 0.5,
        },
    },
    "& .importedStepsDisabledAll": {
      ".locked-col, .ag-cell[col-id='sequenceNumber'] , .ag-cell[col-id='testStep'], .ag-cell[col-id='expectedResult']":
        {
          backgroundColor: "#ccc",
          opacity: 0.5,
          border: "0px solid #7D9BF9",
        },
      ".locked-col, .ag-cell[col-id='0']": {
        backgroundColor: "transparent",
        opacity: 1,
      },
      ".ag-root ag-unselectable ag-layout-normal": {
        background: "white",
        opacity: 1,
      },
      "& .ag-full-width-container": {
        "& .locked-col, .ag-cell[col-id='sequenceNumber'] , .ag-cell[col-id='name'] , .ag-cell[col-id='description'] , .ag-cell[col-id='uielement'], .ag-cell[col-id='action'], .ag-cell[col-id='screenshot'], .ag-cell[col-id='StoreVariable'], .ag-cell[col-id='retryDuration'], .ag-cell[col-id='isDisable'], .ag-cell[col-id='consecutiveThreshold']":
          {
            backgroundColor: "transparent",
            opacity: 1,
            border: "1px solid #7D9BF9",
          },
      },
    },
    "& .manageTableDuplicates": {
      ".locked-col, .ag-cell[col-id='name']": {
        backgroundColor: "red",
        opacity: 0.5,
      },
    },
    "& .exePopBody": {
      "& .MuiFormGroup-root": {
        flexDirection: "row",
      },
    },
    "& .MuiModal-root": {
      "& .MuiBox-scroll": {
        top: "53%",
        maxHeight: "calc(100vh - 75px)",
        overflow: "auto",
      },
    },
    "& .deleteDialogBox": {
      "& .MuiDialogActions-root": {
        justifyContent: "center",
        paddingTop: 0,
        paddingBottom: "16px",
      },
    },
    "& .MuiSnackbar-root": {
      zIndex: 3,
    },

    "& .appList": {
      ".ag-cell[col-id='name'] a": {
        color: "#7D9BF9",
      },
      ".ag-cell[col-id='noOfTestCases'] a": {
        border: "1px solid #381FD1",
        display: "inline-block",
        padding: "2px 4px",
        lineHeight: "14px",
        borderRadius: " 2px",
        color: "#381FD1",
        fontWeight: "600",
        minWidth: "20px",
        textAlign: "center",
        cursor: "pointer",
        textDecoration: "none",
      },
      ".ag-cell[col-id='noOfTestSuites'] a": {
        border: "1px solid #381FD1",
        display: "inline-block",
        padding: "2px 4px",
        lineHeight: "14px",
        borderRadius: " 2px",
        color: "#381FD1",
        fontWeight: "600",
        minWidth: "20px",
        textAlign: "center",
        cursor: "pointer",
        textDecoration: "none",
      },
    },
    "& .genKeyMain": {
      margin: "auto",
      textAlign: "center",
      padding: "16px",
      "& .code": {
        backgroundColor: "#eee",
        padding: "8px",
        fontWeight: "600",
        color: "#7D9BF9",
        fontSize: "20px",
        marginTop: "16px",
      },
      "& button": {
        margin: "auto",
        marginTop: "16px",
      },
      h6: {
        textTransform: "uppercase",
      },
    },

    "& .helpText": {
      span: {
        fontWeight: "200",
        display: "inline-flex",
        fontSize: "12px",
        alignItems: "center",
        marginLeft: "5px",
      },
    },
    "& .AgentSListMain": {
      marginLeft: "-8px",
      marginRight: "-8px",
    },
    "& .AgentSingle": {
      position: "relative",
      margin: "8px",
      "&:hover": {
        cursor: "pointer",
      },
      img: {
        width: "100%",
      },
      "& .AgentInfo": {
        position: "absolute",
        width: "100%",
        padding: "0px 15px 0px 15px",
        maxHeight: "55%",
        overflowY: "auto",
        top: "33px",
        p: {
          marginBottom: "2px",
          padding: "2px 4px",
          backgroundColor: "#eee",
          span: {
            fontWeight: "bold",
          },
        },
        "&.noAgents": {
          p: {
            backgroundColor: "transparent",
            fontSize: "20px",
            textAlign: "center",
          },
        },
      },
    },
    "& .Aonline": {
      position: "absolute",
      right: "20px",
      top: "13px",
      color: "green",
      display: "flex",
      alignItems: "center",
      lineHeight: "0",
      "& svg": {
        marginRight: "4px",
      },
    },
    "& .Aoffline": {
      color: "red",
    },
    "& .DS-Acc": {
      "&.MuiAccordion-root.MuiPaper-root": {
        padding: 0,
      },

      ".MuiAccordionSummary-content": {
        margin: 0,
      },
      "& .MuiAccordionDetails-root": {
        padding: "8px",
      },
      "& .MuiCollapse-entered": {
        "& .MuiAccordionDetails-root": {
          borderTop: "1px solid #eee",
        },
      },
    },
    "& .active-ds": {
      display: "flex",
      alignItems: "center",
      img: {
        height: "25px",
        marginRight: "8px",
      },
      p: {
        borderLeft: "1px solid #ccc",
        padding: "0 8px",
      },
      "& .Aonline , Aoffline": {
        position: "static",
        borderLeft: "1px solid #ccc",
        paddingLeft: "8px",
      },
    },
    "& .Aauts": {
      "& .DBAdd": {
        display: "none",
      },
    },
    "& .TCExList": {
      "& .ag-center-cols-container": {
        cursor: "pointer",
      },
    },
    "& .chartPaper": {
      padding: "16px",
      minHeight: "calc(100% - 30px)",
    },
    "& .MuiStack-root": {
      overflow: "hidden",
      marginTop: "-8px",
      "& .MuiTextField-root": {
        width: "100%",
      },
    },
    "& .TCReports": {
      "& .MuiAccordion-root.MuiPaper-root": {
        backgroundColor: "transparent",
        boxShadow: "none",
        padding: 0,
        "& .MuiAccordionDetails-root": {
          padding: 0,
        },
        "&.Mui-expanded": {},
        "&:before": {
          content: "none",
        },
      },
      "& .MuiButtonBase-root.MuiAccordionSummary-root": {
        minHeight: "auto",
        "& .MuiAccordionSummary-content": {
          margin: 0,
        },
      },
      "& .dateSec": {
        display: "flex",
        alignItems: "center",
        backgroundColor: "#f3fbff",
        padding: "16px",
        boxShadow: "0 0px 12px rgb(0 0 0 / 8%)",
        "& .MuiInputLabel-outlined": {
          top: "1px",
        },
      },
      "& .ag-cell[col-id='pass']": {
        color: "#41b883",
        fontWeight: "600",
      },
      "& .ag-cell[col-id='fail']": {
        color: "#dd1b16",
        fontWeight: "600",
      },
      "& .ag-cell[col-id='skip']": {
        color: "#00d8ff",
        fontWeight: "600",
      },
      "& .ag-cell[col-id='executions']": {
        color: "#ffce56",
        fontWeight: "600",
      },
    },
    // "& .ag-theme-quartz .ag-row-hover": {
    //   cursor: "pointer",
    // },
    "& .ag-cell .rag-element ,.ag-cell .jiraTktBtn.MuiButton-root": {
      padding: "2px 6px",
      lineHeight: "16px",
      textAlign: "center",
      borderRadius: "4px",
      fontSize: "12px",
    },
    "& .ag-cell.rag-green": {
      "& .rag-element": {
        backgroundColor: "#41b883",
        color: "#fff",
        cursor: "pointer",
      },
    },
    "& .ag-cell.rag-red": {
      "& .rag-element": {
        backgroundColor: "#dd1b16",
        color: "#fff",
        cursor: "pointer",
      },
    },
    "& .ag-cell.rag-skip": {
      "& .rag-element": {
        backgroundColor: "#00d8ff",
        color: "#fff",
        cursor: "pointer",
      },
    },
    "& .ag-cell.rag-progress": {
      "& .rag-element": {
        backgroundColor: "#ed8f13",
        color: "#fff",
        cursor: "pointer",
      },
    },
    "& .ag-cell.rag-aborted": {
      "& .rag-element": {
        backgroundColor: "#b30000",
        color: "#fff",
        cursor: "pointer",
      },
    },
    "& .non-editable": {
      cursor: "default",
    },
    "& .ag-checkbox-input-wrapper input": {
      cursor: "pointer",
    },
    "& .jiraTktBtn.MuiButton-root": {
      marginTop: "-4px",
      marginLeft: "4px",
    },
    "& .TStestcases": {
      minHeight: "calc(100% - 30px)",
      "& h6": {
        padding: "8px",
        backgroundColor: "rgb(138 168 189 / 46%)",
      },
      "& .clearFilters": {
        display: "none",
      },
      "& .createBtn": {
        padding: 0,
      },
      ".infoMessage": {
        textAlign: "center",
        border: "1px dashed #ccc",
        padding: "30px",
        margin: "8px",
        top: "8px",
        position: "relative",
        "& p": {
          fontSize: "16px",
          color: "#ccc",
        },
      },
    },
    "& .chartjs-tooltip": {
      background: "rgba(0,0,0,.7)",
      borderRadius: "0.375rem",
      color: "#fff",
      opacity: "0",
      padding: "0.25rem 0.5rem",
      pointerEvents: "none",
      position: "absolute",
      transform: "translate(-50%)",
      transition: "all .15s ease",
      zIndex: 2,
    },
    "& .impAUT": {
      "& .createBtn p": {
        color: "#1976d2",
        border: "1px solid #ccc",
        padding: "4px 8px",
        borderRadius: "4px",
      },
    },
    "& .IFSecIcons": {
      padding: "2px 4px",
      display: "inline-block",
    },
    "& .popupInnerhead": {
      backgroundColor: "#7D9BF9",
      borderRadius: "0",
      color: "#fff",
      "& .MuiTypography-bold": {
        color: "#fff",
      },
    },

    "& .ag-dnd-ghost": {
      width: "200px !important",
    },
    // "& .ag-theme-quartz .ag-row-dragging": {
    //   opacity: "1",
    // },
    "& .ag-cell": {
      ".MuiCircularProgress-root": {
        width: "20px !important",
        height: "20px !important",
      },
      "& .MuiIconButton-root": {
        color: "#fff",
        borderRadius: "4px",
        height: "auto",
        minHeight: "auto",
        padding: "2px 2px",
        fontSize: "16px",
        marginRight: "4px",
        "& .DBAdd": {
          display: "inherit",
        },
        "&.MuiIconButton-colorError": {
          backgroundColor: "#d32f2f",
        },
        "&.MuiIconButton-colorSuccess": {
          backgroundColor: "#2e7d32",
        },
        "&.MuiIconButton-colorWarning": {
          backgroundColor: "#ed6c02",
        },
        "&.MuiIconButton-colorSecondary": {
          backgroundColor: "#9c27b0",
        },
        "&.MuiIconButton-colorPrimary": {
          backgroundColor: "#1976d2",
        },
      },
    },

    // "& .MuiModal-root .MuiDrawer-paperAnchorRight": {
    //   backgroundColor: "#F7F8FB",
    // },

    "& .selectAllText": {
      position: "absolute",
      width: "150px",
      top: "6px",
      right: "48px",
    },
    "& .selectAllBox": {
      position: "absolute",
      right: "78px",
    },
    "& .closeCircle": {
      position: "absolute",
      right: "20px",
      top: "30px",
      borderRadius: "10px",
    },
    "& .tsArrows": {
      display: "flex",
      alignItems: "center",
      height: "100%",
      "& .MuiBox-root": {
        display: "grid",
        margin: "auto",
        "& .MuiIconButton-root": {
          "& svg": {
            fontSize: "45px",
          },
        },
      },
    },

    "& .pvTestSch": {
      "& .clearFilters": {
        display: "none",
      },
      "& .createBtn": {
        paddingRight: 16,
      },
    },

    "& .CreateSteps": {
      "& .ag-cell[col-id='name'] , .ag-cell[col-id='uielement'] , .ag-cell[col-id='action'] , .ag-cell[col-id='screenshot'] , .ag-cell[col-id='description'], .ag-cell[col-id='retryDuration'], .ag-cell[col-id='isDisable'], .ag-cell[col-id='consecutiveThreshold']":
        {
          borderColor: "#7D9BF9",
        },
      // "& .ag-cell[col-id='name'] , .ag-cell[col-id='uielement'] , .ag-cell[col-id='action'] , .ag-cell[col-id='screenshot'] , .ag-cell[col-id='description'], .ag-cell[col-id='retryDuration'], .ag-cell[col-id='isDisable'], .ag-cell[col-id='consecutiveThreshold']":
      //   {
      //     borderColor: "#7D9BF9",
      //   },
    },
    "& .CTSec": {
      "& .createBtn": {
        display: "flex",
        alignItems: "center",
        "& .helpText": {
          borderRight: "1px solid #ccc",
          paddingRight: "12px",
          svg: {
            fontSize: "28px",
          },
          "& .MuiIconButton-root": {
            border: "none",
          },
          "& .MuiIconButton-root:hover": {
            border: "none",
          },
        },
      },
    },
    "& .ag-cell[col-id='screenshotPath']": {
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },

    ".customModel": {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#fff",
      borderTop: "5px solid #096eb6",
      boxShadow: 24,
      padding: 4,
      "& .cPopup.MuiIconButton-root": {
        position: "absolute",
        right: "0",
        top: "0",
      },
    },
    "& .desCell , .restext": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: 1,
      WebkitBoxOrient: "vertical",
    },

    "& .catStyle": {
      span: {
        backgroundColor: "#cfd0ff",
        padding: "4px",
        display: "block",
        borderRadius: "4px",
      },
    },
    "& .list-view .MuiTableContainer-root": {
      borderRadius: 0,
    },
    // "& .ag-theme-quartz .ag-ltr .ag-row-group-leaf-indent": {
    //   marginLeft: "0",
    // },
    "& .configMain": {
      width: "100%",
      marginTop: "12px",
      position: "relative",
      "& .apiActions": {
        "& .MuiListItemButton-root": {
          padding: "0 12px 0 10px",
          gap: "4px",
          borderBottom: "1px solid #eee",
        },
      },
      "& .MuiCheckbox-root": {
        padding: 0,
      },
      "& .apiList": {
        "& .MuiFormControlLabel-root": {
          marginRight: 0,
          gap: "4px",
        },
      },
      "& .exeDetails": {
        padding: "1.5px 12px",
        backgroundColor: "#eaeff4",
      },
    },
    "& .ConfigPreview": {
      "& .runOrder": {
        height: "auto",
        backgroundColor: "transparent",
        padding: "0",
        "& .OL": {
          marginBottom: "12px",
          backgroundColor: "#eaeff4",
          padding: "4px 12px",
          "& .MuiCollapse-vertical": {
            backgroundColor: "transparent",
          },
        },
      },
      "& .DSListItem": {
        backgroundColor: "#fff",
        borderLeft: "1px solid #e0e0e0",
        cursor: "initial",
        "&:hover": {
          boxShadow: "none",
          transform: "none",
        },
        "& .configTC": {
          backgroundColor: "#eaeff4",
          padding: "4px",
          marginBottom: "4px",
        },
      },
    },
    "& .configTiles": {
      padding: "0px 12px",
      marginTop: "12px",
      "& .DSListItem": {
        borderLeft: "1px solid #e0e0e0",
      },
    },
    "& .closeDrawerIcon": {
      position: "absolute",
      right: "4px",
      top: "4px",
    },
    "& .editConfig": {
      "& .runOrder": {
        minHeight: "calc(100% - 0px)",
        height: "auto",
      },
    },
    "& .bddGridDisable": {
      background: "#ccc",
    },

    "& .customTabs": {
      "&.viewVar": {
        position: "absolute",
        top: "40px",
        right: "0px",
        zIndex: 2,
        width: "90%",
        padding: "10px 10px 0px",
        backgroundColor: "#f5f5f5",
        boxShadow: "0 0 10px rgb(0 0 0 / 21%)",
        maxHeight: "calc(100vh - 210px)",
        overflow: "auto",
        "& .refreshBtn": {
          display: "none",
        },
      },

      "& .MuiTabs-root": {
        minHeight: "30px",
      },
      "& .MuiTabs-flexContainer": {
        "& .MuiTab-root": {
          padding: "0",
          marginRight: "16px",
          minHeight: "30px",
          minWidth: "auto",
        },
      },
      "& .MuiAccordionDetails-root": {
        padding: 0,
      },
      " & .MuiAccordionSummary-root": {
        minHeight: "auto",
        padding: "0 14px",
        "& .MuiAccordionSummary-content": {
          margin: "4px 0px",
        },
        "&.Mui-expanded": {
          minHeight: "auto",
        },
      },
    },

    "& .notificationMain": {
      display: "flex",
      alignItems: "center",
      textAlign: "center",
    },
    "& .notItems": {
      "& .MuiMenu-paper": {
        overflow: "auto",
        // overflow: "visible",
        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
        marginTop: "12px",
        width: "460px",
        maxWidth: "460px",
        padding: "8px 16px",
        "& .MuiAvatar-root": {
          width: "32px",
          height: "32px",
          marginRight: "8px",
          fontSize: "16px",
        },
        "&:before": {
          display: "block",
          position: "absolute",
          top: "0",
          right: "14px",
          width: "10px",
          height: "10px",
          backgroundColor: "#fff",
          transform: "translateY(-50%) rotate(45deg)",
          zIndex: "0px",
        },
        "& ul": {
          "& li": {
            padding: "8px 0px",
            whiteSpace: "normal",
            "& p": {
              paddingRight: "35px",
              "& span": {
                color: "#ccc",

                fontWeight: "200",
                fontSize: "10px",
              },
            },
          },
        },
        "& .MuiTabs-root": {
          minHeight: "30px",
        },
        "& .MuiTabs-flexContainer": {
          borderBottom: "2px solid #ddd",
          "& .MuiTab-root": {
            padding: "0px 16px 0px 0px",
            minWidth: "auto",
            minHeight: "30px",
          },
        },
        "& .listBox": {
          paddingTop: "16px",
          "& .timeNot": {
            color: "#ccc",
            textTransform: "uppercase",
          },
        },
      },
      "& .notOnline": {
        position: "absolute",
        right: "8px",
        "& .MuiCheckbox-root": {
          padding: "5px",
          "& .MuiSvgIcon-root": {
            fontSize: "10px",
          },
          "& .MuiSvgIcon-root[data-testid='RadioButtonUncheckedIcon']": {
            color: "#fff",
          },
        },
      },
    },
    // "& .ag-row , .ag-cell": {
    //   height: "36px !important",
    //   display: "flex",
    //   alignItems: "center",
    // },

    // skill AI chat bot
    "& .right-pos": {
      left: "0px !important",
      right: "auto !important",
    },
    // "& .skil-ai-v-css": {
    //   fontFamily: '"Roboto","Helvetica","Arial",sans-serif !important',
    //   "& .chat-container": {
    //     fontFamily: '"Roboto","Helvetica","Arial",sans-serif !important',
    //     height: "auto !important",
    //     "& .chat-content": {
    //       fontFamily: '"Roboto","Helvetica","Arial",sans-serif !important',
    //     },
    //     "& .chat-content-message-container": {
    //       fontFamily: '"Roboto","Helvetica","Arial",sans-serif !important',
    //       height: "calc(100vh - 256px) !important",
    //     },
    //   },
    // },
  };
};
