import { useEffect, useRef, useState } from "react";
import { Box, Typography, IconButton, Grid, Tooltip, Divider } from "@mui/material";
import { InfoOutlined as InfoOutlinedIcon } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import ResultsListView from "./ResultsListView";
import ResultTile from "./ResultTile";
import SkeletonLoader from "../../SkeletonLoader";
import CustomToggleButton from "../../CustomComponents/CustomButtons/CustomToggleButton";
import { testcaseServices } from "../../../Redux/services/testcase.services";
import SelectDateRange from "../../CustomComponents/SelectDateRange";
import PreviewTestData from "../../Reports/ExecutionReports/PreviewTestData";

const ResultsList = () => {
  const gridRef = useRef();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [tcExecutions, setTcExecutions] = useState([]);
  const [resultsLoading, setResultsLoading] = useState(false);

  // const sortBy = searchParams.get("sortBy");
  const startDate = new Date(searchParams.get("startDate"));
  const endDate = new Date(searchParams.get("endDate"));
  const viewType = searchParams.get("viewType");
  const testCaseId = searchParams.get("testCaseId");
  const testCaseName = searchParams.get("tcName");
  const exeInstanceId = searchParams.get("exeInstanceId");

  const handleClickRow = (executionResult) => {
    setSearchParams((prevParams) => {
      const newParams = new URLSearchParams(prevParams);
      newParams.set("exeInstanceId", executionResult?.id);
      return newParams;
    });
  };

  const onChangeToggleView = (newVal) => {
    if (newVal) {
      setSearchParams((prev) => {
        const newState = new URLSearchParams(prev);
        newState.set("viewType", newVal);
        return newState;
      });
    }
  };

  const fetchTestCaseExecutions = async () => {
    setResultsLoading(true);
    try {
      const sartDataImMillies = startDate.getTime();
      const endDataInMillies = endDate.getTime();
      const { data } = await testcaseServices.getTestCaseExecutions(testCaseId, sartDataImMillies, endDataInMillies);
      setTcExecutions(data?.testCaseExecutionInstanceResponseList);
    } catch (err) {
      console.log(err);
    } finally {
      setResultsLoading(false);
    }
  };

  useEffect(() => {
    fetchTestCaseExecutions();
  }, [testCaseId]);

  const getExecutionsList = () => {
    if (viewType === "grid") {
      return (
        <Grid className="gridExecutionList" container>
          {tcExecutions.map((obj, index) => (
            <Grid key={index} item sm={12} md={6} lg={4} xl={4}>
              <ResultTile executionResult={obj} handleClickRow={handleClickRow} />
            </Grid>
          ))}
        </Grid>
      );
    }
    return <ResultsListView gridRef={gridRef} executions={tcExecutions} />;
  };

  const onChangeDateRange = (data) => {
    setSearchParams((prev) => {
      const newState = new URLSearchParams(prev);
      newState.set("startDate", data?.startDate);
      newState.set("endDate", data?.endDate);
      return newState;
    });
  };

  const handleOnSubmitDateRange = () => {
    fetchTestCaseExecutions();
  };

  const handleShowPreviewTestData = () => {
    setSearchParams((prev) => {
      const newState = new URLSearchParams(prev);
      newState.delete("exeInstanceId");
      return newState;
    });
  };

  const renderContent = () => {
    if (resultsLoading) {
      return <SkeletonLoader />;
    }

    if (tcExecutions?.length > 0) {
      return getExecutionsList();
    }

    return (
      <Box className="noExecutionsFound">
        <Typography variant="sectionHeading" sx={{ opacity: 0.5 }}>
          {t("No Executions Found")}
        </Typography>
      </Box>
    );
  };

  return (
    <>
      <Box className="resultActionsToolbar">
        <Typography variant="sectionHeading">{t("Execution List")} :</Typography>
        <Box className="v-center" gap={2}>
          <Typography variant="body1">
            <strong>TestCase:</strong> {testCaseName}
          </Typography>

          <Box className="v-center" gap={2} sx={{ float: "right" }}>
            <Typography variant="body1">
              <strong>Start Date : </strong>
              {startDate.toDateString()}
            </Typography>
            <Typography variant="body1">
              <strong> End Date : </strong>
              {endDate.toDateString()}
            </Typography>
            <SelectDateRange
              setSelectedDateRange={onChangeDateRange}
              selectedDateRange={{ startDate, endDate, key: "selection" }}
              onClickSubmit={handleOnSubmitDateRange}
            />
          </Box>
          <Divider />
          <CustomToggleButton viewType={viewType} onChange={(event, newVal) => onChangeToggleView(newVal)} />

          <IconButton sx={{ p: 0 }}>
            <Tooltip arrow placement="left" title={t("execution_tool_tip")}>
              <InfoOutlinedIcon />
            </Tooltip>
          </IconButton>
        </Box>
      </Box>

      {renderContent()}
      {exeInstanceId && <PreviewTestData setPreviewTestData={handleShowPreviewTestData} />}
    </>
  );
};

export default ResultsList;
