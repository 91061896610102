import { IconButton, Tooltip } from "@mui/material";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import { CustomDeleteIcon } from "../CustomComponents/Icons/IconButtons";
import { useCheckAccess } from "../../CustomHooks/useCheckAccess";

const UploadFileActionCells = ({ data, context }) => {
  const { copyPath, onClickDeleteFiles } = context;
  const { checkAccess } = useCheckAccess();
  return (
    <>
      {checkAccess("fileUploads", "Copy") && (
        <Tooltip title="Copy Path" placement="top" arrow>
          <IconButton onClick={() => copyPath(data)} size="small">
            <FileCopyOutlinedIcon fontSize="small" sx={{ color: "#611EB6" }} />
          </IconButton>
        </Tooltip>
      )}
      {checkAccess("fileUploads", "Delete") && (
        <CustomDeleteIcon onClick={() => onClickDeleteFiles([data])} height="20" width="20" />
      )}
    </>
  );
};

export default UploadFileActionCells;
