import { getTimeDifference, ExecutionStatusCodes } from "../../../utilities/ReusableFunctions";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import { InfoOutlined as InfoIcon } from "@mui/icons-material";

import { formatDateTime } from "../../../utils/formatDate";

const executedBydetails = (props) => {
  return (
    <Box className="flex column" gap={0.5}>
      <Typography
        sx={{
          color: "#64748B",
          fontSize: "12px",
          fontWeight: 600,
        }}>
        {props?.data?.executionMode === "SCHEDULER" ? "Scheduler" : props?.data?.executedBy}
      </Typography>

      <Typography
        sx={{
          color: "#6C757D",
          fontSize: "10px",
          fontWeight: 300,
        }}>
        {props?.data?.executedAt ? formatDateTime(props?.data?.executedAt) : ""}
      </Typography>
    </Box>
  );
};

const autDetails = (props) => {
  return (
    <Box className="flex column" gap={0.5}>
      <Typography
        sx={{
          color: "#64748B",
          fontSize: "12px",
          fontWeight: 600,
        }}>
        {props?.data?.autName}
      </Typography>

      <Typography
        sx={{
          color: "#6C757D",
          fontSize: "10px",
          fontWeight: 300,
        }}>
        {`${props?.data?.autType} (${props?.data?.autEnvironmentDetails?.name})`}
      </Typography>
    </Box>
  );
};

const platFormDetails = (props) => {
  return (
    <Box className="flex column" gap={0.5}>
      <Typography
        sx={{
          color: "#64748B",
          fontSize: "12px",
          fontWeight: 600,
        }}>
        {props?.data?.noOfPlatforms}
        <span style={{ color: "#6C757D", fontSize: "10px", fontWeight: 300 }}>
          {props?.data?.parallelExecution ? " (Parallel)" : " (Sequential)"}{" "}
        </span>
      </Typography>
    </Box>
  );
};

const exeResult = (params) => {
  const { handleClickExeRow } = params.context;
  return (
    <>
      <Box>
        <Tooltip title={params?.data?.remarks} placement="top-left" arrow>
          <Button
            size="small"
            className="v-center"
            sx={{
              background: ExecutionStatusCodes[params?.data?.result].color,
              color: "#fff",
              p: "3px 5px",
              gap: "5px",
              // "&.Mui-disabled": { color: "rgba(0, 0, 0, 0.26)", backgroundColor: "rgba(0, 0, 0, 0.12)", border: "none" },
              "&:hover": { background: ExecutionStatusCodes[params?.data?.result].hoverColor },
            }}
            onClick={() => handleClickExeRow(params?.data)}>
            {params?.data?.result}{" "}
            {params?.data?.result === "DISCARDED" ? <InfoIcon sx={{ fontSize: "18px", pb: "2px" }} /> : ""}
          </Button>
        </Tooltip>
      </Box>
    </>
  );
};

const getDuration = (props) => {
  return (
    <Box className="flex column" gap={0.5}>
      <Typography
        sx={{
          color: "#64748B",
          fontSize: "12px",
          fontWeight: 600,
        }}>
        {props?.data?.startedAt ? formatDateTime(props?.data?.startedAt) : ""}
      </Typography>

      <Typography
        sx={{
          color: "#6C757D",
          fontSize: "10px",
          fontWeight: 300,
        }}>
        {getTimeDifference(props?.data?.startedAt, props?.data?.finishedAt)?.readableFormat}
      </Typography>
    </Box>
  );
};
export const ExecutionsListModel = [
  {
    field: "name",
    headerName: "Execution Id",
    suppressColumnsToolPanel: true,
    headerTooltip: "Execution Name",
    cellClass: "non-editable",
    filter: true,
  },
  {
    field: "autName",
    headerName: "Aut Name",
    suppressColumnsToolPanel: true,
    headerTooltip: "Aut Name",
    cellClass: "non-editable",
    cellRenderer: autDetails,
    filter: false,
  },
  {
    field: "requestedBy",
    headerName: "Requested By",
    suppressColumnsToolPanel: true,
    headerTooltip: "Requested By",
    cellRenderer: executedBydetails,
    cellClass: "non-editable",
    filter: false,
  },
  {
    field: "noOfPlatforms",
    headerName: "No Of Platforms",
    suppressColumnsToolPanel: true,
    headerTooltip: "No Of Platforms",
    cellClass: "non-editable",
    cellRenderer: platFormDetails,
    filter: false,
  },
  {
    field: "startedAt",
    headerName: "Started At",
    suppressColumnsToolPanel: true,
    headerTooltip: "Started At",
    cellRenderer: getDuration,
    cellClass: "non-editable",
    filter: false,
  },

  {
    field: "result",
    headerName: "Result",
    suppressColumnsToolPanel: true,
    headerTooltip: "Result",
    cellClass: "non-editable",
    cellRenderer: exeResult,
    filter: false,
  },
];
