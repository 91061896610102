import { memo } from "react";
import { Checkbox, TableCell, TableHead, TableRow } from "@mui/material";
import { setSelectedStepsData } from "../../../../Redux/reducers/testcaseSlice";
import { useDispatch } from "react-redux";

const HeaderTableCells = ({ stepViewType, isSelectedAll, formattedData }) => {
  const dispatch = useDispatch();
  const handleSelectAllCheckboxChange = (event) => {
    dispatch(setSelectedStepsData(event.target.checked ? formattedData : []));
  };
  return (
    <TableHead>
      <TableRow>
        <TableCell width={40} align="center">
          <Checkbox
            disabled={formattedData?.length === 0}
            checked={isSelectedAll}
            onChange={handleSelectAllCheckboxChange}
          />
        </TableCell>
        <TableCell width={60} align="center">
          S.No
        </TableCell>
        <TableCell width={stepViewType ? "400px" : "15%"} align="left">
          Name
        </TableCell>
        <TableCell width={stepViewType ? "400px" : "15%"} align="left">
          UI Actions
        </TableCell>

        {!stepViewType && (
          <>
            <TableCell width="15%" align="left">
              UI Element
            </TableCell>
            <TableCell width={90} align="center">
              Screenshot
            </TableCell>
            <TableCell width={90} align="center">
              Disable
            </TableCell>
            <TableCell width="15%" align="left">
              Select Data Type
            </TableCell>
            <TableCell align="left">Actions</TableCell>{" "}
          </>
        )}
      </TableRow>
    </TableHead>
  );
};

export default memo(HeaderTableCells);
