import { Box, Divider, Typography } from "@mui/material";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import CustomVideoPlayer from "./CustomVideoPlayer";

const MacroHelpInfo = () => (
  <Box
    sx={{
      p: 3,
      width: "25%",
      position: "fixed",
      right: 0,
      top: 0,
      bottom: 0,
      overflowY: "auto",
      bgcolor: "background.paper",
      pt: "120px",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    }}>
    <Box className="v-center" gap={1} py={1}>
      <Typography variant="h6">?</Typography>
      <Divider orientation="vertical" flexItem />
      <Typography>Help Text</Typography>
    </Box>
    <Divider sx={{ mb: 1 }} />
    <Typography variant="body1" paragraph>
      On this screen, you can manage and view the list of macros associated with different applications. The dashboard
      categorizes macros by application types, such as <b> Salesforce</b>, <b>Web</b>, <b>SAP</b>, and <b>Android</b>.
      <br />
      For each category, the number of applications and corresponding macros are displayed:
      <br />
      For example:
      <br />
      <b>Salesforce:</b> Displays the number of applications and the count of macros created for Salesforce.
      <br />
      <b>SAP:</b> Indicates the number of SAP applications and available macros.
      <br />
      <b>Android:</b> Lists the Android applications and the associated macros.
      <br />
      This panel allows you to easily navigate and manage macros across various platforms, enabling efficient automation
      and consistency in your workflows.
    </Typography>

    <Box className="v-center" gap={1} pb={1}>
      <PlayCircleIcon color="primary" fontSize="small" />
      <Divider orientation="vertical" flexItem />
      <Typography>Help Video</Typography>
    </Box>
    <Divider sx={{ mb: 1 }} />
    <CustomVideoPlayer
      src="https://www.youtube.com/watch?v=qLmgX4kd-Sg&ab_channel=KairosTechnologies"
      type="video/mp4"
    />
    {/* <Typography variant="body1" paragraph>
      Consequat mauris nunc congue nisi vitae suscipit. Fringilla est ullamcorper eget nulla facilisi etiam dignissim
      diam. Pulvinar elementum integer enim neque volutpat ac tincidunt. Ornare suspendisse sed nisi lacus sed viverra
      tellus. Purus sit amet volutpat consequat mauris. Elementum eu facilisis sed odio morbi. Euismod lacinia at quis
      risus sed vulputate odio. Morbi tincidunt ornare massa eget egestas purus viverra accumsan in. In hendrerit
      gravida rutrum quisque non tellus orci ac. Pellentesque nec nam aliquam sem et tortor. Habitant morbi tristique
      senectus et. Adipiscing elit duis tristique sollicitudin nibh sit. Ornare aenean euismod elementum nisi quis
      eleifend. Commodo viverra maecenas accumsan lacus vel facilisis. Nulla posuere sollicitudin aliquam ultrices
      sagittis orci a.
    </Typography> */}
  </Box>
);

export default MacroHelpInfo;
