import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { LoadingButton } from "@mui/lab";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { authServices } from "../../Redux/services/auth.services";
import { azureLogin } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { setUserDetails } from "../../Redux/reducers/authSlice";
import { encryptData } from "../../helpers/encryptionUtils";
import { showToast } from "../../Redux/reducers/toastSlice";
import ChangePassword from "../Login/ChangePassword";
const LicenseAgreement = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.auth.user_details);

  const [showChangePassword, setShowChangePassword] = useState(false);

  const [open, setOpen] = React.useState(true);
  const [loading, setLoading] = useState(false);

  const handleLogout = async () => {
    const data = { UserId: userDetails?.UserId, SessionId: userDetails?.SessionId };

    try {
      await authServices.logout(data);
    } catch (error) {
      dispatch(showToast({ message: error?.response?.data?.message || error.message, type: "error" }));
    }
    localStorage.removeItem("kitapUser");
    dispatch(setUserDetails({}));
    navigate("/login");
  };
  const acceptLicense = async () => {
    if (azureLogin) {
      setLoading(true);

      try {
        await authServices.acceptLicense(userDetails);
        const modifinedData = { ...userDetails, isULAAgreed: true };
        dispatch(setUserDetails(modifinedData));
        localStorage.setItem("kitapUser", JSON.stringify(encryptData(JSON.stringify(modifinedData))?.encryptedData));
        setOpen(false);
      } catch (error) {
        dispatch(showToast({ message: error?.response?.data?.message || error.message, type: "error" }));
      }
      setLoading(false);
    } else {
      setShowChangePassword(true);
    }
  };

  return (
    <>
      {!showChangePassword ? (
        <Dialog
          sx={{
            "& .MuiBackdrop-root": { backdropFilter: "blur(6px)" },
            "& .MuiPaper-root": {
              boxShadow:
                "0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12) !important",
            },
          }}
          open={open}
          maxWidth="md">
          <DialogTitle>End User License Agreement (EULA)</DialogTitle>
          <DialogContent dividers>
            <DialogContentText>
              INTRODUCTION
              <br />
              This End User License Agreement (hereinafter referred to as {"EULA"}) governs the terms and conditions of
              the use of KiTAP (hereinafter also termed as {"Software"} or {"Product"}). The EULA is an integral part of
              the License Contract between Kairos (Licensor) and the Customer (Licensee). By installing, copying, or
              engaging in any other use, whether in part or in whole, of KiTAP, you, the Licensee, expressly agree to be
              bound by the terms and conditions outlined in this EULA.
              <br />
              <br />
              DEFINITIONS
              <br />
              <strong>Licensee:</strong> a physical person or legal entity that has acquired a license to the Software.
              <br />
              <strong>EULA:</strong> this standard End User License Agreement.
              <br />
              <strong>License:</strong> the non-exclusive and non-transferable right to use KiTAP, bound by various
              terms and conditions as detailed in this EULA.
              <br />
              <strong>Subscription:</strong> authorized use of KiTAP (through a License), as granted by the Licensor for
              a defined period through a separate legally binding contract / agreement.
              <br />
              <strong>End User:</strong> a person who is using or accessing (in any form) the KiTAP from a device, where
              it has either been installed or has access to single KiTAP instance, for their work.
              <br />
              <strong>Agreement:</strong> acceptance of this EULA and/or any other legally binding artifact suggesting
              or detailing the purchase and/or use of KiTAP.
              <br />
              <strong>Software:</strong> any software (whether provided as downloadable or as an online service), tool,
              utility, documentation, or similar delivered by Kairos including the license file, and any revision,
              update, or upgrade hereto which are made available by Kairos to the Customer under the terms and
              conditions set out in this EULA.
              <br />
              <br />
              GRANT OF LICENSE
              <br />
              <strong>License Grant:</strong> Subject to the terms and conditions of this Agreement, the Licensor hereby
              grants the Licensee a non-exclusive, non-transferable license to use the KiTAP (the {"Software"}) for the
              duration of this Agreement and in accordance with the limitations set forth herein.
              <br />
              <strong>Scope of Use:</strong> The License granted herein permits the Licensee to install and use the
              Software on a single [device/computer] at any given time, unless otherwise expressly authorized by the
              Licensor in writing. The Licensee acknowledges that additional Licenses may be required for simultaneous
              use on multiple devices or by multiple End User(s).
              <br />
              <strong>Restrictions on Use:</strong> (a) The Licensee shall not sublicense, sell, lease, rent,
              distribute, or otherwise transfer the Software or any rights thereto to any third party without the prior
              written consent of the Licensor. (b) The Licensee shall not modify, reverse engineer, decompile,
              disassemble, or create derivative works based on the Software, except as expressly permitted by applicable
              law.
              <br />
              <strong> No Further Rights: </strong>The Licensee acknowledges and agrees that, except for the express
              License granted herein, no other rights or licenses are granted, whether expressed or implied, including
              any rights under patents, copyrights, trademarks, or trade secrets.
              <br />
              <strong>Reservation of Rights:</strong> All rights not expressly granted herein are reserved by the
              Licensor. The Licensee acknowledges that the License granted hereunder does not provide any ownership
              rights to the Software or any intellectual property rights therein.
              <br />
              <strong> Compliance with Laws:</strong> The Licensee shall use the Software in compliance with all
              applicable laws, regulations, and third-party agreements.
              <br />
              <strong>Termination of License:</strong> This License is effective until terminated. The Licensor reserves
              the right to terminate this license immediately and without notice if the Licensee fails to comply with
              any term or condition of this Agreement. Upon termination, the Licensee shall cease all use of the
              Software and promptly uninstall and destroy all copies of the Software in its possession or control.
              <br />
              <br />
              OWNERSHIP AND INTELLECTUAL PROPERTY
              <br />
              <strong>Retained Ownership: </strong> The Licensee acknowledges and agrees that the Licensor retains all
              right, title, and interest in and to the Software, including all intellectual property rights, proprietary
              rights, and any modifications or enhancements made to the Software.
              <br />
              <strong>Intellectual Property Rights:</strong> The Licensee understands that the Software is protected by
              copyright, trademark, patent, trade secret, and other intellectual property laws. The Licensee further
              acknowledges that any unauthorized use, reproduction, or distribution of the Software may result in civil
              and criminal penalties.
              <br />
              <strong>No Transfer of Rights:</strong> This Agreement does not grant the Licensee any ownership interest
              or rights in the Software beyond the express License granted herein. The Licensee shall not, by virtue of
              this Agreement, acquire any rights in the {"Licensor's"}intellectual property other than those expressly
              granted in this Agreement.
              <br />
              <strong>Reporting of Unauthorized Use:</strong> The Licensee agrees to promptly report to the Licensor any
              known or suspected unauthorized use or infringement of the Software by third parties.
              <br />
              <br />
              SUPPORT
              <br />
              <strong>Support Contract:</strong> Kairos agrees to provide technical support for the licensed Product as
              per the terms outlined in a separately purchased Support Contract. In the absence of a separate Support
              Contract, the scope of technical support is limited to the following:
              <br />
              <strong>Query and Clarification:</strong> Kairos shall provide technical query and clarification support
              for the Product through {"Kairos's"} designated support email, as specified in the Contract.
              <br />
              <strong>Response Time:</strong> Kairos commits to responding to your technical queries within twenty-four
              (24) clock hours of receipt. However, it is acknowledged that the resolution of the query depends on
              various factors, including the complexity of the reported problem and potential solutions.
              <br />
              <strong>No Guaranteed Resolution:</strong> Kairos makes no claim to guarantee the resolution of the
              technical query or clarification within any specific timeframe, including the Contract period. Due to the
              inherent uncertainties in resolving technical issues, Kairos shall not be held liable for any
              compensation, whether monetary or otherwise, arising from the inability to resolve the query within a
              defined period or at all.
              <br />
              <strong>Customization/Alteration/Modification:</strong> If the resolution of a technical issue requires
              any customization, alteration, or modification to the Product, such activities are deemed out of the scope
              of this agreement. Any such customization, alteration, or modification must be separately negotiated and
              contracted with Kairos.
              <br />
              <br />
              <strong>TERM AND TERMINATION:</strong>
              <br />
              <strong>Duration of License:</strong> The License granted under this Agreement shall be effective upon the
              {"Licensee's"} acceptance of the terms herein and shall continue for the duration for which the License
              has been granted by the Licensor, unless earlier terminated as provided herein.
              <br />
              <strong>Termination for Breach:</strong> The Licensor reserves the right to terminate this license
              immediately and without notice in the event of a material breach by the Licensee of any term or condition
              of this Agreement. A material breach includes, but is not limited to, unauthorized use of the Software,
              failure to pay applicable fees (if any), or violation of the restrictions outlined in this Agreement.
              <br />
              <strong>Effect of Termination:</strong> Upon termination of the license, the Licensee shall immediately
              cease all use of the Software and shall uninstall and destroy all copies of the Software in their
              possession or control. Termination of the license shall not relieve the Licensee of any obligations
              accrued prior to such termination.
              <br />
              <strong>Survival of Certain Provisions:</strong> The provisions of this Agreement that, by their nature,
              shall survive termination, including but not limited to the sections related to Ownership and Intellectual
              Property, Limitation of Liability, Indemnification, and Governing Law and Dispute Resolution.
              <br />
              <strong>Termination for Convenience:</strong> Either party may terminate this Agreement for convenience
              upon written notice to the other party. In such cases, the Licensee shall comply with the termination
              procedures specified in this Agreement.
              <br />
              <strong>Obligations Upon Termination:</strong> Upon termination of this Agreement for any reason, the
              Licensee shall promptly return to the Licensor or, at the {"Licensor's"} option, destroy all copies of the
              Software and any related documentation in their possession or control.
              <br />
              <br />
              PAYMENT TERMS FOR SUBSCRIPTION LICENSE
              <br />
              <strong>License Fees:</strong> The Licensee shall pay the license fee for the Subscription License in
              advance for the entire term of the contract.
              <br />
              <strong>Payment Due Date:</strong> The License fee is due upon the issuance of the invoice by the
              Licensor.
              <br />
              <strong>Invoice Issuance:</strong> The Licensor shall issue an invoice to the Licensee detailing the
              license fees payable for the Subscription License.
              <br />
              <strong>Late Payment Penalties:</strong> In the event of late payment, the Licensee shall be subject to
              late payment penalties.
              <br />
              <br />
              INFRINGEMENTS OF THIRD-PARTY RIGHTS
              <br />
              Kairos represents and warrants that it has the right to license the Software, including any documentation,
              to the Licensee, and that Kairos holds the necessary rights, titles, and licenses to allow the Licensee to
              perform all rights contemplated by this Agreement, and that the Software does not infringe any{" "}
              {"third-party's"}
              right that is valid within and enforceable in Union of India.
              <br />
              The above representations and warranties do not apply to infringements or misappropriations resulting from
              modifications of the Software, including any documentation, by the Licensee, or the {"Licensee's"}{" "}
              operation or use of the Software with devices, data, or software furnished by the Licensee. If the
              Licensee embeds fonts or any other third-party intellectual properties using the Software, it is the sole
              responsibility of the Licensee to have the necessary rights to do so.
              <br />
              If a third party towards the Licensee claims that the Software infringes third-party rights, the Licensee
              shall immediately inform Kairos in writing and Kairos shall take over the defense of the claim.
              <br />
              If the Licensee becomes aware of any infringement or potential infringement of the Software it shall
              promptly notify Kairos in writing.
              <br />
              <br />
              PROVIDED {"AS IS"}
              <br />
              The Software and any related documentation are provided {"as is"} without warranty of any kind, either
              express or implied, including, without limitation, the implied warranties or merchantability, fitness for
              a particular purpose. The entire risk arising out of the use or performance of the Software remains with
              Licensee.
              <br />
              The Software delivered under this Agreement is a standard product, and the Licensee is aware that no
              software product is faultless in all situations and combinations.
              <br />
              Kairos shall not be liable for any defects. This Agreement includes neither warranty against defects, nor
              any warranty of fitness or suitability for a particular purpose.
              <br />
              Immediately after a reproducible error or defect is discovered or should be discovered, the Licensee must
              give notice to Kairo’s Partner whom the Licensee is cooperating with at the time of the notification. Upon
              notification, the Licensee must specify the error or defect.
              <br />
              Kairos will use its best endeavors to remedy errors and defects which are standard features or
              functionality of the KiTAP, and have neither been customized / modified, nor dropped from the most recent
              version, and are reproducible in the most recent version of the Software.
              <br />
              The actual scope and procedure for remedy of any errors or defects is at the free and independent
              discretion of Kairos, who may remedy essential errors and defects by releasing an update of the Software.
              Kairos will remedy non-essential errors and defects to the extent Kairos finds it necessary.
              <br />
              <br />
              <strong>LIMITATION OF LIABILITY</strong>
              <br />
              Kairos expressly disclaims any liability, whether expressed or implied with regard to (i) defects and
              errors, which are not related to the Software, but which are related to external factors, including other
              software products of the Licensee, (ii) acts or omissions of the Partners of Kairos (iii) the interaction
              between the Software and any other hardware and/or software environment and organization at the Licensee’s
              location or at any remote location, including but not limited to hosting or data centers (iv) errors,
              defects, and inexpediency of third-party’s standard products, delivered by Kairos (v) the Licensee’s
              changes and/or modifications in or with the Software, and (vi) compatibility between the Software and any
              new version, update etc. of third-party’s software.
              <br />
              <br />
              Kairos shall not in any way be liable for circumstances related to the non-fulfilment of the Licensee’s
              duties in respect of this Agreement. In no event shall Kairos be held liable for any damage resulting from
              loss of data, loss of profits or goodwill or other consequential damages. {"Kairo's"} liability can under
              no circumstances exceed the license fee paid by the Licensee under the Agreement excluding any update fee
              paid in accordance with clause 6 above.
              <br />
              <br />
              <strong>INDEMNIFICATION:</strong>
              <strong>Obligation to Indemnify:</strong>
              <br />
              The Licensee agrees to indemnify, defend, and hold harmless the Licensor, its officers, directors,
              employees, and agents from and against all claims, damages, liabilities, costs, and expenses (including
              reasonable
              {"attorney's"} fees) arising out of or related to the {"Licensee's"} use or misuse of the Software,
              violation of this Agreement, or infringement of any third-party rights.
              <br />
              <strong>Notice of Claim:</strong> In the event of a claim for which indemnification is sought, the
              Licensor shall promptly notify the Licensee in writing of the claim. The Licensee shall have the right to
              assume the defense of such claim with counsel of its choice.
              <br />
              <strong>Settlement:</strong> The Licensee shall not settle any claim that could result in liability for
              the Licensor without the {"Licensor's"} prior written consent, which shall not be unreasonably withheld.
              <br />
              <strong>Limitation of Liability:</strong> The {"Licensee's"} indemnification obligations under this
              section are subject to the limitations of liability set forth in this Agreement.
              <br />
              <br />
              <strong>GOVERNING LAW & ARBITRATION</strong>
              <br />
              <strong>Governing Law:</strong> This Agreement shall be governed by and construed in accordance with the
              laws of Union of India, without regard to its conflict of law principles.
              <br />
              <strong>Arbitration/Conciliation:</strong> Any dispute, controversy, or claim arising out of or in
              connection with this Agreement, or the breach, termination, or invalidity thereof, shall be settled by
              arbitration/conciliation in accordance with the rules of Arbitration and Conciliation Act,1996. and
              judgment upon the award rendered by the arbitrator(s)/conciliator(s) may be entered in any court having
              jurisdiction thereof.
              <br />
              <strong>Appointment of Arbitrator: </strong>
              The arbitrator shall be appointed by the Licensor. If the parties are unable to agree on the choice of an
              arbitrator within 15 days of the initiation of the arbitration proceedings, the appointment shall be made
              by Hon’ble High Court for the state of Telangana.
              <br />
              <strong>Place of Arbitration/Conciliation: </strong>
              The place of arbitration/conciliation shall be at Hyderabad Telangana. The language to be used in the
              arbitral/conciliation proceedings shall be English.
              <br />
              <br />
              <strong>Equitable Relief:</strong>
              <br />
              Notwithstanding the arbitration/conciliation provisions, either party may seek injunctive or other
              equitable relief in any court of competent jurisdiction to prevent or address any actual or threatened
              breach of confidentiality, intellectual property rights, or unauthorized use of the Software.
              <br />
              <br />
              <strong>VALIDITY</strong>
              <br />
              If any provision in this Agreement is held to be illegal, invalid, or unenforceable, such provision(s)
              shall nonetheless be enforced to the fullest extent permitted by applicable law, to reflect the original
              intent of the parties. Such provision(s) shall not affect the legality and validity of the other
              provisions in this Agreement.
              <br />
              <br />
              <strong>INTERPRETATION</strong>
              <br />
              If any provision of this EULA is held illegal or unenforceable by any court of competent jurisdiction,
              such provision shall be deemed separable from the remaining provisions of this agreement and shall not
              affect or impair the validity or enforceability of the remaining provisions of this agreement.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleLogout} color="error">
              Decline
            </Button>

            <LoadingButton
              disabled={loading}
              onClick={acceptLicense}
              variant="contained"
              className="loader-button"
              size="small"
              loading={loading}
              loadingPosition="start"
              startIcon={<></>}>
              Accept
            </LoadingButton>
          </DialogActions>
        </Dialog>
      ) : (
        <ChangePassword />
      )}
    </>
  );
};

export default LicenseAgreement;
