import { Box, Typography } from "@mui/material";
import { useEffect, useMemo, useRef, useState, useCallback } from "react";
import SkeletonLoader from "../SkeletonLoader";

import { FileUploadListHeadCells } from "./FileUploadListHeadCells";
import AddIcon from "@mui/icons-material/Add";
import InnerHeader from "../Layout/InnerHeader";
import CustomReactAgGrid from "../CustomComponents/CustomReactAgGrid";
import { CustomDeleteIcon } from "../CustomComponents/Icons/IconButtons";
import CustomDeleteModal from "../ReusableComponents/CustomDeleteModal";
import { DeleteAlertPopup } from "../ReusableComponents/CustomAlerts";
import CustomButton from "../CustomComponents/CustomButtons/CustomButton";
import { fileStorageServices } from "../../Redux/services/fileStorage.services";
import { useCheckAccess } from "../../CustomHooks/useCheckAccess";
import { showToast } from "../../Redux/reducers/toastSlice";
import { useDispatch } from "react-redux";
import UploadFileActionCells from "./UploadFileActionCells";

// FileUploadButton Component
const FileUploadButton = ({ listOfFiles }) => {
  const dispatch = useDispatch();
  const fileInput = useRef();

  const [files, setFiles] = useState(null);
  const [loadingUpload, setLoadingUpload] = useState(false);

  const handleFileChange = (e) => {
    const selectedFiles = e.target.files;
    if (selectedFiles.length > 0) {
      setFiles(selectedFiles);
    }
  };

  const uploadFilesToAzureBlob = async () => {
    setLoadingUpload(true);
    try {
      const uploadedFiles = await Promise.all(
        Array.from(files).map(async (file) => {
          const formData = new FormData();
          formData.append("file", file);
          const res = await fileStorageServices.fileUploadToStorage(formData);
          return {
            fileName: res.data?.fileName,
            filePath: res.data.filePath,
            originalFileName: res.data.originalFileName,
            fileSizeInKb: res.data.fileSizeInKB,
            fileProvider: res.data.fileProvider,
            status: "VALID",
            module: "TEST_DATA",
          };
        }),
      );
      await fileStorageServices.uploadFileResponseToServer(uploadedFiles);

      dispatch(showToast({ message: "Selected files uploaded successfully", type: "success" }));

      listOfFiles();
    } catch (error) {
      dispatch(showToast({ message: error?.response?.data?.message || error.message, type: "error" }));
    } finally {
      setLoadingUpload(false);
      setFiles(null);
      fileInput.current.value = null;
    }
  };

  return (
    <>
      <Box className="v-center" gap={2}>
        <input multiple type="file" ref={fileInput} onChange={handleFileChange} style={{ display: "none" }} />
        <CustomButton
          className="no-shadow"
          startIcon={<AddIcon />}
          onClick={() => fileInput.current.click()}
          color="success">
          Upload Files
        </CustomButton>
      </Box>
      {files && (
        <DeleteAlertPopup
          loadingUpload={loadingUpload}
          open={true}
          setOpen={() => {
            setFiles(null);
            fileInput.current.value = null;
          }}
          delFunction={uploadFilesToAzureBlob}
          alertTitle="Are you sure you want to upload the selected files?"
        />
      )}
    </>
  );
};

// FileUploads Component
const FileUploads = () => {
  const dispatch = useDispatch();
  const gridRef = useRef();

  const { checkAccess } = useCheckAccess();
  const [filesLoading, setFilesLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const listOfFiles = useCallback(async () => {
    setFilesLoading(true);
    try {
      const { data } = await fileStorageServices.getUploadedFiles("testdata");
      setRows(data);
    } catch (error) {
      dispatch(showToast({ message: error?.response?.data?.message || error.message, type: "error" }));
    } finally {
      setFilesLoading(false);
    }
  }, []);

  useEffect(() => {
    listOfFiles();
  }, [listOfFiles]);

  const handleSelectionChange = useCallback(() => {
    const gridApi = gridRef.current?.api;
    if (gridApi) {
      const selectedNodes = gridApi.getSelectedNodes();
      const selectedData = selectedNodes.map((node) => node.data);
      setSelectedFiles(selectedData);
    }
  }, []);

  const copyPath = (row) => {
    navigator.clipboard.writeText(row.filePath);

    dispatch(showToast({ message: "File Path Copied", type: "success" }));
  };

  const handleDeleteFiles = async () => {
    const ids = selectedFiles.map((file) => file.id);
    try {
      setDeleteLoading(true);
      await fileStorageServices.deleteFiles({ fileIdList: ids });
      gridRef.current.api.applyTransaction({ remove: selectedFiles });
      setRows((prevRows) => prevRows.filter((row) => !ids.includes(row.id)));
      dispatch(showToast({ message: "Selected files have been deleted", type: "success" }));

      closeDeleteModal();
    } catch (error) {
      dispatch(showToast({ message: error?.response?.data?.message || error.message, type: "error" }));
    } finally {
      setDeleteLoading(false);
    }
  };
  const onClickDeleteFiles = (row) => {
    setSelectedFiles(row);
  };

  const closeDeleteModal = () => {
    setSelectedFiles([]);
  };

  const action = useMemo(
    () => ({
      headerName: "Actions",
      sortable: false,
      cellRenderer: UploadFileActionCells,
      suppressColumnsToolPanel: true,
      minWidth: 100,
    }),
    [],
  );

  useMemo(() => {
    FileUploadListHeadCells[FileUploadListHeadCells.length - 1] = action;
  }, [action]);

  return (
    <Box sx={{ width: "100%" }}>
      <InnerHeader>
        <Box className="v-center" width="100%">
          <Typography variant="h6">Uploaded Files List</Typography>
          <Box className="v-center" gap={2} sx={{ ml: "auto" }}>
            {checkAccess("fileUploads", "Delete") && selectedFiles.length > 0 ? (
              <CustomDeleteIcon onClick={() => onClickDeleteFiles(gridRef.current.api.getSelectedRows())} />
            ) : (
              checkAccess("fileUploads", "Create") && <FileUploadButton listOfFiles={listOfFiles} />
            )}
          </Box>
        </Box>
      </InnerHeader>
      <Box className="pt74">
        {filesLoading && <SkeletonLoader />}
        <CustomReactAgGrid
          gridRef={gridRef}
          columnDefs={FileUploadListHeadCells}
          rowData={rows}
          onSelectionChanged={handleSelectionChange}
          context={{
            onClickDeleteFiles: onClickDeleteFiles,
            copyPath: copyPath,
          }}
        />
        {checkAccess("fileUploads", "Delete") && selectedFiles?.length > 0 && (
          <CustomDeleteModal
            loading={deleteLoading}
            disabled={deleteLoading}
            onClickDelete={handleDeleteFiles}
            handleClose={closeDeleteModal}
          />
        )}
      </Box>
    </Box>
  );
};

export default FileUploads;
