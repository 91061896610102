import { formatDateTime } from "../../utils/formatDate";

const StartDate = (props) => formatDateTime(props?.data?.startedAt);

const FinishedAt = (props) => {
  if (props?.data?.finishedAt) {
    return <>{formatDateTime(props?.data?.finishedAt)}</>;
  }
  return <></>;
};
export const PreviewHeadCell = [
  {
    field: "name",
    headerName: "Execution Id",
    sortable: true,
    suppressColumnsToolPanel: true,
    filter: true,
  },
  {
    field: "startedAt",
    headerName: "Started At",
    sortable: true,
    suppressColumnsToolPanel: true,
    filter: true,
    cellRenderer: StartDate,
  },

  {
    field: "finishedAt",
    headerName: "Finished At",
    sortable: true,
    suppressColumnsToolPanel: true,
    filter: true,
    cellRenderer: FinishedAt,
  },

  {
    field: "autDetails.name",
    headerName: "AUT Name",
    sortable: true,
    suppressColumnsToolPanel: true,
    filter: true,
  },
  {
    field: "autDetails.type",
    headerName: "AUT Type",
    sortable: true,
    suppressColumnsToolPanel: true,
    filter: true,
  },
  {
    field: "result",
    headerName: "Status",
    sortable: true,
    suppressColumnsToolPanel: true,
    filter: true,
  },

  {},
];
