import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { Box, Typography } from "@mui/material";
import CustomLoadingButton from "../../../CustomComponents/CustomButtons/CustomLoadingButton";
import CustomButton from "../../../CustomComponents/CustomButtons/CustomButton";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SuccessTestcaseCreate({ successDetails, handleClose, onClickCreateTestData }) {
  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { maxWidth: "500px" } }}
      open={true}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}>
      <DialogTitle textAlign={"center"}>
        <CheckCircleOutlineOutlinedIcon color="success" fontSize="large" />

        <Typography my={1} variant="h6">
          {successDetails?.name} test case {successDetails?.status} successfully!
        </Typography>

        <Typography variant="h6">Do you want to add test data now?</Typography>
        <Box className="center" sx={{ gap: 3, mt: 2 }}>
          <CustomLoadingButton onClick={onClickCreateTestData}>Yes</CustomLoadingButton>
          <CustomButton onClick={handleClose} variant="outlined" color="error">
            No
          </CustomButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          NOTE: Test case can be executed only after providing test data. You can provide the test data at a later point
          of time also from the Test Case Management page
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}
