import { createSlice } from "@reduxjs/toolkit";
import { autServices } from "../services/aut.services";

const initialState = {
  application_types: [],
  applications_loading: false,
  aut_list: [],
  aut_list_loading: false,
};

const autSlice = createSlice({
  name: "aut",
  initialState,
  reducers: {
    setAutList: (state, action) => {
      state.aut_list = action.payload;
    },
    setAppTypes: (state, action) => {
      state.application_types = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(autServices.fetchAppTypes.pending, (state) => {
        state.aut_list = [];
        state.applications_loading = true;
      })
      .addCase(autServices.fetchAppTypes.fulfilled, (state, action) => {
        state.applications_loading = false;
        state.application_types = action.payload;
      })
      .addCase(autServices.fetchAppTypes.rejected, (state) => {
        state.applications_loading = false;
      })

      .addCase(autServices.fetchListOfAuts.pending, (state) => {
        state.aut_list_loading = true;
      })
      .addCase(autServices.fetchListOfAuts.fulfilled, (state, action) => {
        state.aut_list_loading = false;
        const formattedAuts = action.payload?.length > 0 ? action.payload : [];
        state.aut_list = formattedAuts;
      })
      .addCase(autServices.fetchListOfAuts.rejected, (state) => {
        state.aut_list_loading = false;
      });
  },
});

export const { setAutList, setAppTypes } = autSlice.actions;

export default autSlice.reducer;
