import { useEffect, useRef, useState, useCallback } from "react";
import { Box } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { testcaseServices } from "../../../Redux/services/testcase.services";

import SkeletonLoader from "../../SkeletonLoader";
import CustomReactAgGrid from "../../CustomComponents/CustomReactAgGrid";
import ConfigActionToolbar from "./ConfigActionToolbar";
import CustomDeleteModal from "../../ReusableComponents/CustomDeleteModal";

import { ConfigListHeadcells } from "./ConfigListHeadcells";
import { autServices } from "../../../Redux/services/aut.services";
import { showToast } from "../../../Redux/reducers/toastSlice";

const ExecutionConfigList = () => {
  const gridRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const autType = searchParams.get("autType");
  const autName = searchParams.get("autName");
  const autId = searchParams.get("autId");
  // const agentId = searchParams.get("agentId");

  const [executionConfigList, setExecutionConfigList] = useState([]);
  const [loadingConfigs, setLoadingConfigs] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [selectedTestConfigs, setSelectedTestConfigs] = useState([]);

  useEffect(() => {
    dispatch(autServices.fetchAppTypes());
    if (autType) {
      dispatch(autServices.fetchListOfAuts(autType));
    }
    if (autId) {
      fetchExecutionConfigs();
    }
  }, []);

  const fetchExecutionConfigs = async () => {
    setLoadingConfigs(true);
    try {
      const { data } = await testcaseServices.getExecutionConfigs(autId);
      setExecutionConfigList(
        data?.executionConfigResponseList?.map((el) => ({ ...el, noOfPlatforms: el?.platformList?.length })) || [],
      );
    } catch (error) {
      console.error("Error fetching execution configs list:", error);
    } finally {
      setLoadingConfigs(false);
    }
  };

  const handleExecuteTestConfig = async (row) => {
    try {
      await testcaseServices.getExecuteConfig(row?.id, row?.version);
      dispatch(showToast({ message: "Config accepted for execution successfully", type: "success" }));
    } catch (error) {
      dispatch(showToast({ message: error?.response?.data?.message || error.message, type: "error" }));
    }
  };

  const handleViewExecutionReports = (row) => {
    navigate(`/executionsreport/${row.id}?source=testCase`);
  };

  const handleEditTestConfig = (row) => {
    navigate(
      `/configs/create?autId=${autId}&testConfigId=${row.id}&configVersion=${row.version}&autType=${autType}&autName=${autName}`,
    );
  };

  const handleDeleteTestConfigs = async () => {
    setDeleteLoading(true);
    const testConfigsIdsToDelete = selectedTestConfigs.map((testCase) => testCase.id);

    try {
      await testcaseServices.deleteTestConfigs({ deleteIdList: testConfigsIdsToDelete });
      if (gridRef?.current?.api) {
        gridRef.current.api.applyTransaction({ remove: selectedTestConfigs });
      }
      setSelectedTestConfigs([]);

      dispatch(showToast({ message: "Selected test cases are deleted", type: "success" }));
    } catch (error) {
      dispatch(showToast({ message: error?.response?.data?.message || error.message, type: "error" }));
    }
    setDeleteLoading(false);
    setIsDeleteModalOpen(false);
  };

  const handleDeleteButtonClick = () => {
    setIsDeleteModalOpen(true);
  };

  const handleSelectionChange = useCallback(() => {
    const gridApi = gridRef.current?.api;
    if (gridApi) {
      const selectedNodes = gridApi.getSelectedNodes();
      const selectedData = selectedNodes.map((node) => node.data);
      setSelectedTestConfigs(selectedData);
    }
  }, []);

  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
  };

  return (
    <>
      {loadingConfigs && <SkeletonLoader />}
      <Box sx={{ mt: 2, pb: 6, "& .ag-root-wrapper": { borderRadius: "0px", border: "0px", boxShadow: "none" } }}>
        <ConfigActionToolbar
          selectedTestConfigs={selectedTestConfigs}
          gridRef={gridRef}
          onClickDelete={handleDeleteButtonClick}
          onClickEditTestConfig={handleEditTestConfig}
          executeTestConfig={handleExecuteTestConfig}
          viewExecutionReports={handleViewExecutionReports}
        />
        <CustomReactAgGrid
          gridRef={gridRef}
          columnDefs={ConfigListHeadcells}
          rowData={executionConfigList}
          onSelectionChanged={handleSelectionChange}
          rowHeight={52}
        />
      </Box>

      {isDeleteModalOpen && (
        <CustomDeleteModal
          loading={deleteLoading}
          disabled={deleteLoading}
          onClickDelete={handleDeleteTestConfigs}
          handleClose={handleDeleteModalClose}
        />
      )}
    </>
  );
};

export default ExecutionConfigList;
