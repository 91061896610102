import { useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Box, Grid, Typography, IconButton } from "@mui/material";
import { CustomAgGrid } from "../AgGrid";
import { PreviewHeadCell } from "./PreviewHeadCell";
import { formatDateTime } from "../../utils/formatDate";

const ActionsCell = (props) => {
  return (
    <>
      {Number.parseInt(props?.data?.response) ? (
        <IconButton
          title="Preview"
          size="small"
          color="secondary"
          onClick={() => props?.colDef.viewTestReports(props?.data?.response)}>
          View Result
        </IconButton>
      ) : (
        <Typography>{props?.data?.response}</Typography>
      )}
    </>
  );
};

const PreviewScheduledTest = ({ previewScheduledData }) => {
  const navigate = useNavigate();
  const params = useParams();
  const gridRef = useRef();
  const source = "all";
  const viewTestReports = (rowData) => {
    navigate(`/executionsreport/${params?.id}/${rowData}?source=${source}`);
  };
  const action = {
    headerName: "Actions",
    sortable: false,
    cellRenderer: ActionsCell,
    viewTestReports: viewTestReports,
    lockPosition: "right",
    cellClass: "locked-col",
    suppressColumnsToolPanel: true,
    filter: false,
    headerTooltip: "Actions",
    gridRef: gridRef,
  };
  PreviewHeadCell[PreviewHeadCell.length - 1] = action;
  return (
    <Box my={1} className="TCReports">
      <Typography variant="h5">Preview Scheduled Test</Typography>
      <Box my={1} className="dateSec">
        <Grid container alignItems={"center"} spacing={3}>
          <Grid item md={3}>
            <Box display="flex">
              <Typography variant="bold"> Name : </Typography>
              <Typography> {previewScheduledData?.name}</Typography>
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box display="flex">
              <Typography variant="bold">Description : </Typography>
              <Typography>{previewScheduledData?.description}</Typography>
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box display="flex">
              <Typography variant="bold">Frequency : </Typography>
              <Typography> {previewScheduledData?.frequency}</Typography>
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box display="flex">
              <Typography variant="bold">Scheduled At : </Typography>
              <Typography>{formatDateTime(previewScheduledData?.scheduledAt)}</Typography>
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box display="flex">
              <Typography variant="bold">Active : </Typography>
              <Typography>{previewScheduledData?.enabled ? "Yes" : "No"}</Typography>
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box display="flex">
              <Typography variant="bold"> No Of Times : </Typography>
              <Typography> {previewScheduledData?.noOfTimes}</Typography>
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box display="flex">
              <Typography variant="bold">Created By : </Typography>
              <Typography>{previewScheduledData?.createdBy}</Typography>
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box display="flex">
              <Typography variant="bold">Created At : </Typography>
              <Typography>{formatDateTime(previewScheduledData?.createdAt)}</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <CustomAgGrid
        gridRef={gridRef}
        headCells={PreviewHeadCell}
        setSelected={() => null}
        rows={previewScheduledData?.scheduledExecutionResponseList}
        columnsAutoFit={true}
      />
    </Box>
  );
};

export default PreviewScheduledTest;
