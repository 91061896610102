import { Box, Button, Chip, Typography } from "@mui/material";
// import { useState } from "react";

const buttonsList = [
  { label: "All" },
  { label: "PASSED", chip: { label: "", sx: { backgroundColor: "#4CAF50" } } },
  { label: "FAILED", chip: { label: "", sx: { backgroundColor: "#F9594B" } } },
  { label: "SKIPPED", chip: { label: "", sx: { backgroundColor: "#61C5F6" } } },
  { label: "DISABLED", chip: { label: "", sx: { backgroundColor: "#D9D1D3" } } },
];

export const ExecutionStatusCodes = {
  PASSED: {
    status: "Passed",
    color: "#4CAF50",
  },
  FAILED: {
    status: "Failed",
    color: "#F9594B",
  },
  OTHERS: {
    status: "Others",
    color: "#61C5F6",
  },
  ABORTED: {
    status: "Aborted",
    color: "#61C5F6",
  },
  SKIPPED: {
    status: "Skipped",
    color: "#61C5F6",
  },
  QUEUED: {
    status: "Queued",
    color: "#61C5F6",
  },
  INPROGRESS: {
    status: "In Progress",
    color: "#61C5F6",
  },
  DISABLED: {
    status: "Disabled",
    color: "#61C5F6",
  },
  INTERRUPTED: {
    status: "Interrupted",
    color: "#61C5F6",
  },
  DISCARDED: {
    status: "Discarded",
    color: "#61C5F6",
  },
};

export const StatusButtons = ({ buttons = buttonsList, setSelectedStatusBtn, selectedStatusBtn }) => {
  // const [selected, setSelected] = useState("All");

  const handleButtonClick = (label) => {
    setSelectedStatusBtn(label);
  };

  return (
    <Box className="status-buttons-container">
      {buttons.map(({ label, chip }) => (
        <Button
          key={label}
          variant="text"
          size="small"
          onClick={() => handleButtonClick(label)}
          sx={{
            textDecoration: selectedStatusBtn === label ? "underline" : "none",
            color: selectedStatusBtn === label ? "primary.main" : "grey.600",
            fontWeight: selectedStatusBtn === label ? 600 : 500,
            padding: 0,
            minWidth: 0,
          }}>
          {chip ? (
            <Box className="center" gap={0.5}>
              <Chip {...chip} size="small" />
              <Typography variant={selectedStatusBtn === label && "bold"}>{label}</Typography>
            </Box>
          ) : (
            label
          )}
        </Button>
      ))}
    </Box>
  );
};

export const summarizeTestData = (testcasesList) => {
  const summary = {
    passed: { count: 0, statusCode: "PASSED" },
    failed: { count: 0, statusCode: "FAILED" },
    notExecuted: { count: 0, statusCode: "OTHERS" },
    total: testcasesList.length,
  };

  testcasesList.forEach((testcase) => {
    switch (testcase.result) {
      case "PASSED":
        summary.passed.count += 1;
        break;
      case "FAILED":
        summary.failed.count += 1;
        break;
      default:
        summary.notExecuted.count += 1;
        break;
    }
  });

  return summary;
};
export const truncateText = (text, length) => {
  if (text?.length > length) {
    return `${text.substring(0, length)}...`;
  }
  return text;
};
