import { useCallback } from "react";
import { Box, Divider, IconButton, Tooltip } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
// import SearchIcon from "@mui/icons-material/Search";
// import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import PlayCircleFilledWhiteOutlinedIcon from "@mui/icons-material/PlayCircleFilledWhiteOutlined";
// import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import { CustomDeleteIcon, CustomEditIcon } from "../../CustomComponents/Icons/IconButtons";
import { useCheckAccess } from "../../../CustomHooks/useCheckAccess";

const ConfigActionToolbar = ({
  selectedTestConfigs,
  gridRef,
  onClickDelete,
  onClickEditTestConfig,
  executeTestConfig,
}) => {
  const [searchParams] = useSearchParams();
  const { checkAccess } = useCheckAccess();
  const filter = JSON.parse(searchParams.get("filter")) || false;

  const canEdit = selectedTestConfigs?.length === 1;
  const canDelete = selectedTestConfigs?.length > 0;

  const clearFilters = useCallback(() => {
    gridRef.current?.api.setFilterModel(null);
  }, [gridRef]);

  return (
    <Box
      className="v-center"
      sx={{ borderRadius: "12px 12px 0 0", backgroundColor: "#fff", height: "48px", boxSizing: "border-box" }}>
      <Box className="center" width={50}>
        <IconButton onClick={clearFilters} size="small" disabled={!filter}>
          <FilterAltOutlinedIcon />
        </IconButton>
      </Box>

      <Divider orientation="vertical" flexItem />

      <Box className="v-center" pl="4px">
        {checkAccess("configs", "Edit") && (
          <CustomEditIcon
            height="20"
            width="18"
            onClick={() => onClickEditTestConfig(selectedTestConfigs[0])}
            disabled={!canEdit}
          />
        )}
        {/* {checkAccess("configs", "Preview") && (
          <Tooltip title="Reports" placement="top" arrow>
            <IconButton
              //   onClick={() => viewExecutionReports(selectedTestConfigs[0])}
              size="small"
              disabled={!canEdit}
              sx={{ color: "#611EB6" }}>
              <AssessmentOutlinedIcon />
            </IconButton>
          </Tooltip>
        )} */}

        {checkAccess("configs", "Execute") && (
          <Tooltip title="Execute" placement="top" arrow>
            <IconButton
              onClick={() => executeTestConfig(selectedTestConfigs[0])}
              size="small"
              disabled={!canEdit}
              sx={{ color: "#611EB6" }}>
              <PlayCircleFilledWhiteOutlinedIcon />
            </IconButton>
          </Tooltip>
        )}

        {checkAccess("configs", "Delete") && (
          <CustomDeleteIcon onClick={onClickDelete} disabled={!canDelete} height="19.5" width="20" />
        )}
      </Box>

      {/* will enable in feature */}

      {/* <Box className="v-center" ml="auto" px={2}>
        <IconButton size="small">
          <SearchIcon />
        </IconButton>
        <Tooltip
          title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
          placement="top-start"
          arrow>
          <IconButton size="small">
            <InfoOutlinedIcon />
          </IconButton>
        </Tooltip>
      </Box> */}
    </Box>
  );
};

export default ConfigActionToolbar;
