import { Box, Divider, Drawer, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import SkeletonLoader from "../../SkeletonLoader";
import AUTForm from "./AutForm";
import { autServices } from "../../../Redux/services/aut.services";

const CreateAUT = ({ autDetails, editAutDetails, onSuccessCreateAut, onClose }) => {
  const [formData, setFormData] = useState(null);
  const [loadingAutDetails, setLoadingAutDetails] = useState(false);

  const applicationType = formData?.type || autDetails?.type;

  const fetchAutDetails = async () => {
    setLoadingAutDetails(true);
    try {
      const data = await autServices.getAutDetailsById(editAutDetails?.id);
      setFormData(data || {});
    } catch (error) {
      console.error("Failed to fetch AUT details:", error);
    } finally {
      setLoadingAutDetails(false);
    }
  };
  useEffect(() => {
    if (editAutDetails?.id) {
      fetchAutDetails();
    }
  }, [editAutDetails?.id]);

  return (
    <Drawer anchor="right" open={true} onClose={onClose}>
      <Box sx={{ width: 500 }} noValidate autoComplete="off">
        <Typography sx={{ px: 2, py: 1 }} variant="h6">
          {formData ? "Update AUT" : "Create New AUT"} - {applicationType}
        </Typography>
        <Divider />
        {loadingAutDetails ? (
          <SkeletonLoader />
        ) : (
          <AUTForm
            formData={formData}
            autDetails={autDetails}
            onClose={onClose}
            onSuccessCreateAut={onSuccessCreateAut}
          />
        )}
      </Box>
    </Drawer>
  );
};

export default CreateAUT;
