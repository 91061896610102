import { useState } from "react";
import { useFormContext, Controller, useFieldArray } from "react-hook-form";
import {
  Select,
  MenuItem,
  TextField,
  Grid,
  Box,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Tabs,
  Tab,
  IconButton,
  FormHelperText,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import AutFileUpload from "../AutFileUpload";
import dayjs from "dayjs";
import { AdditionalConfigInputs } from "./AdditionalConfigInputs";

const label = { tab1: "Native", tab2: "Hybrid" };

const getInitialTab = (url) => {
  if (!url) {
    return "one";
  }
  const fileExtensions = [".apk", ".ipa", ".zip"];
  return fileExtensions.some((ext) => url.endsWith(ext)) ? "one" : "two";
};

const formatDateTime = (date) => dayjs(date || new Date()).format("YYYY-MM-DD");

const Environments = ({ autType, showEnvError, formData }) => {
  const { control, watch, setValue } = useFormContext();
  const { fields, append, remove } = useFieldArray({ control, name: "autEnvironmentResponseList" });
  const [envs, setEnvs] = useState(["Dev", "Test", "Uat", "Prod"]);
  const [customEnvInput, setCustomEnvInput] = useState("");
  const [selectedEnv, setSelectedEnv] = useState(fields.length > 0 ? fields[0].name : "addNew");
  const [tabValue, setTabValue] = useState(getInitialTab(formData?.url));

  const handleAddCustomEnv = () => {
    if (customEnvInput && !fields.some((env) => env.name === customEnvInput)) {
      append({
        name: customEnvInput,
        description: "",
        url: "",
        fileId: null,
        buildVersion: "",
        buildDate: formatDateTime(),
        environmentProperties: {},
      });
      setEnvs([...envs, customEnvInput]);
      setCustomEnvInput("");
      setSelectedEnv(customEnvInput);
    }
  };

  const handleTabChange = (event, newValue) => {
    if (newValue === "addNew") {
      setCustomEnvInput("");
    }
    setSelectedEnv(newValue);
  };

  const handleRemoveEnv = (e, envName) => {
    e.stopPropagation();
    const index = fields.findIndex((env) => env.name === envName);
    remove(index);
    setSelectedEnv(fields.length > 1 ? fields[0].name : "addNew");
  };

  const activeEnvIndex = fields.findIndex((env) => env.name === selectedEnv);
  const environments = watch("autEnvironmentResponseList");

  const isValidHttpUrl = (string) => {
    try {
      const url = new URL(string);
      return url.protocol === "http:" || url.protocol === "https:";
    } catch {
      return false;
    }
  };

  const handleTabValueChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const onSuccessUploadFile = (fileId, fileName) => {
    setValue(`autEnvironmentResponseList.${activeEnvIndex}.fileId`, fileId);
    setValue(`autEnvironmentResponseList.${activeEnvIndex}.fileName`, fileName);
  };

  const onChangeEnv = (e) => {
    const envName = e.target.value;
    if (envName !== "addNew") {
      append({ name: envName, url: "", buildVersion: "", buildDate: formatDateTime() });
      setSelectedEnv(envName);
    }
  };

  return (
    <>
      {(autType === "ANDROID" || autType === "IOS") && (
        <Box className="formTabs" sx={{ width: "100%", mb: 2 }}>
          <Tabs value={tabValue} onChange={handleTabValueChange}>
            <Tab value="one" label={label.tab1} />
            <Tab value="two" label={label.tab2} disabled />
          </Tabs>
        </Box>
      )}
      <Box sx={{ backgroundColor: "#f6f6f6", borderRadius: "8px", border: "1px solid #ccc" }}>
        <Box py={1} px={2}>
          <Typography variant="h6">Environments</Typography>
        </Box>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            sx={{ minHeight: "26px" }}
            value={selectedEnv}
            onChange={handleTabChange}
            textColor={selectedEnv !== "addNew" ? "primary" : "orange"}
            variant="scrollable"
            scrollButtons="auto"
            indicatorColor={selectedEnv !== "addNew" ? "primary" : "warning"}
            TabIndicatorProps={{ sx: { bgcolor: selectedEnv !== "addNew" ? "primary.main" : "orange" } }}>
            {fields.map((env) => (
              <Tab
                sx={{ py: 0, minHeight: "24px" }}
                key={env.name}
                value={env.name}
                label={
                  <Box className="v-center">
                    {env.name}
                    <IconButton color="error" disabled size="small" onClick={(e) => handleRemoveEnv(e, env.name)}>
                      {/* <CloseIcon fontSize="10px" /> */}
                    </IconButton>
                  </Box>
                }
              />
            ))}
            <Tab
              value="addNew"
              label="Add New Env"
              icon={<AddIcon fontSize="small" />}
              iconPosition="start"
              sx={{ py: 0, px: 1, minHeight: "24px", color: "orange" }}
            />
          </Tabs>
        </Box>
        {selectedEnv !== "addNew" ? (
          <Grid container spacing={1} alignItems="center" p={2}>
            <Grid item xs={12}>
              {autType === "ANDROID" || autType === "IOS" ? (
                <AutFileUpload
                  onSuccessUploadFile={onSuccessUploadFile}
                  executableFilePath={environments[activeEnvIndex]?.fileName}
                  autType={autType}
                />
              ) : (
                <Controller
                  name={`autEnvironmentResponseList.${activeEnvIndex}.url`}
                  control={control}
                  rules={{
                    required: "URL is required",
                    validate: { validUrl: (value) => isValidHttpUrl(value) || "Invalid URL" },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      fullWidth
                      size="small"
                      placeholder="URL"
                      label="URL"
                      required
                      value={environments[activeEnvIndex]?.url || ""}
                      error={Boolean(error)}
                      helperText={error ? error.message : ""}
                    />
                  )}
                />
              )}
            </Grid>
            <Grid item xs={6}>
              <Controller
                name={`autEnvironmentResponseList.${activeEnvIndex}.buildVersion`}
                control={control}
                rules={{ required: "Build Number is required" }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    fullWidth
                    size="small"
                    placeholder="Build Number"
                    label="Build Number"
                    required
                    value={environments[activeEnvIndex]?.buildVersion || ""}
                    error={Boolean(error)}
                    helperText={error ? error.message : ""}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name={`autEnvironmentResponseList.${activeEnvIndex}.buildDate`}
                control={control}
                rules={{ required: "Release Date is required" }}
                render={({ field, fieldState: { error } }) => {
                  const currentValue = environments[activeEnvIndex]?.buildDate || formatDateTime();
                  return (
                    <TextField
                      {...field}
                      fullWidth
                      required
                      size="small"
                      label="Release Date"
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ max: formatDateTime() }}
                      value={formatDateTime(currentValue)}
                      onChange={(e) => field.onChange(formatDateTime(e.target.value))}
                      error={Boolean(error)}
                      helperText={error ? error.message : ""}
                    />
                  );
                }}
              />
            </Grid>
            <AdditionalConfigInputs environments={environments} activeEnvIndex={activeEnvIndex} setValue={setValue} />
          </Grid>
        ) : (
          <Box p={2}>
            <FormControl error={showEnvError && !fields?.length} required fullWidth size="small">
              <InputLabel>Select environment</InputLabel>
              <Select
                onKeyDown={(e) => e.stopPropagation()}
                onClick={(e) => e.stopPropagation()}
                value=""
                onChange={onChangeEnv}
                label="Select environment">
                {envs.map((env) => (
                  <MenuItem
                    onKeyDown={(e) => e.stopPropagation()}
                    onClick={(e) => e.stopPropagation()}
                    key={env}
                    value={env}
                    disabled={fields.some((e) => e.name === env)}>
                    {env}
                  </MenuItem>
                ))}
                <MenuItem onKeyDown={(e) => e.stopPropagation()} onClick={(e) => e.stopPropagation()} sx={{ px: 0 }}>
                  <Box
                    onMouseDown={(e) => e.stopPropagation()}
                    onClick={(e) => e.stopPropagation()}
                    className="v-center"
                    sx={{ px: 2, width: "100%", gap: 2 }}>
                    <TextField
                      // fullWidth
                      // label="Enter environment name"
                      size="small"
                      sx={{ py: 0, "& input": { py: 0.6 } }}
                      placeholder="Add custom"
                      value={customEnvInput}
                      onChange={(e) => setCustomEnvInput(e.target.value)}
                      InputProps={{ onKeyDown: (e) => e.stopPropagation() }}
                    />
                    <Button size="small" variant="contained" onClick={handleAddCustomEnv}>
                      Add
                    </Button>
                  </Box>
                </MenuItem>
              </Select>
              {showEnvError && !fields?.length && (
                <FormHelperText sx={{ p: 0, m: 0, fontSize: "10px" }}>Select Atleast one Envirounment</FormHelperText>
              )}
            </FormControl>
          </Box>
        )}
      </Box>
    </>
  );
};

export default Environments;
