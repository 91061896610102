import axios from "axios";
import { AuthUrl, KeyManagerUrl } from "../../constants";
import { decryptData, encryptData } from "../../helpers/encryptionUtils";

const login = async (Email, Password) => {
  const data = encryptData(JSON.stringify({ Email, Password }));
  const response = await axios.post(`${AuthUrl}/api/Login/Userlogin`, data);
  return decryptData(response?.data);
};

const logout = async (data) => {
  const reqData = encryptData(JSON.stringify({ UserId: data?.UserId, SessionId: data?.SessionId }));
  const response = await axios.post(`${AuthUrl}/api/Login/UserLogout`, reqData);
  return decryptData(response?.data);
};

const acceptLicense = async (data) => {
  const reqData = encryptData(JSON.stringify({ UserId: data?.UserId }));
  const response = await axios.post(`${AuthUrl}/api/Login/ULAAgreement`, reqData);
  return decryptData(response?.data);
};

const changePassword = async (passwordObj) => {
  const data = encryptData(JSON.stringify(passwordObj));
  const response = await axios.post(`${AuthUrl}/api/Login/ChangePassword`, data);
  return decryptData(response?.data);
};

const getDecryptionkey = (data) => {
  return axios.post(KeyManagerUrl, data);
};

const getRefreshToken = (data) => {
  return axios.post(`${AuthUrl}/api/Login/RefreshToken`, data);
};

export const authServices = { login, logout, acceptLicense, changePassword, getDecryptionkey, getRefreshToken };
