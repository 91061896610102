import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDrop, useDrag } from "react-dnd";
import { useDispatch } from "react-redux";
import { Box, TextField, Typography } from "@mui/material";
import DragIndicatorOutlinedIcon from "@mui/icons-material/DragIndicatorOutlined";
import { setSelectedStep } from "../../../../../Redux/reducers/testcaseSlice";
import CustomButton from "../../../../CustomComponents/CustomButtons/CustomButton";
import CustomLoadingButton from "../../../../CustomComponents/CustomButtons/CustomLoadingButton";
import { useSearchParams } from "react-router-dom";
import { macrosServices } from "../../../../../Redux/services/macros.services";
import { CustomDeleteIcon } from "../../../../CustomComponents/Icons/IconButtons";
import { showToast } from "../../../../../Redux/reducers/toastSlice";

const ItemTypes = {
  STEP: "STEP",
  CREATE_MACRO: "CREATE_MACRO",
};

const DraggableMacroRow = ({ index, step, moveStep, onDelete }) => {
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CREATE_MACRO,
    item: { type: ItemTypes.CREATE_MACRO, row: step, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: ItemTypes.CREATE_MACRO,
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveStep(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  const opacity = isDragging ? 0.5 : 1;

  return (
    <Box ref={(node) => drag(drop(node))} sx={{ opacity, cursor: "pointer" }}>
      <Box className="v-center" gap={0.5} sx={{ border: "1px solid #ccc", p: 0.5, borderRadius: "4px" }}>
        <DragIndicatorOutlinedIcon fontSize="small" color="primary" />
        <Box sx={{ flexGrow: 1 }}>
          <Box className="v-center" sx={{ gap: 1 }}>
            <Typography variant="bold" sx={{ fontSize: "10px" }}>
              {step?.name}
            </Typography>
            <Typography
              sx={{
                background: "#E68200",
                borderRadius: "4px",
                color: "#fff",
                px: 0.5,
                py: 0.1,
                fontSize: "10px",
                fontWeight: 600,
              }}>
              Step - {step?.sequenceNumber}
            </Typography>
          </Box>
          <Typography sx={{ fontSize: "10px" }}>{step?.description || "--"}</Typography>
        </Box>
        <CustomDeleteIcon
          onClick={() => onDelete(index)} // Call delete handler
        />
      </Box>
    </Box>
  );
};

const CreateMacroForm = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const autId = searchParams.get("autId");
  const [loadingCreateMacro, setLoadingCreateMacro] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({});

  const [steps, setSteps] = useState([]);

  const moveStep = (dragIndex, hoverIndex) => {
    const updatedSteps = [...steps];
    const [movedStep] = updatedSteps.splice(dragIndex, 1);
    updatedSteps.splice(hoverIndex, 0, movedStep);
    setSteps(updatedSteps);
  };

  const handleDeleteStep = (index) => {
    const updatedSteps = steps.filter((_, stepIndex) => stepIndex !== index);
    setSteps(updatedSteps);
  };

  const [, drop] = useDrop({
    accept: ItemTypes.STEP,
    drop: (item) => {
      if (item.row?.version) {
        dispatch(showToast({ message: "Macros are not allowed to add as marco step", type: "error" }));
        return;
      }
      setSteps([...steps, item.row]);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  const onClickCancel = () => {
    setSearchParams((prev) => {
      const newState = new URLSearchParams(prev);
      newState.delete("stepViewType");
      return newState;
    });
  };

  const onClickCreateMacro = async (data) => {
    setLoadingCreateMacro(true);
    const payLoad = {
      version: null,
      name: data.name,
      description: data.description,
      autId: autId,
      testStepRequestList: steps,
    };

    await macrosServices.createMacro(payLoad);

    setSearchParams((prev) => {
      const newState = new URLSearchParams(prev);
      newState.set("stepViewType", "macros");
      return newState;
    });
    dispatch(setSelectedStep(null));
    setLoadingCreateMacro(false);
  };

  return (
    <Box ref={drop}>
      <Box
        sx={{
          background: "#F4F1FF",
          px: 1,
          height: 42,
          display: "flex",
          alignItems: "center",
        }}>
        <Typography variant="h6">Create New Macro</Typography>
      </Box>

      <Box
        component="form"
        onSubmit={handleSubmit(onClickCreateMacro)}
        noValidate
        autoComplete="off"
        sx={{ minHeight: 440, maxHeight: 486, gap: 1, p: 1 }}
        className="flex column">
        <TextField
          required
          label="Name"
          size="small"
          fullWidth
          error={Boolean(errors.name)}
          helperText={errors.name?.message}
          {...register("name", {
            required: "Name is required",
            minLength: { value: 4, message: "Minimum length 4 characters" },
          })}
        />

        <TextField
          label="Description"
          size="small"
          fullWidth
          multiline
          rows={3}
          error={Boolean(errors.description)}
          helperText={errors.description?.message}
          {...register("description", {
            maxLength: { value: 200, message: "Maximum length 200 characters" },
          })}
        />

        <Typography variant="bold">Added Steps</Typography>
        <Box className="flex column" sx={{ overflow: "auto", gap: 0.5 }}>
          {steps.map((step, index) => (
            <DraggableMacroRow
              key={index}
              index={index}
              step={step}
              moveStep={moveStep}
              onDelete={handleDeleteStep} // Pass the delete handler
            />
          ))}
        </Box>

        <Box className="v-center h-center" sx={{ p: 1, border: "1px dashed #ccc" }}>
          Drop here Steps
        </Box>

        <Box sx={{ mt: "auto" }} className="space-between">
          <CustomButton variant="outlined" onClick={onClickCancel} color="error">
            Cancel
          </CustomButton>
          <CustomLoadingButton
            loading={loadingCreateMacro}
            disabled={loadingCreateMacro || !steps?.length}
            type="submit">
            Create Macro
          </CustomLoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

export default CreateMacroForm;
