import { Box, Button, Tooltip, Typography } from "@mui/material";
import { getTimeDifference, ExecutionStatusCodes } from "../../../utilities/ReusableFunctions";
import ModalPopup from "../../ReusableComponents/ModalPopup";
import { useState } from "react";
import JiraBugCreate from "../../Reports/ExecutionReports/JiraBugCreate";
import { InfoOutlined as InfoIcon } from "@mui/icons-material";
import { formatDateTime } from "../../../utils/formatDate";

const CustomExecutionBox = ({ title, value }) => {
  const backgroundColors = {
    Executed: "rgba(72, 64, 197, 0.16)",
    Pass: "rgba(0, 193, 99, 0.16)",
    "Skip / Abort": "rgba(255, 153, 0, 0.16)",
    Fail: " rgba(255, 21, 0, 0.16)",
  };

  return (
    <Box
      className="center column"
      sx={{
        p: 1,
        width: "60px",
        height: "40px",
        boxSizing: "border-box",
        flexShrink: 0,
        background: backgroundColors[title],
      }}>
      <Typography
        sx={{
          fontSize: "8px",
          color: "#64748B",
        }}>
        {title}
      </Typography>
      <Typography>{value}</Typography>
    </Box>
  );
};

const Executions = (props) => {
  return (
    <Box sx={{ py: 1, width: "100%", boxSizing: "border-box" }} className="space-between" gap={1}>
      <CustomExecutionBox title="Total" value={props?.data?.noOfSteps} />
      <CustomExecutionBox title="Pass" value={props?.data?.passCount} />
      <CustomExecutionBox title="Skip / Disable" value={props?.data?.otherCount} />
      <CustomExecutionBox title="Fail" value={props?.data?.failCount} />
    </Box>
  );
};

const AuthorDetails = (props) => {
  return (
    <Box className="flex column" gap={0.5}>
      <Typography
        sx={{
          color: "#64748B",
          fontSize: "12px",
          fontWeight: 600,
        }}>
        {props?.data?.executedBy}
      </Typography>

      <Typography
        sx={{
          color: "#6C757D",
          fontSize: "10px",
          fontWeight: 300,
        }}>
        {formatDateTime(props?.data?.executedAt)}
      </Typography>
    </Box>
  );
};

// const ResultComponent = ({ title }) => {
//   const backgroundColors = {
//     Pass: "#E0F6EC",
//     Fail: "#FFEAEB",
//     Abort: "FFF3E5",
//   };

//   const colors = {
//     Pass: "#03A973",
//     Fail: "#F9594B",
//     Abort: "#E68200",
//   };

//   return (
//     <Box
//       sx={{
//         padding: "4px 8px",
//         backgroundColor: backgroundColors[title],
//         borderRadius: "2px",
//         color: colors[title],
//       }}>
//       <Typography>{title}</Typography>
//     </Box>
//   );
// };

const getDuration = (props) => {
  return (
    <Box className="flex column" gap={0.5}>
      <Typography
        sx={{
          color: "#64748B",
          fontSize: "12px",
          fontWeight: 600,
        }}>
        {props?.data?.startedAt ? formatDateTime(props?.data?.startedAt) : ""}
      </Typography>

      <Typography
        sx={{
          color: "#6C757D",
          fontSize: "10px",
          fontWeight: 300,
        }}>
        {getTimeDifference(props?.data?.startedAt, props?.data?.finishedAt)?.readableFormat}
      </Typography>
    </Box>
  );
};

const ExeResult = (params) => {
  const { handleClickRow } = params.context;
  const [createBugData, setCreateBugData] = useState(false);

  const createJira = () => {
    setCreateBugData(true);
  };
  return (
    <>
      <Box className="v-center">
        <Tooltip title={params?.data?.remarks} placement="top-end" arrow>
          <Button
            size="small"
            className="v-center"
            sx={{
              background: ExecutionStatusCodes[params?.data?.result].color,
              color: "#fff",
              p: "3px 5px",
              gap: "5px",
              "&:hover": {
                background: ExecutionStatusCodes[params?.data?.result].hoverColor,
              },
            }}
            onClick={() => handleClickRow(params?.data)}>
            {params?.data?.result}{" "}
            {params?.data?.result === "DISCARDED" ? <InfoIcon sx={{ fontSize: "18px", pb: "2px" }} /> : ""}{" "}
          </Button>
        </Tooltip>
        {params?.data?.result === "FAILED" && (
          <Button size="small" variant="contained" color="primary" className="jiraTktBtn" onClick={createJira}>
            Create Bug
          </Button>
        )}
      </Box>

      {createBugData && (
        <ModalPopup
          open={true}
          width={"40%"}
          setOpen={setCreateBugData}
          data={<JiraBugCreate setCreateBugData={setCreateBugData} />}
        />
      )}
    </>
  );
};
export const ExecutionsHeadCells = [
  // {
  //   sortable: false,
  //   headerCheckboxSelection: true,
  //   checkboxSelection: true,
  //   lockPosition: "left",
  //   cellClass: "locked-col",
  //   suppressColumnsToolPanel: true,
  //   suppressHeaderMenuButton: true,
  //   minWidth: 50,
  //   filter: false,
  // },

  // {
  //   headerName: "S.No",
  //   sortable: true,
  //   valueGetter: "node.rowIndex + 1",
  //   suppressColumnsToolPanel: true,
  //   filter: false,
  //   suppressHeaderMenuButton: true,
  //   headerTooltip: "S.No",
  //   width: 75,
  //   maxWidth: 75,
  //   minWidth: 100,
  // },

  {
    field: "executionName",
    headerName: "Execution",
    sortable: true,
    filter: false,
    suppressHeaderMenuButton: true,
    headerTooltip: "Execution Name",
    cellClass: "non-editable",
    minWidth: 150,
  },
  {
    field: "testDataName",
    headerName: "Test Data",
    sortable: true,
    suppressColumnsToolPanel: true,
    filter: true,
    // comparator: customComparator,
    headerTooltip: "Test Case Name",
    flex: 1.5,
    minWidth: 150,
  },
  {
    field: "browser",
    headerName: "Browser",
    sortable: true,
    filter: false,
    suppressHeaderMenuButton: true,
    headerTooltip: "Browser",
    cellClass: "non-editable",
    minWidth: 100,
    maxWidth: 100,
  },
  {
    field: "executedBy",
    headerName: "Executed By",
    sortable: true,
    filter: true,
    headerTooltip: "Executed By",
    flex: 1.5,
    cellRenderer: AuthorDetails,
    cellStyle: { padding: 8 },
    minWidth: 150,
  },
  {
    field: "startedAt",
    headerName: "Started At",
    sortable: true,
    filter: true,
    headerTooltip: "Started At",
    flex: 1.5,
    cellRenderer: getDuration,
    cellStyle: { padding: 8 },
    minWidth: 150,
  },
  {
    field: "",
    headerName: "Steps",
    sortable: true,
    suppressColumnsToolPanel: true,
    filter: true,
    headerTooltip: "Created Date",
    cellRenderer: Executions,
    minWidth: 280,
    maxWidth: 280,
    flex: 2.5,
    cellStyle: { padding: 8 },
  },
  {
    field: "result",
    headerName: "Result / Status",
    sortable: true,
    filter: true,
    headerTooltip: "Result",
    cellRenderer: ExeResult,
    pinned: "right",
    flex: 1.5,
    lockPinned: true,
    cellClass: "lock - pinned",
    cellStyle: { padding: 8 },
  },
];
