import { useEffect, useRef, useState } from "react";
import { Box, Grid } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import CustomReactAgGrid from "../../CustomComponents/CustomReactAgGrid";
import { ExecutionsListModel } from "./ExecutionsListModel";
import ExecutionActions from "./ExecutionActions";
import { autServices } from "../../../Redux/services/aut.services";
import ResultTile from "../../TestCases/Executions/ResultTile";
import PreviewTestData from "./PreviewTestData";
// import PreviewTestData from "../ExecutionReports/PreviewTestData/index";

// const executionTypes = ["Cicd", "Scheduler", "Manual"];

// const SelectExecutionType = ({ executionType, setExecutionType }) => {
//   const handleChange = (event) => {
//     setExecutionType(event.target.value);
//   };

//   return (
//     <Box className="v-center" gap={2}>
//       <FormControl
//         className="lineargradient-formcontrol"
//         fullWidth
//         variant="outlined"
//         size="small"
//         sx={{ maxWidth: "180px" }}>
//         <InputLabel id="execution-type-select-label">Execution Type</InputLabel>
//         <Select
//           size="small"
//           labelId="execution-type-select-label"
//           id="execution-type-select"
//           value={executionType}
//           onChange={handleChange}
//           label="Execution Type"
//           input={<OutlinedInput label="Execution Type" />}>
//           {executionTypes.map((type, index) => (
//             <MenuItem value={type} key={index}>
//               {type}
//             </MenuItem>
//           ))}
//         </Select>
//       </FormControl>
//     </Box>
//   );
// };

const ExecutionsList = () => {
  const gridRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const autType = searchParams.get("autType");
  const autId = Number.parseInt(searchParams.get("autId"));
  const selectedTab = searchParams.get("selectedTab");
  const viewType = searchParams.get("view");
  const exeInstanceId = searchParams.get("exeInstanceId");

  // const [showPreviewData, setShowPreviewData] = useState(false);
  const [autExeList, setAutExeList] = useState([]);
  // const [executionType, setExecutionType] = useState("");

  const handleClickExeRow = (row) => {
    // setSearchParams((prev) => {
    //   const newState = new URLSearchParams(prev);
    //   newState.set("exeInstanceId", row?.id);
    //   return newState;
    // });
    return navigate(`/executionsreport/${row?.id}?autType=${row?.autType}`);

    // setShowPreviewData(true);
  };

  const getAutExecutionsList = async () => {
    try {
      const { data } = await autServices.getAutExecutionsList(autId, autType, selectedTab);
      const formattedAutExeList = data?.executionResponseList?.map((el) => ({
        ...el,
        autName: el?.autDetails?.name,
        autType: el?.autDetails?.type,
        configName: el?.executionConfigDetails?.name,
      }));
      setAutExeList(formattedAutExeList || []);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getAutExecutionsList();
  }, [autType, selectedTab, autId]);

  const handleClickRow = (executionResult) => {
    setSearchParams((prevParams) => {
      const newParams = new URLSearchParams(prevParams);
      newParams.set("exeInstanceId", executionResult?.id);
      return newParams;
    });
  };

  const handleShowPreviewTestData = () => {
    setSearchParams((prev) => {
      const newState = new URLSearchParams(prev);
      newState.delete("exeInstanceId");
      return newState;
    });
  };

  return (
    <Box
      className="executionsListContainer"
      sx={{ pb: 6, "& .ag-root-wrapper": { borderRadius: "0px", border: "0px", boxShadow: "none" } }}>
      <ExecutionActions
        // item={<SelectExecutionType executionType={executionType} setExecutionType={setExecutionType} />}
        gridRef={gridRef}
      />
      {viewType === "grid" ? (
        <Grid className="gridExecutionList" container>
          {autExeList.map((obj, index) => (
            <Grid key={index} item sm={12} md={6} lg={4} xl={4}>
              <ResultTile executionResult={obj} handleClickRow={handleClickRow} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <CustomReactAgGrid
          gridRef={gridRef}
          columnDefs={ExecutionsListModel}
          rowData={autExeList || []}
          handleClickExeRow={handleClickExeRow}
          context={{ handleClickExeRow }}
        />
      )}

      {exeInstanceId && <PreviewTestData setPreviewTestData={handleShowPreviewTestData} />}
    </Box>
  );
};

export default ExecutionsList;
