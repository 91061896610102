import { Box, Typography } from "@mui/material";
// import { useSearchParams } from "react-router-dom";

// import CustomToggleButton from "../../CustomComponents/CustomButtons/CustomToggleButton";
const ExecutionActions = () => {
  // const [searchParams, setSearchParams] = useSearchParams();
  // const viewType = searchParams.get("view");

  // const handleToggleView = (event, newViewType) => {
  //   if (newViewType) {
  //     setSearchParams((prev) => {
  //       const newState = new URLSearchParams(prev);
  //       newState.set("view", newViewType);
  //       return newState;
  //     });
  //   }
  // };

  return (
    <Box
      className="space-between"
      sx={{
        borderRadius: "12px 12px 0 0",
        backgroundColor: "#fff",
        height: "48px",
        boxSizing: "border-box",
        px: 2,
      }}>
      <Typography variant="h6">Executions List</Typography>

      <Box className="v-center" gap={2}>
        {/* {item} */}
        {/* <CustomToggleButton viewType={viewType || "list"} onChange={handleToggleView} /> */}
      </Box>
    </Box>
  );
};

export default ExecutionActions;
