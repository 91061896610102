import { useCallback, useMemo } from "react";
import { Box, Grid, Table, TableContainer, Typography } from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedStepsData, setTestStepsData } from "../../../../Redux/reducers/testcaseSlice";
import CustomTablePagination from "../../../CustomComponents/CustomTablePagination";
import HeaderTableCells from "./HeaderTableCells";
import TableRows from "./TableRows";
import { useLocation, useSearchParams } from "react-router-dom";
import CustomButton from "../../../CustomComponents/CustomButtons/CustomButton";
import { BulkEditButton, CustomDeleteIcon } from "../../../CustomComponents/Icons/IconButtons";
import { useCheckAccess } from "../../../../CustomHooks/useCheckAccess";
const newStep = {
  id: null,
  name: "",
  description: "",
  source: null,
  elementLocator: null,
  action: "",
  screenshot: false,
  storeVariable: null,
  definedClass: null,
  isDisable: false,
  tdmAttributeType: null,
  tdmAttributeProperties: null,
  consecutiveThreshold: null,
  retryDuration: null,
  isSensitive: false,
};

const useStepViewType = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const stepViewType = searchParams.get("stepViewType");

  const updateStepViewType = (type) => {
    setSearchParams((prev) => {
      const newState = new URLSearchParams(prev);
      newState.set("stepViewType", type);
      return newState;
    });
  };

  return { stepViewType, updateStepViewType };
};
const BulkEdit = ({ rowData, setRowData, page, rowsPerPage, setPage, setRowsPerPage }) => {
  const location = useLocation();
  const { pathname } = location;
  const { checkAccess } = useCheckAccess();
  const dispatch = useDispatch();
  const { stepViewType, updateStepViewType } = useStepViewType();

  const [, setSearchParams] = useSearchParams();
  const selectedStepsData = useSelector((state) => state.testcase.selected_steps_data);

  const isDisabled = rowData?.some((item, index, array) => {
    if (item.name === "") {
      return true;
    }
    return array.findIndex((obj) => obj.name === item.name) !== index;
  });

  const formattedData = useMemo(
    () => rowData?.slice((page - 1) * rowsPerPage, page * rowsPerPage),
    [rowData, page, rowsPerPage],
  );

  const handleChangePage = useCallback(
    (event, newPage) => {
      setPage(newPage);
      if (selectedStepsData.length) {
        dispatch(setSelectedStepsData([]));
      }
    },
    [selectedStepsData],
  );

  const handleChangeRowsPerPage = useCallback(
    (event) => {
      setRowsPerPage(Number(event.target.value));
      setPage(1);
      if (selectedStepsData.length) {
        dispatch(setSelectedStepsData([]));
      }
    },
    [selectedStepsData],
  );
  const toggleBulkEdit = () => {
    dispatch(setTestStepsData(rowData));
    setSearchParams((prev) => {
      const newState = new URLSearchParams(prev);
      newState.set("isBulkEdit", false);
      return newState;
    });
  };

  const handleAddStep = () => {
    const newStepsData = [...rowData, { ...newStep, sequenceNumber: rowData?.length + 1 }];
    setRowData(newStepsData);
    const newPage = Math.ceil(newStepsData.length / rowsPerPage);
    setPage(newPage);
  };

  const handleDeleteSteps = () => {
    const stepsIdsToDelete = selectedStepsData.map((step) => step.sequenceNumber);
    const filteredData = rowData.filter((step) => !stepsIdsToDelete.includes(step.sequenceNumber));
    setRowData(filteredData);
    dispatch(setSelectedStepsData([]));
  };

  const onClickMacros = () => {
    updateStepViewType("macros");
    // dispatch(setSelectedStep(null));
  };

  const onClickCreateMacros = () => {
    updateStepViewType("createMacros");
    // dispatch(setSelectedStep(null));
  };

  return (
    <Grid
      item
      xs={stepViewType ? 8 : 12}
      //  ref={drop}
    >
      <Box className="space-between" sx={{ background: "#F4F1FF", px: 1, height: 42 }}>
        <Box className="v-center" ml={1} gap={1}>
          <Typography variant="bold">
            List of Steps -{" "}
            <Typography variant="bold" sx={{ color: "#E68200" }} component="span">
              [ {rowData?.length} ]
            </Typography>
          </Typography>

          <BulkEditButton disabled={isDisabled} onClick={toggleBulkEdit}>
            Advanced Edit
          </BulkEditButton>
        </Box>
        <Box className="v-center">
          <CustomDeleteIcon
            onClick={handleDeleteSteps}
            color="error"
            disabled={!selectedStepsData?.length}
            size="small"
          />

          {!pathname?.includes("macros") && (
            <>
              {checkAccess("Macros", "Create") && (
                <CustomButton
                  // disabled={!selectedStepsData?.length}
                  sx={{ mr: 1, py: 0.2 }}
                  onClick={onClickCreateMacros}
                  color="warning"
                  variant="outlined">
                  Create Macro
                </CustomButton>
              )}
              <CustomButton
                // disabled={!selectedStepsData?.length}
                sx={{ mr: 1, py: 0.2 }}
                onClick={onClickMacros}
                color="error"
                variant="outlined">
                Macros
              </CustomButton>
            </>
          )}

          <CustomButton
            sx={{ py: 0.2 }}
            onClick={handleAddStep}
            color="success"
            variant="outlined"
            startIcon={<AddOutlinedIcon />}>
            Add Step
          </CustomButton>
        </Box>
      </Box>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <HeaderTableCells
            stepViewType={stepViewType}
            isSelectedAll={formattedData.length && selectedStepsData.length === formattedData.length}
            formattedData={formattedData}
          />
        </Table>
        <Table
          sx={{
            borderBottom: "1px solid #ADB5BD80",
            "& tr": { "& td": { p: "0px 10px", height: 50, borderLeft: "none !important" } },
          }}>
          <TableRows
            page={page}
            rowsPerPage={rowsPerPage}
            setRowData={setRowData}
            rowData={rowData}
            formattedData={formattedData}
          />
        </Table>
      </TableContainer>

      {rowData?.length > 0 && (
        <CustomTablePagination
          currentPage={page}
          rowsLength={rowData?.length}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </Grid>
  );
};

export default BulkEdit;
