import { Box, IconButton, Tooltip, useTheme, Typography } from "@mui/material";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";

export const CustomPreviewIcon = ({ title, onClick, testData, disabled = false, ...props }) => {
  const theme = useTheme();
  const color = theme.palette.primary.main;
  const fillColor = disabled ? "#8FA1AA" : color;

  return (
    <Tooltip className="v-center" title={title} placement="top" arrow>
      <IconButton
        size="small"
        title={title}
        onClick={() => onClick(testData?.id)}
        color={disabled ? "default" : "primary"}
        disabled={disabled}
        {...props}>
        <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12.499 19.3302C10.4739 19.3353 8.48802 18.7581 6.76741 17.6645C5.04681 16.5708 3.66058 15.0045 2.76647 13.1438C2.73059 13.07 2.71191 12.9887 2.71191 12.9063C2.71191 12.8239 2.73059 12.7426 2.76647 12.6688C3.66456 10.8114 5.05172 9.24787 6.77142 8.15476C8.49113 7.06165 10.4749 6.48242 12.499 6.48242C14.5231 6.48242 16.5069 7.06165 18.2266 8.15476C19.9463 9.24787 21.3335 10.8114 22.2316 12.6688C22.2675 12.7426 22.2862 12.8239 22.2862 12.9063C22.2862 12.9887 22.2675 13.07 22.2316 13.1438C21.3375 15.0045 19.9513 16.5708 18.2306 17.6645C16.51 18.7581 14.5242 19.3353 12.499 19.3302ZM3.8183 12.9063C4.65253 14.5233 5.90172 15.8767 7.43134 16.8207C8.96097 17.7647 10.7131 18.2635 12.499 18.2635C14.285 18.2635 16.0371 17.7647 17.5667 16.8207C19.0963 15.8767 20.3455 14.5233 21.1798 12.9063C20.3455 11.2893 19.0963 9.93592 17.5667 8.99193C16.0371 8.04794 14.285 7.54908 12.499 7.54908C10.7131 7.54908 8.96097 8.04794 7.43134 8.99193C5.90172 9.93592 4.65253 11.2893 3.8183 12.9063ZM12.499 16.6396C11.778 16.6396 11.0731 16.4206 10.4735 16.0104C9.87396 15.6002 9.40667 15.0171 9.13072 14.335C8.85478 13.6528 8.78258 12.9022 8.92325 12.178C9.06393 11.4538 9.41116 10.7886 9.92104 10.2665C10.4309 9.74436 11.0805 9.3888 11.7878 9.24475C12.495 9.1007 13.228 9.17463 13.8942 9.45719C14.5604 9.73976 15.1298 10.2183 15.5304 10.8322C15.931 11.4461 16.1449 12.1679 16.1449 12.9063C16.1437 13.8961 15.7592 14.845 15.0757 15.5448C14.3923 16.2447 13.4656 16.6384 12.499 16.6396ZM12.499 10.2397C11.984 10.2397 11.4805 10.3961 11.0522 10.6891C10.624 10.9821 10.2902 11.3986 10.0931 11.8858C9.89599 12.3731 9.84442 12.9093 9.9449 13.4265C10.0454 13.9438 10.2934 14.419 10.6576 14.7919C11.0218 15.1648 11.4858 15.4188 11.991 15.5217C12.4961 15.6246 13.0198 15.5718 13.4956 15.37C13.9715 15.1681 14.3782 14.8263 14.6643 14.3878C14.9505 13.9493 15.1032 13.4337 15.1032 12.9063C15.1025 12.1993 14.8279 11.5215 14.3397 11.0215C13.8514 10.5216 13.1895 10.2404 12.499 10.2397Z"
            fill={fillColor}
          />
        </svg>
      </IconButton>
    </Tooltip>
  );
};

export const CustomPreviewOutlinedIcon = ({ title = "Preview", onClick, disabled = false, ...props }) => {
  return (
    <Tooltip className="v-center" title={title} placement="top" arrow>
      <IconButton title={title} onClick={onClick} disabled={disabled} {...props}>
        <svg width="40" height="40" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_603_27987)">
            <path
              d="M1.04167 14.5827C0.466667 14.5827 0 14.116 0 13.541V9.37435C0 6.50352 2.33542 4.16602 5.20833 4.16602H9.375C9.95 4.16602 10.4167 4.63268 10.4167 5.20768C10.4167 5.78268 9.95 6.24935 9.375 6.24935H5.20833C3.48542 6.24935 2.08333 7.65143 2.08333 9.37435V13.541C2.08333 14.116 1.61667 14.5827 1.04167 14.5827Z"
              fill="white"
            />
            <path
              d="M48.959 14.5827C48.384 14.5827 47.9173 14.116 47.9173 13.541V9.37435C47.9173 7.65143 46.5152 6.24935 44.7923 6.24935H40.6257C40.0507 6.24935 39.584 5.78268 39.584 5.20768C39.584 4.63268 40.0507 4.16602 40.6257 4.16602H44.7923C47.6652 4.16602 50.0007 6.50352 50.0007 9.37435V13.541C50.0007 14.116 49.534 14.5827 48.959 14.5827Z"
              fill="white"
            />
            <path
              d="M9.375 45.8327H5.20833C2.33542 45.8327 0 43.4952 0 40.6243V36.4577C0 35.8827 0.466667 35.416 1.04167 35.416C1.61667 35.416 2.08333 35.8827 2.08333 36.4577V40.6243C2.08333 42.3473 3.48542 43.7493 5.20833 43.7493H9.375C9.95 43.7493 10.4167 44.216 10.4167 44.791C10.4167 45.366 9.95 45.8327 9.375 45.8327Z"
              fill="white"
            />
            <path
              d="M44.7923 45.8327H40.6257C40.0507 45.8327 39.584 45.366 39.584 44.791C39.584 44.216 40.0507 43.7493 40.6257 43.7493H44.7923C46.5152 43.7493 47.9173 42.3473 47.9173 40.6243V36.4577C47.9173 35.8827 48.384 35.416 48.959 35.416C49.534 35.416 50.0007 35.8827 50.0007 36.4577V40.6243C50.0007 43.4952 47.6652 45.8327 44.7923 45.8327Z"
              fill="white"
            />
            <path
              d="M25.0007 37.5C16.9736 37.5 10.134 32.4625 8.36523 25.2479C8.32357 25.0854 8.32357 24.9146 8.36523 24.7521C10.134 17.5375 16.9736 12.5 25.0007 12.5C33.0277 12.5 39.8673 17.5375 41.6361 24.7521C41.6777 24.9146 41.6777 25.0854 41.6361 25.2479C39.8673 32.4625 33.0277 37.5 25.0007 37.5ZM10.4507 25C12.0882 31.1458 18.0319 35.4167 25.0007 35.4167C31.9694 35.4167 37.9132 31.1458 39.5507 25C37.9132 18.8542 31.9715 14.5833 25.0007 14.5833C18.0298 14.5833 12.0882 18.8542 10.4507 25Z"
              fill="white"
            />
            <path
              d="M25 31.25C21.5542 31.25 18.75 28.4458 18.75 25C18.75 21.5542 21.5542 18.75 25 18.75C28.4458 18.75 31.25 21.5542 31.25 25C31.25 28.4458 28.4458 31.25 25 31.25ZM25 20.8333C22.7021 20.8333 20.8333 22.7021 20.8333 25C20.8333 27.2979 22.7021 29.1667 25 29.1667C27.2979 29.1667 29.1667 27.2979 29.1667 25C29.1667 22.7021 27.2979 20.8333 25 20.8333Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_603_27987">
              <rect width="40" height="40" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </IconButton>
    </Tooltip>
  );
};

export const CustomEditIcon = ({
  title = "Edit",
  size = "small",
  color = "#611EB6",
  width = "20",
  height = "22",
  onClick,
  disabled = false,
  ...rest
}) => {
  const defaultColor = color || "#000"; // Default color
  const disabledColor = "#B0B0B0"; // Color for disabled state

  return (
    <Tooltip className="v-center" title={title} placement="top" arrow>
      <IconButton size={size} onClick={onClick} disabled={disabled} {...rest}>
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 22" fill="none">
          <g clipPath="url(#clip0_3073_38304)">
            <path
              d="M15.8333 0H4.16667C1.86917 0 0 2.01086 0 4.48253V17.0336C0 19.5053 1.86917 21.5161 4.16667 21.5161H15.8333C18.1308 21.5161 20 19.5053 20 17.0336V4.48253C20 2.01086 18.1308 0 15.8333 0ZM18.3333 17.0336C18.3333 18.5164 17.2117 19.7231 15.8333 19.7231H4.16667C2.78833 19.7231 1.66667 18.5164 1.66667 17.0336V4.48253C1.66667 2.99971 2.78833 1.79301 4.16667 1.79301H15.8333C17.2117 1.79301 18.3333 2.99971 18.3333 4.48253V17.0336ZM11.5658 5.71881L5.97667 11.7317C5.3475 12.4085 5 13.3095 5 14.267V15.6888C5 16.1846 5.37333 16.5853 5.83333 16.5853H7.155C8.045 16.5853 8.8825 16.2124 9.51167 15.5346L15.1008 9.52178C16.0758 8.47287 16.0758 6.76772 15.1008 5.71881C14.1558 4.70307 12.51 4.70307 11.5658 5.71881ZM8.33333 14.267C8.0225 14.6005 7.59333 14.7923 7.155 14.7923H6.66667V14.267C6.66667 13.7883 6.84 13.3382 7.155 12.9993L10.6967 9.18918L11.875 10.4568L8.33333 14.267ZM13.9225 8.25412L13.0533 9.18918L11.875 7.92152L12.7442 6.98647C13.0583 6.64669 13.6075 6.64669 13.9225 6.98647C14.2475 7.3361 14.2475 7.90449 13.9225 8.25412Z"
              fill={disabled ? disabledColor : defaultColor}
            />
          </g>
          <defs>
            <clipPath id="clip0_3073_38304">
              <rect width="20" height="21.5161" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </IconButton>
    </Tooltip>
  );
};

export const CustomDeleteIcon = ({
  title = "delete",
  size = "small",
  placement = "top",
  disabled = false,
  height = 20,
  width = 20,
  onClick,
  ...rest
}) => {
  const theme = useTheme();
  const color = theme.palette.error.main;

  const defaultColor = color || "#000"; // Default color
  const disabledColor = "#B0B0B0"; // Color for disabled state

  return (
    <Tooltip className="v-center" title={title} placement={placement} arrow>
      <IconButton disabled={disabled} size={size} onClick={onClick} {...rest}>
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 22 22" fill="none">
          <g clipPath="url(#clip0_3073_38458)">
            <path
              d="M19.2507 3.66667H16.409C16.1962 2.63213 15.6333 1.70257 14.8151 1.03466C13.9969 0.366749 12.9735 0.00133331 11.9173 0L10.084 0C9.0278 0.00133331 8.00436 0.366749 7.18617 1.03466C6.36798 1.70257 5.80507 2.63213 5.59232 3.66667H2.75065C2.50754 3.66667 2.27438 3.76324 2.10247 3.93515C1.93056 4.10706 1.83398 4.34022 1.83398 4.58333C1.83398 4.82645 1.93056 5.05961 2.10247 5.23151C2.27438 5.40342 2.50754 5.5 2.75065 5.5H3.66732V17.4167C3.66877 18.6318 4.15213 19.7967 5.01135 20.656C5.87058 21.5152 7.03552 21.9985 8.25065 22H13.7507C14.9658 21.9985 16.1307 21.5152 16.99 20.656C17.8492 19.7967 18.3325 18.6318 18.334 17.4167V5.5H19.2507C19.4938 5.5 19.7269 5.40342 19.8988 5.23151C20.0707 5.05961 20.1673 4.82645 20.1673 4.58333C20.1673 4.34022 20.0707 4.10706 19.8988 3.93515C19.7269 3.76324 19.4938 3.66667 19.2507 3.66667ZM10.084 1.83333H11.9173C12.4859 1.83403 13.0404 2.01059 13.5046 2.33882C13.9689 2.66705 14.3203 3.13087 14.5106 3.66667H7.49073C7.68102 3.13087 8.03239 2.66705 8.49667 2.33882C8.96095 2.01059 9.5154 1.83403 10.084 1.83333ZM16.5007 17.4167C16.5007 18.146 16.2109 18.8455 15.6952 19.3612C15.1795 19.8769 14.48 20.1667 13.7507 20.1667H8.25065C7.52131 20.1667 6.82183 19.8769 6.30611 19.3612C5.79038 18.8455 5.50065 18.146 5.50065 17.4167V5.5H16.5007V17.4167Z"
              fill={disabled ? disabledColor : defaultColor}
            />
            <path
              d="M9.16667 16.4993C9.40978 16.4993 9.64294 16.4028 9.81485 16.2309C9.98676 16.059 10.0833 15.8258 10.0833 15.5827V10.0827C10.0833 9.83957 9.98676 9.60641 9.81485 9.4345C9.64294 9.26259 9.40978 9.16602 9.16667 9.16602C8.92355 9.16602 8.69039 9.26259 8.51849 9.4345C8.34658 9.60641 8.25 9.83957 8.25 10.0827V15.5827C8.25 15.8258 8.34658 16.059 8.51849 16.2309C8.69039 16.4028 8.92355 16.4993 9.16667 16.4993Z"
              fill={disabled ? disabledColor : defaultColor}
            />
            <path
              d="M12.8327 16.4993C13.0758 16.4993 13.309 16.4028 13.4809 16.2309C13.6528 16.059 13.7493 15.8258 13.7493 15.5827V10.0827C13.7493 9.83957 13.6528 9.60641 13.4809 9.4345C13.309 9.26259 13.0758 9.16602 12.8327 9.16602C12.5896 9.16602 12.3564 9.26259 12.1845 9.4345C12.0126 9.60641 11.916 9.83957 11.916 10.0827V15.5827C11.916 15.8258 12.0126 16.059 12.1845 16.2309C12.3564 16.4028 12.5896 16.4993 12.8327 16.4993Z"
              fill={disabled ? disabledColor : defaultColor}
            />
          </g>
          <defs>
            <clipPath id="clip0_3073_38458">
              <rect width={22} height={22} fill="white" />
            </clipPath>
          </defs>
        </svg>
      </IconButton>
    </Tooltip>
  );
};

export const CustomExpandIcon = ({
  title = "Expand",
  size = "small",
  color = "",
  height = "24",
  width = "24",
  onClick,
  disabled = false,
  ...rest
}) => {
  const defaultColor = color || "#00000069"; // Default color
  const disabledColor = "#B0B0B0"; // Color for disabled state

  return (
    <Tooltip className="v-center" title={title} placement="top" arrow>
      <IconButton size={size} onClick={onClick} disabled={disabled} {...rest}>
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M18.1249 10.6975C17.7331 10.6975 17.4999 10.38 17.4999 9.98825V7.64825L14.1471 10.9412C14.0086 11.0798 13.7974 11.149 13.6159 11.149C13.4344 11.149 13.2379 11.0798 13.0994 10.9412C12.8224 10.6642 12.8149 10.1572 13.0921 9.88025L16.4069 6.5H14.0089C13.6171 6.5 13.2996 6.26675 13.2996 5.875C13.2996 5.48325 13.6174 5.25 14.0089 5.25H17.6329C18.2759 5.25 18.7499 5.72125 18.7499 6.3645V9.98825C18.7499 10.38 18.5166 10.6975 18.1249 10.6975Z"
            fill={disabled ? disabledColor : defaultColor}
          />
          <path
            d="M7.76965 17.25H10.1677C10.5594 17.25 10.8769 17.4833 10.8769 17.875C10.8769 18.2668 10.5594 18.5 10.1677 18.5H6.5439C5.90065 18.5 5.50015 18.1258 5.50015 17.4825V13.8585C5.50015 13.4668 5.7334 13.1493 6.12515 13.1493C6.5169 13.1493 6.75015 13.4668 6.75015 13.8585V16.1985L10.0662 12.9055C10.3432 12.6285 10.8037 12.6285 11.0804 12.9055C11.3574 13.1825 11.3632 13.6413 11.0859 13.918L7.76965 17.25Z"
            fill={disabled ? disabledColor : defaultColor}
          />
          <path
            d="M22 3.4185C22 2.635 21.365 2 20.5815 2H3.4185C2.635 2 2 2.635 2 3.4185V20.5815C2 21.365 2.635 22 3.4185 22H20.5815C21.365 22 22 21.365 22 20.5815V3.4185ZM20.75 20.5H3.5V3.25H20.75V20.5Z"
            fill={disabled ? disabledColor : defaultColor}
          />
        </svg>
      </IconButton>
    </Tooltip>
  );
};

export const BulkEditButton = ({ onClick, disabled, children }) => {
  return (
    <Box
      onClick={(e) => (disabled ? null : onClick(e))}
      className="v-center"
      sx={{
        gap: 0.5,
        py: 0.5,
        px: 1,
        borderRadius: 1,
        mr: 0.5,
        border: "1px solid",
        borderColor: disabled ? "#CCC" : "#611EB6",
        cursor: disabled ? "not-allowed" : "pointer",
        color: disabled ? "#CCC" : "#611EB6",
        pointerEvents: disabled ? "none" : "auto",
        backgroundColor: disabled ? "#F0F0F0" : "transparent",
      }}>
      <ModeEditOutlineIcon fontSize="10px" color="#611EB6" />

      <Typography variant="body2">{children}</Typography>
    </Box>
  );
};
