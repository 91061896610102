import { useEffect, useState, useCallback } from "react";
import { Box, Card, Divider, Grid, Typography } from "@mui/material";
import { useLocation, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import MacroHelpInfo from "../SelectAutType/MacroHelpInfo";
import TestCaseHelpInfo from "./TestCaseHelpInfo";
import SelectedAutList from "./SelectedAutList";
import { autServices } from "../../Redux/services/aut.services";
import ConfigHelpInfo from "./ConfigHelpInfo";
import { useCheckAccess } from "../../CustomHooks/useCheckAccess";

const LABELS = { macros: "Macros", testcases: "Test Cases", configs: "Configs" };

const HELP_COMPONENTS = { macros: <MacroHelpInfo />, testcases: <TestCaseHelpInfo />, configs: <ConfigHelpInfo /> };

const APP_COUNT_KEYS = { macros: "noOfMacros", testcases: "noOfTestCases", configs: "noOfExecutionConfigs" };

const selectedAutTypeStyle = {
  border: "1px solid #66A2EE",
  background: "linear-gradient(90deg, #D9D7FF 0%, #EBD2FF 100%)",
  boxShadow: "0px 4px 4px 0px rgba(10, 10, 10, 0.25) !important",
  "&:hover": { transform: "none" },
};
const SelectAutType = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [appTypes, setAppTypes] = useState([]);
  const { checkAccess } = useCheckAccess();

  const autType = searchParams.get("autType");
  const { pathname } = location;
  const currentPath = pathname.split("/").pop();
  const heading = LABELS[currentPath];
  const countKey = APP_COUNT_KEYS[currentPath];

  const fetchAppTypes = useCallback(async () => {
    try {
      const response = await dispatch(autServices.fetchAppTypes());
      setAppTypes(response.payload);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    fetchAppTypes();
  }, []);

  const handleAutTypeClick = (type) => {
    setSearchParams((params) => {
      params.set("autType", type);
      return params;
    });
    dispatch(autServices.fetchListOfAuts(type));
  };

  const renderApplicationCard = (appType, index) => {
    const { type, noOfAuts, disable } = appType;
    const totalCount = appType[countKey] || 0;

    return (
      <Box key={index} sx={{ gridColumn: "span 1" }}>
        <Card
          onClick={() => {
            disable ? null : handleAutTypeClick(type);
          }}
          className={`space-between ${checkAccess(type, "Hide") || disable ? "disabledapplicationTile2" : ""}`}
          sx={{
            p: 3,
            py: 2,
            borderRadius: "8px",
            "&:hover": {
              transform: disable ? "none" : "translateY(-5px)",
            },
            ...(!disable && {
              cursor: "pointer",
              transition: "transform 0.3s ease",
              "&:hover": { transform: "translateY(-5px)" },
            }),
            ...(autType === type && selectedAutTypeStyle),
          }}>
          <Box sx={{ width: "50%" }} className="center column">
            <img src={`./applicationTypes/${type?.toLowerCase()}.svg`} alt={type} />
            <Typography variant="h6" sx={{ mt: 1, color: "#64748B" }}>
              {type}
            </Typography>
          </Box>
          <Divider variant="middle" orientation="vertical" flexItem />
          <Box sx={{ width: "50%" }} className="center column">
            <Typography variant="h6" sx={{ mt: 1, color: "#64748B" }}>
              Applications
            </Typography>
            <Typography sx={{ color: "#E68200", fontSize: 20 }} variant="h6">
              {noOfAuts}
            </Typography>
            <Typography variant="h6" sx={{ mt: 1, color: "#64748B" }}>
              {heading}
            </Typography>
            <Typography sx={{ color: "#E68200", fontSize: 20 }} variant="h6">
              {totalCount}
            </Typography>
          </Box>
        </Card>
      </Box>
    );
  };

  // const totalApplications = appTypes.reduce((acc, appType) => acc + (appType.noOfAuts || 0), 0);
  // const totalCount = appTypes.reduce((acc, appType) => acc + (appType[countKey] || 0), 0);

  return (
    <Box sx={{ display: "flex" }}>
      <Box sx={{ flexGrow: 1, mr: "30%" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box sx={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: 2 }}>
              {/* <Box sx={{ gridColumn: "span 1", gridRow: "span 2" }}> */}
              {/* <Card sx={{ height: "100%", p: 2, py: 1, borderRadius: "8px" }}>
                  <Box className="center column" sx={{ height: "50%" }}>
                    <img src={APPLICATION_IMAGES.ANDROID} height={32} width={32} alt="ANDROID" />
                    <Typography sx={{ color: "#64748B" }} variant="h6">
                      Total Applications
                    </Typography>
                    <Typography sx={{ color: "#E68200", fontSize: 20 }} variant="h6">
                      {totalApplications}
                    </Typography>
                  </Box>
                  <Divider variant="middle" />
                  <Box className="center column" sx={{ height: "50%" }}>
                    <img src={APPLICATION_IMAGES.ANDROID} height={32} width={32} alt="Android" />
                    <Typography sx={{ color: "#64748B" }} variant="h6">
                      Total {heading}
                    </Typography>
                    <Typography sx={{ color: "#E68200", fontSize: 20 }} variant="h6">
                      {totalCount}
                    </Typography>
                  </Box>
                </Card> */}
              {/* </Box> */}
              {appTypes?.map(renderApplicationCard)}
            </Box>
          </Grid>
        </Grid>
      </Box>

      {HELP_COMPONENTS[currentPath]}

      {autType && <SelectedAutList />}
    </Box>
  );
};

export default SelectAutType;
