import { Box, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";

const SelectedAutDetails = () => {
  const [searchParams] = useSearchParams();
  const autType = searchParams.get("autType");
  const autName = searchParams.get("autName");
  // const autId = searchParams.get("autId");
  return (
    <Box className="v-center" gap={1}>
      <Box
        sx={{
          backgroundColor: "#fff",
          display: "flex",
          width: "200px",
          height: "32px",
          padding: "0px 16px",
          alignItems: "center",
          gap: "8px",
          flexShrink: 0,
          borderRadius: "6px",
          border: "none",
          backdropFilter: "blur(1.36px)",
          color: "#495057",
        }}>
        <Typography>
          Aut Type: <strong>{autType}</strong>
        </Typography>
      </Box>

      <Box
        sx={{
          backgroundColor: "#fff",
          display: "flex",
          width: "200px",
          height: "32px",
          padding: "0px 16px",
          alignItems: "center",
          gap: "8px",
          flexShrink: 0,
          borderRadius: "6px",
          border: "none",
          backdropFilter: "blur(1.36px)",
          color: "#495057",
        }}>
        <Typography>
          Aut Name: <strong>{autName}</strong>
        </Typography>
      </Box>
    </Box>
  );
};

export default SelectedAutDetails;
