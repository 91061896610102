import { useState, useEffect, useRef } from "react";
import { Box, Grid, IconButton, Paper, Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import FullScreenDialog from "../Create/CreateTestStepsContainer/FullWidthScreen";
import CustomButton from "../../CustomComponents/CustomButtons/CustomButton";
import CustomLoadingButton from "../../CustomComponents/CustomButtons/CustomLoadingButton";
import TestDataList from "./TestDataList";
import CreateTestDataForm from "./CreateTestDataForm";
import { useDispatch, useSelector } from "react-redux";
import { testcaseServices } from "../../../Redux/services/testcase.services";
import { useSearchParams } from "react-router-dom";
import { showToast } from "../../../Redux/reducers/toastSlice";
import { isDataRequiredForStep } from "../../../utilities/defaultData";
import { CustomExpandIcon } from "../../CustomComponents/Icons/IconButtons";
import CustomDeleteModal from "../../ReusableComponents/CustomDeleteModal";
import HelpTextTestData from "./HelpTextTestData";

const CreateTestData = () => {
  const dispatch = useDispatch();

  const scrollRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const testCaseId = Number.parseInt(searchParams.get("testCaseId"));
  const testDataId = Number.parseInt(searchParams.get("testDataId"));
  const testCaseVersion = Number.parseInt(searchParams.get("testCaseVersion"));
  const testDataVersion = Number.parseInt(searchParams.get("testDataVersion"));
  const testDataDeleteId = Number.parseInt(searchParams.get("testDataDeleteId"));

  const { test_steps_data: stepsData, step_action_data: stepActionData } = useSelector((state) => state.testcase);

  const [selectedTestData, setSelectedTestData] = useState([]);
  const [testDataList, setTestDataList] = useState([{ id: "new" }]);
  const [testDataInputs, setTestDataInputs] = useState({ name: "", description: "" });
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [errors, setErrors] = useState({});
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [deleteLoading, setDeleteLoding] = useState(false);
  const [loadingTestData, setLoadingTestData] = useState(false);

  useEffect(() => {
    fetchTestDataDetails();
  }, []);

  const fetchTestDataDetails = async (isCreate) => {
    setLoadingTestData(true);
    try {
      const clonedStepsData = JSON.parse(JSON.stringify(stepsData));
      const response = await testcaseServices.getTestData(testCaseId, testCaseVersion);
      const { testDataResponseList } = response.data;
      setTestDataList(testDataResponseList?.length ? testDataResponseList : [{ id: "new" }]);
      if (isCreate) {
        setSearchParams((params) => {
          params.delete("testDataId");
          params.delete("testDataVersion");
          return params;
        });
        setSelectedTestData([]);
      } else {
        if (testDataResponseList?.length) {
          const { name, description } = testDataResponseList[0];
          setTestDataInputs({ name, description });
          setSearchParams((params) => {
            params.set("testDataId", testDataResponseList[0]?.id);
            params.set("testDataVersion", testDataResponseList[0]?.version);
            return params;
          });
          setSelectedTestData(
            isDataRequiredForStep(clonedStepsData, stepActionData)?.map((el) => ({
              ...el,
              value: testDataResponseList[0]?.data[el.id] || "",
              // testDataId: testDataResponseList[0].id,
            })),
          );
        } else {
          setSelectedTestData(isDataRequiredForStep([...clonedStepsData], stepActionData));
        }
      }
    } catch (error) {
      dispatch(showToast({ message: error?.response?.data?.message || error.message, type: "error" }));
    }
    setLoadingTestData(false);
  };

  const validateForm = (inputs) => {
    const errors = {};
    if (!inputs.name) {
      errors.name = "Test Data Name is required.";
    }
    return errors;
  };

  const saveTestData = async (isNew) => {
    const validationErrors = validateForm(testDataInputs);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      scrollToTop();
      return;
    }
    setLoadingCreate(isNew ? "create" : "Update");
    try {
      const testDataObj = selectedTestData.reduce((acc, el) => {
        if (el.name) {
          acc[el.id] = el.value || "";
        }
        return acc;
      }, {});
      const payload = {
        id: testDataId,
        version: testDataVersion || null,
        name: testDataInputs.name,
        description: testDataInputs.description,
        isDefault: false,
        testCaseId,
        testCaseVersion: testCaseVersion,
        data: testDataObj,
      };

      await (testDataId && !isNew
        ? testcaseServices.updateTestData(payload)
        : testcaseServices.createTestData(payload));
      fetchTestDataDetails("true");
      scrollToTop();
      dispatch(showToast({ message: "Test Data Created Successfully", type: "success" }));
    } catch (error) {
      if (error?.response?.status === 409) {
        setErrors((prevErrors) => ({ ...prevErrors, name: "Test Data Name is Already Exist." }));
        scrollToTop();
      }
      dispatch(showToast({ message: error?.response?.data?.message || error.message, type: "error" }));
    }
    setLoadingCreate(false);
  };

  const toggleFullScreen = () => {
    setIsFullScreen((prev) => !prev);
  };

  const handleTestDataInputChange = (e) => {
    const { name, value } = e.target;
    setTestDataInputs((prev) => ({ ...prev, [name]: value }));
    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const scrollToTop = () =>
    setTimeout(() => scrollRef.current.scrollIntoView({ behavior: "smooth", block: "start" }), 400);

  const onClickAddNewTestData = () => {
    setTestDataList((prevState) => [...prevState, { id: "new" }]);
    setTestDataInputs({ name: "", description: "" });
    setSelectedTestData(isDataRequiredForStep([...stepsData], stepActionData));
    setSearchParams((params) => {
      params.set("testDataId", null);
      params.set("testDataVersion", null);
      return params;
    });
  };

  const onClickTestData = (testDataItem) => {
    const clonedStepsData = JSON.parse(JSON.stringify(stepsData));
    const { name, description } = testDataItem;
    setTestDataInputs({ name, description });
    setSelectedTestData(
      isDataRequiredForStep(clonedStepsData, stepActionData)?.map((el) => ({
        ...el,
        value: testDataItem?.data[el.id] || "",
        // testDataId: testDataItem?.id,
      })),
    );
    setSearchParams((params) => {
      params.set("testDataId", testDataItem?.id);
      params.set("testDataVersion", testDataItem?.version);
      return params;
    });
  };

  const handleCancelTestDataCreate = () => {
    const clonedStepsData = JSON.parse(JSON.stringify(stepsData));
    const { name, description } = testDataList[0];
    setTestDataInputs({ name, description });
    const testDataDetails = clonedStepsData.map((el) => ({
      ...el,
      value: testDataList[0]?.data[el.id] || "",
      // testDataId: testDataList[0].id,
    }));
    setSearchParams((params) => {
      params.set("testDataId", testDataList[0].id);
      params.set("testDataVersion", testDataList[0].version);
      return params;
    });
    setSelectedTestData(isDataRequiredForStep(testDataDetails, stepActionData));
    setTestDataList((prevState) => prevState.filter((each) => each.id !== "new"));
  };

  const handleCloseDelete = () => {
    setSearchParams((prevParams) => {
      const newParams = new URLSearchParams(prevParams);
      newParams.delete("testDataDeleteId");
      return newParams;
    });
  };

  const handleDeleteTestData = async () => {
    setDeleteLoding(true);
    try {
      await testcaseServices.deleteTestData({ deleteIdList: [testDataDeleteId] });
      setTestDataList((prevState) => prevState.filter((each) => each.id !== testDataDeleteId));
      handleCloseDelete();
      dispatch(showToast({ message: "Test Data deleted successfull", type: "success" }));
    } catch (error) {
      dispatch(showToast({ message: error?.response?.data?.message || error.message, type: "error" }));
    }
    setDeleteLoding(false);
  };

  const handleClose = () => {
    setSearchParams((prevParams) => {
      const newParams = new URLSearchParams(prevParams);
      newParams.set("showTestDataHelpText", true);
      return newParams;
    });
  };

  const renderComponent = () => (
    <Paper ref={scrollRef} sx={{ color: "#64748B", borderRadius: "12px", mt: isFullScreen ? 0 : 3 }}>
      {!isFullScreen && (
        <Box className="v-center space-between" sx={{ p: 1, gap: 1 }}>
          <CustomExpandIcon size="small" onClick={toggleFullScreen} />
          <Box>
            {/* <CustomLoadingButton
          // onClick={() => handleExportTestCase(selectedTestCases[0])}
          >
            Export Test Data
          </CustomLoadingButton>
          <CustomButton
            className="no-shadow"
            // onClick={handleButtonClick}
            sx={{ ml: 3 }}
            variant="contained"
            size="small"
            color="warning">
            Import Test Data
            <input
              type="file"
              accept=".xlsx"
              hidden
              // ref={fileInputRef}
              // onChange={(e) => handleFileChange(e, fileInputRef)}
            />
          </CustomButton> */}

            <IconButton onClick={handleClose} sx={{ ml: "auto" }} size="small">
              <Tooltip title="Test data Special Cases" placement="left" arrow>
                <InfoOutlinedIcon />
              </Tooltip>
            </IconButton>
          </Box>
          {/* <CustomLoadingButton>Download Template</CustomLoadingButton> */}
        </Box>
      )}
      <Grid container sx={{ borderBottom: "1px solid #ccc" }}>
        <TestDataList
          loadingTestData={loadingTestData}
          testDataList={testDataList}
          onClickAddNewTestData={onClickAddNewTestData}
          onClickTestData={onClickTestData}
        />
        <CreateTestDataForm
          stepActionData={stepActionData}
          errors={errors}
          dataInputs={testDataInputs}
          selectedTestData={selectedTestData}
          handleDataInputsChange={handleTestDataInputChange}
          setSelectedTestData={setSelectedTestData}
        />
      </Grid>
      {Boolean(selectedTestData?.length) && (
        <Box className="v-center" sx={{ p: 1, gap: 2, justifyContent: "flex-end" }}>
          {!testDataId && (
            <CustomButton
              disabled={testDataList?.length === 1}
              onClick={handleCancelTestDataCreate}
              size="small"
              variant="outlined"
              color="error">
              Cancel
            </CustomButton>
          )}
          <CustomLoadingButton
            disabled={loadingCreate}
            loading={loadingCreate && loadingCreate !== "create"}
            onClick={() => saveTestData()}>
            {testDataId ? "Update Test Data" : "Save Test Data"}
          </CustomLoadingButton>
          {Boolean(testDataId) && (
            <CustomButton
              disabled={loadingCreate}
              loading={loadingCreate === "create"}
              variant="contained"
              color="warning"
              onClick={() => saveTestData("new")}>
              Create As New
            </CustomButton>
          )}
        </Box>
      )}
    </Paper>
  );

  return (
    <Box pb={8}>
      {isFullScreen ? (
        <FullScreenDialog open={isFullScreen} setOpen={setIsFullScreen}>
          {renderComponent()}
        </FullScreenDialog>
      ) : (
        renderComponent()
      )}

      {/* <Box className="space-between" p={1} my={1}>
        <CustomButton onClick={() => null} size="small" variant="outlined" color="error">
          Cancel
        </CustomButton>

        <CustomLoadingButton onClick={handleNavigateToTestCaseList}>Save Test case & Test Data</CustomLoadingButton>
      </Box> */}

      {Boolean(testDataDeleteId) && (
        <CustomDeleteModal
          loading={deleteLoading}
          disabled={deleteLoading}
          onClickDelete={handleDeleteTestData}
          handleClose={handleCloseDelete}
        />
      )}

      <HelpTextTestData />
    </Box>
  );
};

export default CreateTestData;
