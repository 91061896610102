import React from "react";
import { Box, FormControl, MenuItem, Pagination, Select } from "@mui/material";

const CustomTablePagination = React.memo(
  ({ currentPage, rowsLength, rowsPerPage, handleChangePage, handleChangeRowsPerPage }) => {
    return (
      <Box className="space-between" px={1}>
        <FormControl sx={{ minWidth: 80 }} size="small">
          <Select value={rowsPerPage} onChange={handleChangeRowsPerPage}>
            {[10, 25, 50, 100].map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Pagination
          className="paging-panel"
          count={Math.ceil(rowsLength / rowsPerPage)}
          onChange={handleChangePage}
          page={currentPage}
        />
      </Box>
    );
  },
);

CustomTablePagination.displayName = "CustomTablePagination";

export default CustomTablePagination;
