import React from "react";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Slide, TextField, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import CustomLoadingButton from "../../CustomComponents/CustomButtons/CustomLoadingButton";
import CustomButton from "../../CustomComponents/CustomButtons/CustomButton";

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

Transition.displayName = "Transition";

const ExecutionFormModal = ({ configInputs, loadingExecution, handleInputChange, onClickExecute, handleClose }) => {
  const [searchParams] = useSearchParams();
  const configId = searchParams.get("testConfigId");

  const handleExecute = (type) => {
    const actions = {
      createAndExecute: () => onClickExecute("createAndExecute"),
      create: () => onClickExecute("create"),
      update: () => onClickExecute("update"),
      updateAndExecute: () => onClickExecute("updateAndExecute"),
    };
    actions[type]?.();
  };

  const isNameValid = configInputs?.name?.length >= 4;

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { maxWidth: "500px", color: "#64748b" } }}
      open={true}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}>
      <DialogTitle sx={{ px: 2, pb: 0, pt: 1 }}>
        <Typography variant="h6">Create Execution Config</Typography>
      </DialogTitle>
      <DialogContent sx={{ px: 2, pb: 1 }}>
        <Box sx={{ pt: 1, gap: 1 }}>
          <TextField
            autoFocus
            required
            name="name"
            label="Execution Config Name"
            size="small"
            fullWidth
            value={configInputs.name}
            onChange={handleInputChange}
          />
          <TextField
            sx={{ mt: 1.5 }}
            name="description"
            label="Description"
            size="small"
            fullWidth
            multiline
            rows={3}
            value={configInputs.description}
            onChange={handleInputChange}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between" }}>
        <CustomButton onClick={handleClose} variant="outlined" color="error">
          Cancel
        </CustomButton>
        <Box className="v-center" gap={1}>
          <CustomLoadingButton
            disabled={!isNameValid || loadingExecution}
            onClick={() => handleExecute(configId ? "updateAndExecute" : "createAndExecute")}>
            {configId ? "Update & Execute" : "Create & Execute"}
          </CustomLoadingButton>
          <CustomLoadingButton
            disabled={!isNameValid || loadingExecution}
            onClick={() => handleExecute(configId ? "update" : "create")}>
            {configId ? "Update" : "Create"}
          </CustomLoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ExecutionFormModal;
