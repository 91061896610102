import { memo, useCallback, useState } from "react";
import {
  Box,
  Divider,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  Typography,
  // IconButton,
  // Tooltip,
} from "@mui/material";
import PropTypes from "prop-types";
// import InfoIcon from "@mui/icons-material/Info";

const labelStyle = {
  minWidth: "150px",
  color: "#666666",
  fontSize: "12px",
  fontWeight: 600,
};

const valueStyle = {
  color: "#666666",
  fontSize: "12px",
  fontWeight: 400,
  wordBreak: "break-all",
};

const RenderDetailRow = memo(({ label, value }) => (
  <Box display="flex" alignItems="flex-start" py={1}>
    <Typography sx={labelStyle}>{label} :</Typography>
    <Typography sx={valueStyle}>{value ?? "N/A"}</Typography>
  </Box>
));
RenderDetailRow.displayName = "RenderDetailRow";

RenderDetailRow.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any,
};

const MacroStepListItem = memo(({ item, index }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const open = Boolean(anchorEl);

  return (
    <>
      <ListItem
        id={item?.name}
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        disablePadding
        className="v-center">
        <ListItemButton className="v-center" sx={{ p: 0 }}>
          <Typography variant="body2" sx={{ minWidth: 15, color: "#666", fontWeight: 600 }}>
            {index + 1}.
          </Typography>
          <ListItemText primary={item?.name} />
        </ListItemButton>
        {/* <Tooltip title="Details" placement="top">
          <IconButton onMouseEnter={handlePopoverOpen}>
            <InfoIcon fontSize="small" />
          </IconButton>
        </Tooltip> */}
      </ListItem>

      <Popover
        id="mouse-over-popover"
        sx={{ pointerEvents: "none" }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "&::before": {
              // eslint-disable-next-line quotes
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        onClose={handleCloseMenu}
        disableRestoreFocus>
        <Box sx={{ px: 2, py: 1 }}>
          <RenderDetailRow label="Name" value={item?.name} />
          <Divider />
          <RenderDetailRow label="Description" value={item?.description} />
          <Divider />
          <RenderDetailRow label="UI Element" value="Lorem Ipsum is simply dummy text" />
          <Divider />
          <RenderDetailRow label="Action" value={item?.action} />
          <Divider />
          <RenderDetailRow label="Screenshot" value={item?.screenshot?.toString()} />
          <Divider />
          <RenderDetailRow label="Consecutive Threshold" value={item?.consecutiveThreshold} />
          <Divider />
          <RenderDetailRow label="Retry Duration" value={item?.retryDuration} />
          <Divider />
          <RenderDetailRow label="Disable" value={item?.isDisable?.toString()} />
        </Box>
      </Popover>
    </>
  );
});
MacroStepListItem.displayName = "MacroStepListItem";

MacroStepListItem.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    action: PropTypes.string,
    screenshot: PropTypes.bool,
    consecutiveThreshold: PropTypes.number,
    retryDuration: PropTypes.number,
    isDisable: PropTypes.bool,
  }).isRequired,
  index: PropTypes.number.isRequired,
};

export default MacroStepListItem;
