import { useState } from "react";
import { Box, TextField, Grid, Button } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import CustomLoadingButton from "../../CustomComponents/CustomButtons/CustomLoadingButton";
import FormTabsSF from "./FormTabs";
import Environments from "./Environments";
import { autServices } from "../../../Redux/services/aut.services";
import { showToast } from "../../../Redux/reducers/toastSlice";
import { useDispatch } from "react-redux";
const passwordFields = { tab1: "Password", tab2: "Azure Key vault", labelName: "Password", type: "password" };

const AUTForm = ({ autDetails, formData = null, onSuccessCreateAut, onClose }) => {
  const dispatch = useDispatch();
  const applicationType = formData?.type || autDetails?.type;
  const methods = useForm({ defaultValues: formData });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const [loadingCreate, setLoadingCreate] = useState(false);
  const [showEnvError, setShowEnvError] = useState(false);

  const createAutPayload = (data) => {
    const payload = {
      ...(formData?.id && { id: formData?.id }),
      name: data.name.trim(),
      description: data.description?.trim() || "",
      type: applicationType,
      autProperties: {
        connection: data?.connection,
        userName: data.username,
        password: data.password,
        securitytoken: data.securitytoken,
        azurekeyvault: data.azurekeyvault,
        organizationId: data.organizationId,
      },
      autEnvironmentRequestList:
        data?.autEnvironmentResponseList?.map((el) => ({
          ...el,
          buildDate: new Date(el.buildDate).getTime(),
        })) || [],
    };

    return payload;
  };

  const handleSaveOrUpdate = async (data) => {
    if (!data?.autEnvironmentResponseList?.length) {
      setShowEnvError(true);
      return;
    }
    setLoadingCreate(true);
    try {
      const autPayload = createAutPayload(data);

      if (
        ["ANDROID", "IOS"].includes(applicationType) &&
        !autPayload.autEnvironmentRequestList?.some((each) => each?.fileId)
      ) {
        dispatch(showToast({ message: "Please upload the required file", type: "warning" }));

        setLoadingCreate(false);
        return;
      }

      const result = formData ? await autServices.updateAut(autPayload) : await autServices.saveAut(autPayload);

      dispatch(showToast({ message: `AUT '${data.name}' ${formData ? "updated" : "created"}`, type: "success" }));

      onSuccessCreateAut({ ...result.data, noOfEnvironments: result.data?.autEnvironmentResponseList?.length });
    } catch (error) {
      dispatch(showToast({ message: error?.response?.data?.message || error.message, type: "error" }));
    } finally {
      setLoadingCreate(false);
    }
  };

  const checkUniqueName = (value) => {
    if (!value.trim()) {
      return "Aut name cannot be empty";
    }
    return true;
  };

  // const testAut = () => {
  //   const { url } = getValues();
  //   if (isValidHttpUrl(url)) {
  //     window.open(url.trim());
  //   } else {
  //    dispatch(showToast({ message: error?.response?.data?.message || error.message, type: "error" }));
  //   }
  // };

  return (
    <FormProvider {...methods}>
      <Box component="form" onSubmit={handleSubmit(handleSaveOrUpdate)} noValidate autoComplete="off">
        <Grid container spacing={2} sx={{ p: 2 }}>
          <Grid item xs={12}>
            <TextField
              required
              label="Name"
              size="small"
              fullWidth
              defaultValue={formData?.name}
              error={Boolean(errors.name)}
              helperText={errors.name?.message}
              {...register("name", {
                required: "Aut name is required",
                minLength: { value: 4, message: "Minimum length 4 characters" },
                validate: checkUniqueName,
              })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Description"
              size="small"
              fullWidth
              multiline
              rows={3}
              defaultValue={formData?.description}
              error={Boolean(errors.description)}
              helperText={errors.description?.message}
              {...register("description", {
                maxLength: { value: 200, message: "Maximum length 200 characters" },
              })}
            />
          </Grid>

          {applicationType === "SALESFORCE" && (
            <>
              <Grid item xs={12}>
                <TextField
                  required
                  label="User Name"
                  size="small"
                  fullWidth
                  defaultValue={formData?.autProperties?.userName}
                  error={Boolean(errors.username)}
                  helperText={errors.username?.message}
                  {...register("username", {
                    required: "User name is required",
                    minLength: { value: 4, message: "Minimum length 4 characters" },
                  })}
                />
              </Grid>
              <Grid item xs={12}>
                <FormTabsSF label={passwordFields} register={register} errors={errors} formData={formData} />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Environments formData={formData} showEnvError={showEnvError} autType={applicationType} />
          </Grid>
        </Grid>
        <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end", gap: 2, mt: 1, px: 2 }}>
          <Button variant="outlined" color="error" size="small" onClick={onClose}>
            Cancel
          </Button>
          {/* {applicationType !== "ANDROID" && applicationType !== "IOS" && (
            <Button variant="contained" color="success" size="small" onClick={testAut}>
              Test
            </Button>
          )} */}
          <CustomLoadingButton type="submit" disabled={loadingCreate} loading={loadingCreate}>
            {formData ? "Update" : "Save"}
          </CustomLoadingButton>
        </Box>
      </Box>
    </FormProvider>
  );
};

export default AUTForm;
