import { useEffect, useRef } from "react";

const SessionTimeout = ({ timeout, onTimeout }) => {
  const timerRef = useRef(null);

  const resetTimer = () => {
    clearTimeout(timerRef.current);
    startTimer();
  };

  const startTimer = () => {
    timerRef.current = setTimeout(() => {
      onTimeout();
    }, timeout);
  };

  const handleUserActivity = () => {
    resetTimer();
  };

  useEffect(() => {
    const userActivityEvents = ["load", "mousemove", "mousedown", "click", "scroll", "keypress", "keydown"];

    userActivityEvents.forEach((eventName) => {
      window.addEventListener(eventName, handleUserActivity);
    });

    startTimer();

    return () => {
      userActivityEvents.forEach((eventName) => {
        window.removeEventListener(eventName, handleUserActivity);
      });
      clearTimeout(timerRef.current);
    };
  }, [timeout, onTimeout]);

  return <></>;
};

export default SessionTimeout;
