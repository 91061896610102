import { useState } from "react";
import PropTypes from "prop-types";
import { Box, Typography, Link } from "@mui/material";

const ReadMoreLess = ({ text, maxLength = 100, maxWidth = "100%" }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMore = (e) => {
    e.stopPropagation();
    setIsExpanded((prev) => !prev);
  };

  const shouldShowReadMore = text.length > maxLength;

  return (
    <Box padding={0.5} sx={{ maxWidth }}>
      <Typography
        variant="body2"
        noWrap={!isExpanded}
        sx={{
          display: "inline",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "pre-line",
        }}>
        {isExpanded ? text : text.slice(0, maxLength)}
      </Typography>
      {shouldShowReadMore && (
        <Link component="button" onClick={toggleReadMore} color="primary" sx={{ marginLeft: 1 }}>
          {isExpanded ? "less" : "more"}
        </Link>
      )}
    </Box>
  );
};

ReadMoreLess.propTypes = {
  text: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ReadMoreLess;
