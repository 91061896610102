import { Box, Button, Typography } from "@mui/material";
import { customComparator } from "../AgGrid/CustomSort";
import { ExecutionStatusCodes } from "../../utilities/ReusableFunctions";
import { formatDateTime } from "../../utils/formatDate";
import { useSearchParams } from "react-router-dom";
import { dateRangeObj } from "../../utilities/defaultData";

const CustomExecutionBox = ({ title, value }) => {
  const backgroundColors = {
    Executed: "rgba(72, 64, 197, 0.16)",
    Pass: "rgba(0, 193, 99, 0.16)",
    "Skip / Abort": "rgba(255, 153, 0, 0.16)",
    Fail: " rgba(255, 21, 0, 0.16)",
  };

  return (
    <Box
      className="center column"
      sx={{
        p: 1,
        width: "60px",
        height: "40px",
        boxSizing: "border-box",
        flexShrink: 0,
        background: backgroundColors[title],
      }}>
      <Typography
        sx={{
          fontSize: "8px",
          color: "#64748B",
        }}>
        {title}
      </Typography>
      <Typography>{value}</Typography>
    </Box>
  );
};

const Executions = (props) => {
  return (
    <Box sx={{ py: 1, width: "100%", boxSizing: "border-box" }} className="space-between" gap={1}>
      <CustomExecutionBox title="Executed" value={props?.data?.executionCount} />
      <CustomExecutionBox title="Pass" value={props?.data?.passCount} />
      <CustomExecutionBox title="Skip / Abort" value={props?.data?.otherCount} />
      <CustomExecutionBox title="Fail" value={props?.data?.failCount} />
    </Box>
  );
};

const AuthorDetails = (props) => {
  return (
    <Box className="flex column" gap={0.5}>
      <Typography
        sx={{
          color: "#64748B",
          fontSize: "12px",
          fontWeight: 600,
        }}>
        {props?.data?.createdBy}
      </Typography>

      <Typography
        sx={{
          color: "#6C757D",
          fontSize: "10px",
          fontWeight: 300,
        }}>
        {formatDateTime(props?.data?.createdAt)}
      </Typography>
    </Box>
  );
};

const ResultComponent = ({ title, scrollRef, gridRef }) => {
  const [, setSearchParams] = useSearchParams();
  const autoScroll = () => {
    setTimeout(() => {
      scrollRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }, 600);
  };

  const handleViewExecutionReports = (title) => {
    const gridApi = gridRef?.current?.api;
    if (gridApi) {
      gridApi.deselectAll();
    }
    setSearchParams((prev) => {
      const newState = new URLSearchParams(prev);
      newState.set("testCaseId", title?.id);
      newState.set("tcName", title?.name);
      newState.set("startDate", dateRangeObj.startDate);
      newState.set("endDate", dateRangeObj.endDate);
      newState.set("lastExecutedInstanceId", title?.lastExecutedInstanceId);
      return newState;
    });
    autoScroll();
  };

  return (
    <Button
      onClick={() => handleViewExecutionReports(title)}
      sx={{
        padding: "4px 8px",
        backgroundColor: ExecutionStatusCodes[title?.lastExecutedResult]?.color,
        borderRadius: "2px",
        color: "#fff",
        "&:hover": {
          backgroundColor: ExecutionStatusCodes[title?.lastExecutedResult]?.hoverColor,
        },
      }}>
      <Typography>{title?.lastExecutedResult}</Typography>
    </Button>
  );
};

const LastExecutionDetails = (props) => {
  return (
    <Box className="v-center" gap={2}>
      <ResultComponent scrollRef={props?.context?.scrollRef} title={props?.data} gridRef={props?.context?.gridRef} />
    </Box>
  );
};
const handleGoToEdit = (params) => {
  const navigate = params.context.navigate;
  const autId = params.context.autId;
  const autType = params.context.autType;
  const autName = params.context.autName;

  const handleClick = () => {
    navigate(
      `/testcases/create?autId=${autId}&testCaseId=${params.data.id}&testCaseVersion=${params.data.version}&autType=${autType}&autName=${autName}`,
    );
  };

  return (
    <Typography onClick={handleClick} sx={{ cursor: "pointer", color: "blue", textDecoration: "underline" }}>
      {params.value}
    </Typography>
  );
};
export const testCaseListHeadCells = [
  {
    sortable: false,
    headerCheckboxSelection: true,
    checkboxSelection: true,
    lockPosition: "left",
    cellClass: "locked-col",
    suppressColumnsToolPanel: true,
    suppressHeaderMenuButton: true,
    minWidth: 50,
    maxWidth: 50,
    filter: false,
  },

  {
    headerName: "S.No",
    sortable: true,
    valueGetter: "node.rowIndex + 1",
    suppressColumnsToolPanel: true,
    filter: false,
    suppressHeaderMenuButton: true,
    headerTooltip: "S.No",
    maxWidth: 75,
    minWidth: 75,
  },

  {
    field: "name",
    headerName: "Test Case Name",
    sortable: true,
    suppressColumnsToolPanel: true,
    filter: true,
    comparator: customComparator,
    cellRenderer: handleGoToEdit,
    headerTooltip: "Test Case Name",
    flex: 1.5,
    minWidth: 150,
  },

  // {
  //   field: "version",
  //   headerName: "Version",
  //   sortable: true,
  //   filter: false,
  //   suppressHeaderMenuButton: true,
  //   headerTooltip: "Version",
  //   cellClass: "non-editable",
  // },

  {
    field: "noOfSteps",
    headerName: "Steps",
    sortable: true,
    filter: false,
    suppressHeaderMenuButton: true,
    headerTooltip: "Steps",
    cellClass: "non-editable",
    minWidth: 90,
    maxWidth: 90,
  },
  {
    field: "noOfMacros",
    headerName: "Macros",
    sortable: true,
    filter: false,
    suppressHeaderMenuButton: true,
    headerTooltip: "Macros",
    cellClass: "non-editable",
    minWidth: 90,
    maxWidth: 90,
  },
  {
    field: "createdBy",
    headerName: "Author",
    sortable: true,
    filter: true,
    headerTooltip: "Author",
    flex: 1.5,
    minWidth: 150,
    cellRenderer: AuthorDetails,
    cellStyle: { padding: 8 },
  },
  {
    field: "",
    headerName: "Executions",
    sortable: true,
    suppressColumnsToolPanel: true,
    filter: true,
    headerTooltip: "Executions",
    cellRenderer: Executions,
    minWidth: 280,
    maxWidth: 280,
    flex: 2.5,
    cellStyle: { padding: 8 },
  },

  {
    field: "lastExecutedResult",
    headerName: "Last Execution Result",
    sortable: true,
    suppressColumnsToolPanel: true,
    filter: true,
    headerTooltip: "Last Execution Result",
    cellRenderer: LastExecutionDetails,
    minWidth: 100,
    cellStyle: { padding: 8 },
    pinned: "right",
    lockPinned: true,
    cellClass: "lock - pinned",
  },
];
