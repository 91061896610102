import { Tooltip, IconButton } from "@mui/material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import EditOutlined from "@mui/icons-material/EditOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { useState } from "react";
import CreateCICD from "./CreateCICD.js";
// import KiTAPServer from "../../services/KiTAPServer.js";

const PipelineActions = ({ data, colDef }) => {
  const [editPipeLineData, setEditPipeLineData] = useState({});

  const row = data;
  const handleClose = (resData) => {
    if (resData) {
      colDef?.setPipeLineListData((prevState) => {
        return prevState?.map((each) => {
          if (each?.id === resData?.id) {
            return { ...each, ...resData };
          }
          return each;
        });
      });

      setEditPipeLineData({});
    } else {
      setEditPipeLineData({});
    }
  };
  const handlePreview = () => {
    colDef?.onClickPreview(row?.id);
  };
  const onClosePreview = () => {
    setEditPipeLineData(row);
    colDef?.closePreview();
  };

  // const executePipeLine = async () => {
  //   const { data } = await KiTAPServer.executeCICDPlineLine(row?.name);
  // };
  return (
    <>
      <Tooltip title="Edit" placement="top-end" arrow>
        <IconButton color="success" onClick={onClosePreview} size="small">
          <EditOutlined />
        </IconButton>
      </Tooltip>
      <Tooltip title="Preview" placement="top-end" arrow>
        <IconButton title="Preview" onClick={handlePreview} size="small" color="secondary">
          <VisibilityOutlinedIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete" placement="top-end" arrow>
        <IconButton
          onClick={() => {
            colDef?.deleteOption(row?.id, "single");
          }}
          size="small"
          color="error">
          <DeleteForeverOutlinedIcon fontSize="12px" />
        </IconButton>
      </Tooltip>
      {/* <IconButton
          title="Execute"
          onClick={executePipeLine}
          size="small"
          color="success"
        >
          <PlayCircleFilledWhiteOutlinedIcon fontSize="12px" />
        </IconButton> */}
      {Object.keys(editPipeLineData).length > 0 && (
        <CreateCICD defaultData={editPipeLineData} handleClose={handleClose} />
      )}
    </>
  );
};

export default PipelineActions;
