const CustomVideoPlayer = ({
  src = "https://www.w3schools.com/html/mov_bbb.mp4",
  type = "video/mp4",
  width = 320,
  height = 200,
  poster = "https://pbs.twimg.com/media/GQ6M-jgXYAE7DEY?format=jpg&name=4096x4096",
}) => {
  return (
    <div>
      <video
        width={width}
        height={height}
        controls
        poster={poster}
        preload="none"
        // onError={() => alert("Video failed to load. Please check the source URL.")}
      >
        <source src={src} type={type} />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default CustomVideoPlayer;
