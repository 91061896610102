import { Box, Button, Divider, Paper, Typography } from "@mui/material";
import ResultLabelComponent from "./ResultLabelComponent";
import { ExecutionStatusCodes, getTimeDifference } from "../../../../utilities/ReusableFunctions";
import ModalPopup from "../../../ReusableComponents/ModalPopup";
import JiraBugCreate from "../../../Reports/ExecutionReports/JiraBugCreate";
import { useState } from "react";
import { formatDateTime } from "../../../../utils/formatDate";

const ExeResult = ({ executionResult, handleClickRow }) => {
  const [createBugData, setCreateBugData] = useState(false);

  const createJira = () => {
    setCreateBugData(true);
  };

  return (
    <>
      <Box className="v-center" gap={1}>
        <Button
          size="small"
          sx={{
            background: ExecutionStatusCodes[executionResult?.result]?.color,
            color: "#fff",
            p: "3px 5px",
            ml: "auto",
            "&:hover": { background: ExecutionStatusCodes[executionResult?.result]?.hoverColor },
          }}
          onClick={() => handleClickRow(executionResult)}>
          {executionResult?.result}
        </Button>
        {executionResult?.result === "FAILED" && (
          <Button size="small" sx={{ p: "3px 5px" }} variant="contained" color="primary" onClick={createJira}>
            Create Bug
          </Button>
        )}
      </Box>

      {createBugData && (
        <ModalPopup
          open={true}
          width={"40%"}
          setOpen={setCreateBugData}
          data={<JiraBugCreate setCreateBugData={setCreateBugData} />}
        />
      )}
    </>
  );
};
const CustomExecutionBox = ({ title, value }) => {
  const backgroundColors = {
    Total: "rgba(72, 64, 197, 0.16)",
    Pass: "rgba(0, 193, 99, 0.16)",
    "Skip / Disable": "rgba(255, 153, 0, 0.16)",
    Fail: " rgba(255, 21, 0, 0.16)",
  };

  return (
    <Box
      className="center column"
      sx={{
        p: 1,
        minWidth: "60px",
        height: "40px",
        boxSizing: "border-box",
        flexShrink: 0,
        background: backgroundColors[title],
      }}>
      <Typography sx={{ fontSize: "8px", color: "#64748B" }}>{title}</Typography>
      <Typography>{value}</Typography>
    </Box>
  );
};

const ResultsDetails = ({ executionResult }) => {
  return (
    <Box sx={{ py: 1, width: "100%", boxSizing: "border-box" }} className="v-center" gap={1}>
      <CustomExecutionBox title="Total" value={executionResult?.noOfSteps} />
      <CustomExecutionBox title="Pass" value={executionResult?.passCount} />
      <CustomExecutionBox title="Skip / Disable" value={executionResult?.otherCount} />
      <CustomExecutionBox title="Fail" value={executionResult?.failCount} />
    </Box>
  );
};

const ResultTile = ({ executionResult, handleClickRow }) => {
  return (
    <Box component={Paper} className="executionCard ">
      {/* <Box component={Paper} className="executionCard disableExecutioncard" gap={1}> */}
      {/* <img src={fileNotFound} width="40%" alt="" /> */}
      <Typography sx={{ opacity: 0.5 }} variant="h6" textAlign="center" color="error">
        {executionResult?.message}
      </Typography>
      <ResultLabelComponent title="Execution Name" value={executionResult?.executionName} />
      <ResultLabelComponent title="Test Data" value={executionResult?.testDataName} />

      <ResultLabelComponent title="Browser" value={executionResult?.platform?.browserPlatform?.browserName || ""} />
      <ResultLabelComponent
        title="Executed By"
        value={`${executionResult?.executedBy} / ${executionResult?.executedAt}`}
      />
      <ResultLabelComponent
        title="Started At"
        value={`${formatDateTime(executionResult?.startedAt)} / ${getTimeDifference(executionResult?.startedAt, executionResult?.finishedAt)?.readableFormat}`}
      />

      <Divider
        sx={{ bgcolor: "#1976d2", borderRightWidth: 2, mx: 1, display: "none" }}
        component="span"
        orientation="vertical"
        variant="middle"
        flexItem
        color="primary"
      />

      {/* <Divider className="tile-divider" m={0} variant="middle" /> */}
      <ResultsDetails executionResult={executionResult} />

      <ExeResult executionResult={executionResult} handleClickRow={handleClickRow} />
    </Box>
  );
};

export default ResultTile;
