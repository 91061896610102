import { useCallback, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
// import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import { Box, Grid, IconButton, Paper, MenuItem, FormControl, Select } from "@mui/material";
import FilterAltOffOutlinedIcon from "@mui/icons-material/FilterAltOffOutlined";
import { formatGridRefOnActions, formatGridRefOnActionsForImportedSteps } from "../../utilities/ReusableFunctions";

export function CustomAgGrid(props) {
  const {
    gridRef,
    setSelected,
    rows,
    setRows,
    headCells,
    filterData,
    setFilterData,
    detailCellRendererParams,
    rowDragEntireRow,
    masterDetail,
    onRowClicked,
    onCellClicked,
    hideClearFilters,
    onPageSizeChange,
    setSelectedRows,
    isDefaultAdd,
    getRowId,
    defaultSelectedRows = [],
    customOnSelectionChanged,
    gridIndex,
    stepRows,
    setStepRows,
    columnsAutoFit,
    disableAgGrid = false,
  } = props;
  const [pageSize, setPageSize] = useState(25);
  const [resetStatus, setResetStatus] = useState([]);

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
    };
  }, []);
  const gridOptions = {
    context: {
      filterData: filterData,
      setFilterData: setFilterData,
    },
    suppressCellSelection: true,
    isRowSelectable: (rowNode) => {
      return !(rowNode.data?.UsedInTests?.length > 0);
    },
  };

  // on selection change
  const onSelectionChanged = useCallback((e) => {
    const currentPage = gridRef?.current?.api.paginationGetCurrentPage();
    const pageSize = gridRef?.current?.api.paginationGetPageSize();
    if (customOnSelectionChanged) {
      customOnSelectionChanged(e, gridIndex);
    }
    const selectedRows = gridRef?.current?.api?.rowModel.rowsToDisplay
      ?.slice(currentPage * pageSize, pageSize * (currentPage + 1))
      .filter((node) => node.isSelected())
      .map((node) => node.data);
    const selected =
      Array.isArray(selectedRows) &&
      selectedRows.map((obj) => {
        if (obj._id) {
          return obj._id;
        } else if (obj.testCaseId) {
          return obj.testCaseId;
        } else if (obj.dbId) {
          return obj.dbId;
        }
        return obj.id;
      });
    if (setSelected) {
      setSelected(selected);
    }
    if (setSelectedRows) {
      setSelectedRows(selected);
    }
  }, []);

  // on clear filter
  const clearFilters = useCallback(() => {
    gridRef?.current?.api.setFilterModel(null);
  }, []);

  // on filter change
  const onFilterChanged = useCallback(() => {
    const data = Object.keys(gridRef?.current?.api.getFilterModel());
    setResetStatus(data);
    gridRef?.current?.api.hideOverlay();
    gridRef?.current?.api.deselectAll();
    if (gridRef?.current?.api.rowModel.rowsToDisplay.length === 0) {
      gridRef?.current?.api.showNoRowsOverlay();
    }
  }, []);

  const onFirstDataRendered = useCallback((params) => {
    console.log(params);
  }, []);

  const onPageSizeChanged = useCallback((e) => {
    const value = e?.target?.value;
    setPageSize(value);
    if (onPageSizeChange) {
      onPageSizeChange(Number(value));
    }
    gridRef?.current?.api.paginationSetPageSize(Number(value));
  }, []);

  const onPaginationChanged = useCallback((e) => {
    e.api.deselectAll();
  }, []);

  const onGridSizeChanged = () => {
    // params.api.sizeColumnsToFit();
  };

  const onRowDragEnded = useCallback((event) => {
    if (customOnSelectionChanged) {
      formatGridRefOnActionsForImportedSteps(event?.api?.rowModel?.rowsToDisplay, stepRows, setStepRows);
    } else {
      formatGridRefOnActions(gridRef?.current?.api?.rowModel?.rowsToDisplay, setRows);
    }
  }, []);

  const onGridReady = useCallback(
    (params) => {
      if (gridRef?.current) {
        if (isDefaultAdd) {
          params.api.forEachNode((ele) => {
            if (ele?.data?.isDefault === "true") {
              ele.setSelected(true);
            }
          });
        }
        setTimeout(() => {
          if (!columnsAutoFit) {
            const allColumnIds = [];
            gridRef?.current?.columnApi?.getColumns()?.forEach((column) => {
              allColumnIds.push(column.getId());
            });
            gridRef?.current?.columnApi?.autoSizeColumns(allColumnIds, false);
          } else {
            gridRef?.current?.api?.sizeColumnsToFit();
          }

          // Assuming selectedRows contains the IDs of rows you want to pre-select
          if (defaultSelectedRows?.length) {
            const selectedRows = rows.filter((row) => {
              if (row?.id) {
                return defaultSelectedRows.includes(row?.id);
              } else if (row?._id) {
                return defaultSelectedRows.includes(row?._id);
              }
              return "";
            });
            // Select the pre-defined rows
            gridRef.current.api.forEachNode((node) => {
              if (
                selectedRows.some((selectedRow) => {
                  if (selectedRow?.id) {
                    return selectedRow.id === node.data.id;
                  }
                  return selectedRow._id === node.data._id;
                })
              ) {
                node.setSelected(true);
              }
            });
          }
        }, 100);
      }
    },
    [gridRef?.current, rows],
  );

  return (
    <>
      {!hideClearFilters && resetStatus?.length > 0 && (
        <Box className="clearFilters">
          <IconButton color="error" size="small" variant="oulined" onClick={clearFilters}>
            <FilterAltOffOutlinedIcon />
          </IconButton>
        </Box>
      )}
      <Grid container>
        <Grid item sm={12}>
          <Box sx={{ marginTop: "5px" }} className={`ag-theme-balham ${disableAgGrid && "manageTestDataDisabledAll"}`}>
            <Box position={"relative"}>
              <Paper>
                <AgGridReact
                  ref={gridRef}
                  rowData={rows}
                  onSelectionChanged={onSelectionChanged}
                  columnDefs={headCells}
                  animateRows={true}
                  defaultColDef={defaultColDef}
                  onFilterChanged={onFilterChanged}
                  rowSelection="multiple"
                  suppressRowClickSelection={true}
                  gridOptions={gridOptions}
                  paginationPageSize={pageSize}
                  pagination={true}
                  onFirstDataRendered={onFirstDataRendered}
                  onPaginationChanged={onPaginationChanged}
                  onGridSizeChanged={onGridSizeChanged}
                  rowDragManaged={true}
                  rowDragEntireRow={rowDragEntireRow ? rowDragEntireRow : false}
                  rowDragMultiRow={true}
                  masterDetail={masterDetail ? masterDetail : false}
                  detailCellRendererParams={detailCellRendererParams}
                  onRowDragEnd={onRowDragEnded}
                  onRowClicked={onRowClicked}
                  onCellClicked={onCellClicked}
                  // onCellValueChanged={onCellValueChanged}
                  stopEditingWhenCellsLoseFocus={true}
                  suppressMovableColumns={true}
                  getRowId={getRowId}
                  onGridReady={onGridReady}></AgGridReact>
                <FormControl className="rowsDisplay" fullWidth size="small">
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={pageSize}
                    onChange={onPageSizeChanged}>
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                    <MenuItem value={250}>250</MenuItem>
                    <MenuItem value={500}>500</MenuItem>
                  </Select>
                </FormControl>
              </Paper>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
