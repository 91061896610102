import { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  // Checkbox,
  CircularProgress,
  IconButton,
  InputBase,
  List,
  Typography,
} from "@mui/material";
import { useDrop, useDrag } from "react-dnd";
import { Info, Search } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DragIndicatorOutlinedIcon from "@mui/icons-material/DragIndicatorOutlined";
import FolderIcon from "@mui/icons-material/Folder";
import { useDispatch, useSelector } from "react-redux";
import { setMacrosList } from "../../../../../Redux/reducers/testcaseSlice";
import MacroStepListItem from "./MacroStepListItem";
import SkeletonLoader from "../../../../SkeletonLoader";
import { macrosServices } from "../../../../../Redux/services/macros.services";
import { useSearchParams } from "react-router-dom";
import CustomButton from "../../../../CustomComponents/CustomButtons/CustomButton";

const ItemTypes = {
  MACRO: "MACRO",
};

const MacroItem = ({ index, macro, moveMacroRow }) => {
  const dispatch = useDispatch();
  const { macros_list: macrosList } = useSelector((state) => state.testcase);
  const [loadingSteps, setLoadingSteps] = useState(false);
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.MACRO,
    item: { type: ItemTypes.MACRO, row: macro, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: ItemTypes.MACRO,
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveMacroRow(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  const [expanded, setExpanded] = useState(false);

  const handleChange = async () => {
    if (!macro?.steps) {
      setLoadingSteps(true);
      try {
        const { data } = await macrosServices.getMacroById(macro?.id, macro?.version);
        const updatedMacrosList = [...macrosList]?.map((each) =>
          each?.id === macro?.id ? { ...each, steps: data?.testStepResponseList } : each,
        );
        dispatch(setMacrosList(updatedMacrosList));
      } catch (e) {
        console.log(e);
      }
      setLoadingSteps(false);
    }
    setExpanded(!expanded);
  };

  const opacity = isDragging ? 0.5 : 1;

  return (
    <Accordion
      sx={{
        "& .Mui-expanded": { minHeight: "40px !important", m: "0px !important" },
        "&:before": { display: "none" },
        border: "1px solid #F3F3F3",
        borderRadius: "4px",
        paddingRight: "5px",
        boxShadow: "none !important",
      }}
      expanded={expanded}
      onChange={handleChange}>
      <AccordionSummary
        sx={{
          border: "none",
          p: 0,
          "& .MuiPaper-root": { m: "0px !important" },
          "& .MuiAccordionSummary-root": { minHeight: "40px !important" },
          "& .MuiAccordionSummary-content": { m: 0, py: 0, px: 1 },
          "& .MuiAccordion-root": { p: 0, m: "0px !important" },
          "& .Mui-expanded": { m: 0, pt: 0.5, alignItems: "center", "& svg": { width: "auto" } },
        }}
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${index}bh-content`}
        id={`panel${index}bh-header`}>
        <Box ref={(node) => drag(drop(node))} style={{ opacity }} className="v-center" gap={0.5}>
          {/* <Checkbox sx={{ p: 0 }} size="small" /> */}
          <DragIndicatorOutlinedIcon fontSize="small" color="primary" />
          <Box sx={{ display: "flex", alignItems: "flex-start", justifyContent: "flex-start", gap: 0.5 }}>
            <FolderIcon fontSize="small" sx={{ color: "#EAEAEA" }} />
            <Box>
              <Typography variant="bold" sx={{ fontSize: "10px" }}>
                {macro?.name}
              </Typography>
              <Typography sx={{ fontSize: "10px", color: "#9A9A99" }}>{macro?.description}</Typography>
            </Box>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 1 }}>
        {loadingSteps && !macro?.steps && <CircularProgress size={20} />}

        <List sx={{ py: 0 }}>
          {macro?.steps?.map((each, i) => (
            <MacroStepListItem item={each} key={i} index={i} />
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

const Macros = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const autId = searchParams?.get("autId");
  const [searchInput, setSearchInput] = useState("");
  const [loadingMacro, setLoadingMacros] = useState(false);
  const { macros_list: macrosList } = useSelector((state) => state.testcase);

  const moveMacroRow = (dragIndex, hoverIndex) => {
    const dragRow = macrosList[dragIndex];
    const updatedMacrosList = [...macrosList];
    updatedMacrosList.splice(dragIndex, 1);
    updatedMacrosList.splice(hoverIndex, 0, dragRow);
    dispatch(setMacrosList(updatedMacrosList));
  };

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  const fetchListMacros = async () => {
    setLoadingMacros(true);
    try {
      const res = await macrosServices.getMacrosList(autId);
      dispatch(setMacrosList(res?.data?.macroResponseList || []));
    } catch (e) {
      console.log(e);
    }
    setLoadingMacros(false);
  };

  useEffect(() => {
    fetchListMacros();
  }, []);

  const onClickCancel = () => {
    setSearchParams((prev) => {
      const newState = new URLSearchParams(prev);
      newState.delete("stepViewType");
      return newState;
    });
  };

  return (
    <Box sx={{ height: "100%" }}>
      <Box className="v-center" sx={{ background: "#F4F1FF", px: 1, height: 42 }}>
        <Box className="v-center">
          {/* <Checkbox size="small" /> */}
          <Typography variant="h6">Macros</Typography>
        </Box>
        {/* <Box sx={{ ml: "auto" }} className="v-center">
          <Checkbox sx={{ p: 0, m: 0, pr: 0.5 }} size="small" /> <Typography>Recently Added</Typography>
        </Box> */}
        <IconButton sx={{ ml: "auto" }} size="small">
          <Info fontSize="small" />
        </IconButton>
      </Box>
      <Box sx={{ p: 1, minHeight: "400px" }}>
        <Box
          className="v-center"
          sx={{ p: "4px", px: 1, color: "#CCCCCC", background: "#EEEEEE", borderRadius: "4px" }}>
          <InputBase
            fullWidth
            size="small"
            className="searchInput"
            placeholder="Search Test Case"
            value={searchInput}
            onChange={handleSearchChange}
          />
          <IconButton sx={{ p: 0 }} size="small">
            <Search fontSize="0.75rem" />
          </IconButton>
        </Box>
        {loadingMacro ? (
          <SkeletonLoader />
        ) : (
          <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }} mt={1.5}>
            {macrosList
              ?.filter((each) => {
                const regex = new RegExp(searchInput, "i");
                return regex.test(each?.name);
              })
              .map((macro, index) => (
                <MacroItem key={index} index={index} macro={macro} moveMacroRow={moveMacroRow} />
              ))}
          </Box>
        )}
      </Box>
      <CustomButton sx={{ ml: 1 }} variant="outlined" onClick={onClickCancel} color="error">
        Cancel
      </CustomButton>
    </Box>
  );
};

export default Macros;
