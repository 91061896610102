import { Box, Pagination } from "@mui/material";

import { useSearchParams } from "react-router-dom";

import ResultsList from "./ResultsList";

const Executions = () => {
  const [searchParams] = useSearchParams();
  const currentPage = JSON.parse(searchParams.get("currentPage"));
  const viewType = searchParams.get("viewType");

  return (
    <Box className="executionsListContainer">
      <Box>
        <ResultsList />

        {viewType === "grid" && (
          <Pagination
            sx={{ my: 1.5, marginLeft: "auto", float: "right" }}
            count={Math.ceil(10 / 20)}
            page={currentPage}
            // onChange={handlePageChange}
          />
        )}
      </Box>
    </Box>
  );
};

export default Executions;
