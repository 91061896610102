import { Box, Typography } from "@mui/material";
import SelectScheduledDate from "./SelectScheduledDate";

const Schedule = ({ scheduledData, setScheduledData }) => {
  return (
    <Box className="" sx={{ p: 1, border: "1px solid #ccc", borderRadius: 1 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Schedule
      </Typography>
      <SelectScheduledDate scheduledData={scheduledData} setScheduledData={setScheduledData} />
    </Box>
  );
};

export default Schedule;
