import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";

import EngineTile from "./EngineTile";
import SelectDevices from "./DevicesList/SelectDevices";
import SkeletonLoader from "../SkeletonLoader";
import { enginesServices } from "../../Redux/services/engines.services";

const EnginesList = ({ engines, setEngines }) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const engineId = Number.parseInt(searchParams.get("engineId"), 10);

  const [loadingEngineList, setLoadingEngineList] = useState(false);

  useEffect(() => {
    fetchEnginesList();
  }, []);

  const fetchEnginesList = async () => {
    setLoadingEngineList(true);
    try {
      const response = await enginesServices.getListOfEngines();
      setEngines(response?.data || []);
    } catch (error) {
      console.error("Failed to fetch engines list:", error);
      // Optionally, show error message to the user
    } finally {
      setLoadingEngineList(false);
    }
  };

  const handleSelectDevice = (engineData) => {
    setSearchParams({ engineId: engineData?.id });
  };

  const handleCloseSelectDeviceDialog = (isUpdated) => {
    setSearchParams((prev) => {
      const newState = new URLSearchParams(prev);
      newState.delete("engineId");
      return newState;
    });

    if (isUpdated) {
      fetchEnginesList();
    }
  };

  const mobileDeviceIdList = engines.find((each) => each?.id === engineId)?.mobileDeviceIdList;

  return (
    <>
      {loadingEngineList && <SkeletonLoader />}
      {engines.length > 0 ? (
        <Box sx={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: 2 }}>
          {engines.map((engine) => (
            <EngineTile handleSelectDevice={handleSelectDevice} engine={engine} key={engine.id} />
          ))}
        </Box>
      ) : (
        <Typography variant="h6" sx={{ opacity: 0.6, mt: "10%", textAlign: "center" }}>
          {t("text_provide_list_of_test_execution_engine_nodes_in_properties_file")}
        </Typography>
      )}
      {Boolean(engineId) && (
        <SelectDevices
          engineId={engineId}
          mobileDeviceIdList={mobileDeviceIdList}
          handleClose={handleCloseSelectDeviceDialog}
        />
      )}
    </>
  );
};

export default EnginesList;
