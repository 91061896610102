import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import SettingsSuggestOutlinedIcon from "@mui/icons-material/SettingsSuggestOutlined";
import CustomSwitchButton from "../../../CustomComponents/CustomSwitchButton";
import CustomButton from "../../../CustomComponents/CustomButtons/CustomButton";
import SelectUIElement from "./SelectUIElement";
import DataTypeSelector from "./DataTypeSelector";
import { setSelectedStep, deleteTestStepsData, updateTestStepsData } from "../../../../Redux/reducers/testcaseSlice";

const AddStepForm = ({ defaultValues }) => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const autType = searchParams.get("autType");
  const { test_steps_data: stepsData, step_action_data: stepActionData } = useSelector((state) => state.testcase);
  const filteredStepActionData = stepActionData.filter((each) => each?.applicableAutTypes?.includes(autType));
  const [uiElements, setUIElements] = useState({});
  const isEdit = !defaultValues?.isNewStep;
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    reset,
    trigger,
    setValue,
  } = useForm({
    defaultValues: {
      sequenceNumber: null,
      name: "",
      description: "",
      action: filteredStepActionData[0]?.actionCode,
      actionData: "",
      screenshot: false,
      isDisable: false,
      isSensitive: false,
      tdmAttributeType: "",
      tdmAttributeProperties: {},
    },
  });
  const selectedActionData = watch("action");
  const isUiElementRequired =
    filteredStepActionData?.find((each) => each?.actionCode === selectedActionData)?.isElementRequired || false;

  useEffect(() => {
    if (isEdit) {
      reset(defaultValues || {});
      setUIElements(defaultValues?.elementLocator || {});
    }
  }, [defaultValues, reset, isEdit]);

  const onSubmitAddStep = (data) => {
    const hasValidElements = Object.values(uiElements)?.some((value) => {
      if (value !== null && value !== undefined) {
        return String(value).trim() !== "";
      }
      return false;
    });
    if (isUiElementRequired && !hasValidElements) {
      setSearchParams((prev) => {
        const newState = new URLSearchParams(prev);
        newState.set("elementError", true);
        return newState;
      });
      return;
    }
    const formattedData = { ...defaultValues, ...data, elementLocator: uiElements };
    dispatch(updateTestStepsData(formattedData));
    setSearchParams((prev) => {
      const newState = new URLSearchParams(prev);
      newState.delete("elementError");
      newState.set("stepViewType", "preview");
      return newState;
    });
  };

  const onClickCancel = () => {
    if (!isEdit) {
      dispatch(deleteTestStepsData(defaultValues));
      searchParams.delete("stepViewType");
      setSearchParams(searchParams);
      dispatch(setSelectedStep(null));
    } else {
      setSearchParams((prev) => {
        const newState = new URLSearchParams(prev);
        newState.set("stepViewType", "preview");
        return newState;
      });
      dispatch(setSelectedStep(defaultValues));
    }
  };

  const isNameUnique = (name) => {
    return !stepsData.some((step) => step.name !== defaultValues?.name && step.name === name) || "Name must be unique";
  };

  return (
    <>
      <Box sx={{ background: "#F4F1FF", px: 1, height: 42, display: "flex", alignItems: "center" }}>
        <Typography variant="h6">{isEdit ? "Edit Step" : "Create Step"}</Typography>
      </Box>
      <Box sx={{ p: 1.5 }}>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          sx={{ minHeight: 440, maxHeight: 440, gap: 1 }}
          className="flex column"
          onSubmit={handleSubmit(onSubmitAddStep)}>
          <TextField
            required
            label="Name"
            // InputLabelProps={getInputLabelProps("name")}
            size="small"
            fullWidth
            sx={{ "& p": { m: 0, fontSize: "10px" } }}
            error={Boolean(errors.name)}
            helperText={errors.name?.message}
            {...register("name", {
              required: "Name is required",
              minLength: { value: 4, message: "Minimum length 4 characters" },
              validate: isNameUnique,
            })}
          />

          <TextField
            label="Description"
            size="small"
            fullWidth
            // InputLabelProps={getInputLabelProps("description")}
            multiline
            rows={3}
            error={Boolean(errors.description)}
            helperText={errors.description?.message}
            {...register("description", {
              maxLength: { value: 200, message: "Maximum length 200 characters" },
            })}
          />

          <FormControl size="small" fullWidth error={Boolean(errors.action)}>
            <InputLabel required>Action</InputLabel>
            <Controller
              name="action"
              control={control}
              render={({ field }) => (
                <Select {...field} label="Action" value={field.value || ""} error={Boolean(errors.action)}>
                  {filteredStepActionData?.map((el) => (
                    <MenuItem key={el.id} value={el.actionCode}>
                      {el?.displayName}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>

          {isUiElementRequired && (
            <SelectUIElement
              error={Boolean(errors.elementLocator)}
              defaultValues={uiElements}
              setUIElements={setUIElements}
            />
          )}

          <Grid container spacing={1.5}>
            <Grid item xs={4}>
              <Controller
                name="screenshot"
                control={control}
                render={({ field }) => (
                  <CustomSwitchButton
                    labels={["", "Screenshot"]}
                    checked={field.value}
                    onChange={(e) => field.onChange(e.target.checked)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="isDisable"
                control={control}
                render={({ field }) => (
                  <CustomSwitchButton
                    labels={["", "Is Disable?"]}
                    checked={field.value}
                    onChange={(e) => field.onChange(e.target.checked)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Box className="v-center" gap={1}>
                <Button
                  color="warning"
                  size="small"
                  className="flex-end"
                  startIcon={<SettingsSuggestOutlinedIcon sx={{ mt: "-22px" }} fontSize="small" />}>
                  Advance options
                </Button>
              </Box>
            </Grid>

            <DataTypeSelector control={control} errors={errors} watch={watch} trigger={trigger} setValue={setValue} />
          </Grid>
          <Box sx={{ pb: 2 }} className="space-between">
            <CustomButton onClick={onClickCancel} variant="outlined" color="error">
              Cancel
            </CustomButton>
            <Button sx={{ width: "fit-content", ml: "auto" }} type="submit" size="small" variant="contained">
              {isEdit ? "Update Step" : "Add Step"}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AddStepForm;
