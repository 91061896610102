import Configurations from "../resources/Config.json";

import { getActionKeyDisplayName, getActionKeyword } from "./Actions";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import {
  dataRowUniqColumnName,
  descriptionColumn,
  headerConstants,
  isDefaultColumn,
  sequenceNumberColumn,
} from "../components/TestCases/TestCaseModel";
import { CustomHeader } from "../components/TestCases/TestCaseListModel";
import { AllExecutionReportHeaders } from "../components/Reports/AllExecutionReportsModel";
import dayjs from "dayjs";

// test case reusable functions
export const convertScreenShot = (screenshot) => {
  if (screenshot === null || screenshot === undefined || screenshot === "" || screenshot === "NO") {
    return false;
  }
  return screenshot;
};

export const getErrorThreshold = (errThreshold) => {
  if (!errThreshold) {
    return Configurations?.defaultErrorThreshold;
  } else if (errThreshold < Configurations.minimumErrorThreshold) {
    return Configurations.minimumErrorThreshold;
  } else if (errThreshold > Configurations.maximumErrorThreshold) {
    return Configurations.maximumErrorThreshold;
  }
  return errThreshold;
};

export const createTestCaseFromGrid = (cases, data, autId) => {
  const lowCodeTestStepRequestList = getGridRowsData(cases);
  return {
    name: data?.name,
    description: data?.description,
    customReference: data?.customReference,
    errorThreshold: getErrorThreshold(data?.errorThreshold),
    autId,
    lowCodeTestStepRequestList,
  };
};

export const getGridRowsData = (cases) => {
  return Array.isArray(cases)
    ? cases.map((e, i) => ({
        sequenceNumber: i + 1,
        name: e?.data?.name,
        description: e?.data?.description,
        locator: e?.data?.uielement,
        action: e?.data?.action?.actionCode ? e?.data?.action?.actionCode : "waitFor",
        screenshot: convertScreenShot(e?.data?.screenshot),
        StoreVariable: e?.data?.StoreVariable,
        consecutiveThreshold: setConsecutiveThreshold(e?.data?.consecutiveThreshold),
        retryDuration: setRetryDuration(e?.data?.retryDuration),
        isDisable: e?.data?.isDisable?.toString()?.trim() === "true" || e?.data?.isDisable === true ? "true" : "false",
      }))
    : [];
};

export const updateTestCaseFromGrid = (steps, id, autId, testCaseName, desc, errThreshold, customReference) => {
  const lowCodeTestStepRequestList = getGridRowsDataWithDisplayName(steps);
  return {
    name: testCaseName,
    id,
    autId,
    description: desc,
    errorThreshold: getErrorThreshold(errThreshold),
    customReference,
    lowCodeTestStepRequestList,
  };
};

export const getGridRowsDataWithDisplayName = (steps) => {
  return Array.isArray(steps)
    ? steps.map((e, i) => ({
        id: e?.data?.id,
        sequenceNumber: i + 1,
        name: e?.data?.name,
        description: e?.data?.description,
        locator: e?.data?.uielement === "Xpath or UI Element might be require" ? "" : e?.data?.uielement,
        action: getActionKeyword(e?.data?.action?.displayName),
        screenshot: convertScreenShot(e?.data?.screenshot),
        storeVariable: e?.data?.StoreVariable,
        consecutiveThreshold: setConsecutiveThreshold(e?.data?.consecutiveThreshold),
        retryDuration: setRetryDuration(e?.data?.retryDuration),
        isDisable: e?.data?.isDisable === "true" ? "true" : "false",
      }))
    : [];
};

export const createStepsAndDataFromResponse = (response, setStepRows, setDataHeaders, setTestData, actionData) => {
  const stepsData = response?.testStepResponseList;
  const steps = [];
  const testDataHeaders = [
    headerConstants,
    sequenceNumberColumn,
    dataRowUniqColumnName,
    isDefaultColumn,
    descriptionColumn,
  ];
  stepsData.forEach((step) => {
    // formatting step rows
    const stepRow = {
      id: step?.id,
      sequenceNumber: step?.sequenceNumber,
      name: step?.name,
      description: step?.description,
      uielement: step?.locator,
      headerTooltip: `${step?.name}`,
      action: {
        displayName: getActionKeyDisplayName(step?.action),
      },
      screenshot: convertScreenShot(step?.screenshot),
      consecutiveThreshold: step?.consecutiveThreshold,
      retryDuration: step?.retryDuration,
      isDisable: step?.isDisable ? "true" : "false",
    };
    steps.push(stepRow);
    // formatting data headers
    if (isInputDataRequired(step.action, actionData)) {
      testDataHeaders.push({
        field: step?.id.toString(),
        sortable: true,
        suppressColumnsToolPanel: true,
        filter: true,
        editable: true,
        minWidth: 100,
        headerTooltip: `${step?.name}`,
        headerComponent: () => {
          const additionalData = step;
          return <CustomHeader additionalData={additionalData} />;
        },
      });
    }
  });
  setStepRows(steps);
  setDataHeaders(testDataHeaders);

  // formatting test data rows
  if (response?.testDataResponseList) {
    const testdata = response?.testDataResponseList;
    const formattedTestData = Array.isArray(testdata)
      ? testdata.map((e) => {
          // e.data["dbId"] = e?.dbId;
          e.data["uniq name"] = e?.name;
          e.data.sequenceNo = e?.sequenceNo;
          e.data.isDefault = e?.isDefault ? "true" : "false";
          e.data.description = e?.description;
          return e.data;
        })
      : [];

    setTestData(formattedTestData);
  }
};

// execute testCase popup
export const createTestDataTableFromResponse = (response, setDataHeaders, setDataRows, actionData) => {
  let stepsData = [];
  if (response?.testStepResponseList.length > 0) {
    stepsData = response?.testStepResponseList;
  }
  if (response?.importedTestStepResponseList.length > 0) {
    response?.importedTestStepResponseList.forEach((importedTestStep) => {
      stepsData = stepsData.concat(importedTestStep.testStepResponseList);
    });
  }

  const testDataHeaders = [
    headerConstants,
    { ...sequenceNumberColumn, editable: false },
    dataRowUniqColumnName,
    { ...isDefaultColumn, editable: false },
    { ...descriptionColumn, editable: false },
  ];
  stepsData.forEach((step) => {
    // formatting data headers
    if (isInputDataRequired(step?.action, actionData)) {
      testDataHeaders.push({
        field: step?.id.toString(),
        sortable: true,
        suppressColumnsToolPanel: true,
        filter: true,
        editable: false,
        minWidth: 100,
        headerTooltip: `${step?.name}`,
        headerComponent: () => {
          const additionalData = step;
          return <CustomHeader additionalData={additionalData} />;
        },
      });
    }
  });

  setDataHeaders(testDataHeaders);

  // formatting test data rows
  const testdata = response?.testDataResponseList;
  const formattedTestData = Array.isArray(testdata)
    ? testdata.map((e) => {
        e.data.dbId = e?.dbId;
        e.data["uniq name"] = e?.name;
        e.data.sequenceNo = e?.sequenceNo;
        e.data.isDefault = e?.isDefault ? "true" : "false";
        e.data.description = e?.description;
        return e.data;
      })
    : [];

  setDataRows(formattedTestData);
};

export const createTestDataTableFromResponseForSuite = (response, setDataHeaders, setDataRows) => {
  const testDataHeaders = [
    headerConstants,
    { ...sequenceNumberColumn, editable: false },
    dataRowUniqColumnName,
    { ...isDefaultColumn, editable: false },
    { ...descriptionColumn, editable: false },
  ];
  if (setDataHeaders) {
    setDataHeaders(testDataHeaders);
  }

  // formatting test data rows
  const testdata = response.testDataDetailsList;
  const formattedTestData = Array.isArray(testdata)
    ? testdata.map((e) => {
        e.dbId = e?.id; // changed id to dbId here
        e["uniq name"] = e?.name;
        e.isDefault = e?.isDefault === true ? "true" : "false";
        return e;
      })
    : [];
  if (setDataRows) {
    setDataRows(formattedTestData);
  }
  const obj = {
    testDataHeaders: testDataHeaders,
    formattedTestData: formattedTestData,
  };
  return obj;
};

// export const isInputDataRequired = (actionkeyword) => {
//   const result = Actions.find(
//     (element) => element.ActionCode === actionkeyword
export const isInputDataRequired = (actionkeyword, actionData) => {
  if (actionkeyword === "Select Action") {
    return false;
  }
  const result = actionData.find((element) => element.actionCode === actionkeyword);
  return result?.isInputDataRequired;
};

export const isInputDataRequiredByDisplayName = (dispalyName, actionData) => {
  const result = actionData.find((element) => element.displayName === dispalyName);
  return result?.isInputDataRequired;
};

// test data reusable functions
export const createTestDataTableHeadersWithOneEmptyRow = (gridRefData, actionData) => {
  const testDataHeaders = [
    headerConstants,
    sequenceNumberColumn,
    dataRowUniqColumnName,
    isDefaultColumn,
    descriptionColumn,
  ];
  gridRefData.forEach((step) => {
    if (isInputDataRequired(step?.action, actionData)) {
      testDataHeaders.push({
        field: step?.id.toString(),
        headerName: step?.sequenceNumber,
        sortable: true,
        suppressColumnsToolPanel: true,
        filter: true,
        editable: true,
        headerTooltip: `${step?.name}`,
        headerComponent: () => {
          const additionalData = step;
          return <CustomHeader additionalData={additionalData} />;
        },
      });
    }
  });
  return testDataHeaders;
};

export const updateTestDataTableHeaders = (resStepData, actionData) => {
  const testDataHeaders = [
    headerConstants,
    sequenceNumberColumn,
    dataRowUniqColumnName,
    isDefaultColumn,
    descriptionColumn,
  ];
  resStepData?.forEach((step) => {
    if (isInputDataRequired(step?.action, actionData)) {
      testDataHeaders.push({
        field: step?.id.toString(),
        headerName: step?.sequenceNumber,
        sortable: true,
        suppressColumnsToolPanel: true,
        filter: true,
        editable: true,
        minWidth: 100,
        headerTooltip: `${step?.name}`,
        headerComponent: () => {
          const additionalData = step;
          return <CustomHeader additionalData={additionalData} />;
        },
      });
    }
  });
  return testDataHeaders;
};

export const validateDataGrid = (gridData) => {
  if (gridData.length === 0) {
    return true;
  }

  for (const { data } of gridData) {
    let isRowFilled = false;
    for (const key in data) {
      if (!["uniq name", "id", "dbId"].includes(key)) {
        if (checkValue(data[key])) {
          isRowFilled = true;
        }
      }
    }

    if (!isRowFilled) {
      return true;
    }
  }
  return null;
};

export const validateDefaultData = (gridData) => {
  let count = 0;
  for (const { data } of gridData) {
    for (const key in data) {
      if (key === "isDefault" && (data[key] === "true" || data[key] === true)) {
        count += 1;
      }
    }
  }
  if (count === 0) {
    return "less default";
  }
  if (count > 1) {
    return "more default";
  }
  return null;
};

const checkValue = (value) => {
  let trimValue;
  if (typeof value === "string") {
    trimValue = value?.trim();
  }

  return trimValue !== undefined && trimValue !== null && trimValue !== "";
};

export const formatTestDataToSaveInDatabase = (rows, testCase) => {
  const testDataRows =
    Array.isArray(rows) &&
    rows.map((e, i) => {
      const data = e?.data;
      const name = data["uniq name"];
      const dbId = data?.dbId;
      const description = data.description;
      const sequenceNo = i + 1;
      const isDefault = data.isDefault === "true" || data.isDefault === true ? "true" : "false";
      delete data["uniq name"];
      delete data?.dbId;
      delete data?.id;
      delete data?.description;
      delete data?.sequenceNo;
      delete data?.isDefault;
      return {
        name: name,
        data: data,
        dbId: dbId,
        sequenceNo: sequenceNo,
        description: description,
        isDefault: isDefault,
      };
    });
  return {
    testCaseId: testCase?.id,
    testDataRequestList: testDataRows,
  };
};
export const getField = function (tooltipVal, dataHeaders) {
  let f;
  for (let i = 0; i < dataHeaders.length; i++) {
    const dh = dataHeaders[i];
    if (tooltipVal.trim() === dh.headerTooltip) {
      f = dh.field;
      break;
    }
  }
  return f;
};

export const formatGridRefOnActoinForTestdata = (gridApi, testCaseName, setRows, dataHeaders) => {
  const result = [];
  gridApi.forEachNode((node, ind) => {
    if (dataHeaders) {
      Object.keys(node.data).forEach((k) => {
        const fld = getField(k, dataHeaders);
        if (fld && k !== "Name") {
          node.data[fld] = node.data[k];
          delete node.data[k];
        } else {
          k === "Name" && delete node.data[k];
        }
      });
    }
    const data = node?.data;
    if (!data["uniq name"]) {
      data["uniq name"] = `${String(testCaseName)}_TD_${node?.__objectId}`;
    }
    if (!data.isDefault) {
      data.isDefault = "false";
    }
    data.sequenceNo = ind + 1;
    result.push(data);
  });
  setRows(result);
};

// All common reusable functions
export const formatGridRefOnActions = (rowsToDisplay, setStepRows) => {
  const rows =
    Array.isArray(rowsToDisplay) &&
    rowsToDisplay.map((e, i) => {
      const obj = e?.data;
      if (obj.sequenceNo) {
        obj.sequenceNo = i + 1;
      } else {
        obj.sequenceNumber = i + 1;
      }
      return obj;
    });
  setStepRows(rows);
};

export const formatGridRefOnActionsForImportedSteps = (rowsToDisplay, stepRows, setStepRows) => {
  let checkInd = "";
  if (rowsToDisplay?.[0].data?.importedTestStepSequenceNumber) {
    checkInd = rowsToDisplay?.[0].data?.importedTestStepSequenceNumber - 1;
  } else if (rowsToDisplay?.[0].data?.sequenceNumber) {
    const del = Number.parseInt(String(rowsToDisplay?.[0].data?.sequenceNumber).split(".")[0]);
    checkInd = del - 1;
  }
  const rows =
    Array.isArray(rowsToDisplay) &&
    rowsToDisplay.map((e, i) => {
      const obj = e?.data;
      if (obj.sequenceNo) {
        obj.sequenceNo = i + 1;
      } else {
        const num = i * 0.001;
        obj.sequenceNumber = checkInd + 1 + num;
      }
      return obj;
    });

  const newSteprows = stepRows.map((each, ind) => {
    if (ind === checkInd) {
      each.kitapSteps = rows;
    }
    return each;
  });
  setStepRows(newSteprows);
};

export const formatGridRefOnActionsTestCaseList = (rowsToDisplay, setRows) => {
  const rows =
    Array.isArray(rowsToDisplay) &&
    rowsToDisplay.map((e, i) => {
      const obj = e?.data;
      obj.seqnum = i + 1;
      return obj;
    });
  setRows(rows);
};

export const formatGridRefOnActionsTestSuiteList = (rowsToDisplay, setRows) => {
  const rows =
    Array.isArray(rowsToDisplay) &&
    rowsToDisplay.map((e, i) => {
      const obj = e?.data;
      obj.SeqNum = i + 1;
      return obj;
    });
  setRows(rows);
};

export const validateCreateTest = (api) => {
  const obj = {};
  const nameRows = [];
  const actionRows = [];
  const alertTextItems = [];
  api.forEachNode((node) => {
    const value = node.data.name;
    if (validateNameForLengthAndEmptyValues(value)) {
      nameRows.push(node);
    } else {
      if (value in obj) {
        obj[value] = obj[value] + 1;
        nameRows.push(node);
      } else {
        obj[value] = 1;
      }
    }

    if (validateValue(node?.data?.action?.displayName)) {
      actionRows.push(node);
    }
  });

  if (nameRows?.length > 0) {
    setCellBackgroundColor("name", nameRows, api);
    alertTextItems.push("Step name should not be empty.");
    alertTextItems.push("Step name shoud not be duplicate.");
  }
  if (actionRows?.length > 0) {
    setCellBackgroundColor("action", actionRows, api);
    alertTextItems.push("Action cannot be default.");
  }
  if (alertTextItems.length > 0) {
    const alertContent = (
      <>
        <p>Validation Failed, Please check following conditions.</p>{" "}
        <ul>
          {alertTextItems.map((item) => (
            <li key={item}>{item}</li>
          ))}
        </ul>
      </>
    );
    return alertContent;
  }
  return null;
};

export const uniqueTestStepValidation = (api) => {
  const obj = {};
  api?.forEachNode((row) => {
    const key = row.data.name;
    if (key in obj) {
      obj[key] = obj[key] + 1;
      setSingleCellBackgroundColor("name", row, api);
    } else {
      obj[key] = 1;
    }
  });
};

export const setSingleCellBackgroundColor = (column, row, api) => {
  const cell = api.getCellRendererInstances({
    columns: [column],
    rowNodes: [row],
  })[0];
  if (cell) {
    const element = cell.getGui();
    element.style.backgroundColor = "#e95d08";
  }
};

export const setCellBackgroundColor = (column, rows, api) => {
  rows.forEach((row) => {
    const cell = api.getCellRendererInstances({
      columns: [column],
      rowNodes: [row],
    })[0];
    if (cell) {
      const element = cell.getGui();
      element.style.backgroundColor = "#e95d08";
    }
  });
};

export const validateNameForLengthAndEmptyValues = (value) => {
  if (value?.length < 4) {
    return true;
  }
  return value === "" || value === null || value === undefined;
};

export const wrongDataAlert = (value, api) => {
  api?.forEachNode((node) => {
    if (node.data.name === value) {
      setSingleCellBackgroundColor("name", node, api);
    }
  });
};

/* Used to add suffixes for duplicated steps */
export const handleAddSuffixes = (gridRef, jsonData, actionData) => {
  let rowsLength = gridRef.current.api.rowModel.rowsToDisplay.length + 1;
  const allGridRows = gridRef.current.api.rowModel.rowsToDisplay.map((row) => {
    row.data.name = row.data.name.split(" - ")[0];
    return row.data;
  });

  const allRows = [...allGridRows];
  gridRef.current.api.applyTransaction({
    remove: allGridRows,
  });
  const objs = {};
  actionData.forEach((action) => {
    objs[action?.displayName] = action;
  });
  jsonData.forEach((step) => {
    const newRow = {
      sequenceNumber: Number(rowsLength++),
      name: step?.Name.trim(),
      description: step?.Description,
      uielement: step["UI Element"] ? step["UI Element"] : "",
      action: objs[step?.Action],
      screenshot:
        step?.Screenshot &&
        (step?.Screenshot === "Yes" || step?.Screenshot?.toString()?.trim() === "true" || step?.Screenshot === true)
          ? "true"
          : "false",
      consecutiveThreshold: setConsecutiveThreshold(step["Consecutive Threshold"]),
      retryDuration: setRetryDuration(step["Retry Duration"]),
      isDisable: step?.Disable?.toString()?.trim() === "true" || step?.Disable === true ? "true" : "false",
    };
    allRows.push(newRow);
  });

  const duplicateArr = [];
  const frequencyMap = {};
  const updatedRows = allRows.map((row) => {
    if (frequencyMap[row.name]) {
      frequencyMap[row.name]++;
      duplicateArr.push({
        ...row,
        name: `${row.name} - ${frequencyMap[row.name] - 1}`,
      });
      return {
        ...row,
        name: `${row.name} - ${frequencyMap[row.name] - 1}`,
      };
    }
    frequencyMap[row.name] = 1;
    return row;
  });
  gridRef.current.api.applyTransaction({
    add: updatedRows,
  });
  return duplicateArr;
};

const setConsecutiveThreshold = (value) => {
  if (value === undefined || value === null || value === "") {
    return Configurations.minimumConsecutiveThreshold;
  } else if (value < Configurations.minimumConsecutiveThreshold) {
    return Configurations.minimumConsecutiveThreshold;
  } else if (value > Configurations.maximumConsecutiveThreshold) {
    return Configurations.maximumConsecutiveThreshold;
  }
  return value;
};

const setRetryDuration = (value) => {
  if (value === undefined || value === null || value === "") {
    return Configurations.minimumRetryDuration;
  } else if (value < Configurations.minimumRetryDuration) {
    return Configurations.minimumRetryDuration;
  } else if (value > Configurations.maximumRetryDuration) {
    return Configurations.maximumRetryDuration;
  }
  return value;
};

export const validateUploadedFileForSteps = (jsonData, headers, autType, actionData) => {
  if (
    headers.includes("Name") &&
    headers.includes("Description") &&
    headers.includes("UI Element") &&
    headers.includes("Action") &&
    headers.includes("Consecutive Threshold") &&
    headers.includes("Retry Duration") &&
    headers.includes("Disable") &&
    headers.includes("Screenshot")
  ) {
    const FilterActions = actionData.filter((e) => e?.applicableAutTypes.includes(autType));
    const DisplayNames = FilterActions.map((e) => {
      return e.displayName;
    });
    let wrongActionValue = false;
    let wrongNameValue = false;
    let actionAlertText = "";
    let nameAlertText = "";
    jsonData.forEach((e, i) => {
      const counter = i + 1;
      if (e.Action !== undefined && e.Action !== null && e.Action !== "") {
        if (!DisplayNames.includes(e.Action.trim())) {
          actionAlertText += `\n From uploaded steps; step ${counter} Action is invalid`;
          wrongActionValue = true;
        }
      } else {
        actionAlertText += `\n From uploaded steps; step ${counter} Action is invalid`;
        wrongActionValue = true;
      }

      if (e.Name !== undefined && e.Name !== null && e.Name !== "") {
        e.Name = e.Name.trim();
        if (e.Name.length < 4) {
          nameAlertText += `\n From uploaded steps; step ${counter} Step name is less than 4 characters`;
          wrongNameValue = true;
        }
      } else {
        nameAlertText += `\n From uploaded steps; step ${counter} Step name is invalid`;
        wrongNameValue = true;
      }
    });

    return {
      wrongActionValue: wrongActionValue,
      wrongNameValue: wrongNameValue,
      actionAlertText: actionAlertText,
      nameAlertText: nameAlertText,
    };
  }
  return { wrongFileValue: true };
};

export const validateValue = (value) => {
  return value === "Select Action" || value === "" || value === null || value === undefined;
};

export const CountArrayOfValue = (array) => {
  const valueCount = array.reduce((count, obj) => {
    const res = obj.result;
    count[res] = (count[res] || 0) + 1;
    return count;
  }, {});

  return {
    passCount: valueCount?.PASS ? valueCount?.PASS : 0,
    failCount: valueCount?.FAIL ? valueCount?.FAIL : 0,
    skipCount: valueCount?.SKIP ? valueCount?.SKIP : 0,
  };
};

export const isValidHttpUrl = (string) => {
  let url;
  try {
    url = new URL(string);
  } catch (err) {
    // eslint-disable-next-line no-unused-vars
    const _ = err;
    return false;
  }
  return ["http:", "https:", "localhost:"].includes(url.protocol);
};

export const setReportTestStepResult = (data) => {
  const result =
    Array.isArray(data) &&
    data.map((e) => {
      e.startedAt = dateAndTimeFormatter(e?.startedAt, Configurations?.dateAndTimeWithSecondsAndMeridian);
      e.finishedAt = dateAndTimeFormatter(e?.finishedAt, Configurations?.dateAndTimeWithSecondsAndMeridian);
      e.duration = toHoursAndMinutes(e?.duration);
      return e;
    });
  return result;
};

export const setExecutionRequestToTimeFormat = (data) => {
  const result =
    Array.isArray(data) &&
    data.map((e) => {
      e.acceptedAt = dateAndTimeFormatter(e?.acceptedAt, Configurations?.dateAndTimeWithSecondsAndMeridian);
      e.noOfDataSets = e.testDataIdList.length;
      e.noOfBrowsers = e.browsers.length;
      if (e.startedAt) {
        e.startedAt = dateAndTimeFormatter(e?.startedAt, Configurations?.dateAndTimeWithSecondsAndMeridian);
      }
      if (e.abortedAt) {
        e.abortedAt = dateAndTimeFormatter(e?.abortedAt, Configurations?.dateAndTimeWithSecondsAndMeridian);
      }
      return e;
    });
  return result;
};

export const utcEpochToLocalDateAndTime = (utcEpoch) => {
  if (utcEpoch) {
    const utcDateAndTime = new Date(utcEpoch);
    return new Date(utcDateAndTime.toLocaleString());
  }
  return null;
};

export const localDateTimeToUTCEpoch = (date) => {
  if (date) {
    const utcDateTimeString = new Date(date).toUTCString();
    return new Date(utcDateTimeString).getTime();
  }
  return null;
};

export const dateAndTimeFormatter = (value, format) => {
  // let d = new Date(0)
  // d.setUTCSeconds(value).toString()
  if (value) {
    return dayjs(value).format(format);
  }
  return null;
};

export const getDateAtDayStart = (numberOfDays, type) => {
  let duration = "";
  if (type === "add") {
    duration = `+${numberOfDays}`;
    const date = dayjs().add(Number(duration), "day");
    return date.startOf("day")?._d;
  } else if (type === "subtract") {
    duration = `-${numberOfDays}`;
    const date = dayjs(new Date()).add(Number(duration), "day");
    return date.startOf("day")?._d;
  }
  return null;
};

export const getDateAtDayEnd = () => {
  const date = dayjs().endOf("day")?._d;
  return date;
};

export const toHoursAndMinutes = (totalSeconds) => {
  const totalMinutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  let value = "";
  if (Number(hours) > 0) {
    value = `${hours} hr`;
  }

  if (Number(minutes) > 0) {
    value = value.length > 0 ? `${value} ${minutes} min` : `${minutes} min`;
  }

  value = value.length > 0 ? `${value} ${seconds} sec` : `${seconds} sec`;
  return value;
};

export const formatEpochTimeLocale = (epochMilliSeconds) => {
  if (epochMilliSeconds) {
    const dateObject = new Date(epochMilliSeconds);
    const options = {
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "numeric",
      minute: "2-digit",
    };
    const formatter = new Intl.DateTimeFormat([], options); // Use default locale
    console.log("time after format", formatter.format(dateObject));
    return formatter.format(dateObject);
  }
  return "_";
};

export const getTimeDifference = (time1, time2) => {
  if (time1 && time2) {
    const differenceMillis = Math.abs(time2 - time1);
    const differenceSeconds = Math.floor(differenceMillis / 1000);
    const hours = Math.floor(differenceSeconds / 3600);
    const minutes = Math.floor((differenceSeconds % 3600) / 60);
    const seconds = differenceSeconds % 60;

    if (hours > 0) {
      return {
        readableFormat: `${hours}h ${minutes}m`,
      };
    } else if (minutes > 0) {
      return {
        readableFormat: `${minutes}m ${seconds}s`,
      };
    } else if (seconds > 0) {
      return {
        readableFormat: `${seconds}s ${differenceMillis % 1000}ms`,
      };
    }
    return {
      readableFormat: `${differenceMillis}ms`,
    };
  }
  return {
    readableFormat: "_",
  };
};

export const setListOfAutsFormat = (data) => {
  const result =
    Array.isArray(data) &&
    data.map((e) => {
      e.url = e.executableFilePath ? e?.executableFilePath : e?.url;
      return e;
    });
  return result;
};

// Utility function to convert string to array buffer
export const s2ab = (s) => {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i < s.length; i++) {
    view[i] = s.charCodeAt(i) & 0xff;
  }
  return buf;
};

export const createAUTFromResponse = (data) => {
  return {
    id: data?.autId,
    name: data?.autName,
    description: data?.autDescription,
    type: data?.autType,
    url: data?.url,
    executableFilePath: data?.executableFilePath,
  };
};

export const extractTestCasesForTestSuite = (testCases) => {
  if (!testCases) {
    return [];
  }
  return (
    Array.isArray(testCases) &&
    testCases.map((testCase) => {
      return {
        testCaseId: testCase?.id,
        testCaseName: testCase?.name,
        testCaseVersion: testCase?.version,
        noOfTestSteps: testCase?.noOfSteps,
        IsTestDataExist: testCase?.isTestDataExist,
      };
    })
  );
};

export const formatExecutionRowsAndHeadersFromResponse = (
  data,
  allExecutionHeaders,
  setAllExecutionHeaders,
  setAllExecutionRows,
  source,
) => {
  const nonTestCasefields = ["autType", "autName", "noOfTestCases", "noOfTestSuites"];
  const allExeHeads = AllExecutionReportHeaders;
  const exeHeaders = [];
  if (source === "testCase" || source === "testSuite") {
    allExeHeads.forEach((el) => {
      if (!nonTestCasefields.includes(el.field)) {
        exeHeaders.push(el);
      }
    });
    setAllExecutionHeaders(exeHeaders);
    if (data?.executionResponseList) {
      const exeRows = [];
      data.executionResponseList.forEach((ele) => {
        const obj = {};
        obj.id = ele?.id;
        obj.executionId = ele?.name;
        obj.noOfEnvironments = ele?.noOfEnvironments;
        obj.parallelExecution = ele?.parallelExecution;
        obj.requestedAt = dateAndTimeFormatter(ele?.requestedAt, Configurations?.dateAndTimeWithSecondsAndMeridian);
        obj.startedAt = dateAndTimeFormatter(ele?.startedAt, Configurations?.dateAndTimeWithSecondsAndMeridian);
        obj.finishedAt = dateAndTimeFormatter(ele?.finishedAt, Configurations?.dateAndTimeWithSecondsAndMeridian);
        obj.result = ele?.result;
        obj.remarks = ele?.remarks;
        exeRows.push(obj);
      });
      setAllExecutionRows(exeRows);
    }
  } else {
    setAllExecutionHeaders(allExeHeads);
    if (data?.executionResponseList) {
      const allExeRows = [];
      data.executionResponseList.forEach((ele) => {
        const obj = {};
        obj.id = ele?.id;
        obj.executionId = ele?.name;
        obj.autName = ele?.autDetails?.name;
        obj.autType = ele?.autDetails?.type;
        obj.noOfTestSuites = ele?.noOfTestSuites;
        obj.noOfTestCases = ele?.noOfTestCases;
        obj.noOfEnvironments = ele?.noOfEnvironments;
        obj.parallelExecution = ele?.parallelExecution;
        obj.requestedAt = dateAndTimeFormatter(ele?.requestedAt, Configurations?.dateAndTimeWithSecondsAndMeridian);
        obj.startedAt = dateAndTimeFormatter(ele?.startedAt, Configurations?.dateAndTimeWithSecondsAndMeridian);
        obj.finishedAt = dateAndTimeFormatter(ele?.finishedAt, Configurations?.dateAndTimeWithSecondsAndMeridian);
        obj.result = ele?.result;
        obj.remarks = ele?.remarks;
        allExeRows.push(obj);
      });
      setAllExecutionRows(allExeRows);
    }
  }
};

export const formatStepsFromZephyr = (response) => {
  const steps = response.map((step, index) => {
    return {
      sequenceNumber: index + 1,
      name: step?.name,
      description: step?.description,
      uielement: step?.locator,
      action: {
        displayName: getActionKeyDisplayName(step?.action),
      },
      screenshot: convertScreenShot(step?.screenshot),
    };
  });
  return steps;
};

export const exportSteps = (rows, fileName) => {
  const exportableRows = [
    ["Name", "UI Element", "Action", "Screenshot", "Consecutive Threshold", "Retry Duration", "Disable", "Description"],
  ];
  Array.isArray(rows) &&
    rows.forEach((row) => {
      exportableRows.push([
        row.name,
        row.uielement,
        row.action.displayName,
        row.screenshot,
        row.consecutiveThreshold,
        row.retryDuration,
        row.isDisable,
        row.description,
      ]);
    });
  const validatedWorkbook = XLSX.utils.book_new();
  const validatedWorksheet = XLSX.utils.aoa_to_sheet(exportableRows);
  XLSX.utils.book_append_sheet(validatedWorkbook, validatedWorksheet, "Exported Steps");
  const validatedDataFile = XLSX.write(validatedWorkbook, {
    type: "binary",
    bookType: "xlsx",
  });
  saveAs(new Blob([s2ab(validatedDataFile)]), `${fileName}_Exported_Steps.xlsx`);
};

export const exportsReports = (gridRef, reportType, fileName, sheetName) => {
  const columnDefs = gridRef?.current?.columnApi?.columnModel.columnDefs;
  const rowData = gridRef.current.props.rowData;

  // Extract header row from columnDefinitions
  const headerRow = columnDefs.map((column) => column.headerName);

  // Create an array of arrays with header row followed by data rows
  const finalResult = [headerRow, ...rowData.map((value) => columnDefs.map((column) => value[column.field]))];

  const validatedWorkbook = XLSX.utils.book_new();
  const validatedWorksheet = XLSX.utils.aoa_to_sheet(finalResult);
  XLSX.utils.book_append_sheet(validatedWorkbook, validatedWorksheet, sheetName);
  const validatedDataFile = XLSX.write(validatedWorkbook, {
    type: "binary",
    bookType: "xlsx",
  });
  saveAs(new Blob([s2ab(validatedDataFile)]), fileName);
};

export const extractDateFromCompleteDate = (dateString) => {
  const date = new Date(dateString).getDate();
  const month = new Date(dateString).getMonth() + 1;
  const year = new Date(dateString).getFullYear();
  return `${date}-${month}-${year}`;
};

export const formatFeatureFileTestCaseSteps = (steps) => {
  if (Array.isArray(steps)) {
    const allRows = steps.map((e, i) => {
      return {
        sequenceNumber: i + 1,
        name: e?.keyword + e?.text,
        description: e?.text,
        locator: "Xpath or UI Element might be require",
        action: "Select Action",
        screenshot: false,
        StoreVariable: null,
        consecutiveThreshold: Configurations.minimumConsecutiveThreshold,
        retryDuration: Configurations.minimumRetryDuration,
        isDisable: "false",
      };
    });

    const duplicateArr = [];
    const frequencyMap = {};
    const updatedRows = allRows.map((row) => {
      if (frequencyMap[row.name]) {
        frequencyMap[row.name]++;
        duplicateArr.push({
          ...row,
          name: `${row.name} - ${frequencyMap[row.name] - 1}`,
        });
        return {
          ...row,
          name: `${row.name} - ${frequencyMap[row.name] - 1}`,
        };
      }
      frequencyMap[row.name] = 1;
      return row;
    });
    return updatedRows;
  }
  return [];
};

export const ExecutionStatusCodes = {
  PASSED: {
    status: "PASSED",
    color: "#4CAF50",
    hoverColor: "#388E3C",
  },
  FAILED: {
    status: "FAILED",
    color: "#F9594B",
    hoverColor: "#D32F2F",
  },
  OTHERS: {
    status: "OTHERS",
    color: "#61C5F6",
    hoverColor: "#42A5F5",
  },
  ABORTED: {
    status: "ABORTED",
    color: "#61C5F6",
    hoverColor: "#42A5F5",
  },
  SKIPPED: {
    status: "SKIPPED",
    color: "#61C5F6",
    hoverColor: "#42A5F5",
  },
  QUEUED: {
    status: "QUEUED",
    color: "#61C5F6",
    hoverColor: "#42A5F5",
  },
  INPROGRESS: {
    status: "INPROGRESS",
    color: "#61C5F6",
    hoverColor: "#42A5F5",
  },
  DISABLED: {
    status: "DISABLED",
    color: "#61C5F6",
    hoverColor: "#42A5F5",
  },
  INTERRUPTED: {
    status: "INTERRUPTED",
    color: "#61C5F6",
    hoverColor: "#42A5F5",
  },
  DISCARDED: {
    status: "DISCARDED",
    color: "rgba(0, 0, 0, 0.26)",
    hoverColor: "rgba(0, 0, 0, 0.26)",
  },
};
