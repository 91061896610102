import { formatDateTime } from "../../utils/formatDate";

const StartDate = (props) => formatDateTime(props?.data?.startedAt);
const FinishedAt = (props) => formatDateTime(props?.data?.finishedAt);

export const PreviewHeadCell = [
  {
    field: "startedAt",
    headerName: "Started At",
    sortable: true,
    suppressColumnsToolPanel: true,
    filter: true,
    cellRenderer: StartDate,
  },

  {
    field: "finishedAt",
    headerName: "Finished At",
    sortable: true,
    suppressColumnsToolPanel: true,
    filter: true,
    cellRenderer: FinishedAt,
  },

  {
    field: "statusCode",
    headerName: "Status",
    sortable: true,
    suppressColumnsToolPanel: true,
    filter: true,
  },

  // {},
];
