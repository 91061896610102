import { useState, useRef, useCallback } from "react";
import { Box } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import CustomReactAgGrid from "../../CustomComponents/CustomReactAgGrid";
import CustomActionsToolbar from "../../CustomComponents/CustomActionsToolbar";
import CustomDeleteModal from "../../ReusableComponents/CustomDeleteModal";
import AutsGridViewList from "./AutsGridViewList";
import { AutListHeadCells } from "./AutsListModel";
import { autServices } from "../../../Redux/services/aut.services";
import { setAppTypes, setAutList } from "../../../Redux/reducers/autSlice";
import { useDispatch } from "react-redux";
import { showToast } from "../../../Redux/reducers/toastSlice";

const AutList = ({ autTypes, listOfAuts, onClickEditAut }) => {
  const gridRef = useRef();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  const viewType = searchParams.get("view");
  const appType = searchParams.get("appType");

  const [selectedAuts, setSelectedAuts] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const closeDeleteModal = () => setIsDeleteModalOpen(false);

  const handleDeleteAut = async () => {
    setDeleteLoading(true);
    const autsToDelete = selectedAuts.filter((aut) => aut.noOfTestCases === 0);
    const autIdsToDelete = autsToDelete.map((aut) => aut.id);

    try {
      await autServices.deleteAut({ deleteIdList: autIdsToDelete });
      if (gridRef.current?.api) {
        gridRef.current.api.applyTransaction({ remove: autsToDelete });
      }
      const filteredAuts = listOfAuts.filter((aut) => !autIdsToDelete.includes(aut.id));
      dispatch(setAutList(filteredAuts));
      setSelectedAuts([]);
      dispatch(showToast({ message: "Selected AUTs deleted", type: "success" }));

      const updatedAutTypes = autTypes.map((type) => {
        if (type.type === appType) {
          return { ...type, noOfAuts: type.noOfAuts - autIdsToDelete.length };
        }
        return type;
      });
      dispatch(setAppTypes(updatedAutTypes));
    } catch (error) {
      dispatch(showToast({ message: error?.response?.data?.message || error.message, type: "error" }));
    } finally {
      setDeleteLoading(false);
      closeDeleteModal();
    }
  };

  const handleDeleteButtonClick = () => {
    const autsWithTestCases = selectedAuts.filter((aut) => aut.noOfTestCases > 0);

    if (autsWithTestCases.length > 0) {
      const autNames = autsWithTestCases.map((aut) => aut.name).join(", ");
      const message = `AUTs ${autNames} are associated with one or more test cases. Please delete those test cases to proceed.`;
      dispatch(showToast({ message: message, type: "error" }));

      return;
    }

    setIsDeleteModalOpen(true);
  };

  const handleSelectionChange = useCallback(() => {
    const gridApi = gridRef.current?.api;
    if (gridApi) {
      const selectedNodes = gridApi.getSelectedNodes();
      const selectedData = selectedNodes.map((node) => node.data);
      setSelectedAuts(selectedData);
    }
  }, []);

  const handleSelectAllCheckboxChange = (event) => {
    const { checked } = event.target;
    setSelectedAuts(checked ? listOfAuts : []);
  };

  const handleViewReports = () => {
    const autDetails = selectedAuts[0];
    navigate(`/executionsreport?&autType=${autDetails?.type}&autName=${autDetails?.name}&autId=${autDetails.id}`);
  };

  return (
    <>
      <Box sx={{ pb: 6, "& .ag-root-wrapper": { borderRadius: "0px", border: "0px", boxShadow: "none" } }}>
        <CustomActionsToolbar
          selectedAuts={selectedAuts}
          gridRef={gridRef}
          isChecked={selectedAuts.length === listOfAuts.length}
          onClickDelete={handleDeleteButtonClick}
          onClickEditAut={onClickEditAut}
          setSelectedAuts={setSelectedAuts}
          onChangeCheckbox={handleSelectAllCheckboxChange}
          handleViewReports={handleViewReports}
        />
        {viewType !== "grid" ? (
          <CustomReactAgGrid
            gridRef={gridRef}
            columnDefs={AutListHeadCells}
            rowData={listOfAuts || []}
            onSelectionChanged={handleSelectionChange}
          />
        ) : (
          <AutsGridViewList selectedAuts={selectedAuts} setSelectedAuts={setSelectedAuts} listOfAuts={listOfAuts} />
        )}
      </Box>

      {isDeleteModalOpen && (
        <CustomDeleteModal
          loading={deleteLoading}
          disabled={deleteLoading}
          onClickDelete={handleDeleteAut}
          handleClose={closeDeleteModal}
        />
      )}
    </>
  );
};

export default AutList;
