import { useCallback } from "react";
import { Box, Checkbox, Divider, IconButton, Tooltip } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";

import CustomToggleButton from "./CustomButtons/CustomToggleButton";
import { CustomDeleteIcon, CustomEditIcon } from "./Icons/IconButtons";
import { useCheckAccess } from "../../CustomHooks/useCheckAccess";
const CustomActionsToolbar = ({
  selectedAuts,
  isChecked,
  gridRef,
  onClickDelete,
  onClickEditAut,
  onChangeCheckbox,
  handleViewReports,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { checkAccess } = useCheckAccess();
  const viewType = searchParams.get("view");
  const appType = searchParams.get("appType");
  const filter = JSON.parse(searchParams.get("filter")) || false;

  const canEdit = selectedAuts?.length === 1;
  const canDelete = selectedAuts?.length > 0;

  const clearFilters = useCallback(() => {
    gridRef.current?.api.setFilterModel(null);
  }, [gridRef]);

  const handleToggleView = (event, newViewType) => {
    if (newViewType) {
      setSearchParams((prev) => {
        const newState = new URLSearchParams(prev);
        newState.set("view", newViewType);
        return newState;
      });
    }
  };

  return (
    <Box
      className="v-center"
      sx={{
        borderRadius: "12px 12px 0 0",
        backgroundColor: "#fff",
        height: "48px",
        boxSizing: "border-box",
      }}>
      <Box className="center" width={50}>
        {viewType === "grid" ? (
          <Checkbox checked={isChecked} onChange={onChangeCheckbox} size="small" sx={{ p: 0, color: "#ADB5BD" }} />
        ) : (
          <Tooltip title="clear filter" placement="top" arrow>
            <IconButton onClick={clearFilters} size="small" disabled={!filter}>
              <FilterAltOutlinedIcon />
            </IconButton>
          </Tooltip>
        )}
      </Box>

      <Divider orientation="vertical" flexItem />

      <Box className="v-center" px={1}>
        {checkAccess(appType, "Edit") && (
          <CustomEditIcon onClick={() => onClickEditAut(selectedAuts[0])} disabled={!canEdit} height="20" width="18" />
        )}
        {checkAccess(appType, "Preview") && (
          <Tooltip title="Reports" placement="top" arrow>
            <IconButton onClick={handleViewReports} disabled={!canEdit} sx={{ color: "#611EB6", p: 0, pl: "2px" }}>
              <AssessmentOutlinedIcon />
            </IconButton>
          </Tooltip>
        )}
        {checkAccess(appType, "Delete") && (
          <CustomDeleteIcon onClick={onClickDelete} disabled={!canDelete} height="19.5" width="20" />
        )}
      </Box>

      <Box sx={{ display: "none" }} ml="auto" px={2}>
        <CustomToggleButton
          viewType={viewType || "list"}
          onChange={handleToggleView}
          disabled={selectedAuts?.length > 0}
        />
      </Box>
    </Box>
  );
};

export default CustomActionsToolbar;
